import { Grid } from '@mui/material';
import { memo, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useProductCategoryListQuery } from '../../context/productCategory.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ProductCategoryListComponent from './ProductCategory-grid.component';
import ProductCategoryCreateWraper  from '../create/ProductCategoryCreateWraper';

const ProductList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openProductCategoryCreateDrawer, setOpenProductCategoryCreateDrawer] = useState(false);

  // queries
  const { data: productListData, isLoading: productListLoading } = useProductCategoryListQuery({});

  let totalProductCount = productListData?.count ?? 0;
  let isEmpty = totalProductCount === 0;

  // useEffects.error

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.productAndService.productCategory.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.productAndService.productCategory.create`),
          onClick: () => setOpenProductCategoryCreateDrawer(true),
        }}
      />

      <ProductCategoryListComponent setOpenProductCategoryCreateDrawer={setOpenProductCategoryCreateDrawer} />

      <ProductCategoryCreateWraper open={openProductCategoryCreateDrawer} setOpen={setOpenProductCategoryCreateDrawer} />
    </Grid>
  );
});

export default ProductList;
