/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack, Typography } from '@mui/material';
import EmptyState from 'components/emptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import { useFileFolderListQuery, useFileListQuery } from '../context/document.api';
import FilesGridList from './list/FilesGridList';
import { FileTargetType } from 'components/uploadFile/UploadDocument';
import { useState } from 'react';
import FolderGridList from './list/FoldersGridList';
import FilesGridSkeleton from './list/FilesGridSkeleton';
import { FileContentType } from 'corede-common';
import FilesFilter from './list/FilesFilter.component';
import { IFileListInput } from 'corede-common-cocrm';

const AllDocuments = () => {
  const { t } = useTranslation();
  const [selectedFolder, setSelectedFolder] = useState<FileTargetType | undefined>(undefined);
  const [fileListFilter, setFileListFilter] = useState<IFileListInput>({
    filter: {
      folder: selectedFolder,
      types: [FileContentType.document],
      hasEntity: true,
    },
    pagination: {
      page: 1,
      pageSize: 12,
    },
  });

  const {
    data: fileListData,
    isLoading: fileListLoading,
    isFetching: fileListFetching,
  } = useFileListQuery({
    input: fileListFilter,
  });

  const {
    data: folderListData,
    isLoading: folderListLoading,
    isFetching: folderListFetching,
    error: folderListError,
  } = useFileFolderListQuery({});

  const isEmpty = fileListData && fileListData?.count === 0;

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={5} pl={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{ fontSize: { xs: '20px', md: '24px' }, fontWeight: 'bold' }}>
          {t('disk.document.document.allDocuments')}
        </Typography>

        {/* <Box
            visibility={
              selectedTab === 'Own' || selectedTab === 'Shared with me' ? 'visible' : 'hidden'
            }
          >
            <OutlinedButton
              aria-controls={openMenu ? 'document-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              title={t('upload')}
              onClick={toggleMenu}
              leftIcon={<CloudUpload sx={{ mr: 1, opacity: 0.7 }} />}
            />
          </Box> */}
      </Stack>
      {/* <Menu
          id="document-menu"
          open={openMenu}
          anchorEl={anchorElMenu}
          onClose={() => toggleMenu()}
          MenuListProps={{
            'aria-labelledby': 'document-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleSelectTargetType(FileTargetType.lead)}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('crm.leadLabel')}
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem onClick={() => handleSelectTargetType(FileTargetType.customer)}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('crm.customerLabel')}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() => handleSelectTargetType(FileTargetType.invoice)}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('crm.invoiceLabel')}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() => handleSelectTargetType(FileTargetType.task)}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('crm.taskLabel')}
              </Typography>
            </Stack>
          </MenuItem>
        </Menu> */}
      <FilesFilter fileListFilter={fileListFilter} setFileListFilter={setFileListFilter} />
      {selectedFolder ? (
        <Grid item xs={12}>
          {fileListLoading || fileListFetching ? (
            <FilesGridSkeleton />
          ) : (
            <FilesGridList
              fileData={fileListData}
              title={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              selectedFolder={selectedFolder}
            />
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          {folderListData ? (
            !isEmpty ? (
              <FolderGridList folderData={folderListData} setSelectedFolder={setSelectedFolder} />
            ) : null
          ) : (
            <FilesGridSkeleton />
          )}
          <Box height={20} />
          {fileListData ? (
            !isEmpty ? (
              <FilesGridList
                fileData={fileListData}
                setSelectedFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
              />
            ) : (
              <Grid item xs={12}>
                <EmptyState
                  content1={t('disk.document.document.emptyStateContent1')}
                  content2={t('disk.document.document.emptyStateContent2')}
                />
              </Grid>
            )
          ) : (
            <FilesGridSkeleton />
          )}
        </Grid>
      )}

      {/* <UploadDocumentComponent
        entityId={''}
        targetType={targetType}
        open={openDocumentCreateDialog}
        setOpen={setOpenDocumentCreateDialog}
        useDocumentCreateManyMutation={
          targetType === FileTargetType.lead
            ? useLeadDocumentCreateManyMutation
            : targetType === FileTargetType.customer
              ? useCustomerDocumentCreateManyMutation
              : targetType === FileTargetType.invoice
                ? useInvoiceDocumentCreateManyMutation
                : targetType === FileTargetType.task
                  ? useTaskDocumentCreateManyMutation
                  : useTaskDocumentCreateManyMutation
        }
        useDocumentAddManyMutation={
          targetType === FileTargetType.lead
            ? useLeadDocumentAddManyMutation
            : targetType === FileTargetType.customer
              ? useCustomerDocumentAddManyMutation
              : targetType === FileTargetType.invoice
                ? useInvoiceDocumentAddManyMutation
                : targetType === FileTargetType.task
                  ? useTaskDocumentAddManyMutation
                  : useLeadDocumentAddManyMutation
        }
      /> */}
    </Stack>
  );
};

export default AllDocuments;
