import { Autocomplete, Grid, InputLabel, TextField } from '@mui/material';
import ActionDialog from 'components/dialog/ActionDialog';
import { IUser } from 'corede-common-cocrm';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export const AddAssigneeDialog = (props: {
  openAssigneeAddDialog: boolean;
  setOpenAssigneeAddDialog: Dispatch<React.SetStateAction<boolean>>;
  filteredUserList: IUser[];
  selectedAssignee: IUser | undefined;
  setSelectedAssignee: Dispatch<SetStateAction<IUser | undefined>>;
  taskUpdateLoading: boolean;
  handleAddAssignee: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ActionDialog
      open={props.openAssigneeAddDialog}
      onClose={() => props.setOpenAssigneeAddDialog(false)}
      title={t('crm.addAssignee')}
      width={500}
      buttonTitle={t('crm.addAssignee')}
      handleClick={() => {
        props.handleAddAssignee();
      }}
      loading={props.taskUpdateLoading}
      disabled={props.taskUpdateLoading || !props.selectedAssignee}
    >
      <Grid item xs={12} sx={{ mt: 1 }}>
        <InputLabel sx={{ ml: 1, mb: 0.5 }}>{t('crm.organizationalChart.user.users')}</InputLabel>
        <Autocomplete
          noOptionsText={t('noOptionsText')}
          size="small"
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          fullWidth
          getOptionLabel={(option) => option?.name + ' ' + option?.surname || ''}
          options={props.filteredUserList || []}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, value) => {
            props.setSelectedAssignee(value as IUser);
          }}
        />
      </Grid>
    </ActionDialog>
  );
};
