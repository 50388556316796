/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import DetailBox from 'components/box/DetailBox';
import { ReactNode } from 'react';

interface IDefaultTabContainer {
  children: ReactNode;
  title: string;
  rightButton?: {
    title: string;
    onClick: () => void;
    icon?: React.ReactNode;
  };
}

export const DefaultTabContainer = (props: IDefaultTabContainer) => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Grid container mt={downSm ? 0 : 2} gap={2} mb={2}>
      <Grid item xs={12}>
        <DetailBox title={props.title} rightButton={props.rightButton} type="drawer">
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" height={'100%'} mb={2}>
              {props.children}
            </Stack>
          </Grid>
        </DetailBox>
      </Grid>
    </Grid>
  );
};
