const locale = {
  supports: 'Destek Talepleri',
  support: 'Destek Talebi',
  supportConversions: 'Destek Talepleri Dönüşümleri',
  title: 'Destek Talepleri',
  create: 'Destek Talebi Oluştur',
  emptyState1: 'Henüz destek talebi yok.',
  emptyState2:
    'Müşteri taleplerini yönetin ve destek isteklerini burada takip edin. Zamanında yanıtlar ve mükemmel hizmet için bu alanı kullanabilirsiniz.',
};

export default locale;
