/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import { getCurrentLanguage } from 'localization';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

export interface IContactItem {
  id?: string;
  _id?: string;
  name?: string;
  note?: string;
  contactedDate?: string;
}

export const LeadContactDetailDialog = memo(
  (prop: { open: boolean; item: IContactItem | undefined; onClose: () => void }) => {
    // general
    const { t } = useTranslation();

    // queries

    // constants

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.lead.lead.contactDetail')}
        buttonTitle={t('add')}
        buttonColor="secondary"
        width={500}
        buttonCancelTitle={t('close')}
      >
        <Grid item xs={12}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item display="flex" flexDirection="column" gap={1}>
              {['Contacted Name', 'Note', 'Contacted Date'].map((label, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={'14px'} fontWeight={600}>
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>

            <Grid item display="flex" flexDirection="column" gap={1}>
              {[prop.item?.name, prop.item?.note, prop.item?.contactedDate].map((value, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={'14px'} fontWeight={400}>
                    : {value}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ActionDialog>
    );
  },
);
