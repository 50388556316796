

import { SalesDomainLayout } from "../layouts";
import { InvoicesRouter } from "../subdomains/invoice/routes/InvoicesRouter";
import { PaymentsRouter } from "../subdomains/payment/routes/PaymentsRouter";
import { ReturnsRouter } from "../subdomains/return/routes/ReturnsRouter";
import { salesDomainRoutes } from "./sales.base.route";
import { Route, Routes } from "react-router-dom";

export const SalesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesDomainLayout />}>
        <Route index element={<InvoicesRouter />} />
        <Route
          path={`${salesDomainRoutes.subdomains.invoice.base}/*`}
          element={<InvoicesRouter />}
        />
        <Route
          path={`${salesDomainRoutes.subdomains.payment.base}/*`}
          element={<PaymentsRouter />}
        />
        <Route
          path={`${salesDomainRoutes.subdomains.return.base}/*`}
          element={<ReturnsRouter />}
        />
      </Route>
    </Routes>
  );
};
