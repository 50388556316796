/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useLeadSourceDeleteMutation } from "../context/leadSource.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const LeadSourceDeleteDialog = memo(
  (props: {
    open: boolean;
    itemId: string;
    onClose: () => void;
    setItem: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      leadSourceDelete,
      {
        data: leadSourceDeleteData,
        isLoading: leadSourceDeleteIsLoading,
        error: leadSourceDeleteError,
      },
    ] = useLeadSourceDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.itemId}
        setItem={props.setItem}
        deleteMutation={leadSourceDelete}
        deleteMutationData={{
          data: leadSourceDeleteData,
          isLoading: leadSourceDeleteIsLoading,
          error: leadSourceDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.lead.leadSource.delete"),
          successMessage: t("crm.lead.leadSource.deletedSuccessfully"),
          content: t("crm.lead.leadSource.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
