/* eslint-disable react-hooks/exhaustive-deps */
import { Delete, AddCircle } from '@mui/icons-material';
import { Grid, InputLabel, TextField, Autocomplete, Box, Typography, Stack } from '@mui/material';
import { useDepartmentListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { useLeadListQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useCustomerListQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { useEstimateListQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceListQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { OutlinedButton } from 'components';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { DefaultDatePickerInput } from 'components/form/DefaultDatePickerInput';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { DefaultSwitchInput } from 'components/form/DefaultSwitchInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { Currency, IGraphqlVariables } from 'corede-common';
import {
  ITaskCreateInput,
  ITaskUpdateInput,
  TaskPriority,
  TaskTargetType,
} from 'corede-common-cocrm';
import { convertEnums, generateAutocompleteTranslatedOptions } from 'localization';
import React, { useEffect, useState } from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CombinedErrorsType =
  | FieldErrors<IGraphqlVariables<ITaskCreateInput, undefined>>
  | FieldErrors<IGraphqlVariables<ITaskUpdateInput, undefined>>;

interface ITaskForm {
  // watch: CombinedWatchType
  setValue: UseFormSetValue<IGraphqlVariables<any>>;
  watch: UseFormWatch<IGraphqlVariables<any>>;
  // control: CombinedControlType
  control: Control<IGraphqlVariables<any>>;
  type: 'create' | 'update';
  touchedFields: Partial<Readonly<any>>;
  errors: CombinedErrorsType;
  checklistInitial?: {
    description: string;
    assigneeId: string;
    isChecked: boolean;
  }[];
  getValues: any;
  targetEntityRef: TaskTargetType | undefined;
}

export const UpsertTaskComponent = (props: ITaskForm) => {
  const { t } = useTranslation();
  const [targetOptions, setTargetOptions] = useState([]);
  const [checklist, setChecklist] = useState<
    { description: string; assigneeId: string; isChecked: boolean }[]
  >([]);

  // TODO for skip query
  const { data: userListData, isLoading: userListLoading } = useUserListQuery({});
  const { data: userListForAssigneeData, isLoading: userListForAssigneeLoading } = useUserListQuery(
    {
      input: {
        filter: {
          departmentId: props.watch('input.departmentId'),
        },
      },
    },
    { skip: !props.watch('input.departmentId') },
  );
  const { data: leadListData, isLoading: leadListLoading } = useLeadListQuery({});
  const { data: customerListData, isLoading: customerListLoading } = useCustomerListQuery({});
  const { data: proposalListData, isLoading: proposalListLoading } = useProposalListQuery({});
  const { data: estimateListData, isLoading: estimateListLoading } = useEstimateListQuery({});
  const { data: invoiceListData, isLoading: invoiceListLoading } = useInvoiceListQuery({});

  const { data: departmentListData, isLoading: departmentListLoading } = useDepartmentListQuery({});

  const addChecklistItem = () => {
    setChecklist((prev) => [...prev, { description: '', assigneeId: '', isChecked: false }]);
  };

  const updateChecklistItem = (index: number, field: string, value: any) => {
    setChecklist((prev) =>
      prev?.map((item, i) => (i === index ? { ...item, [field]: value } : item)),
    );
  };

  const removeChecklistItem = (index: number) => {
    setChecklist((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (checklist) {
      props.setValue('input.checklist', checklist);
    }
  }, [checklist]);

  useEffect(() => {
    if (props.checklistInitial) {
      setChecklist(props.checklistInitial);
    }
  }, [props.checklistInitial]);

  useEffect(() => {
    switch (props.watch('input.relatedEntityRef')) {
      case TaskTargetType.User:
        setTargetOptions(
          userListData?.data?.map((user) => ({
            _id: user._id,
            name: user.name,
          })) ?? ([] as any),
        );
        break;
      case TaskTargetType.Lead:
        setTargetOptions(
          leadListData?.data?.map((lead) => ({
            _id: lead._id,
            name: lead.fullName,
          })) ?? ([] as any),
        );
        break;
      case TaskTargetType.Customer:
        setTargetOptions(
          customerListData?.data?.map((customer) => ({
            _id: customer._id,
            name: customer.name,
          })) ?? ([] as any),
        );
        break;
      case TaskTargetType.Proposal:
        setTargetOptions(
          proposalListData?.data?.map((proposal) => ({
            _id: proposal._id,
            name: proposal.prefix,
          })) ?? ([] as any),
        );
        break;
      case TaskTargetType.Estimate:
        setTargetOptions(
          estimateListData?.data?.map((estimate) => ({
            _id: estimate._id,
            name: estimate.prefix,
          })) ?? ([] as any),
        );
        break;
      case TaskTargetType.Invoice:
        setTargetOptions(
          invoiceListData?.data?.map((invoice) => ({
            _id: invoice._id,
            name: invoice.invoiceId,
          })) ?? ([] as any),
        );
        break;
      default:
        setTargetOptions([]);
    }
  }, [
    props.targetEntityRef,
    props.watch('input.relatedEntityRef'),
    userListData,
    leadListData,
    customerListData,
    proposalListData,
    estimateListData,
    invoiceListData,
  ]);

  return (
    <Grid container spacing={2}>
      {props.type === 'create' && (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: !!props.targetEntityRef ? 'none' : 'block',
          }}
        >
          <DefaultEnumAutocompleteInput
            inputName="input.relatedEntityRef"
            control={props.control as Control<IGraphqlVariables<any, any>>}
            label={t('crm.targetType')}
            options={generateAutocompleteTranslatedOptions(TaskTargetType)}
            enum={TaskTargetType}
            disableClearable
            isRequired
          />
        </Grid>
      )}
      {props.type === 'create' && (
         <Grid
         item
         xs={12}
         sm={6}
         sx={{
           display: !!props.targetEntityRef ? 'none' : 'block',
         }}
       >
          <DefaultObjectAutocompleteInput
            key={props.watch('input.relatedEntityRef')}
            inputName="input.relatedEntityId"
            control={props.control}
            options={targetOptions}
            label={t('crm.target')}
            isLoading={
              userListLoading ||
              leadListLoading ||
              customerListLoading ||
              proposalListLoading ||
              estimateListLoading ||
              invoiceListLoading
            }
            errors={props.errors}
            touchedFields={props.touchedFields}
            disableAddNewOption
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.subject"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          label={t('crm.task.task.subject')}
          errors={props.errors}
          touchedFields={props.touchedFields}
          isRequired
          isFirstLetterCapitalize
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.description"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          label={t('crm.task.task.description')}
          errors={props.errors}
          touchedFields={props.touchedFields}
          isFirstLetterCapitalize
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        <DefaultDatePickerInput
          inputName="input.startDate"
          control={props.control}
          label={t('crm.task.task.startDate')}
          errors={props.errors}
          isRequired
        />
      </Grid>
      <Grid item xs={6}>
        <DefaultDatePickerInput
          inputName="input.dueDate"
          control={props.control}
          label={t('crm.task.task.dueDate')}
          errors={props.errors}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultObjectAutocompleteInput
          inputName="input.departmentId"
          control={props.control}
          options={departmentListData?.data || []}
          label={t('crm.task.task.department')}
          errors={props.errors}
          touchedFields={props.touchedFields}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultArrayObjectAutocompleteInput
          inputName="input.assigneeIds"
          control={props.control}
          label={t('crm.task.task.assigneeIds')}
          options={userListForAssigneeData?.data || []}
          loading={userListForAssigneeLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultArrayObjectAutocompleteInput
          inputName="input.followerIds"
          control={props.control}
          label={t('crm.task.task.followerIds')}
          options={userListData?.data || []}
          loading={userListLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('crm.task.task.checklist')}
        </InputLabel>
        <Box sx={{ mt: 1, p: 1, border: '1px solid #ccc', borderRadius: '16px' }}>
          <Grid container spacing={2}>
            {checklist?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={5}>
                  <InputLabel
                    htmlFor={`description-${index}`}
                    sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                  >
                    {t('crm.task.task.description')} {index + 1}
                  </InputLabel>
                  <TextField
                    size="small"
                    id={`description-${index}`}
                    name={`description-${index}`}
                    fullWidth
                    value={item.description}
                    onChange={(e) => updateChecklistItem(index, 'description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel
                    htmlFor={`assignee-${index}`}
                    sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                  >
                    {t('crm.task.task.assignee')} {index + 1}
                  </InputLabel>
                  <Autocomplete
                    noOptionsText={t('noOptionsText')}
                    size="small"
                    options={
                      userListData?.data?.filter((user) =>
                        props.watch('input.assigneeIds')?.includes(user._id),
                      ) ?? []
                    }
                    id={`assignee-${index}`}
                    getOptionLabel={(option) => option.name + ' ' + option.surname}
                    value={userListData?.data?.find((user) => user._id === item.assigneeId) || null}
                    onChange={(e, value) =>
                      updateChecklistItem(index, 'assigneeId', value?._id || null)
                    }
                    renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ visibility: 'hidden', ml: 1, mb: 0.5, fontSize: 'small' }}>
                    .
                  </Typography>
                  <OutlinedButton
                    onClick={() => removeChecklistItem(index)}
                    leftIcon={<Delete sx={{ fontSize: 'medium', color: 'error.main' }} />}
                  />
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Stack sx={{ width: '100%' }}>
                <OutlinedButton
                  title={t('crm.task.task.addChecklistItem')}
                  onClick={addChecklistItem}
                  leftIcon={<AddCircle sx={{ mr: 1 }} />}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <DefaultEnumAutocompleteInput
          inputName="input.priority"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          options={generateAutocompleteTranslatedOptions(TaskPriority)}
          enum={TaskPriority}
          label={t('crm.task.task.priority')}
        />
      </Grid>

      <Grid item xs={6}>
        <DefaultArrayAutocompleteInput
          inputName="input.tags"
          control={props.control}
          options={[]}
          label={t('crm.task.task.tags')}
          errors={props.errors}
          touchedFields={props.touchedFields}
        />
      </Grid>
      <Grid item xs={4}>
        <DefaultTextFieldInput
          inputName="input.hourlyRate"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          errors={props.errors}
          type="number"
          touchedFields={props.touchedFields}
          label={t('crm.task.task.hourlyRate')}
        />
      </Grid>
      <Grid item xs={4}>
        <DefaultEnumAutocompleteInput
          inputName="input.hourlyRateCurrency"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          options={generateAutocompleteTranslatedOptions(Currency)}
          enum={Currency}
          label={t('crm.task.task.hourlyRateCurrency')}
        />
      </Grid>
      <Grid item xs={4}>
        <DefaultSwitchInput
          inputName="input.isBillable"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          description={t('crm.task.task.isBillable')}
        />
      </Grid>
      <Grid item xs={4}>
        <DefaultSwitchInput
          inputName="input.isRecurrent"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          description={t('crm.task.task.isRecurrent')}
        />
      </Grid>
      {props.watch('input.isRecurrent') && (
        <Grid item xs={4}>
          <DefaultTextFieldInput
            inputName="input.repeatDays"
            control={props.control as Control<IGraphqlVariables<any, any>>}
            errors={props.errors}
            type="number"
            touchedFields={props.touchedFields}
            label={t('crm.task.task.repeatDays')}
          />
        </Grid>
      )}
      {props.watch('input.isRecurrent') && (
        <Grid item xs={4}>
          <DefaultTextFieldInput
            inputName="input.totalCycle"
            control={props.control as Control<IGraphqlVariables<any, any>>}
            errors={props.errors}
            type="number"
            touchedFields={props.touchedFields}
            label={t('crm.task.task.totalCycle')}
          />
        </Grid>
      )}
    </Grid>
  );
};
