/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { generateAutocompleteTranslatedOptions, getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

import { UseFormReturn } from 'react-hook-form';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { TicketCategory, TicketPriority, TicketTargetType } from 'corede-common-cocrm';
import { useEstimateListQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceListQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultDateTimePickerInput } from 'components/form/DefaultDateTimePickerInput';
import moment from 'moment';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import { useTaskListQuery } from 'apps/crm/domains/11-task/subdomains/task';
import { useDepartmentListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';

export interface IUpsertTicketComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
  targetId?: string;
  targetType?: TicketTargetType;
}

export const UpsertTicketComponent = (props: IUpsertTicketComponentProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: departmentListData, isLoading: departmentListLoading } = useDepartmentListQuery({});

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListQuery({
    input: {
      filter: {
        departmentId: props.useForm.watch('input.departmentId')
          ? props.useForm.watch('input.departmentId') !== ''
            ? props.useForm.watch('input.departmentId')
            : undefined
          : undefined,
      },
    },
  });

  const {
    data: estimateListData,
    isLoading: estimateListLoading,
    error: estimateListError,
  } = useEstimateListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Estimate),
    },
  );

  const {
    data: proposalListData,
    isLoading: proposalListLoading,
    error: proposalListError,
  } = useProposalListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Proposal),
    },
  );

  const {
    data: invoiceListData,
    isLoading: invoiceListLoading,
    error: invoiceListError,
  } = useInvoiceListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Invoice),
    },
  );

  const {
    data: taskListData,
    isLoading: taskListLoading,
    error: taskListError,
  } = useTaskListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Task),
    },
  );

  //TODO project eklenecek.

  const estimateListForOptions: { name: string; _id: string }[] =
    estimateListData?.data.map((estimate) => ({
      name: estimate.prospectName,
      _id: estimate._id,
    })) ?? [];

  const proposalListForOptions: { name: string; _id: string }[] =
    proposalListData?.data.map((proposal) => ({
      name: proposal.prospectName,
      _id: proposal._id,
    })) ?? [];

  const invoiceListForOptions: { name: string; _id: string }[] =
    invoiceListData?.data.map((invoice) => ({
      name: invoice.invoiceId.toString(),
      _id: invoice._id,
    })) ?? [];

  const taskListForOptions: { name: string; _id: string }[] =
    taskListData?.data.map((task) => ({
      name: task.subject,
      _id: task._id,
    })) ?? [];

  // useEffects.success

  // useEffects.error

  // useEffects init

  useEffect(() => {
    DefaultErrorHandlerUseEffect(userListError as IBaseError, currentLanguage);
  }, [userListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(estimateListError as IBaseError, currentLanguage);
  }, [estimateListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalListError as IBaseError, currentLanguage);
  }, [proposalListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(invoiceListError as IBaseError, currentLanguage);
  }, [invoiceListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskListError as IBaseError, currentLanguage);
  }, [taskListError]);

  return (
    <Grid container spacing={2} sx={{ mb: 2, p: 2 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: !!props.targetType ? 'none' : 'block',
        }}
      >
        <DefaultEnumAutocompleteInput
          inputName="input.targetType"
          label={t('crm.targetType')}
          options={generateAutocompleteTranslatedOptions(TicketTargetType)}
          enum={TicketTargetType}
          control={props.useForm.control}
          disableClearable
          isRequired
        />
      </Grid>

      {(props.useForm.watch('input.targetType') !== TicketTargetType.none || props.targetId) && (
        <Grid item xs={12}>
          <DefaultObjectAutocompleteInput
            key={props.useForm.watch('input.targetId')}
            inputName="input.targetId"
            control={props.useForm.control}
            options={
              props.useForm.watch('input.targetType') === TicketTargetType.Estimate
                ? estimateListForOptions
                : props.useForm.watch('input.targetType') === TicketTargetType.Proposal
                  ? proposalListForOptions
                  : props.useForm.watch('input.targetType') === TicketTargetType.Invoice
                    ? invoiceListForOptions
                    : props.useForm.watch('input.targetType') === TicketTargetType.Task
                      ? taskListForOptions
                      : []
            }
            label={t('crm.target')}
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
            isLoading={
              props.useForm.watch('input.targetType') === TicketTargetType.Estimate
                ? estimateListLoading
                : props.useForm.watch('input.targetType') === TicketTargetType.Proposal
                  ? proposalListLoading
                  : props.useForm.watch('input.targetType') === TicketTargetType.Invoice
                    ? invoiceListLoading
                    : props.useForm.watch('input.targetType') === TicketTargetType.Task
                      ? taskListLoading
                      : false
            }
            disableClearable
            disableAddNewOption
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.subject"
          control={props.useForm.control}
          label={t('crm.subject')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.ticketBody"
          control={props.useForm.control}
          label={t('crm.description')}
          isRequired
          multiline
          rows={3}
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultEnumAutocompleteInput
          inputName="input.priority"
          label={t('crm.priority')}
          isRequired
          options={generateAutocompleteTranslatedOptions(TicketPriority)}
          enum={TicketPriority}
          control={props.useForm.control}
          disableClearable
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.departmentId"
          label={t('crm.department')}
          options={departmentListData?.data ?? []}
          touchedFields={props.useForm.formState.touchedFields}
          disableAddNewOption
          isLoading={departmentListLoading}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultTextFieldInput
          inputName="input.contactName"
          control={props.useForm.control}
          label={t('crm.contactName')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultTextFieldInput
          inputName="input.contactEmail"
          control={props.useForm.control}
          label={t('crm.contactEmail')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>


      <Grid item xs={12}>
        <DefaultArrayObjectAutocompleteInput
          inputName="input.assigneeIds"
          control={props.useForm.control}
          label={t('crm.assignees')}
          options={userListData?.data ?? []}
          loading={userListLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultEnumAutocompleteInput
          inputName="input.category"
          label={t('crm.category')}
          isRequired
          options={generateAutocompleteTranslatedOptions(TicketCategory)}
          enum={TicketCategory}
          control={props.useForm.control}
          disableClearable
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultArrayAutocompleteInput
          inputName="input.tags"
          control={props.useForm.control}
          label={t('crm.tags')}
          options={[]}
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
          placeholder={t('crm.tagsPlaceholder')}
        />
      </Grid>
    </Grid>
  );
};
