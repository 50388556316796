import { Grid } from "@mui/material";
import { memo, useState } from "react";
import { Add, FileUpload } from "@mui/icons-material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import DepartmentListComponent from "./DepartmentList-grid.component";
import { useNavigate } from "react-router-dom";
import { DepartmentCreateDialog } from "../create/DepartmentCreateDialog";
import { useDepartmentListQuery } from "../../context/department.api";

const DepartmentList = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDepartmentCreateDrawer, setOpenDepartmentCreateDrawer] = useState(false);

  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);

  // queries
  const { data: departmentListData, isLoading: departmentListLoading } =
    useDepartmentListQuery({});

  let totalDepartmentCount = departmentListData?.count ?? 0;
  let isEmpty = totalDepartmentCount === 0;

  // useEffects.error 

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.organizationalChart.department.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.organizationalChart.department.create`),
          onClick: () => setOpenDepartmentCreateDrawer(true),
        }}
      />


      <DepartmentListComponent
        setOpenDepartmentCreateDrawer={setOpenDepartmentCreateDrawer}
      />


      <DepartmentCreateDialog
        open={openDepartmentCreateDrawer}
        setOpen={setOpenDepartmentCreateDrawer}
        setOpenRoles={setOpenRolesDrawer}
      />
    </Grid>
  );
});

export default DepartmentList;
