/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card, Grid, useMediaQuery } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
  UserConfirmationStatus,
} from 'corede-common';
import { IUser, IUserDetailResult, IUserListInput } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useUserListQuery, useUserUpdateMutation } from '../../context/user.api';
import { UserInfoCellComponent } from './UserList-grid-userInfoCell.component';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import {
  representArrayOfObjectAsString,
  representString,
  representStringDateAsString,
  representBooleanAsString,
  representDateAsString,
} from 'utils/representationHelper';
import UserUpdateDialogWrapper from '../update/UserUpdateDialog.wrapper';
import { DepartmentCreateDialog } from '../../../department/pages';
import { RolesDrawer } from '../../../permission';
import { UserDeleteDialog } from '../delete/UserDeleteDialog';
import UserDetailWrapper from '../detailDrawer/UserDetailWrapper';

export interface IUserListGridComponent {
  // user

  setOpenUserCreateDrawer?: (value: boolean) => void | undefined;
}

const UserListGridComponent = memo((props: IUserListGridComponent) => {
  // init
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedUser, setSelectedUser] = useState<TListGridSelectedEntity<IUser>>();
  const [openUserDetailDrawer, setOpenUserDetailDrawer] = useState(false);
  const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
  const [openUserUpdateDrawer, setOpenUserUpdateDrawer] = useState(false);
  const [openRoles, setOpenRoles] = useState(false);
  const [openDepartments, setOpenDepartments] = useState(false);

  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [userListFilter, setUserListFilter] = useState<IGraphqlVariables<IUserListInput>>({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = userListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = userListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: userListData,
    isLoading: userListLoading,
    isFetching: userListFetching,
    error: userListError,
    refetch,
  } = useUserListQuery(userListFilter);

  // used for direct update in the list rows
  const [userUpdate, { isLoading: userUpdateLoading, error: userUpdateError }] =
    useUserUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setUserListLoading && props.setUserListLoading(userListLoading);
  // }, [userListLoading]);
  useEffect(() => {
    refetch();
  }, [userListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(userListError as IBaseError, currentLanguage);
  }, [userListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(userUpdateError as IBaseError, currentLanguage);
  }, [userUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(userListFilter?.input?.filter);
  const isEmptyState =
    userListData?.data?.length === 0 && !userListLoading && !userListFetching && isFilterEmpty;

  // custom views

  return (
    <Grid item xs={12} sx={{ height: '100%' }}>
      <DefaultListGridComponent
        selectedEntity={selectedUser}
        setSelectedEntity={setSelectedUser}
        listFilter={userListFilter}
        setListFilter={setUserListFilter}
        listData={userListData}
        pendingNavigation={pendingNavigation}
        setPendingNavigation={setPendingNavigation}
        listFetching
        listLoading
      >
        <Card
          sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: 'calc(100dvh - 320px)' }}
        >
          {userListData ? (
            !isEmptyState ? (
              <BaseDataGrid
                rows={
                  userListData?.data?.map((user, index) => ({
                    id:
                      ((userListFilter.input?.pagination?.page || 1) - 1) *
                        (userListFilter.input?.pagination?.pageSize || 0) +
                      index +
                      1,
                    _id: user._id,
                    userInfo: {
                      fullName: user.name + ' ' + user.surname,
                      email: user.email,
                      avatar: user.profileImage?.thumbnailPublicUrl,
                    },
                    name: user.name,
                    surname: user.surname,
                    roles: representArrayOfObjectAsString({ dataList: user.roles }),
                    email: user.email,
                    status: user.status,
                    phone: representString(user.phoneNumber),
                    lastLoginDate: representStringDateAsString((user as any).lastLoginDate),
                    emailVerified: representBooleanAsString({
                      data: user.emailVerified,
                      trueValue: t('crm.organizationalChart.user.yes'),
                      falseValue: t('crm.organizationalChart.user.no'),
                    }),
                    country: representString(user.country),
                    city: representString(user.city),
                    createdAt: representDateAsString(user.createdAt),
                  })) || []
                }
                columns={[
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'id',
                        disableColumnMenu: true,
                        filterable: false,
                        width: 40,
                        align: 'center',
                      },
                      headerConfig: {
                        name: '#',
                        align: 'center',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.any,
                      column: {
                        field: 'userInfo',
                        width: 180,
                      },
                      headerConfig: {
                        icon: 'radix-icons:person',
                        name: `${t('crm.name')}`,
                      },
                      overrideCell: UserInfoCellComponent(),
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'name',
                        editable: true,
                        width: 100,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.name'),
                        icon: 'mdi:account',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'surname',
                        editable: true,
                        width: 100,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.surname'),
                        icon: 'mdi:account-outline',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'roles',
                        editable: false, // TODO: should be editable
                        sortable: false,
                        width: 150,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.roles'),
                        icon: 'mdi:shield-account',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'email',
                        filterable: true,
                        width: 160,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.emailAddress'),
                        icon: 'mdi:email',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.enum,
                      column: {
                        field: 'status',
                        editable: false,
                        filterable: true,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.status'),
                        icon: 'mdi:checkbox-marked-circle-outline',
                      },
                      filterConfig: {
                        customFieldName: `statuses`,
                        isArrayFilter: true,
                      },
                    },
                    data: UserConfirmationStatus,
                    metaData: {
                      enumColor: {
                        [UserConfirmationStatus.active]: 'info',
                        [UserConfirmationStatus.pending]: 'warning',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'emailVerified',
                        filterable: true,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.emailVerified'),
                        icon: 'mdi:check-circle',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'phone',
                        filterable: true,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.phone'),
                        icon: 'mdi:phone',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'country',
                        filterable: true,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.country'),
                        icon: 'mdi:earth',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'city',
                        filterable: true,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.city'),
                        icon: 'mdi:city',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'lastLoginDate',
                        filterable: false,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.lastLoginDate'),
                        icon: 'mdi:calendar-clock',
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: 'createdAt',
                        filterable: false,
                        width: 120,
                      },
                      headerConfig: {
                        name: t('crm.organizationalChart.user.createdAt'),
                        icon: 'mdi:calendar-plus',
                      },
                    },
                  },
                ]}
                actionColumn={{
                  width: 60,
                  defaultActions: {
                    view: {
                      config: {
                        setDisableFunc: (renderCellCallBackParams) => {
                          return (
                            renderCellCallBackParams.row.status === UserConfirmationStatus.pending
                          );
                        },
                      },
                      clickConfig: {
                        setSelectedRow: setSelectedUser,
                        setOpenAction: setOpenUserDetailDrawer,
                      },
                    },
                    edit: {
                      config: {
                        setDisableFunc: (renderCellCallBackParams) => {
                          return (
                            renderCellCallBackParams.row.status === UserConfirmationStatus.pending
                          );
                        },
                      },
                      clickConfig: {
                        setSelectedRow: setSelectedUser,
                        setOpenAction: setOpenUserUpdateDrawer,
                      },
                    },
                    delete: {
                      clickConfig: {
                        setSelectedRow: setSelectedUser,
                        setOpenAction: setOpenUserDeleteDialog,
                      },
                    },
                  },
                  actionHeaderName: t('crm.actions'),
                }}
                loading={userListLoading || userUpdateLoading || userListFetching}
                listFilter={{
                  filterInput: userListFilter,
                  setFilterInput: setUserListFilter,
                }}
                update={{
                  updateQuery: userUpdate,
                }}
                count={userListData?.count ?? 0}
                config={{
                  columnVisibilityModel: {
                    name: false,
                    surname: false,
                  },
                  features: [],
                }}
                toolbar={{
                  customActions: [],
                }}
              />
            ) : (
              <EmptyState
                content1={t('crm.organizationalChart.user.emptyState1')}
                content2={t('crm.organizationalChart.user.emptyState2')}
                button1={{
                  title: t('crm.organizationalChart.user.create'),
                  onClick: () => (props?.setOpenUserCreateDrawer ?? (() => {}))(true),
                  leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                }}
              />
            )
          ) : null}
        </Card>
        <Box sx={{ width: '100%', height: '10px' }} />
      </DefaultListGridComponent>
      <UserUpdateDialogWrapper
        open={openUserUpdateDrawer}
        setOpen={setOpenUserUpdateDrawer}
        selectedUser={selectedUser}
      />

      <DepartmentCreateDialog
        open={openDepartments}
        setOpen={setOpenDepartments}
        setOpenRoles={setOpenRoles}
      />

      <UserDeleteDialog
        open={openUserDeleteDialog}
        item={selectedUser}
        onClose={() => {
          setSelectedUser(undefined);
          setOpenUserDeleteDialog(false);
        }}
        key={selectedUser?._id}
      />

      <RolesDrawer open={openRoles} setOpen={setOpenRoles} />

      <UserDetailWrapper
        open={openUserDetailDrawer}
        setOpen={setOpenUserDetailDrawer}
        selectedUser={selectedUser as IUserDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: userListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: userListData,
            listFilter: userListFilter,
            setListFilter: setUserListFilter,
            selectedEntity: selectedUser,
            setSelectedEntity: setSelectedUser,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />
    </Grid>
  );
});

export default UserListGridComponent;
