import { useState, lazy, Suspense } from "react";
import { IProductDetailDrawerProps } from "./ProductDetailDrawer";

const ProductDetailDrawer = lazy(() => import("./ProductDetailDrawer"));

const ProductDetailDrawerWrapper = ({
  open,
  setOpen,
  selectedProduct,
  sideNavigationProps,
}: IProductDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ProductDetailDrawer
            open={open}
            setOpen={setOpen}
            selectedProduct={selectedProduct}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default ProductDetailDrawerWrapper;
