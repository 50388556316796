/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  IProposalDetailResult,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { ProposalDetailDrawerTabInfoComponent } from './ProposalDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useProposalDocumentAddManyMutation,
  useProposalDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { ProposalDetailDrawerTabAdditionalInfoComponent } from './ProposalDetailDrawer-tab-additionalInfoSection.component';
// import {
//   useProposalDocumentCreateManyMutation,
//   useProposalDocumentAddManyMutation,
// } from 'apps/disk/domains/document/subdomains/document';

export interface IProposalDetailDrawerRightPanelTabsComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ProposalDetailDrawerRightPanelTabsComponent = (
  props: IProposalDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <DefaultTabsComponent<IProposalDetailResult> entityData={props.proposalDetailData}>
      <ProposalDetailDrawerTabInfoComponent
        proposalDetailData={props.proposalDetailData}
        tabKey="info"
        name="crm.info"
      />

      <ProposalDetailDrawerTabAdditionalInfoComponent
        proposalDetailData={props.proposalDetailData}
        tabKey="additionalInfo"
        name="crm.additionalInfo"
      />

      <TaskListTabComponent
        targetId={props.proposalDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.Proposal}
        name="crm.tasks"
        tabKey="tasks"
      />

      <DocumentListTabComponent
        targetId={props.proposalDetailData?._id ?? ''}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useProposalDocumentCreateManyMutation}
        useDocumentAddManyMutation={useProposalDocumentAddManyMutation}
      />

      <NoteListTabComponent
        targetId={props.proposalDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Proposal}
        targetDisplayName={props.proposalDetailData?.proposalId.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
      />

      <ReminderListTabComponent
        targetId={props.proposalDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Proposal}
        targetDisplayName={props.proposalDetailData?.proposalId.toString() ?? ''}
        tabKey="reminders"
        name="crm.reminders"
      />

      <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      />
    </DefaultTabsComponent>
  );
};
