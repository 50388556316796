/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useReminderDeleteMutation } from "../context/reminder.api";

export const ReminderDeleteDialog = memo(
  (props: {
    open: boolean;
    reminderId: string;
    onClose: () => void;
    setReminder: Dispatch<any>;
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      reminderDelete,
      {
        data: reminderDeleteData,
        isLoading: reminderDeleteIsLoading,
        error: reminderDeleteError,
      },
    ] = useReminderDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.reminderId}
        setItem={props.setReminder}
        deleteMutation={reminderDelete}
        deleteMutationData={{
          data: reminderDeleteData,
          isLoading: reminderDeleteIsLoading,
          error: reminderDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.calendar.reminder.delete"),
          successMessage: t("crm.calendar.reminder.deletedSuccessfully"),
          content: t("crm.calendar.reminder.deleteContent"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
