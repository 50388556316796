/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useTaskDetailQuery, useTaskUpdateMutation } from '../../context/task.api';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { IGraphqlVariables, unknownError } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { ITaskDetailResult, ITaskUpdateInput, TaskTargetType } from 'corede-common-cocrm';
import { useForm } from 'react-hook-form';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import ActionDrawer from 'components/drawer/ActionDrawer';
import { Grid } from '@mui/material';
import { UpsertTaskComponent } from '../form/UpsertTaskComponent';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

type ITaskUpdateDialog = {
  item?: ITaskDetailResult;
  open: boolean;
  setOpen: (open: boolean) => void;
  targetId: string;
  targetEntityRef: TaskTargetType | undefined;
  overlayType: OverlayType;
};

const TaskUpdateDialog = (props: ITaskUpdateDialog) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [checklistInitial, setChecklistInitial] = useState([
    { description: '', assigneeId: '', isChecked: false },
  ]);

  //queries

  const { data: taskDetailData, isLoading: taskDetailLoading } = useTaskDetailQuery(
    {
      input: {
        _id: props.item?._id ?? '',
      },
    },
    {
      skip: !props.open || !props.item,
    },
  );
  //mutation
  const [
    taskUpdate,
    { data: taskUpdateData, isLoading: taskUpdateIsLoading, error: taskUpdateError },
  ] = useTaskUpdateMutation();

  //form

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, touchedFields, isValid },
  } = useForm<IGraphqlVariables<ITaskUpdateInput>>({
    values: {
      input: {
        relatedEntityId: taskDetailData?.relatedEntity?._id ?? undefined,
        relatedEntityRef: taskDetailData?.relatedEntityRef ?? undefined,
        subject: taskDetailData?.subject ?? undefined,
        description: taskDetailData?.description ?? undefined,
        startDate: taskDetailData?.startDate ?? undefined,
        dueDate: taskDetailData?.dueDate ?? undefined,
        assigneeIds: taskDetailData?.assignees?.map((assignee) => assignee._id) ?? undefined,
        followerIds: taskDetailData?.followers?.map((follower) => follower._id) ?? undefined,
        // checklist: (taskDetailData?.checklist as ITaskUpdateInput['checklist']) ?? undefined,
        checklist:
          taskDetailData?.checklist?.map((checklistItem) => ({
            description: checklistItem?.description,
            assigneeId: checklistItem?.assignee?._id,
            isChecked: checklistItem?.isChecked,
          })) ?? [],
        departmentId: taskDetailData?.department?._id ?? undefined,
        priority: taskDetailData?.priority ?? undefined,
        tags: taskDetailData?.tags ?? undefined,
        hourlyRate: taskDetailData?.hourlyRate ?? undefined,
        hourlyRateCurrency: taskDetailData?.hourlyRateCurrency ?? undefined,
        isBillable: taskDetailData?.isBillable ?? undefined,
        isRecurrent: taskDetailData?.isRecurrent ?? undefined,
        repeatDays: taskDetailData?.repeatDays ?? undefined,
        totalCycle: taskDetailData?.totalCycle ?? undefined,
      },
    },
  });

  const onSubmit = async (values: IGraphqlVariables<ITaskUpdateInput>) => {
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await taskUpdate({
      input: {
        ...transformedValues?.input,
      },
      filter: {
        _id: props.item?._id ?? '',
      },
    });
  };

  //effects

  useEffect(() => {
    if (checklistInitial) {
      setValue('input.checklist', checklistInitial);
    }
  }, [checklistInitial]);

  useEffect(() => {
    if (taskUpdateData) {
      enqueueSnackbar(t('crm.task.task.updateSuccess'), { variant: 'success' });
      reset();
      props.setOpen(false);
    }
  }, [taskUpdateData]);

  useEffect(() => {
    if (taskUpdateError) {
      enqueueSnackbar(
        (taskUpdateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [taskUpdateError]);

  useEffect(() => {
    if (!taskDetailLoading && taskDetailData) {
      setValue('input.subject', taskDetailData?.subject);
      setValue('input.description', taskDetailData?.description);
      setValue('input.startDate', taskDetailData?.startDate);
      setValue('input.dueDate', taskDetailData?.dueDate);
      setValue(
        'input.assigneeIds',
        taskDetailData?.assignees?.map((assignee) => assignee._id),
      );
      setValue(
        'input.followerIds',
        taskDetailData?.followers?.map((follower) => follower._id),
      );
      setValue('input.departmentId', taskDetailData?.department?._id);
      setValue('input.priority', taskDetailData?.priority);
      setValue('input.tags', taskDetailData?.tags);
      setValue('input.hourlyRate', taskDetailData?.hourlyRate);
      setValue('input.hourlyRateCurrency', taskDetailData?.hourlyRateCurrency);
      setValue('input.isBillable', taskDetailData?.isBillable);
      setValue('input.isRecurrent', taskDetailData?.isRecurrent);
      setValue('input.repeatDays', taskDetailData?.repeatDays);
      setValue('input.totalCycle', taskDetailData?.totalCycle);
      setChecklistInitial(
        taskDetailData?.checklist?.map((checklistItem) => ({
          description: checklistItem?.description,
          assigneeId: checklistItem?.assignee?._id,
          isChecked: checklistItem?.isChecked,
        })) ?? [],
      );
      setValue('input.relatedEntityId', taskDetailData?.relatedEntity?._id ?? undefined);
      setValue('input.relatedEntityRef', taskDetailData?.relatedEntityRef ?? undefined);
    }
  }, [taskDetailLoading, taskDetailData]);

  const commonContent = (
    <Grid item xs={12}>
      <UpsertTaskComponent
        setValue={setValue}
        watch={watch}
        control={control}
        errors={errors}
        touchedFields={touchedFields}
        checklistInitial={checklistInitial}
        type="update"
        getValues={getValues}
        targetEntityRef={props.targetEntityRef ?? undefined}
      />
    </Grid>
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDrawer
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.task.task.updateTask')}
          loading={taskUpdateIsLoading || taskDetailLoading}
          buttonTitle={t('update')}
          handleSubmit={handleSubmit(onSubmit)}
        >
          {commonContent}
        </ActionDrawer>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.task.task.updateTask')}
          loading={taskUpdateIsLoading || taskDetailLoading}
          buttonTitle={t('update')}
          handleClick={handleSubmit(onSubmit)}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
};

export default TaskUpdateDialog;
