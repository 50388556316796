import { memo, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';

import Container from 'components/dnd/components/Container';
import Items from 'components/dnd/components/Item';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

type DNDType = {
  id: UniqueIdentifier;
  title: string;
  items: {
    id: UniqueIdentifier;
    title: string;
    email?: string;
  }[];
};

export const LeadListKanbanComponent = memo(
  ({ statuses, leads, setOpenLeadStatusesDrawer, setSelectedLead, setOpenUserDrawer }: any) => {
    const { t } = useTranslation();
    const [containers, setContainers] = useState<DNDType[]>([]);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [currentContainerId, setCurrentContainerId] = useState<UniqueIdentifier>();
    const [containerName, setContainerName] = useState('');
    const [itemName, setItemName] = useState('');
    const [showAddContainerModal, setShowAddContainerModal] = useState(false);
    const [showAddItemModal, setShowAddItemModal] = useState(false);

    const onAddContainer = () => {
      if (!containerName) return;
      const id = `container-${uuidv4()}`;
      setContainers([
        ...containers,
        {
          id,
          title: containerName,
          items: [],
        },
      ]);
      setContainerName('');
      setShowAddContainerModal(false);
    };

    const onAddItem = () => {
      if (!itemName) return;
      const id = `item-${uuidv4()}`;
      const container = containers.find((item) => item.id === currentContainerId);
      if (!container) return;
      container.items.push({
        id,
        title: itemName,
      });
      setContainers([...containers]);
      setItemName('');
      setShowAddItemModal(false);
    };

    function findValueOfItems(id: UniqueIdentifier | undefined, type: string) {
      if (type === 'container') {
        return containers.find((item) => item.id === id);
      }
      if (type === 'item') {
        return containers.find((container) => container.items.find((item) => item.id === id));
      }
    }

    const findItemTitle = (id: UniqueIdentifier | undefined) => {
      const container = findValueOfItems(id, 'item');
      if (!container) return '';
      const item = container.items.find((item) => item.id === id);
      if (!item) return '';
      return item.title;
    };

    const findContainerTitle = (id: UniqueIdentifier | undefined) => {
      const container = findValueOfItems(id, 'container');
      if (!container) return '';
      return container.title;
    };

    const findContainerItems = (id: UniqueIdentifier | undefined) => {
      const container = findValueOfItems(id, 'container');
      if (!container) return [];
      return container.items;
    };

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    function handleDragStart(event: DragStartEvent) {
      const { active } = event;
      const { id } = active;
      setActiveId(id);
    }

    const handleDragMove = (event: DragMoveEvent) => {
      const { active, over } = event;

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('item') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainer = findValueOfItems(active.id, 'item');
        const overContainer = findValueOfItems(over.id, 'item');

        if (!activeContainer || !overContainer) return;

        const activeContainerIndex = containers.findIndex(
          (container) => container.id === activeContainer.id,
        );
        const overContainerIndex = containers.findIndex(
          (container) => container.id === overContainer.id,
        );

        const activeitemIndex = activeContainer.items.findIndex((item) => item.id === active.id);
        const overitemIndex = overContainer.items.findIndex((item) => item.id === over.id);

        if (activeContainerIndex === overContainerIndex) {
          let newItems = [...containers];
          newItems[activeContainerIndex].items = arrayMove(
            newItems[activeContainerIndex].items,
            activeitemIndex,
            overitemIndex,
          );

          setContainers(newItems);
        } else {
          let newItems = [...containers];
          const [removeditem] = newItems[activeContainerIndex].items.splice(activeitemIndex, 1);
          newItems[overContainerIndex].items.splice(overitemIndex, 0, removeditem);
          setContainers(newItems);
        }
      }

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('container') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainer = findValueOfItems(active.id, 'item');
        const overContainer = findValueOfItems(over.id, 'container');

        if (!activeContainer || !overContainer) return;

        const activeContainerIndex = containers.findIndex(
          (container) => container.id === activeContainer.id,
        );
        const overContainerIndex = containers.findIndex(
          (container) => container.id === overContainer.id,
        );

        const activeitemIndex = activeContainer.items.findIndex((item) => item.id === active.id);

        let newItems = [...containers];
        const [removeditem] = newItems[activeContainerIndex].items.splice(activeitemIndex, 1);
        newItems[overContainerIndex].items.push(removeditem);
        setContainers(newItems);
      }
    };

    function handleDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      if (
        active.id.toString().includes('container') &&
        over?.id.toString().includes('container') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainerIndex = containers.findIndex(
          (container) => container.id === active.id,
        );
        const overContainerIndex = containers.findIndex((container) => container.id === over.id);
        let newItems = [...containers];
        newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
        setContainers(newItems);
      }

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('item') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainer = findValueOfItems(active.id, 'item');
        const overContainer = findValueOfItems(over.id, 'item');

        if (!activeContainer || !overContainer) return;
        const activeContainerIndex = containers.findIndex(
          (container) => container.id === activeContainer.id,
        );
        const overContainerIndex = containers.findIndex(
          (container) => container.id === overContainer.id,
        );
        const activeitemIndex = activeContainer.items.findIndex((item) => item.id === active.id);
        const overitemIndex = overContainer.items.findIndex((item) => item.id === over.id);

        if (activeContainerIndex === overContainerIndex) {
          let newItems = [...containers];
          newItems[activeContainerIndex].items = arrayMove(
            newItems[activeContainerIndex].items,
            activeitemIndex,
            overitemIndex,
          );
          setContainers(newItems);
        } else {
          let newItems = [...containers];
          const [removeditem] = newItems[activeContainerIndex].items.splice(activeitemIndex, 1);
          newItems[overContainerIndex].items.splice(overitemIndex, 0, removeditem);
          setContainers(newItems);
        }
      }
      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('container') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainer = findValueOfItems(active.id, 'item');
        const overContainer = findValueOfItems(over.id, 'container');

        if (!activeContainer || !overContainer) return;
        const activeContainerIndex = containers.findIndex(
          (container) => container.id === activeContainer.id,
        );
        const overContainerIndex = containers.findIndex(
          (container) => container.id === overContainer.id,
        );
        const activeitemIndex = activeContainer.items.findIndex((item) => item.id === active.id);

        let newItems = [...containers];
        const [removeditem] = newItems[activeContainerIndex].items.splice(activeitemIndex, 1);
        newItems[overContainerIndex].items.push(removeditem);
        setContainers(newItems);
      }
      setActiveId(null);
    }

    useEffect(() => {
      if (statuses && leads) {
        const newContainers = statuses.map((status: any) => ({
          id: `container-${status._id}`,
          title: status.name,
          items: leads
            .filter((lead: any) => lead.status._id === status._id)
            .map((lead: any) => ({
              id: `item-${uuidv4()}`,
              title: lead.fullName,
              status: lead.status,
              email: lead.email,
            })),
        }));

        setContainers(newContainers);
      }
    }, [statuses, leads]);

    return (
      <div style={{ marginTop: 10 }}>
        {/* Add Container Modal */}
        <ActionDialog
          open={showAddContainerModal}
          onClose={() => setShowAddContainerModal(false)}
          title={'Add Container'}
          buttonTitle={'Add Container'}
          buttonColor={'primary'}
          handleClick={() => onAddContainer()}
        >
          <Grid item xs={12}>
            <TextField
              type="text"
              id="containername"
              placeholder="Container Title"
              name="containername"
              value={containerName}
              onChange={(e) => setContainerName(e.target.value)}
            />
          </Grid>
        </ActionDialog>

        {/* Add Item Modal */}
        {/* <Dialog open={showAddItemModal} onClose={() => setShowAddItemModal(false)}>
        <DialogTitle>{t('crm.lead.lead.create')}</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            placeholder="Item Title"
            name="itemname"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onAddItem}
          >{t('crm.lead.lead.create')}</Button>
        </DialogActions>
      </Dialog> */}

        {/* <LeadCreateDrawer
        open={showAddItemModal}
        setOpen={setShowAddItemModal}
        leadStatusCreateOpen={openLeadStatusesDrawer}
        setLeadStatusCreateOpen={setOpenLeadStatusesDrawer}

      /> */}

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <Button variant="contained" onClick={() => setOpenLeadStatusesDrawer(true)}>
            Manage Statuses
          </Button>
        </Stack>

        <Box mt={2}>
          <Grid container spacing={2}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={containers.map((i) => i.id)}>
                {containers.map((container) => (
                  <Grid item xs={3} key={container.id} mb={3}>
                    <Container
                      id={container.id}
                      title={container.title}
                      key={container.id}
                      onAddItem={() => {
                        setShowAddItemModal(true);
                        setCurrentContainerId(container.id);
                      }}
                    >
                      <SortableContext items={container.items.map((i) => i.id)}>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          {container?.items?.map((i) => (
                            <Items
                              title={i.title}
                              id={i.id}
                              key={i.id}
                              email={i.email}
                              setSelectedLead={setSelectedLead}
                              setOpenUserDrawer={setOpenUserDrawer}
                              leads={leads}
                            />
                          ))}
                        </Stack>
                      </SortableContext>
                    </Container>
                  </Grid>
                ))}
              </SortableContext>
              <DragOverlay adjustScale={false}>
                {/* Drag Overlay For item Item */}
                {activeId && activeId.toString().includes('item') && (
                  <Items id={activeId} title={findItemTitle(activeId)} />
                )}
                {/* Drag Overlay For Container */}
                {activeId && activeId.toString().includes('container') && (
                  <Container id={activeId} title={findContainerTitle(activeId)}>
                    {findContainerItems(activeId).map((i) => (
                      <Items key={i.id} title={i.title} id={i.id} />
                    ))}
                  </Container>
                )}
              </DragOverlay>
            </DndContext>
          </Grid>
        </Box>
      </div>
    );
  },
);
