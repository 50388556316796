/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { IUserDetailResult } from 'corede-common-cocrm';

interface IUserDetailProfileSectionComponent {
  userData: IUserDetailResult | undefined;
}

export const UserDetailProfileSectionComponent = (props: IUserDetailProfileSectionComponent) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      position={'absolute'}
      sx={{
        // mt: { xs: '-150px', md: '-120px' },
        // mb: { xs: '30px', md: '0px' },
        bottom: { xs: '80px', md: '40px' },
      }}
    >
      <Avatar
        sx={{
          width: '120px',
          height: '120px',
          ml: 2,
          border: '3px solid #fff',
          objectFit: 'cover',
          boxShadow: '0px 0px 5px #bbb',
          fontSize: '40px',
        }}
        alt={props.userData?.name}
        src={props.userData?.profileImage?.thumbnailPublicUrl}
      >
        {(props.userData?.name + ' ' + props.userData?.surname)
          ?.split(' ')
          ?.map((n) => n[0])
          .join('')
          .toUpperCase()}
      </Avatar>
      <Stack direction={'column'}>
        <Typography fontSize={'24px'} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={'#fff'}>
          {props.userData?.name} {props.userData?.surname}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={'#fff'}>
          {(props.userData as any)?.department?.name}{' '}
        </Typography>
      </Stack>
    </Stack>
  );
};
