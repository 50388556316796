import serviceLocale from "../subdomains/service/locales/tr";
import productLocale from "../subdomains/product/locales/tr";
import productCategoryLocale from "../subdomains/productCategory/locales/tr";

const locale = {
  product: {
    ...productLocale,
  },
  productCategory: {
    ...productCategoryLocale,
  },
  service: {
    ...serviceLocale,
  },
};

export default locale;
