const locale = {
  readAllSuccess: 'Read all notifications successfully!',
  title: 'Notifications',
  markAllAsRead: 'Mark all as read.',
  close: 'Close',
  noNotifications1:"No notifications",
  noNotifications2:"You haven’t received any notifications yet.",
  refresh: 'Refresh',
};

export default locale;
