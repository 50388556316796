
import { useState, lazy, Suspense } from 'react';

const EstimateUpdateDrawer = lazy(() => import('./EstimateUpdateDrawer'));

const EstimateUpdateDrawerWrapper = ({
    open,
    setOpen,
    selectedEstimate,
}: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <EstimateUpdateDrawer
                        open={open}
                        setOpen={setOpen}
                        selectedEstimate={selectedEstimate}
                    />
                </Suspense>
            )}
        </>
    );
};

export default EstimateUpdateDrawerWrapper;
