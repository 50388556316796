import { ICustomerContactListItemResult } from 'corede-common-cocrm';
import { useState, lazy, Suspense, Dispatch, SetStateAction } from 'react';

const ContactUpdateDrawer = lazy(() => import('./ContactUpdateDrawer'));

const ContactUpdateWrapper = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedContact: ICustomerContactListItemResult | undefined;
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ContactUpdateDrawer
            open={props.open}
            setOpen={props.setOpen}
            selectedContact={props.selectedContact}
          />
        </Suspense>
      )}
    </>
  );
};

export default ContactUpdateWrapper;
