import { useState, lazy, Suspense } from 'react';
import { IReminderCreateOverlay } from './ReminderCreateOverlay';

const ReminderCreateOverlay = lazy(() => import('./ReminderCreateOverlay'));

const ReminderCreateOverlayWrapper = ({
  open,
  setOpen,
  targetId,
  targetType,
  overlayType,
}: IReminderCreateOverlay) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ReminderCreateOverlay
            open={open}
            setOpen={setOpen}
            targetId={targetId}
            targetType={targetType}
            overlayType={overlayType}
          />
        </Suspense>
      )}
    </>
  );
};

export default ReminderCreateOverlayWrapper;
