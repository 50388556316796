import {
  ILeadStatusesInput,
  ILeadStatusesRequest,
  ILeadStatusesResponse,
  ILeadStatusesResult,
  leadStatusesQuery,

  ILeadStatusInput,
  ILeadStatusRequest,
  ILeadStatusResponse,
  ILeadStatusResult,
  leadStatusQuery,

  IUpdateLeadStatusInput,
  IUpdateLeadStatusRequest,
  IUpdateLeadStatusResponse,
  IUpdateLeadStatusResult,
  updateLeadStatusQuery,
  IUpdateLeadStatusFilterInput,
  ICreateLeadStatusInput,
  ICreateLeadStatusRequest,
  ICreateLeadStatusResponse,
  ICreateLeadStatusResult,
  createLeadStatusQuery,
  IDeleteLeadStatusInput,
  IDeleteLeadStatusRequest,
  IDeleteLeadStatusResponse,
  IDeleteLeadStatusResult,
  deleteLeadStatusQuery,
  leadStatusListCountQuery,
  ILeadStatusListCountRequest,
  ILeadStatusListCountResponse,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadStatusTags } from "./leadStatus.tags";
import { ICount, IGraphqlVariables } from "corede-common";

export const leadStatusesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    leadStatusList: builder.query<
      ILeadStatusesResult,
      IGraphqlVariables<ILeadStatusesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatusesRequest,
        ILeadStatusesResponse,
        ILeadStatusesResult,
        ILeadStatusesInput
      >({
        query: leadStatusesQuery,
        providesTags: [
          leadStatusTags.leadStatuses,
          leadStatusTags.leadStatus,
        ],
      })
    ),

    leadStatusDetail: builder.query<
      ILeadStatusResult,
      IGraphqlVariables<ILeadStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatusRequest,
        ILeadStatusResponse,
        ILeadStatusResult,
        ILeadStatusInput
      >({
        query: leadStatusQuery,
        providesTags: [leadStatusTags.leadStatus],
      })
    ),



    leadStatusListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatusListCountRequest,
        ILeadStatusListCountResponse,
        ICount,
        undefined
      >({
        query: leadStatusListCountQuery,
        providesTags: [leadStatusTags.leadStatuses],
      })
    ),


    // mutation
    leadStatusUpdate: builder.mutation<
      IUpdateLeadStatusResult,
      IGraphqlVariables<IUpdateLeadStatusInput, IUpdateLeadStatusFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateLeadStatusRequest,
        IUpdateLeadStatusResponse,
        IUpdateLeadStatusResult,
        IUpdateLeadStatusInput,
        IUpdateLeadStatusFilterInput
      >({
        query: updateLeadStatusQuery,
        invalidatesTags: [
          leadStatusTags.leadStatus,
          leadStatusTags.leadStatuses,
          leadStatusTags.leadStatuses,
          leadStatusTags.leadStatus,
        ],
      })
    ),

    leadStatusCreate: builder.mutation<
      ICreateLeadStatusResult,
      IGraphqlVariables<ICreateLeadStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateLeadStatusRequest,
        ICreateLeadStatusResponse,
        ICreateLeadStatusResult,
        ICreateLeadStatusInput
      >({
        query: createLeadStatusQuery,
        invalidatesTags: [
          leadStatusTags.leadStatus,
          leadStatusTags.leadStatuses,
          leadStatusTags.leadStatuses,
          leadStatusTags.leadStatus,
        ],
      })
    ),

    leadStatusDelete: builder.mutation<
      IDeleteLeadStatusResult,
      IGraphqlVariables<IDeleteLeadStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteLeadStatusRequest,
        IDeleteLeadStatusResponse,
        IDeleteLeadStatusResult,
        IDeleteLeadStatusInput
      >({
        query: deleteLeadStatusQuery,
        invalidatesTags: [
          leadStatusTags.leadStatuses,
        ],
      })
    ),
  }),
});

export const {
  useLeadStatusListQuery,
  useLazyLeadStatusListQuery,
  useLeadStatusDetailQuery,
  useLazyLeadStatusDetailQuery,
  useLeadStatusListCountQuery,
  useLazyLeadStatusListCountQuery,

  useLeadStatusUpdateMutation,
  useLeadStatusCreateMutation,
  useLeadStatusDeleteMutation,
} = leadStatusesApi;
