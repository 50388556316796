import { useState, lazy, Suspense } from "react";
import { INoteDetailDrawerProps } from "./NoteDetailDrawer";

const NoteDetailDrawer = lazy(() => import("./NoteDetailDrawer"));

const NoteDetailDrawerWrapper = ({
  open,
  setOpen,
  note: selectedNote,
  sideNavigationProps,
}: INoteDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <NoteDetailDrawer
            open={open}
            setOpen={setOpen}
            note={selectedNote}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default NoteDetailDrawerWrapper;
