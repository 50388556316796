import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useAppSelector, useAppDispatch } from 'context';
import Router from 'Router';
import { theme } from 'theme';
import { SnackbarProvider } from 'components';
import { useEffect } from 'react';
import { setThemeMode } from 'context';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import moment from 'moment';
import { LicenseInfo } from '@mui/x-license';
import { getCurrentLanguage } from 'localization';
import { Language } from 'corede-common';
import { useOrganizationSettingsDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const Wrapper = () => {
  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector((state) => state.theme);
  LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);
  const currentLanguage = getCurrentLanguage();

  const { data: organizationSettingsData } = useOrganizationSettingsDetailOwnQuery({});
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      dispatch(setThemeMode(theme as 'light' | 'dark'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentLanguage === Language.tr) {
      moment.locale('tr');
      // moment.defaultFormat = organizationSettingsData?.common?.dateFormat || 'DD.MM.YYYY';
    } else {
      moment.locale('en-gb');
    }
  }, [currentLanguage, organizationSettingsData]);

  return (
    <ThemeProvider theme={theme(themeMode === 'light' ? 'light' : 'dark')}>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router />
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Wrapper;
