export const documentDomainRoutes = {
  base: 'disk',
  subdomains: {
    shared: {
      base: 'shared-documents',
    },
    my: {
      base: 'my-documents',
    },
    private: {
      base: 'private-documents',
    },
    all: {
      base: 'all-documents',
    },
  },
};

export const baseDiskRoute = (): string => `/${documentDomainRoutes.base}/`;
export const sharedDocumentRoute = (): string =>
  `/${documentDomainRoutes.base}/${documentDomainRoutes.subdomains.shared.base}`;
export const myDocumentRoute = (): string =>
  `/${documentDomainRoutes.base}/${documentDomainRoutes.subdomains.my.base}`;
export const privateDocumentRoute = (): string =>
  `/${documentDomainRoutes.base}/${documentDomainRoutes.subdomains.private.base}`;
export const allDocumentRoute = (): string =>
  `/${documentDomainRoutes.base}/${documentDomainRoutes.subdomains.all.base}`;
