/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IEstimateDetailDrawerLeftPanelAssignedsComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visible?: boolean;
}

/**
 * Container for views in assigned list section of left panel header
 */
export const EstimateDetailDrawerLeftPanelAssignedsComponent = (
  props: IEstimateDetailDrawerLeftPanelAssignedsComponentProps,
) => {
  const { t } = useTranslation();
  return props.visible ? (
    <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
      <Stack direction={'column'} width={'100%'} gap={0.5}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: 'text.primary',
          }}
        >
          {t('crm.preSale.estimate.salesAgentsTitle')}
        </Typography>
        <Divider />
      </Stack>
      <Stack direction={'column'} width={'100%'} gap={1}>
        {props.estimateDetailData?.salesAgents &&
        props.estimateDetailData?.salesAgents?.length > 0 ? (
          props.estimateDetailData?.salesAgents?.map((follower) => (
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              width={'100%'}
              key={follower?._id}
            >
              <Avatar
                alt={follower?.surname}
                src={follower?.profileImage?.thumbnailPublicUrl}
                sx={{ width: 40, height: 40 }}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  color: 'text.primary',
                }}
              >
                {follower?.name} {follower?.surname}
              </Typography>
            </Stack>
          ))
        ) : (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.noSalesAgents')}
          </Typography>
        )}
      </Stack>
    </Stack>
  ) : null;
};
