/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { ICustomerDetailResult } from 'corede-common-cocrm';

interface ICustomerDetailProfileSectionComponent {
  customerData: ICustomerDetailResult | undefined;
}

export const CustomerDetailProfileSectionComponent = (
  props: ICustomerDetailProfileSectionComponent,
) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'end'}
      gap={1}
      sx={{
        mt: '-54px',
      }}
    >
      <Avatar
        sx={{
          width: '108px',
          height: '108px',
          ml: 2,
          border: '5px solid #fff',
          objectFit: 'cover',
          fontSize: '40px',
        }}
        alt={props.customerData?.name}
      >
        {props.customerData?.name
          ?.split(' ')
          ?.map((n) => n[0])
          .join('')
          .toUpperCase()}
      </Avatar>
      <Stack direction={'column'}>
        <Typography variant="h5">{props.customerData?.name} </Typography>
        <Typography variant="subtitle2" color={'text.secondary'}>
          {props.customerData?.department?.name}
        </Typography>
      </Stack>
    </Stack>
  );
};
