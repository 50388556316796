import {
  IEntity,
  IFilter,
  IGraphqlVariables,
  IHasFilter,
  IHasPagination,
  IPaginated,
  IPagination,
} from "corede-common";
import { Dispatch, SetStateAction } from "react";
import { TListGridSelectedEntity } from "../components/list/DefaultList-grid.component";

export interface IPendingNavigation {
  direction: "next" | "back";
  page: number;
}

export interface IHandleSideNavigationNavigateParams<T extends IEntity> {
  selectedEntity: TListGridSelectedEntity<T>;
  setSelectedEntity: Dispatch<SetStateAction<TListGridSelectedEntity<T>>>;
  listFilter: IGraphqlVariables<
    IHasPagination<IPagination> & IHasFilter<IFilter>
  >;
  setListFilter: Dispatch<
    SetStateAction<
      IGraphqlVariables<IHasPagination<IPagination> & IHasFilter<IFilter>>
    >
  >;
  setPendingNavigation: Dispatch<SetStateAction<IPendingNavigation | null>>;
  listData?: IPaginated<T>;
  currentPage: number;
  currentPageSize: number;
}

export function handleSideNavigationNavigate<T extends IEntity>(
  params: IHandleSideNavigationNavigateParams<T>
): (direction: "next" | "back") => any {
  return (direction: "next" | "back"): any => {
    if (!params.selectedEntity || !params.listData?.data?.length) return;

    const currentIndex = params.listData?.data.findIndex(
      (entity) => entity._id === params.selectedEntity?._id
    );

    let newIndex = currentIndex;
    const totalEntityCount = params.listData?.count || 0;
    const maxPage = Math.ceil(totalEntityCount / params.currentPageSize);

    if (direction === "next") {
      newIndex = currentIndex + 1;

      if (newIndex >= params.listData?.data.length) {
        if (params.currentPage >= maxPage) return;
        const nextPage = params.currentPage + 1;
        params.setListFilter({
          ...params.listFilter,
          input: {
            ...params.listFilter.input,
            pagination: {
              ...params.listFilter.input?.pagination,
              page: nextPage,
            },
          },
        });
        params.setPendingNavigation({
          direction: "next",
          page: nextPage,
        });
        return;
      }
    } else if (direction === "back") {
      newIndex = currentIndex - 1;
      if (newIndex < 0) {
        if (params.currentPage <= 1) return;
        const previousPage = params.currentPage - 1;
        params.setListFilter({
          ...params.listFilter,
          input: {
            ...params.listFilter.input,
            pagination: {
              ...params.listFilter.input?.pagination,
              page: previousPage,
            },
          },
        });
        params.setPendingNavigation({
          direction: "back",
          page: previousPage,
        });
        return;
      }
    }

    const globalId =
      (params.currentPage - 1) * params.currentPageSize + (newIndex + 1);
    params.setSelectedEntity({
      ...params.listData?.data[newIndex],
      id: globalId,
    });
  };
}
