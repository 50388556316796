import { commonRoutes } from "routes/route.common";

export const productsAndServicesDomainRoutes = {
  base: "productsAndServices",
  subdomains: {
    product: {
      base: "product",
    },
    service: {
      base: "service",
    },
    category: {
      base: "category",
    },
  },
};

export const baseProductsAndServicesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/`;
export const listProductsRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.product.base}`;
export const listServicesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.service.base}`;
export const listCategoriesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.category.base}`;
