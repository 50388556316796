import {
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  DialogContent,
  Grid,
  Slide,
  InputAdornment,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Iconify } from 'components/iconify';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';
import { Stack, styled } from '@mui/system';
import { baseLeadRoute, importLeadRoute } from 'apps/crm/domains/03-lead/routes/lead.base.route';
import { useNavigate } from 'react-router-dom';
import { baseCustomerRoute } from 'apps/crm/domains/04-customer/routes/customer.base.route';
import {
  listEstimatesRoute,
  listProposalsRoute,
} from 'apps/crm/domains/05-preSale/routes/preSales.base.route';
import {
  listInvoicesRoute,
  listPaymentsRoute,
  listReturnsRoute,
} from 'apps/crm/domains/06-sale/routes/sales.base.route';
import {
  baseContractsRoute,
  listContractsRoute,
} from 'apps/crm/domains/07-contract/routes/contracts.base.route';
import {
  listProductsRoute,
  listServicesRoute,
} from 'apps/crm/domains/08-productAndService/routes/productsAndServices.base.route';
import {
  baseExpensesRoute,
  listExpensesRoute,
} from 'apps/crm/domains/09-expense/routes/expenses.base.route';
import {
  baseProjectsRoute,
  listProjectsRoute,
} from 'apps/crm/domains/10-project/routes/projects.base.route';
import { baseTasksRoute, listTasksRoute } from 'apps/crm/domains/11-task/routes/tasks.base.route';
import {
  listAppointmentsRoute,
  listRemindersRoute,
} from 'apps/crm/domains/12-calendar/routes/calendar.base.route';
import { baseNotesRoute } from 'apps/crm/domains/14-note/routes/notes.base.route';
import {
  baseSupportRoute,
  listSupportRoute,
} from 'apps/crm/domains/15-support/routes/support.base.route';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    width: '300px',
    height: '45px',
    backgroundColor: theme.palette.background.secondary,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    fontWeight: 600,
    fontSize: '14px',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SearchMainDialogComponent = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const staticList = [
    {
      id: 'leadCreate',
      name: t('crm.leadCreate'),
      icon: 'material-symbols-light:social-leaderboard-outline-rounded',
      tags: [
        'lead',
        'create',
        'oluştur',
        'add',
        'ekle',
        'new',
        'yeni',
        'oluşturma',
        'başlat',
        'initiate',
      ],
      link: baseLeadRoute() + '#create',
    },
    {
      id: 'leadList',
      name: t('crm.leadList'),
      icon: 'material-symbols-light:social-leaderboard-outline-rounded',
      tags: [
        'lead',
        'listele',
        'list',
        'istatistik',
        'potansiyel müşteri',
        'güncelle',
        'update',
        'sil',
        'delete',
        'indir',
        'export',
        'download',
        'view',
        'görüntüle',
        'listeleme',
        'filter',
        'sırala',
        'sort',
        'ara',
        'search',
        'remove',
        'düzenle',
        'edit',
      ],
      link: baseLeadRoute(),
    },
    {
      id: 'leadImport',
      name: t('crm.leadImport'),
      icon: 'material-symbols-light:social-leaderboard-outline-rounded',
      tags: [
        'lead',
        'listele',
        'import',
        'içe aktar',
        'potansiyel müşteri',
        'yükle',
        'aktarma',
        'data',
        'veri',
        'transfer',
        'dışarıdan al',
        'upload',
        'import data',
      ],
      link: importLeadRoute(),
    },
    {
      id: 'customerCreate',
      name: t('crm.customerCreate'),
      icon: 'solar:user-rounded-linear',
      tags: [
        'customer',
        'create',
        'oluştur',
        'new',
        'add',
        'ekle',
        'yeni',
        'müşteri oluştur',
        'başlat',
        'initiate',
      ],
      link: baseCustomerRoute() + '#create',
    },
    {
      id: 'customerList',
      name: t('crm.customerList'),
      icon: 'solar:user-rounded-linear',
      tags: [
        'customer',
        'list',
        'listele',
        'müşteri',
        'istatistik',
        'görüntüle',
        'view',
        'listeleme',
        'filter',
        'ara',
        'search',
        'sırala',
        'sort',
        'update',
        'güncelle',
        'sil',
        'delete',
        'indir',
        'export',
        'download',
        'remove',
        'düzenle',
        'edit',
      ],
      link: baseCustomerRoute(),
    },
    // {
    //   "id": "customerImport",
    //   "name": t("crm.customerImport"),
    //   "tags": [
    //     "customer", "import", "içe aktar", "müşteri", "yükle", "aktarma", "upload",
    //     "veri", "data", "transfer", "dışarıdan al", "import data"
    //   ],
    //   "link": baseLeadRoute()
    // },
    {
      id: 'estimateCreate',
      name: t('crm.estimateCreate'),
      icon: 'mage:preview',
      tags: [
        'estimate',
        'create',
        'oluştur',
        'teklif',
        'add',
        'ekle',
        'yeni',
        'başlat',
        'initiate',
      ],
      link: listEstimatesRoute() + '#create',
    },
    {
      id: 'proposalCreate',
      name: t('crm.proposalCreate'),
      icon: 'mage:preview',
      tags: [
        'proposal',
        'create',
        'oluştur',
        'öneri',
        'sunum',
        'teklif',
        'ekle',
        'add',
        'başlat',
        'initiate',
      ],
      link: listProposalsRoute() + '#create',
    },
    {
      id: 'invoiceList',
      name: t('crm.invoiceList'),
      icon: 'solar:sale-broken',
      tags: [
        'invoice',
        'list',
        'listele',
        'fatura',
        'view',
        'görüntüle',
        'güncelle',
        'update',
        'indir',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: listInvoicesRoute(),
    },
    {
      id: 'paymentList',
      name: t('crm.paymentList'),
      icon: 'solar:sale-broken',
      tags: [
        'payment',
        'list',
        'listele',
        'ödeme',
        'view',
        'görüntüle',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: listPaymentsRoute(),
    },
    {
      id: 'returnList',
      name: t('crm.returnList'),
      icon: 'solar:sale-broken',
      tags: [
        'return',
        'list',
        'listele',
        'iade',
        'view',
        'görüntüle',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: listReturnsRoute(),
    },
    {
      id: 'contractCreate',
      name: t('crm.contractCreate'),
      icon: 'teenyicons:contract-outline',
      tags: ['contract', 'create', 'oluştur', 'sözleşme', 'add', 'ekle', 'başlat', 'initiate'],
      link: baseContractsRoute() + '#create',
    },
    {
      id: 'productList',
      name: t('crm.productList'),
      icon: 'fluent-mdl2:product-catalog',
      tags: [
        'product',
        'list',
        'listele',
        'ürün',
        'view',
        'görüntüle',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: listProductsRoute(),
    },
    {
      id: 'serviceCreate',
      name: t('crm.serviceCreate'),
      icon: 'fluent-mdl2:product-catalog',
      tags: ['service', 'create', 'oluştur', 'hizmet', 'add', 'ekle', 'başlat', 'initiate'],
      link: listServicesRoute() + '#create',
    },
    {
      id: 'expenseList',
      name: t('crm.expenseList'),
      icon: 'icon-park-outline:expenses',
      tags: [
        'expense',
        'list',
        'listele',
        'masraf',
        'görüntüle',
        'view',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: baseExpensesRoute(),
    },
    {
      id: 'projectList',
      name: t('crm.projectList'),
      icon: 'streamline:projector-board',
      tags: [
        'project',
        'list',
        'listele',
        'proje',
        'view',
        'görüntüle',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: baseProjectsRoute(),
    },
    {
      id: 'taskCreate',
      name: t('crm.taskCreate'),
      icon: 'carbon:product',
      tags: ['task', 'create', 'oluştur', 'görev', 'add', 'ekle', 'başlat', 'initiate'],
      link: baseTasksRoute() + '#create',
    },
    {
      id: 'reminderCreate',
      name: t('crm.reminderCreate'),
      icon: 'solar:calendar-linear',
      tags: ['reminder', 'create', 'oluştur', 'hatırlatma', 'add', 'ekle', 'başlat', 'initiate'],
      link: listRemindersRoute() + '#create',
    },
    {
      id: 'appointmentCreate',
      name: t('crm.appointmentCreate'),
      icon: 'solar:calendar-linear',
      tags: ['appointment', 'create', 'oluştur', 'randevu', 'add', 'ekle', 'başlat', 'initiate'],
      link: listAppointmentsRoute() + '#create',
    },
    {
      id: 'noteCreate',
      name: t('crm.noteCreate'),
      icon : 'hugeicons:task-01',
      tags: ['note', 'create', 'oluştur', 'not', 'add', 'ekle', 'başlat', 'initiate'],
      link: baseNotesRoute() + '#create',
    },
    {
      id: 'ticketList',
      name: t('crm.ticketList'),
      icon: 'f7:tickets',
      tags: [
        'ticket',
        'list',
        'listele',
        'talep',
        'destek',
        'support',
        'view',
        'görüntüle',
        'update',
        'güncelle',
        'export',
        'download',
        'remove',
        'sil',
        'düzenle',
        'edit',
      ],
      link: baseSupportRoute(),
    },
  ];

  const popularSearches = staticList.filter(
    (item) =>
      item.id === 'taskCreate' ||
      item.id === 'customerList' ||
      item.id === 'leadCreate' ||
      item.id === 'noteCreate',
  );

  const filteredList = staticList.filter((item) =>
    item.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const displayedList = searchTerm ? filteredList?.slice(0, 10) : popularSearches;

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          width: 500,
          maxWidth: 500,
        },
      }}
    >
      <DialogTitle
        bgcolor={'background.paper'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <CustomTextField
          id="outlined-search"
          variant="outlined"
          autoFocus
          placeholder={t('crm.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            bgcolor: 'background.thirdWithBlur',
            '& .MuiInputBase-input': {
              bgcolor: '#00000000',
            },
            '& .MuiOutlinedInput-root': {
              bgcolor: 'background.thirdWithBlur',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'mynaui:search'} width={24} />
              </InputAdornment>
            ),
          }}
        />

        <Tooltip title={t('webNotification.close')}>
          <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
            <Iconify icon={'material-symbols:close'} />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ mt: 0, py: 2 }}>
        {!searchTerm && (
          <Typography
            fontSize={14}
            color="text.secondary"
            sx={{ mt: 2, textTransform: 'uppercase', pl: 2 }}
            fontWeight={600}
          >
            {t('crm.popularSearch')}
          </Typography>
        )}
        {displayedList.length > 0 ? (
          <List>
            {displayedList.map((item) => (
              <ListItem
                key={item.id}
                button
                onClick={() => {
                  props.setOpen(false);
                  setSearchTerm('');
                  navigate(item.link);
                }}
              >
                <ListItemIcon sx={{minWidth: 32}}>
                  <Iconify icon={item.icon} width={24} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Stack alignItems="center" spacing={2} mt={3}>
            <Iconify icon={EMPTY_STATE.icon} width={48} sx={{ color: 'text.secondary' }} />
            <Typography variant="h6" fontWeight="bold">
              {t('crm.noResultsFound')} "{searchTerm}"
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('crm.trySearching')}
            </Typography>
            <Stack spacing={1}>
              {EMPTY_STATE.popularSearches.map((item) => (
                <Stack
                  key={item.id}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(staticList.find((i) => i.id === item.id)?.link || '')}
                >
                  <Iconify icon={item.icon} width={20} />
                  <Typography variant="body1">{item.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

const EMPTY_STATE = {
  icon: 'tabler:file-alert',
  title: 'No results found',
  subtitle: 'Try searching for',
  popularSearches: [
    { id: 'taskCreate', name: 'Create Task', icon: 'carbon:product' },
    { id: 'customerList', name: 'Customer List', icon: 'solar:user-rounded-linear' },
    {
      id: 'leadCreate',
      name: 'Create Lead',
      icon: 'material-symbols-light:social-leaderboard-outline-rounded',
    },
    { id: 'noteCreate', name: 'Create Note', icon: 'hugeicons:task-01' },
  ],
};
