/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from "react";
import { getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import {
  ILeadCreateInput,
  ILeadSourceResult,
  ILeadStatusResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from "corede-common-cocrm";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { enqueueSnackbar } from "notistack";
import "react-quill/dist/quill.snow.css";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useLeadSourceListQuery } from "../../../leadSource";
import { useLeadStatusListQuery } from "../../../leadStatus";
import { useLeadCreateMutation } from "../../context/lead.api";
import { validateCreateLeadInput } from "../../validations/create.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpsertLeadComponent } from "../UpsertLead.component";

interface ILeadCreateDrawer {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  leadStatusList?: ILeadStatusResult[];
  leadSourceList?: ILeadSourceResult[];
}

const LeadCreateDrawer = memo((props: ILeadCreateDrawer) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries
  const {
    data: leadStatusListDataFromApi,
    isLoading: leadStatusListLoading,
    error: leadStatusListError,
  } = useLeadStatusListQuery(
    {},
    {
      skip: !!props.leadStatusList, // Skip the query if props data exists
    }
  );
  const leadStatusList: ILeadStatusResult[] | undefined =
    props.leadStatusList || leadStatusListDataFromApi?.data;

  const {
    data: leadSourceListDataFromApi,
    isLoading: leadSourceListLoading,
    error: leadSourceListError,
  } = useLeadSourceListQuery(
    {},
    {
      skip: !!props.leadSourceList, // Skip the query if props data exists
    }
  );
  const leadSourceList: ILeadSourceResult[] | undefined =
    props.leadSourceList || leadSourceListDataFromApi?.data;

  // mutations
  const [
    leadCreate,
    {
      data: leadCreateData,
      isLoading: leadCreateLoading,
      error: leadCreateError,
    },
  ] = useLeadCreateMutation();

  // constants
  const initialValues = useMemo<IGraphqlVariables<ILeadCreateInput>>(
    () => ({
      input: {
        businessType: LeadBusinessType.b2b,
        fullName: "",
        email: "",
        isJunk: false,
        isPublic: true,
        qualification: LeadQualification.unqualified,
        sourceId: leadSourceList ? leadSourceList[0]._id : "",
        statusId: leadStatusList ? leadStatusList[0]._id : "",
        type: LeadType.neutral,
        tags: [],
        company: undefined,
        position: undefined,
        website: undefined,
        phoneNumber: undefined,
        sector: undefined,
        country: undefined,
        state: undefined,
        city: undefined,
        language: Language.en,
        assignedIds: [],
        note: undefined,
      },
    }),
    []
  );

  // form setup
  const leadCreateUseForm = useForm<IGraphqlVariables<ILeadCreateInput>>({
    defaultValues: initialValues,
    resolver: yupResolver(validateCreateLeadInput),
    mode: "onChange",
  });

  const onSubmit = async (values: IGraphqlVariables<ILeadCreateInput>) => {
    setLoading(true);
    const transformedValues =
      RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
        input: values,
        
      });
    await leadCreate(transformedValues as IGraphqlVariables<ILeadCreateInput>);
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (leadCreateData) {
      enqueueSnackbar(t("crm.lead.lead.createLeadSuccess"), {
        variant: "success",
      });
      leadCreateUseForm.reset();
      props.setOpen(false);
    }
  }, [leadCreateData, leadCreateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadCreateError as IBaseError,
      currentLanguage
    );
  }, [leadCreateError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadStatusListError as IBaseError,
      currentLanguage
    );
  }, [leadStatusListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceListError as IBaseError,
      currentLanguage
    );
  }, [leadSourceListError]);

  return (
    <UpsertLeadComponent
      open={props.open}
      setOpen={props.setOpen}
      title={t("crm.lead.lead.create")}
      buttonTitle={t("crm.lead.lead.create")}
      loading={leadCreateLoading || loading}
      useForm={leadCreateUseForm}
      onSubmit={onSubmit}
      leadSourceList={leadSourceList}
      leadStatusList={leadStatusList}
    />
  );
});

export default LeadCreateDrawer;
