/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from 'corede-common';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInvoicePaymentDeleteMutation } from '../../context/payment.api';
import { PaymentEntityDeleteDialog } from './PaymentEntityDeleteDialog';

export const PaymentDeleteDialog = memo(
  (props: { open: boolean; paymentId: string; invoiceId: string; onClose: () => void }) => {
    // general
    const { t } = useTranslation();
    // mutations
    const [
      paymentDelete,
      { data: paymentDeleteData, isLoading: paymentDeleteIsLoading, error: paymentDeleteError },
    ] = useInvoicePaymentDeleteMutation();

    return (
      <PaymentEntityDeleteDialog
        open={props.open}
        paymentId={props.paymentId}
        invoiceId={props.invoiceId}
        deleteMutation={paymentDelete}
        deleteMutationData={{
          data: paymentDeleteData,
          isLoading: paymentDeleteIsLoading,
          error: paymentDeleteError as IBaseError,
        }}
        dialog={{
          title: t('crm.sale.payment.delete'),
          successMessage: t('crm.sale.payment.deleteSuccess'),
          content: t('crm.sale.payment.deleteConfirm'),
        }}
        onClose={props.onClose}
      />
    );
  },
);
