


import { NotesDomainLayout } from "../layouts";
import { NotesRouter } from "../subdomains/note/routes/NotesRouter";

import { Route, Routes } from "react-router-dom";

export const NotesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<NotesDomainLayout />}>
        <Route index element={<NotesRouter />} />
      </Route>
    </Routes>
  );
};
