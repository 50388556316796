/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { InvoiceDetailDrawerLeftPanelHeaderTagsComponent } from './InvoiceDetailDrawer-leftPanel.header.tags.component';
import { InvoiceDetailDrawerLeftPanelActionsComponent } from './InvoiceDetailDrawer-leftPanel-header-actions.component';

export interface IInvoiceDetailDrawerLeftPanelHeaderComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const InvoiceDetailDrawerLeftPanelHeaderComponent = (
  props: IInvoiceDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* TAGS */}
      {downLg ? null : (
        <InvoiceDetailDrawerLeftPanelHeaderTagsComponent
          status={props.invoiceDetailData?.status}
        />
      )}

      {/* Invoice Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.invoiceDetailData?.invoiceId ?? ''}
      </Typography>

      <InvoiceDetailDrawerLeftPanelActionsComponent
        invoiceDetailData={props.invoiceDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
