/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, Button, MenuItem, Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Icons from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITaskDetailResult, TaskStatus } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { useTaskUpdateMutation } from '../../context/task.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

export interface ITaskDetailDrawerLeftPanelActionsComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert task to customer
 */
export const TaskDetailDrawerLeftPanelActionsComponent = (
  props: ITaskDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [taskUpdate, { data: taskUpdateData, error: taskUpdateError }] = useTaskUpdateMutation();

  const handleTaskUpdate = async (key: TaskStatus) => {
    await taskUpdate({
      filter: {
        _id: props.taskDetailData?._id ?? '',
      },
      input: {
        status: key,
      },
    });
  };

  useEffect(() => {
    if (taskUpdateData) {
      enqueueSnackbar(t('crm.task.task.updateSuccess'), { variant: 'success' });
    }
  }, [taskUpdateData]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskUpdateError as IBaseError, currentLanguage);
  }, [taskUpdateError]);

  // const DrawerMenu = () => {
  //   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //   const open = Boolean(anchorEl);
  //   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  //   };
  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   return (
  //     <div>
  //       <Button
  //         id="task-detail-button"
  //         aria-controls={open ? 'task-detail-menu' : undefined}
  //         aria-expanded={open ? 'true' : undefined}
  //         aria-haspopup="true"
  //         onClick={handleClick}
  //         variant="outlined"
  //         sx={{
  //           height: 40,
  //           width: 40,
  //           fontSize: '13px',
  //           minWidth: 0,
  //           borderRadius: 100,
  //         }}
  //       >
  //         <Icons.MoreVert />
  //       </Button>
  //       <Menu
  //         id="task-detail-menu"
  //         anchorEl={anchorEl}
  //         open={open}
  //         onClose={handleClose}
  //         MenuListProps={{
  //           'aria-labelledby': 'task-detail-button',
  //         }}
  //         sx={{
  //           '& .MuiPaper-root': {
  //             bgcolor: 'background.secondWithBlur',
  //             backdropFilter: 'blur(3px)',
  //             borderRadius: 2,
  //           },
  //         }}
  //       >
  //         {Object.entries(TaskStatus).map(([key, value]) => (
  //           <MenuItem key={key} onClick={() => null}>
  //             <Typography fontSize={'small'} fontWeight={'bold'}>
  //               {getTranslatedEnumValue(value)}
  //             </Typography>
  //           </MenuItem>
  //         ))}
  //       </Menu>
  //     </div>
  //   );
  // };

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="task-detail-button"
          aria-controls={open ? 'task-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          variant="contained"
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <Icons.RefreshRounded sx={{ mr: 1 }} />
          {t('crm.task.task.updateStatus')}
        </Button>
        <Menu
          id="task-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'task-detail-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              bgcolor: 'background.secondWithBlur',
              backdropFilter: 'blur(3px)',
              borderRadius: 2,
            },
          }}
        >
          {Object.entries(TaskStatus).map(([key, value]) => (
            <MenuItem key={key} onClick={() => handleTaskUpdate(value)}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {getTranslatedEnumValue(value)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Task base actions */}
      <StatusMenu />
      {/* Task menu actions */}
      {/* <DrawerMenu /> */}
    </Stack>
  );
};
