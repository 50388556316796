import { demoDomainRoutes } from "../domains/demo/routes/demo.base.route";
import { DemoDomainRouter } from "../domains/demo/routes/DemoDomainRouter";
// import LeadPlusLayout from "../layouts/LeadPlusLayout";
import { Route } from "react-router-dom";
import { DemoRouter } from "../domains/demo/subdomains/demo/routes/DemoRouter";
import LeadPlusLayout from "../layouts/LeadPlusLayout";

export const LeadPlusRouter = () => {
  return (
    <Route path={`/*`} element={<LeadPlusLayout />}>
      <Route index element={<DemoRouter />} />
      <Route
        path={`${demoDomainRoutes.base}/*`}
        element={<DemoDomainRouter />}
      />
      <Route path="*" element={<DemoDomainRouter />} />
    </Route>
  );
};
