export const preSalesDomainRoutes = {
  base: "preSale",
  subdomains: {
    estimate: {
      base: "estimate",
    },
    proposals: {
      base: "proposal",
    },
  },
};

export const basePreSalesRoute = (): string => `/${preSalesDomainRoutes.base}/`;
export const listEstimatesRoute = (): string =>
  `/${preSalesDomainRoutes.base}/${preSalesDomainRoutes.subdomains.estimate.base}`;
export const listProposalsRoute = (): string =>
  `/${preSalesDomainRoutes.base}/${preSalesDomainRoutes.subdomains.proposals.base}`;
