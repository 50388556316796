import { Route, Routes } from 'react-router-dom';
import { List } from '../pages';
import { ServicesLayout } from '../layouts/ServicesLayout';

export const ServicesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ServicesLayout />}>
        <Route index element={<List />} />
      </Route>
    </Routes>
  );
};
