
import { useState, lazy, Suspense } from 'react';

const LeadSourceCreateDrawer = lazy(() => import('./LeadSourceCreateDrawer'));

const LeadSourceCreateDrawerWrapper = ({ 
    open, 
    setOpen,
 }: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <LeadSourceCreateDrawer 
                    open={open} 
                    setOpen={setOpen} 
                    />
                </Suspense>
            )}
        </>
    );
};

export default LeadSourceCreateDrawerWrapper;
