import documentLocale from "../domains/document/locales/en";

const locale = {
  document: {
    ...documentLocale,
  },
  documento: "Request for Quote"
};

export default locale;
