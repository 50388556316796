
import reportLocale from "../subdomains/report/locales/tr";


const locale = {
  // Todo
  // users: {
  //   ...usersLocale,
  // },
  report: {
    ...reportLocale,
  },
};

export default locale;

