import { InputLabel, TextField } from "@mui/material";
import { IGraphqlVariables } from "corede-common";
import { HTMLInputTypeAttribute } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";



export const DefaultPhoneTextFieldInput = (props: {
    inputName: `input.${string}`;
    control: Control<IGraphqlVariables<any, any>>;
    label: string;
    isRequired?: boolean;
    errors: FieldErrors<IGraphqlVariables<any, any>>;
    touchedFields: Partial<Readonly<any>>;
    maxLength?: number;
}) => {

    const getNestedValue = (obj: any, path: string) => {
        return path.split(".").reduce((acc, key) => acc?.[key], obj);
    };

    return <>
        <InputLabel
            htmlFor={props.inputName}
            sx={{ ml: 1, mb: 0.5, fontSize: "small" }}
        >
            {props.label}{props.isRequired ? "*" : ""}
        </InputLabel>
        <Controller
            name={props.inputName}
            control={props.control}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={props.inputName}
                    fullWidth
                    size="small"
                    type="tel"
                    inputProps={{
                        maxLength: props.maxLength || 14, 
                    }}
                    onKeyDown={(e) => {
                        const key = e.key;
                        if (
                            !/[0-9]/.test(key) &&
                            key !== "Backspace" &&
                            key !== "Delete" &&
                            key !== "ArrowLeft" &&
                            key !== "ArrowRight"
                        ) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={!!getNestedValue(props.errors, props.inputName)}
                    helperText={
                        getNestedValue(props.touchedFields, props.inputName) &&
                        getNestedValue(props.errors, props.inputName)?.message
                    }
                />
            )}
        />
    </>
}