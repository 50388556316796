const locale = {
  contacts: 'Contacts',
  title: 'Contacts',
  createContact: 'Create Contact',
  columnId: '#',
  columnName: 'Name',
  columnIcon: 'Icon',
  columnDepartment: 'Department',
  columnParentCategory: 'Parent Category',
  columnActions: 'Actions',
  emptyStateContent1: 'Contacts not found',
  emptyStateContent2: 'Please create a new contact',
  selectIconTitle: 'Select an Icon',
  searchIcons: 'Search Icons',
  relatedContacts: 'Related Contacts',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Create Contact',
  contactNameLabel: 'Contact Name',
  contactEmailLabel: 'Contact Email',
  contactPhoneLabel: 'Contact Phone',
  contactTypeLabel: 'Contact Type',
  contactPictureLabel: 'Contact Picture',
  departmentLabel: 'Department',
  categoryLabel: 'Category',
  contactLanguageLabel: 'Contact Language',
  countryLabel: 'Country',
  stateLabel: 'State',
  cityLabel: 'City',
  uploadPictureText: 'Upload a picture',
  createContactButton: 'Create Contact',
  createSuccess: 'Contact created successfully',
  delete: 'Delete',
  deleteConfirm: 'Are you sure you want to delete this contact? This action cannot be undone.',
  deletedSuccessfully: 'Contact deleted successfully',
  cancel: 'Cancel',
  id: '#',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  type: 'Type',
  phone: 'Phone',
  position: 'Position',
  language: 'Language',
  update: 'Update Contact',
  updateContactButton: 'Update Contact',
  customerId: 'Select A Customer',
  primary: 'Primary',
  customer: 'Customer',
  actions: 'Actions',
  create: 'Create Contact',
  emptyState1: 'No contacts found here.',
  emptyState2: 'You can create or import contacts immediately.',
  deletePrimaryError: 'Primary contact cannot be deleted.',
  updateSuccess: 'Contact updated successfully',
};

export default locale;
