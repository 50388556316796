/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useLeadDeleteMutation } from "../context/lead.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const LeadDeleteDialog = memo(
  (props: {
    open: boolean;
    leadId: string;
    onClose: () => void;
    setLead: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      leadDelete,
      {
        data: leadDeleteData,
        isLoading: leadDeleteIsLoading,
        error: leadDeleteError,
      },
    ] = useLeadDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.leadId}
        setItem={props.setLead}
        deleteMutation={leadDelete}
        deleteMutationData={{
          data: leadDeleteData,
          isLoading: leadDeleteIsLoading,
          error: leadDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.delete"),
          successMessage: t("crm.lead.lead.deletedSuccessfully"),
          content: t("crm.lead.lead.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
