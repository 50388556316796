/* eslint-disable react-hooks/exhaustive-deps */
import {
  AssignmentReturnOutlined,
  DeleteOutlineRounded,
} from "@mui/icons-material";
import { MenuItem, Stack, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { t } from "i18next";

interface IJunkActionButtonComponentProps {
  isJunk?: boolean;
  leadUpdate: any;
}

export function JunkActionButtonComponent(
  props: IJunkActionButtonComponentProps
): (renderCellParams: GridRenderCellParams) => JSX.Element {
  const { isJunk, leadUpdate } = props;

  return (renderCellParams: GridRenderCellParams): JSX.Element => {
    return !isJunk ? (
      <MenuItem
        onClick={() => {
          leadUpdate({
            filter: { _id: renderCellParams.row?._id },
            input: { isJunk: true },
          });
        }}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <DeleteOutlineRounded color="warning" fontSize="small" />
          <Typography fontSize={"12px"} fontWeight={"bold"} ml={1}>
            {t("crm.lead.lead.junk")}
          </Typography>
        </Stack>
      </MenuItem>
    ) : (
      <MenuItem
        onClick={() => {
          leadUpdate({
            filter: { _id: renderCellParams.row?._id },
            input: { isJunk: false },
          });
        }}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <AssignmentReturnOutlined color="warning" fontSize="small" />
          <Typography fontSize={"12px"} fontWeight={"bold"} ml={1}>
            {t("crm.lead.lead.unJunk")}
          </Typography>
        </Stack>
      </MenuItem>
    );
  };
}
