/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useCustomerDeleteMutation } from "../context/customer.api";

export const CustomerDeleteDialog = memo(
  (props: { open: boolean; estimateId: string; onClose: () => void; setEstimate: Dispatch<any> }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      customerDelete,
      {
        data: customerDeleteData,
        isLoading: customerDeleteIsLoading,
        error: customerDeleteError,
      },
    ] = useCustomerDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.estimateId}
        setItem={props.setEstimate}
        deleteMutation={customerDelete}
        deleteMutationData={{
          data: customerDeleteData,
          isLoading: customerDeleteIsLoading,
          error: customerDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.customer.customer.delete"),
          successMessage: t("crm.customer.customer.deletedSuccessfully"),
          content: t("crm.customer.customer.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
