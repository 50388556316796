import { IHasId, IUser } from 'corede-common';
import { IUserDetailResult } from 'corede-common-cocrm';
import { useState, lazy, Suspense, SetStateAction, Dispatch } from 'react';

const UserDetailDrawer = lazy(() => import('./UserDetailDrawer'));

const UserDetailWrapper = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedUser: IUserDetailResult | undefined;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }


  return (
    <>
      {mounted && (
        <Suspense>
          <UserDetailDrawer
            open={props.open}
            setOpen={props.setOpen}
            selectedUser={props.selectedUser as IUserDetailResult & IHasId<number>}
            sideNavigationProps={props.sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default UserDetailWrapper;
