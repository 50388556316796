/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, Button, useMediaQuery, Theme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { IContactHistory, ILeadDetailResult } from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { representDateTimeAsString, representHtmlAsString } from 'utils/representationHelper';
import EmptyState from 'components/emptyState/EmptyState';
import { IContactItem, LeadContactDetailDialog } from './LeadContact-detail.dialog';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { LeadContactAddDialog } from './LeadContact-add.dialog';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import { AddCircle } from '@mui/icons-material';

interface ILeadContactHistoryListTabSectionComponent
  extends DefaultTabComponentProps<ILeadDetailResult> {
  contactHistoryList: IContactHistory[];
  tabKey: 'contactHistory';
  name: 'crm.lead.lead.contactHistory';
  entityData: ILeadDetailResult | undefined;
}

export const LeadContactHistoryListTabSectionComponent = (
  props: ILeadContactHistoryListTabSectionComponent,
) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<IContactItem | undefined>(undefined);

  const [openContactDetail, setOpenContactDetail] = useState(false);
  const [openAddLeadContact, setOpenAddLeadContact] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <DefaultTabContainer title={''}
    rightButton={
      props.contactHistoryList && props.contactHistoryList?.length > 0
        ? {
            title:  t('add'),
            onClick: () => setOpenAddLeadContact(true),
            icon: <AddCircle sx={{ mr: 1 }} />,
          }
        : undefined
    }
    >
      <Stack direction={'column'} gap={2} width={'100%'}>
     

        {props.contactHistoryList?.length > 0 ? (
          <BaseDataGrid
            rows={props.contactHistoryList.map((contact, index) => ({
              id: index + 1,
              _id: contact.contacted?._id,
              name: contact?.contacted?.name + ' ' + contact?.contacted?.surname,
              note: representHtmlAsString({ data: contact?.note }),
              contactedDate: representDateTimeAsString(contact?.contactedDate),
            }))}
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: '#',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'name',
                    editable: false,
                    filterable: false,
                    width: 100,
                  },
                  headerConfig: {
                    name: t('crm.name'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'note',
                    editable: false,
                    filterable: false,
                    width: 350,
                  },
                  headerConfig: {
                    name: t('crm.noteLabel'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'contactedDate',
                    filterable: false,
                    width: 120,
                  },
                  headerConfig: {
                    name: t('crm.lead.lead.contactedDate'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: {
                  clickConfig: {
                    setSelectedRow: (item) => setSelectedRow(item),
                    setOpenAction: () => setOpenContactDetail(true),
                  },
                },
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={false}
            listFilter={{
              filterInput: {},
              setFilterInput: () => null,
            }}
            count={props.contactHistoryList.length}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            update={{
              updateQuery: () => null,
            }}
            disableColumnFilter={true}
            hideFooterPagination={true}
            sortingMode={'client'}
          />
        ) : (
          <EmptyState
            icon={
              <img
                src={DocumentEmptyIcon}
                style={{
                  width: '180px',
                  height: '180px',
                  marginBottom: '30px',
                }}
                alt="There is no document yet."
              />
            }
            content1={t('crm.lead.lead.emptyStateContactContent1')}
            button1={{
              leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
              title: t('add'),
              onClick: () => setOpenAddLeadContact(true),
            }}
          />
        )}
      </Stack>

      <Overlays
        lead={props.entityData}
        selectedRow={selectedRow}
        openContactDetail={openContactDetail}
        setOpenContactDetail={setOpenContactDetail}
        openAddLeadContact={openAddLeadContact}
        setOpenAddLeadContact={setOpenAddLeadContact}
      />
    </DefaultTabContainer>
  );
};

const Overlays = ({
  lead,
  selectedRow,
  openContactDetail,
  setOpenContactDetail,
  openAddLeadContact,
  setOpenAddLeadContact,
}: any) => {
  return (
    <>
      <LeadContactDetailDialog
        open={openContactDetail}
        item={selectedRow}
        onClose={() => setOpenContactDetail(false)}
      />

      <LeadContactAddDialog
        open={openAddLeadContact}
        lead={lead}
        onClose={() => setOpenAddLeadContact(false)}
      />
    </>
  );
};
