const locale = {
  // fields
  users: "Remind Users",
  date: "Remind Date",
  title: "List Reminders",
  detail: "Reminder Detail",
  sharedList: "Shared Reminders",
  privateList: "Private Reminders",
  ownList: "My Reminders",
  allList: "All Reminders",

  // actions
  create: "Create Reminder",
  add: "Add Reminder",
  update: "Update Reminder",
  delete: "Delete Reminder",

  // situational text
  createSuccess: "Reminder created successfully",
  updateSuccess: "Reminder updated successfully",
  deletedSuccessfully: "Reminder deleted successfully",
  deleteContent: "Are you sure you want to delete this reminder. This action cannot be undone.",
  emptyState1: "No reminders added yet.",
  emptyState2: "Store and manage all reminder details here to streamline your invoicing and maintain clear agreements with your customers.",
};

export default locale;
