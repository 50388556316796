import { DemoDomainLayout } from '../layouts';
import { DemoRouter } from '../subdomains/demo/routes/DemoRouter';
import { demoDomainRoutes } from './demo.base.route';
import { Route, Routes } from 'react-router-dom';

export const DemoDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DemoDomainLayout />}>
        <Route index element={<DemoRouter />} />
        <Route path="*" element={<DemoRouter />} />
        <Route path={`${demoDomainRoutes.subdomains.demo.base}/*`} element={<DemoRouter />} />
      </Route>
    </Routes>
  );
};
