/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { InvoiceDetailDrawerRightPanelHeaderComponent } from './InvoiceDetailDrawer-rightPanel-header.component';
import { InvoiceDetailDrawerRightPanelTabsComponent } from './InvoiceDetailDrawer-rightPanel.tabs.component';

export interface IInvoiceDetailDrawerRightPanelComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
}

export const InvoiceDetailDrawerRightPanelComponent = (
  props: IInvoiceDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <InvoiceDetailDrawerRightPanelHeaderComponent invoiceDetailData={props.invoiceDetailData} />

      {/* Tabs Section */}
      <InvoiceDetailDrawerRightPanelTabsComponent invoiceDetailData={props.invoiceDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
