import { IDefaultTabsComponentProps } from "apps/crm/components/tabs/DefaultTabs.component";

/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface
 */
export interface IDefaultDetailTabsComponent<T>
  extends IDefaultTabsComponentProps<T> {
  tabNames: Map<string, string>;
  selectedTabName?: string;
}

/**
 * Container for the tab views in default detail pages.
 */
export const DefaultDetailTabsComponent = <T,>(
  props: IDefaultDetailTabsComponent<T>
) => {
  return (
    <>
      {props.children.map((tabComponent) => {
        if (
          props.selectedTabName ===
          props.tabNames.get(tabComponent.props.tabKey)
        ) {
          return tabComponent;
        }
        return null;
      })}
    </>
  );
};
