import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IInvoicePaymentListInput,
  IInvoicePaymentListRequest,
  IInvoicePaymentListResponse,
  IInvoicePaymentListResult,
  invoicePaymentListQuery,
  IInvoicePaymentDetailInput,
  IInvoicePaymentDetailRequest,
  IInvoicePaymentDetailResponse,
  IInvoicePaymentDetailResult,
  invoicePaymentDetailQuery,

  // mutations
  IInvoicePaymentAddInput,
  IInvoicePaymentAddRequest,
  IInvoicePaymentAddResponse,
  invoicePaymentAddQuery,
  IInvoicePaymentUpdateInput,
  IInvoicePaymentUpdateRequest,
  IInvoicePaymentUpdateResponse,
  invoicePaymentUpdateQuery,
  IInvoicePaymentUpdateFilterInput,
  IInvoicePaymentDeleteInput,
  IInvoicePaymentDeleteRequest,
  IInvoicePaymentDeleteResponse,
  invoicePaymentDeleteQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { paymentTags } from './payment.tags';
import { IGraphqlVariables, IBaseResult, IEntity } from 'corede-common';

export const invoicePaymentsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({

    invoicePaymentList: builder.query<
      IInvoicePaymentListResult,
      IGraphqlVariables<IInvoicePaymentListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoicePaymentListRequest,
        IInvoicePaymentListResponse,
        IInvoicePaymentListResult,
        IInvoicePaymentListInput
      >({
        query: invoicePaymentListQuery,
        providesTags: [paymentTags.payment, paymentTags.payments],
      }),
    ),

    invoicePaymentDetail: builder.query<
      IInvoicePaymentDetailResult,
      IGraphqlVariables<IInvoicePaymentDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoicePaymentDetailRequest,
        IInvoicePaymentDetailResponse,
        IInvoicePaymentDetailResult,
        IInvoicePaymentDetailInput
      >({
        query: invoicePaymentDetailQuery,
        providesTags: [paymentTags.payment, paymentTags.payments],
      }),
    ),

    // mutations

    invoicePaymentAdd: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoicePaymentAddInput, IEntity>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoicePaymentAddRequest,
        IInvoicePaymentAddResponse,
        IBaseResult,
        IInvoicePaymentAddInput,
        IEntity
      >({
        query: invoicePaymentAddQuery,
        invalidatesTags: [paymentTags.payment, paymentTags.payments],
      }),
    ),

    invoicePaymentUpdate: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoicePaymentUpdateInput, IInvoicePaymentUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoicePaymentUpdateRequest,
        IInvoicePaymentUpdateResponse,
        IBaseResult,
        IInvoicePaymentUpdateInput,
        IInvoicePaymentUpdateFilterInput
      >({
        query: invoicePaymentUpdateQuery,
        invalidatesTags: [paymentTags.payment, paymentTags.payments],
      }),
    ),

    invoicePaymentDelete: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoicePaymentDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoicePaymentDeleteRequest,
        IInvoicePaymentDeleteResponse,
        IBaseResult,
        IInvoicePaymentDeleteInput
      >({
        query: invoicePaymentDeleteQuery,
        invalidatesTags: [paymentTags.payments],
      }),
    ),
  }),
});

export const {
  useInvoicePaymentListQuery,
  useLazyInvoicePaymentListQuery,
  useInvoicePaymentDetailQuery,
  useLazyInvoicePaymentDetailQuery,
  useInvoicePaymentAddMutation,
  useInvoicePaymentUpdateMutation,
  useInvoicePaymentDeleteMutation,
} = invoicePaymentsApi;
