/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Grow,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FilledButton, MyTab, SettingsTitle } from 'components';
import {
  Currency,
  ExchangeRateMode,
  IGraphqlVariables,
  Language,
  unknownError,
  IFileMetadata,
} from 'corede-common';
import {
  DateFormat,
  IOrganizationSettingsUpdateInput,
  IOrganizationUpdateInput,
  TimeFormat,
} from 'corede-common-cocrm';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useOrganizationDetailOwnQuery,
  useOrganizationLogoAssignMutation,
  useOrganizationSettingsDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
  useOrganizationUpdateOwnMutation,
  useOrganizationLogoCreateMutation,
} from '../context/setting.api';
import { convertEnums, getCurrentLanguage } from 'localization';
import { RefreshOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { UploadImageComponent } from 'components/uploadFile/UploadImage';
import ActionDialog from 'components/dialog/ActionDialog';
import Company from 'assets/images/company.png';
import {
  useCountryListQuery,
  useLazyCityListQuery,
  useLazyStateListQuery,
} from 'apps/auth/context';
import { useTranslation } from 'react-i18next';
import { validateGeneralFormInput } from '../validations/update.validation';

const Organization = () => {
  // useEffect(() => {
  //   fetch('https://ipapi.co/json/')
  //     .then(response => response.json())
  //     .then(data => {
  //     })
  //     .catch(error => console.error('Error fetching IP information:', error));
  // }, []);

  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const timezones = momentTimezone.tz.names();
  const [tabLabels] = useState([
    t('settings.settings.settings.general'),
    t('settings.settings.settings.localizations'),
    t('settings.settings.settings.finance'),
  ]);

  const [selectedTabName, setSelectedTabName] = useState(t('settings.settings.settings.general'));
  const [openChangeImage, setOpenChangeImage] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);

  const localizedCurrencies = convertEnums(Currency);
  const localizedLanguages = convertEnums(Language);
  const localizedExchangeRateModes = convertEnums(ExchangeRateMode);

  const { data: countryListData } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData }] = useLazyStateListQuery({});
  const [getCityList, { data: cityListData }] = useLazyCityListQuery({});
  const { data: organizationDetailOwnData, refetch: organizationDetailOwnRefetch } =
    useOrganizationDetailOwnQuery({});
  const { data: organizationSettingsDetailOwnData, refetch: organizationSettingsDetailOwnRefetch } =
    useOrganizationSettingsDetailOwnQuery({});
  const [
    organizationUpdateOwn,
    {
      data: organizationUpdateOwnData,
      isLoading: organizationUpdateOwnLoading,
      error: organizationUpdateOwnError,
    },
  ] = useOrganizationUpdateOwnMutation();

  const [
    organizationSettingsUpdateOwn,
    {
      data: organizationSettingsUpdateOwnData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();

  const handleSaveLogo = async () => {};

  // General Settings

  const generalFormInitialValues = useMemo(
    () => ({
      input: {
        name: undefined,
        legalName: undefined,
        summary: undefined,
        website: undefined,
        addresses: [
          {
            address: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            fullName: '',
            phone: '',
            street: '',
          },
        ],
        phone: undefined,
        email: undefined,
        socialMedias: {
          facebook: undefined,
          instagram: undefined,
          linkedin: undefined,
          x: undefined,
          youtube: undefined,
        },
      },
    }),
    [],
  );

  const generalForm = useForm<IGraphqlVariables<IOrganizationUpdateInput>>({
    defaultValues: generalFormInitialValues,
    resolver: yupResolver(validateGeneralFormInput),
    mode: 'onChange',
  });

  const generalFormOnSubmit = async (values: IGraphqlVariables<IOrganizationUpdateInput>) => {
    organizationUpdateOwn({
      input: values.input,
    });
  };

  useEffect(() => {
    if (organizationDetailOwnData) {
      generalForm.setValue('input.name', organizationDetailOwnData?.name);
      generalForm.setValue('input.legalName', organizationDetailOwnData?.legalName);
      generalForm.setValue('input.summary', organizationDetailOwnData?.summary);
      generalForm.setValue('input.website', organizationDetailOwnData?.website);
      generalForm.setValue('input.phone', organizationDetailOwnData?.phone);
      generalForm.setValue('input.email', organizationDetailOwnData?.email);
      generalForm.setValue('input.socialMedias', organizationDetailOwnData?.socialMedias);
      generalForm.setValue(
        'input.addresses.0.address',
        organizationDetailOwnData?.addresses?.[0]?.address ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.country',
        organizationDetailOwnData?.addresses?.[0]?.country ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.state',
        organizationDetailOwnData?.addresses?.[0]?.state ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.city',
        organizationDetailOwnData?.addresses?.[0]?.city ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.zip',
        organizationDetailOwnData?.addresses?.[0]?.zip ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.fullName',
        organizationDetailOwnData?.addresses?.[0]?.fullName ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.phone',
        organizationDetailOwnData?.addresses?.[0]?.phone ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.street',
        organizationDetailOwnData?.addresses?.[0]?.street ?? '',
      );

      // setAddresses(organizationDetailOwnData?.addresses ?? []);
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (organizationDetailOwnData?.addresses?.[0]?.country) {
      getStateList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.addresses?.[0]?.country,
          },
        },
      });
    }
  }, [organizationDetailOwnData?.addresses?.[0]?.country]);

  useEffect(() => {
    if (generalForm.watch('input.addresses.0.country')) {
      getStateList({
        input: {
          filter: {
            country_name: generalForm.watch('input.addresses.0.country'),
          },
        },
      });
    }
  }, [generalForm.watch('input.addresses.0.country')]);

  useEffect(() => {
    if (organizationDetailOwnData?.addresses?.[0]?.state) {
      getStateList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.addresses?.[0]?.country,
            state_name: organizationDetailOwnData?.addresses?.[0]?.state,
          },
        },
      });
    }
  }, [
    organizationDetailOwnData?.addresses?.[0]?.state,
    organizationDetailOwnData?.addresses?.[0]?.country,
  ]);

  useEffect(() => {
    if (generalForm.watch('input.addresses.0.state')) {
      getCityList({
        input: {
          filter: {
            state_name: generalForm.watch('input.addresses.0.state'),
          },
        },
      });
    }
  }, [generalForm.watch('input.addresses.0.state')]);

  useEffect(() => {
    if (organizationUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
      organizationDetailOwnRefetch();
    }
  }, [organizationUpdateOwnData]);

  useEffect(() => {
    if (organizationUpdateOwnError) {
      enqueueSnackbar(
        (organizationUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationUpdateOwnError]);

  // Localizations

  const localizationsFormInitialValues = useMemo(
    () => ({
      input: {
        common: {
          timeZone: undefined,
          language: undefined,
          dateFormat: undefined,
          timeFormat: undefined,
        },
      },
    }),
    [],
  );

  const localizationsForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    defaultValues: localizationsFormInitialValues,
    // resolver: yupResolver(validateOrganizationSettingsUpdateInput),
    mode: 'onSubmit',
  });

  const localizationsFormOnSubmit = (
    values: IGraphqlVariables<IOrganizationSettingsUpdateInput>,
  ) => {
    organizationSettingsUpdateOwn({ input: values.input });
  };

  useEffect(() => {
    if (organizationSettingsDetailOwnData) {
      localizationsForm.setValue(
        'input.common.timeZone',
        organizationSettingsDetailOwnData?.common?.timeZone,
      );
      localizationsForm.setValue(
        'input.common.language',
        organizationSettingsDetailOwnData?.common?.language,
      );
      localizationsForm.setValue(
        'input.common.dateFormat',
        organizationSettingsDetailOwnData?.common?.dateFormat,
      );
      localizationsForm.setValue(
        'input.common.timeFormat',
        organizationSettingsDetailOwnData?.common?.timeFormat,
      );
    }
  }, [organizationSettingsDetailOwnData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateOwnData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnError) {
      enqueueSnackbar(
        (organizationSettingsUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationSettingsUpdateOwnError]);

  // Finance

  const financeFormInitialValues = useMemo(
    () => ({
      input: {
        common: {
          // taxOffice: undefined,
          // vatNumber: undefined,
          exchangeRates: {
            mode: ExchangeRateMode.automatic,
            adjustment: undefined,
            currency: Currency.USD,
            manualRates: [
              {
                fromCurrency: Currency.USD,
                toCurrency: Currency.TRY,
                rate: 35,
                updatedAt: new Date(),
              },
            ],
          },
        },
      },
    }),
    [],
  );

  const financeForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    defaultValues: financeFormInitialValues,
    // resolver: yupResolver(validateOrganizationSettingsUpdateInput),
    mode: 'onChange',
  });

  const financeFormOnSubmit = (values: IGraphqlVariables<IOrganizationSettingsUpdateInput>) => {
    organizationSettingsUpdateOwn({ input: values.input });
  };

  useEffect(() => {
    if (organizationSettingsDetailOwnData) {
      financeForm.setValue(
        'input.common.exchangeRates.mode',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.mode,
      );
      financeForm.setValue(
        'input.common.currency',
        organizationSettingsDetailOwnData?.common?.currency,
      );
      financeForm.setValue(
        'input.common.adjustment',
        organizationSettingsDetailOwnData?.common?.adjustment,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.fromCurrency',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.fromCurrency ??
          Currency.USD,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.toCurrency',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.toCurrency ??
          Currency.TRY,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.rate',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.rate ?? 35,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.updatedAt',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.updatedAt ??
          new Date(),
      );
    }
  }, [organizationSettingsDetailOwnData]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <MyTab
          type="settings"
          labels={tabLabels}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
        />
      </Stack>
      {selectedTabName === t('settings.settings.settings.general') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.general')} />
          <Grid item xs={12} md={3}>
            <Grow in={!!organizationDetailOwnData} timeout={500}>
              <Card
                sx={{
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                  py: 10,
                }}
              >
                <Stack
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={2}
                  width={'100%'}
                >
                  <InputLabel
                    htmlFor="input.name"
                    sx={{ ml: 1, mb: 0.5, fontSize: 'small', cursor: 'pointer' }}
                  >
                    {t('settings.settings.settings.organizationLogo')}
                  </InputLabel>
                  <Box
                    sx={{
                      border: '1px dashed',
                      borderColor: 'primary.light',
                      borderRadius: '55px',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenChangeImage(true)}
                  >
                    <Avatar
                      key={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
                      src={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
                      sx={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '55px',
                        border: '5px solid',
                        borderColor: 'background.secondary',
                      }}
                    >
                      <img src={Company} alt="company" style={{ width: '100%', height: '100%' }} />
                    </Avatar>
                  </Box>
                </Stack>
              </Card>
            </Grow>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grow in={selectedTabName === t('settings.settings.settings.general')} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.generalInfo')}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={1.4}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="generalForm.input.name"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.name')}
                        </InputLabel>
                        <Controller
                          name="input.name"
                          control={generalForm.control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="input.name"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                generalForm.setValue(
                                  'input.name',
                                  e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                                );
                              }}
                              error={!!generalForm.formState.errors.input?.name}
                              helperText={generalForm.formState.errors.input?.name?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="generalForm.input.legalName"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.legalName')}
                        </InputLabel>
                        <Controller
                          name="input.legalName"
                          control={generalForm.control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="input.legalName"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                generalForm.setValue(
                                  'input.legalName',
                                  e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                                );
                              }}
                              error={!!generalForm.formState.errors.input?.legalName}
                              helperText={generalForm.formState.errors.input?.legalName?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="generalForm.input.email"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.email')}
                        </InputLabel>
                        <Controller
                          name="input.email"
                          control={generalForm.control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="input.email"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                generalForm.setValue('input.email', e.target.value);
                              }}
                              error={!!generalForm.formState.errors.input?.email}
                              helperText={generalForm.formState.errors.input?.email?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.summary"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.summary')}
                    </InputLabel>
                    <Controller
                      name="input.summary"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.summary"
                          size="small"
                          multiline
                          rows={7}
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue(
                              'input.summary',
                              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                            );
                          }}
                          error={!!generalForm.formState.errors.input?.summary}
                          helperText={generalForm.formState.errors.input?.summary?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.website"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.website')}
                    </InputLabel>
                    <Controller
                      name="input.website"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.website"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.website', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.website}
                          helperText={generalForm.formState.errors.input?.website?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.phone"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.phone')}
                    </InputLabel>
                    <Controller
                      name="input.phone"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.phone"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.phone', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.phone}
                          helperText={generalForm.formState.errors.input?.phone?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.address')}
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="generalForm.input.addresses.0.address"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.address')}
                    </InputLabel>
                    <Controller
                      name="input.addresses.0.address"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.addresses.0.address"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.addresses.0.address', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.addresses?.[0]?.address}
                          helperText={
                            !!generalForm.formState.errors.input?.addresses?.[0]?.address?.message
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor="generalForm.input.addresses.0.country"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {' '}
                      {t('settings.settings.settings.country')}
                    </InputLabel>
                    <Controller
                      name="input.addresses.0.country"
                      control={generalForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          options={[...(countryListData?.data || [])]}
                          size="small"
                          fullWidth
                          id="input.country"
                          isOptionEqualToValue={(option, value) => option.name === value?.name}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue.name);
                            } else {
                              field.onChange('');
                            }
                          }}
                          value={
                            field.value
                              ? countryListData?.data?.find(
                                  (country) => country.name === field.value,
                                ) || null
                              : null
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor="generalForm.input.addresses.0.state"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.state')}
                    </InputLabel>
                    <Controller
                      name="input.addresses.0.state"
                      control={generalForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          options={[...(stateListData?.data || [])]}
                          size="small"
                          fullWidth
                          id="input.state"
                          isOptionEqualToValue={(option, value) => option.name === value?.name}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue.name);
                            } else {
                              field.onChange('');
                            }
                          }}
                          value={
                            field.value
                              ? stateListData?.data?.find((state) => state.name === field.value) ||
                                null
                              : null
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor="generalForm.input.addresses.0.city"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.city')}
                    </InputLabel>
                    <Controller
                      name="input.addresses.0.city"
                      control={generalForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          options={[...(cityListData?.data || [])]}
                          size="small"
                          fullWidth
                          id="input.city"
                          isOptionEqualToValue={(option, value) => option.name === value?.name}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue.name);
                            } else {
                              field.onChange('');
                            }
                          }}
                          value={
                            field.value
                              ? cityListData?.data?.find((city) => city.name === field.value) ||
                                null
                              : null
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor="generalForm.input.addresses.0.zip"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.zip')}
                    </InputLabel>
                    <Controller
                      name="input.addresses.0.zip"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.addresses.0.zip"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.addresses.0.zip', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.addresses?.[0]?.zip}
                          helperText={
                            !!generalForm.formState.errors.input?.addresses?.[0]?.zip?.message
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.socialMedia')}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.socialMedias.facebook"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      Facebook
                    </InputLabel>
                    <Controller
                      name="input.socialMedias.facebook"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.socialMedias.facebook"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.socialMedias.facebook', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.socialMedias?.facebook}
                          helperText={
                            generalForm.formState.errors.input?.socialMedias?.facebook?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.socialMedias.instagram"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      Instagram
                    </InputLabel>
                    <Controller
                      name="input.socialMedias.instagram"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.socialMedias.instagram"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.socialMedias.instagram', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.socialMedias?.instagram}
                          helperText={
                            generalForm.formState.errors.input?.socialMedias?.instagram?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.socialMedias.linkedin"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      Linkedin
                    </InputLabel>
                    <Controller
                      name="input.socialMedias.linkedin"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.socialMedias.linkedin"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.socialMedias.linkedin', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.socialMedias?.linkedin}
                          helperText={
                            generalForm.formState.errors.input?.socialMedias?.linkedin?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.socialMedias.x"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      X
                    </InputLabel>
                    <Controller
                      name="input.socialMedias.x"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.socialMedias.x"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.socialMedias.x', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.socialMedias?.x}
                          helperText={generalForm.formState.errors.input?.socialMedias?.x?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      htmlFor="generalForm.input.socialMedias.youtube"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      Youtube
                    </InputLabel>
                    <Controller
                      name="input.socialMedias.youtube"
                      control={generalForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.socialMedias.youtube"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            generalForm.setValue('input.socialMedias.youtube', e.target.value);
                          }}
                          error={!!generalForm.formState.errors.input?.socialMedias?.youtube}
                          helperText={
                            generalForm.formState.errors.input?.socialMedias?.youtube?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <FilledButton
                      leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                      color="primary"
                      loading={organizationUpdateOwnLoading}
                      title={t('settings.settings.settings.updateSettings')}
                      onClick={generalForm.handleSubmit(generalFormOnSubmit)}
                    />
                  </Stack>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
      {selectedTabName === t('settings.settings.settings.localizations') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.localizations')} />

          <Grid item xs={12} lg={6}>
            <Grow
              in={selectedTabName === t('settings.settings.settings.localizations')}
              timeout={500}
            >
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="timezone-autocomplete"
                      sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                    >
                      {t('settings.settings.settings.timezone')}*
                    </InputLabel>
                    <Controller
                      name="input.common.timeZone"
                      control={localizationsForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          {...field}
                          id="timezone-autocomplete"
                          size="small"
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          options={timezones.map((timezone) => ({
                            name: timezone,
                            _id: timezone,
                          }))}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} />}
                          disableClearable
                          value={field.value ? { name: field.value, _id: field.value } : undefined}
                          onChange={(e, value) => {
                            field.onChange(value?._id);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="language" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                      {t('settings.settings.settings.language')}
                    </InputLabel>
                    <Controller
                      name="input.common.language"
                      control={localizationsForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          id="language"
                          size="small"
                          fullWidth
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          options={Object.values(Language).map((language) => ({
                            name: localizedLanguages[language as keyof typeof Language],
                            _id: language,
                          }))}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            field.value
                              ? {
                                  name: localizedLanguages[field.value as keyof typeof Language],
                                  _id: field.value,
                                }
                              : null
                          }
                          onChange={(e, value) => {
                            field.onChange(value?._id);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="dateFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                      {t('settings.settings.settings.dateFormat')}
                    </InputLabel>
                    <Controller
                      name="input.common.dateFormat"
                      control={localizationsForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          {...field}
                          id="dateFormat"
                          size="small"
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          options={Object.values(DateFormat).map((dateFormat) => ({
                            name: dateFormat,
                            value: dateFormat,
                          }))}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} />}
                          disableClearable
                          value={
                            field.value ? { name: field.value, value: field.value } : undefined
                          }
                          onChange={(e, value) => {
                            field.onChange(value?.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="timeFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                      {t('settings.settings.settings.timeFormat')}
                    </InputLabel>
                    <Controller
                      name="input.common.timeFormat"
                      control={localizationsForm.control}
                      render={({ field }) => (
                        <Autocomplete
noOptionsText={t("noOptionsText")}
                          {...field}
                          id="timeFormat"
                          size="small"
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          options={Object.values(TimeFormat).map((timeFormat) => ({
                            name: timeFormat,
                            value: timeFormat,
                          }))}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} />}
                          disableClearable
                          value={
                            field.value ? { name: field.value, value: field.value } : undefined
                          }
                          onChange={(e, value) => {
                            field.onChange(value?.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/*   <Grid item xs={12} >
                  <InputLabel htmlFor="timeFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("settings.settings.settings.timeFormat")}</InputLabel>
                  <Controller
                    name="input.common.timeFormat"
                    control={commonForm.control}
                    render={({ field }) => (
                      <Autocomplete
noOptionsText={t("noOptionsText")}
                        id="language"
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        options={Object.values(Language).map((language) => ({
                          name: localizedLanguages[language as keyof typeof Language],
                          _id: language,
                        }))}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          field.value
                            ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
                            : null
                        }
                        onChange={(e, value) => {
                          field.onChange(value?._id);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputLabel htmlFor="locale" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("settings.settings.settings.locale")}*
                  </InputLabel>
                  <Controller
                    name="input.common.locale"
                    control={commonForm.control}
                    render={({ field }) => (
                      <Autocomplete
noOptionsText={t("noOptionsText")}
                        id="locale"
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={Object.keys(Locale).map((key) => ({
                          value: key,
                          label: key,
                        }))}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => <TextField {...params} />}
                        value={field.value ? { value: field.value, label: field.value } : null}
                        onChange={(e, value) => {
                          field.onChange(value?.value);
                        }}
                      />
                    )}
                  />
                </Grid>*/}
                  <Grid item xs={12} mt={2}>
                    <Stack direction={'row'} justifyContent={'flex-end'}>
                      <FilledButton
                        leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                        color="primary"
                        loading={organizationSettingsUpdateOwnLoading}
                        title={t('settings.settings.settings.updateSettings')}
                        onClick={localizationsForm.handleSubmit(localizationsFormOnSubmit)}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
      {
        selectedTabName === t('settings.settings.settings.finance') && (
          <Grid container spacing={2} px={2}>
            <SettingsTitle selectedTabName={t('settings.settings.settings.finance')} />
            <Grid item xs={12} lg={6}>
              <Grow in={selectedTabName === t('settings.settings.settings.finance')} timeout={500}>
                <Card
                  sx={{
                    p: 2,
                    border: '1px solid',
                    bgcolor: 'background.thirdWithBlur',
                    borderColor: 'primary.lighter',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="taxOffice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t('settings.settings.settings.taxOffice')}*
                      </InputLabel>
                      <Controller
                        name="input.common.timeZone"
                        control={financeForm.control}
                        disabled
                        render={({ field }) => (
                          <Autocomplete
noOptionsText={t("noOptionsText")}
                            {...field}
                            id="timezone-autocomplete"
                            disabled
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={timezones.map((timezone) => ({
                              name: timezone,
                              _id: timezone,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            disableClearable
                            value={
                              field.value ? { name: field.value, _id: field.value } : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="vatNumber" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t('settings.settings.settings.vatNumber')}
                      </InputLabel>
                      <Controller
                        name="input.common.language"
                        control={financeForm.control}
                        disabled
                        render={({ field }) => (
                          <Autocomplete
noOptionsText={t("noOptionsText")}
                            id="language"
                            size="small"
                            disabled
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(Language).map((language) => ({
                              name: localizedLanguages[language as keyof typeof Language],
                              _id: language,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? {
                                    name: localizedLanguages[field.value as keyof typeof Language],
                                    _id: field.value,
                                  }
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="input.common.currency"
                        sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                      >
                        {t('settings.settings.settings.currency')}
                      </InputLabel>
                      <Controller
                        name="input.common.currency"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
noOptionsText={t("noOptionsText")}
                            id="financeForm"
                            disableClearable
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            size="small"
                            options={Object.values(Currency).map((currency) => ({
                              name: localizedCurrencies[currency as keyof typeof Currency],
                              _id: currency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? {
                                    name: localizedCurrencies[field.value as keyof typeof Currency],
                                    _id: field.value,
                                  }
                                : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="adjustment" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t('settings.settings.settings.adjustment')}
                      </InputLabel>
                      <Controller
                        name="input.common.adjustment"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
noOptionsText={t("noOptionsText")}
                            id="adjustment"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option === value}
                            options={[0, 1, 2, 3]}
                            getOptionLabel={(option) => option.toString()}
                            renderInput={(params) => <TextField {...params} />}
                            value={field.value}
                            onChange={(e, value) => {
                              field.onChange(value);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor="exchangeRateMode"
                        sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                      >
                        {t('settings.settings.settings.exchangeRateMode')}
                      </InputLabel>
                      <Controller
                        name="input.common.exchangeRates.mode"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
noOptionsText={t("noOptionsText")}
                            id="mode"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(ExchangeRateMode).map((mode) => ({
                              name: localizedExchangeRateModes[
                                mode as keyof typeof ExchangeRateMode
                              ],
                              _id: mode,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? {
                                    name: localizedExchangeRateModes[
                                      field.value as keyof typeof ExchangeRateMode
                                    ],
                                    _id: field.value,
                                  }
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="input.common.currency"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.fromCurrency')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.fromCurrency"
                          control={financeForm.control}
                          render={({ field }) => (
                            <Autocomplete
noOptionsText={t("noOptionsText")}
                              id="financeForm"
                              disableClearable
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              size="small"
                              options={Object.values(Currency).map((currency) => ({
                                name: localizedCurrencies[currency as keyof typeof Currency],
                                _id: currency,
                              }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                field.value
                                  ? {
                                      name: localizedCurrencies[
                                        field.value as keyof typeof Currency
                                      ],
                                      _id: field.value,
                                    }
                                  : undefined
                              }
                              onChange={(e, value) => {
                                field.onChange(value?._id);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="input.common.currency"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.toCurrency')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.toCurrency"
                          control={financeForm.control}
                          render={({ field }) => (
                            <Autocomplete
noOptionsText={t("noOptionsText")}
                              id="financeForm"
                              disableClearable
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              size="small"
                              options={Object.values(Currency).map((currency) => ({
                                name: localizedCurrencies[currency as keyof typeof Currency],
                                _id: currency,
                              }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                field.value
                                  ? {
                                      name: localizedCurrencies[
                                        field.value as keyof typeof Currency
                                      ],
                                      _id: field.value,
                                    }
                                  : undefined
                              }
                              onChange={(e, value) => {
                                field.onChange(value?._id);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel htmlFor="rate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                          {t('settings.settings.settings.rate')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.rate"
                          control={financeForm.control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              type="number"
                              fullWidth
                              {...field}
                              onChange={(e) => {
                                const value =
                                  e.target.value === '' ? '' : parseFloat(e.target.value);
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel htmlFor="updatedAt" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                          {t('settings.settings.settings.updatedAt')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.updatedAt"
                          control={financeForm.control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={field.value ? moment(field.value) : null}
                              onChange={(date) => field.onChange(date)}
                              maxDate={moment()}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                      <Stack direction={'row'} justifyContent={'flex-end'}>
                        <FilledButton
                          leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                          color="primary"
                          loading={organizationSettingsUpdateOwnLoading}
                          title={t('settings.settings.settings.updateSettings')}
                          onClick={financeForm.handleSubmit(financeFormOnSubmit)}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grow>
            </Grid>
          </Grid>
        )
        //   <Grid item xs={12} lg={6}>

        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="dateFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("settings.settings.settings.dateFormat")}</InputLabel>
        //             <Controller
        //               name="input.common.dateFormat"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        // noOptionsText={t("noOptionsText")}
        //                   id="language"
        //                   size="small"
        //                   fullWidth
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   options={Object.values(Language).map((language) => ({
        //                     name: localizedLanguages[language as keyof typeof Language],
        //                     _id: language,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
        //                       : null
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="timeFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("settings.settings.settings.timeFormat")}</InputLabel>
        //             <Controller
        //               name="input.common.timeFormat"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="language"
        //                   size="small"
        //                   fullWidth
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   options={Object.values(Language).map((language) => ({
        //                     name: localizedLanguages[language as keyof typeof Language],
        //                     _id: language,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
        //                       : null
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="input.common.adjustment" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.adjustment")}*
        //             </InputLabel>
        //             <Controller
        //               name="input.common.adjustment"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <TextField
        //                   {...field}
        //                   id="input.common.adjustment"
        //                   size="small"
        //                   fullWidth
        //                   required
        //                   type="number"
        //                   inputProps={{
        //                     step: "0.01",
        //                   }}
        //                   onChange={(e) => {
        //                     const value = parseFloat(e.target.value);
        //                     field.onChange(value);
        //                   }}
        //                   error={!!commonForm.formState.errors.input?.common?.adjustment}
        //                   helperText={commonForm.formState.errors.input?.common?.adjustment?.message}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12}  >
        //             <InputLabel htmlFor="input.common.currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.currency")}*
        //             </InputLabel>
        //             <Controller
        //               name="input.common.currency"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
        //                       : undefined
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12}  >
        //             <InputLabel htmlFor="input.common.currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.taxRate")}
        //             </InputLabel>
        //             <Controller
        //               name="input.common.currency"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
        //                       : undefined
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} mt={2} sx={{
        //             p: 1,
        //             boxShadow: 'none',
        //             borderRadius: "16px",
        //             border: '1px solid',
        //             borderColor: 'primary.light',
        //           }}>
        //             <Grid container spacing={1}>
        //               <Grid item xs={12}>
        //                 <Box>
        //                   <Typography color={"text.primary"} fontSize={14} fontWeight={600}>{t("settings.settings.settings.exchangeRates")}</Typography>
        //                   <Divider />
        //                 </Box>
        //               </Grid>
        //               <Grid item xs={12}>
        //                 {
        //                   manualRates?.map((item: any, index: number) => {
        //                     return (
        //                       <Stack direction="row" spacing={1} px={1} key={index} alignItems={"center"}>
        //                         <Typography key={index}>
        //                           {item.fromCurrency} -&gt; {item.toCurrency} : {item.rate} | {moment(item.updatedAt).format('YYYY-MM-DD')}
        //                         </Typography>
        //                         <IconButton
        //                           size="small"
        //                           color="error"
        //                           onClick={() => {
        //                             const updatedManualRates = manualRates.filter((id: any) => id !== item);
        //                             setManualRates(updatedManualRates);
        //                           }}
        //                         >
        //                           <Close fontSize="small" />
        //                         </IconButton>
        //                       </Stack>
        //                     )
        //                   })
        //                 }
        //               </Grid>
        //               <Grid item xs={4}>
        //                 <InputLabel htmlFor="fromCurrency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.fromCurrency")}
        //                 </InputLabel>
        //                 <Autocomplete
//noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={manualRate?.toCurrency ? { name: localizedCurrencies[manualRate?.toCurrency as keyof typeof Currency], _id: manualRate?.toCurrency } : undefined}
        //                   onChange={(e, value) => {
        //                     setManualRate(manualRate ? { ...manualRate, toCurrency: value?._id } : undefined);
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={4}>
        //                 <InputLabel htmlFor="rate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.rate")}
        //                 </InputLabel>
        //                 <TextField
        //                   id="rate"
        //                   fullWidth
        //                   type="number"
        //                   size="small"
        //                   variant="outlined"
        //                   value={manualRate?.rate !== undefined ? manualRate.rate : ''}
        //                   onChange={(e) => {
        //                     const value = e.target.value;
        //                     if (value === '') {
        //                       setManualRate(manualRate ? { ...manualRate, rate: parseFloat("0") } : undefined);
        //                     } else {
        //                       setManualRate(manualRate ? { ...manualRate, rate: parseFloat(value) } : undefined);
        //                     }
        //                   }}
        //                   inputProps={{
        //                     step: 0.01,
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={6}>
        //                 <InputLabel htmlFor="updatedAt" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.updatedAt")}
        //                 </InputLabel>
        //                 <DatePicker
        //                   slotProps={{
        //                     textField: {
        //                       size: 'small',
        //                       fullWidth: true,
        //                     },
        //                   }}
        //                   value={manualRate?.updatedAt ? moment(manualRate?.updatedAt) : undefined}
        //                   onChange={(date) => {
        //                     setManualRate(manualRate ? { ...manualRate, updatedAt: date?.toDate?.() ?? new Date() } : undefined);
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={6}>
        //                 <Box height={20} />
        //                 <OutlinedButton
        //                   leftIcon={<Add />}
        //                   color="tertiary"
        //                   fullWidth
        //                   title={"Add"}
        //                   onClick={() => {
        //                     if (manualRate) {
        //                       const isDuplicate = manualRates && manualRates?.some(
        //                         (rate) =>
        //                           rate.fromCurrency === manualRate.fromCurrency &&
        //                           rate.toCurrency === manualRate.toCurrency &&
        //                           rate.rate === manualRate.rate &&
        //                           rate.updatedAt === manualRate.updatedAt
        //                       );
        //                       if (!isDuplicate && manualRates) {
        //                         setManualRates([...manualRates, manualRate]);
        //                       }
        //                       if (!isDuplicate && !manualRates) {
        //                         setManualRates([manualRate]);
        //                       }

        //                     }
        //                   }}
        //                 />
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //
        //         </Grid>
        //       </Card>
        //     </Grow>
        //   </Grid>
        // </Grid>
      }

      <UploadImageComponent
        currentImage={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
        setLoading={setUploadLoading}
        useImageCreateMutation={useOrganizationLogoCreateMutation}
        useImageAssignMutation={useOrganizationLogoAssignMutation}
        open={openChangeImage}
        setOpen={setOpenChangeImage}
      />
    </Stack>
  );
};

export default Organization;
