/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Stack,
  IconButton,
  Checkbox,
  TextField,
} from '@mui/material';
import { AuthLayout } from '../layouts';
import { useRegisterMutation, setUser, useLatestPolicyQuery } from '../context';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'context';
import { useEffect, useRef, useState } from 'react';
import { Iconify } from 'components';
import LoginCo from 'assets/images/login-co.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes/routes';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';
import { getCurrentLanguage } from 'localization';
import { IRegisterInput, PolicyDomain, PolicyTarget, PolicyType } from 'corede-common-cocrm';
import parse from 'html-react-parser';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import { Turnstile } from '@marsidev/react-turnstile';
import { validateSignupInput } from '../validations/signup.validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

const Signup = () => {
  const ref = useRef<any>();
  const [token, setToken] = useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const [getRegister, { data, isLoading, error }] = useRegisterMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = useState<string[]>([]);
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState<boolean>(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState<boolean>(false);
  const [openExplicitPolicy, setOpenExplicitPolicy] = useState<boolean>(false);
  const [openKvkkPolicy, setOpenKvkkPolicy] = useState<boolean>(false);
  const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType>(PolicyType.terms);

  const { data: latestPolicyData, isLoading: latestPolicyLoading } = useLatestPolicyQuery({
    input: {
      domain: PolicyDomain.app,
      language: currentLanguage,
      target: PolicyTarget.general,
      type: selectedPolicyType,
    },
  });

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter((item) => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  const signupForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(validateSignupInput),
    defaultValues: {
      input: {
        name: undefined,
        surname: undefined,
        email: '',
        password: '',
        passwordAgain: '',
        role: 'user',
        referrerCode: undefined,
        isChecked: false,
        isChecked2: false,
      },
    },
    reValidateMode: 'onChange',
  });

  const handleSignup = async (values: IGraphqlVariables<IRegisterInput>) => {
    try {
      await getRegister({
        input: {
          name: values.input?.name,
          surname: values.input?.surname,
          email: values.input?.email,
          password: values.input?.password,
          role: values.input?.role,
          language: currentLanguage as Language,
        },
        headers: { authorization: token },
      });
    } catch (error) {
      ref?.current?.reset();
      DefaultErrorHandlerUseEffect(error as IBaseError, currentLanguage);
    }
  };

  useEffect(() => {
    if (error) {
      ref?.current?.reset();
      DefaultErrorHandlerUseEffect(error as IBaseError, currentLanguage);
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t('auth.signupSuccess'), { variant: 'success' });
      dispatch(setUser(data));
      // navigate(routes.auth.confirmation + "?token=" + data?.devMetaData?.token + "&code=" + data?.devMetaData?.code);
      navigate(routes.auth.signupSuccessfull);
    }
  }, [data, dispatch, enqueueSnackbar]);

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <AuthLayout>
      <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          textAlign={'center'}
          color={'GrayText'}
          py={2}
        ></Typography>
        <Grid
          item
          xs={12}
          display={'flex'}
          height={'100vh'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              mt: 2,
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t('auth.logoAlt')} width={120} />
            <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} py={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={'center'}>
                {t('auth.letsRegister')}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              sx={{ width: '100%' }}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <form onSubmit={signupForm.handleSubmit(handleSignup)} style={{ width: '100%' }}>
                <Box display={'flex'} flexDirection={'column'} gap={2.7} alignItems={'center'}>
                  <Stack direction={'row'} gap={3} width={'100%'} justifyContent={'space-between'}>
                    <Controller
                      name="input.name"
                      control={signupForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.name"
                          placeholder={t('auth.name')}
                          fullWidth
                          type={'text'}
                          value={field.value}
                          onChange={(e) =>
                            field.onChange(
                              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                            )
                          }
                          // error={!!getNestedValue(signupForm.formState.touchedFields, 'input.name')}
                          helperText={
                            getNestedValue(signupForm.formState.touchedFields, 'input.name') &&
                            getNestedValue(signupForm.formState.errors, 'input.name')?.message
                          }
                          FormHelperTextProps={{
                            sx: {
                              color: 'error.main',
                              fontSize: 9,
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="input.surname"
                      control={signupForm.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="input.surname"
                          placeholder={t('auth.surname')}
                          fullWidth
                          type={'text'}
                          value={field.value}
                          onChange={(e) =>
                            field.onChange(
                              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                            )
                          }
                          helperText={
                            getNestedValue(signupForm.formState.touchedFields, 'input.surname') &&
                            getNestedValue(signupForm.formState.errors, 'input.surname')?.message
                          }
                          FormHelperTextProps={{
                            sx: {
                              color: 'error.main',
                              fontSize: 9,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="input.email"
                    control={signupForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.email"
                        placeholder={t('auth.emailAddress')}
                        fullWidth
                        type={'email'}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        helperText={
                          getNestedValue(signupForm.formState.touchedFields, 'input.email') &&
                          getNestedValue(signupForm.formState.errors, 'input.email')?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="input.password"
                    control={signupForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.password"
                        placeholder={t('auth.password')}
                        fullWidth
                        type={visibility.includes('password') ? 'text' : 'password'}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => handleChangeVisibility('password')}>
                              {visibility.includes('password') ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                        helperText={
                          getNestedValue(signupForm.formState.touchedFields, 'input.password') &&
                          getNestedValue(signupForm.formState.errors, 'input.password')?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="input.passwordAgain"
                    control={signupForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.passwordAgain"
                        placeholder={t('auth.passwordAgain')}
                        fullWidth
                        type={visibility.includes('passwordAgain') ? 'text' : 'password'}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => handleChangeVisibility('passwordAgain')}>
                              {visibility.includes('passwordAgain') ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        helperText={
                          getNestedValue(
                            signupForm.formState.touchedFields,
                            'input.passwordAgain',
                          ) &&
                          getNestedValue(signupForm.formState.errors, 'input.passwordAgain')
                            ?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                          },
                        }}
                      />
                    )}
                  />

                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={0.7}
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                  >
                    <Controller
                      name="input.isChecked"
                      control={signupForm.control}
                      render={({ field }) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                          sx={{ cursor: 'pointer', flexWrap: 'wrap', whiteSpace: 'nowrap' }}
                        >
                          <Checkbox
                            {...field}
                            checked={field.value || false}
                            color={
                              getNestedValue(signupForm.formState.errors, 'input.isChecked')
                                ? 'error'
                                : 'secondary'
                            }
                          />
                          <Typography fontSize={14} fontWeight={400}>
                            {t('auth.iAccept')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                            onClick={(e) => {
                              setOpenTermsAndConditions(true);
                              setSelectedPolicyType(PolicyType.terms);
                              e.stopPropagation();
                            }}
                            sx={{ color: 'secondary.main', ml: 0.5 }}
                          >
                            {t('auth.termsAndConditions')}
                          </Typography>
                          <Typography fontSize={14} fontWeight={400} sx={{ ml: 0.5 }}>
                            {t('auth.with')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                            onClick={(e) => {
                              setOpenPrivacyPolicy(true);
                              setSelectedPolicyType(PolicyType.privacy);
                              e.stopPropagation();
                            }}
                            sx={{ color: 'secondary.main', ml: 0.5 }}
                          >
                            {t('auth.privacyPolicy')}
                          </Typography>
                        </Stack>
                      )}
                    />
                    {/* {getNestedValue(signupForm.formState.errors, 'input.isChecked') && (
                      <Typography fontSize={12} marginTop={-2} fontWeight={400} color={'error'}>
                        {getNestedValue(signupForm.formState.errors, 'input.isChecked')?.message ??
                          ''}
                      </Typography>
                    )} */}

                    <Controller
                      name="input.isChecked2"
                      control={signupForm.control}
                      render={({ field }) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                          sx={{ cursor: 'pointer', flexWrap: 'wrap', whiteSpace: 'nowrap' }}
                        >
                          <Checkbox
                            {...field}
                            checked={field.value || false}
                            color={
                              getNestedValue(signupForm.formState.errors, 'input.isChecked2')
                                ? 'error'
                                : 'secondary'
                            }
                          />
                          <Typography fontSize={14} fontWeight={400}>
                            {t('auth.iAccept')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                            onClick={(e) => {
                              setOpenExplicitPolicy(true);
                              setSelectedPolicyType(PolicyType.explicit);
                              e.stopPropagation();
                            }}
                            sx={{ color: 'secondary.main', ml: 0.5 }}
                          >
                            {t('auth.explicitConsent')}
                          </Typography>
                          <Typography fontSize={14} fontWeight={400} sx={{ ml: 0.5 }}>
                            {t('auth.and')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                            onClick={(e) => {
                              setOpenKvkkPolicy(true);
                              setSelectedPolicyType(PolicyType.kvkk);
                              e.stopPropagation();
                            }}
                            sx={{ color: 'secondary.main', ml: 0.5 }}
                          >
                            {t('auth.kvkkPolicy')}
                          </Typography>
                        </Stack>
                      )}
                    />
                    {/* {getNestedValue(signupForm.formState.errors, 'input.isChecked2') && (
                      <Typography fontSize={12} marginTop={-2} fontWeight={400} color={'error'}>
                        {getNestedValue(signupForm.formState.errors, 'input.isChecked2') ?? ''}
                      </Typography>
                    )} */}
                  </Box>
                  <Turnstile
                    ref={ref}
                    siteKey={process.env.REACT_APP_CLOUDFLARE_KEY as string}
                    style={{
                      borderRadius: '14px',
                    }}
                    onError={(e) => {
                      console.log(e, 'error');
                    }}
                    onExpire={(e) => {
                      console.log(e, 'expired');
                    }}
                    onSuccess={(e) => {
                      setToken(e);
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !signupForm.watch('input.isChecked') ||
                        !signupForm.watch('input.isChecked2')
                      ) {
                        enqueueSnackbar(t('auth.acceptAllPolicies'), { variant: 'warning' });
                      } else if (
                        signupForm.watch('input.isChecked') &&
                        signupForm.watch('input.isChecked2')
                      ) {
                        e.preventDefault();
                        signupForm.handleSubmit(handleSignup)();
                      }
                    }}
                    disabled={!!isLoading || !signupForm.formState?.isValid}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {isLoading ? <CircularProgress size="1rem" /> : t('auth.signUp')}
                  </Button>
                </Box>
              </form>
            </Box>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              alignItems={'center'}
              width={'100%'}
            >
              <Box bgcolor={'#aaa'} width={'80%'} height={'1px'} sx={{ borderRadius: 1 }} />
              <Typography
                width="100%"
                fontSize={14}
                fontWeight={400}
                color={'GrayText'}
                textAlign={'center'}
              >
                {t('auth.orContinueWith')}
              </Typography>
              <Box bgcolor={'#aaa'} width={'80%'} height={'1px'} sx={{ borderRadius: 1 }} />
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
            >
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  p: 1.5,
                  color: '#fff',
                  '&:hover': { backgroundColor: 'primary.dark', boxShadow: 2 },
                }}
                onClick={() => {
                  (!signupForm.watch('input.isChecked') || !signupForm.watch('input.isChecked2')) &&
                    enqueueSnackbar(t('auth.acceptAllPolicies'), { variant: 'warning' });
                  signupForm.watch('input.isChecked') &&
                    signupForm.watch('input.isChecked2') &&
                    (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google?action=register`);
                }}
              >
                <Iconify icon={'ant-design:google-outlined'} width={24} sx={{ color: '#fff' }} />
              </IconButton>
              {/* <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
                <Iconify icon={"ic:baseline-apple"} width={24} sx={{ color: "#fff" }} />
              </IconButton> */}
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  p: 1.5,
                  color: '#fff',
                  '&:hover': { backgroundColor: 'primary.dark', boxShadow: 2 },
                }}
                onClick={() => {
                  (!signupForm.watch('input.isChecked') || !signupForm.watch('input.isChecked2')) &&
                    enqueueSnackbar(t('auth.acceptAllPolicies'), { variant: 'warning' });
                  signupForm.watch('input.isChecked') &&
                    signupForm.watch('input.isChecked2') &&
                    (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/linkedin?action=register`);
                }}
              >
                <Iconify icon={'ri:linkedin-fill'} width={24} sx={{ color: '#fff' }} />
              </IconButton>
            </Stack>
          </Box>
        </Grid>

        <Typography
          fontSize={14}
          fontWeight={400}
          textAlign={'center'}
          color={'GrayText'}
          py={2}
          sx={{ cursor: 'pointer' }}
        >
          {t('auth.alreadyHaveAccount')}{' '}
          <span style={{ color: '#000' }} onClick={() => navigate(routes.auth.login)}>
            {t('auth.login')}
          </span>
        </Typography>
      </Stack>

      {!latestPolicyLoading && (
        <ActionDialog
          open={openTermsAndConditions}
          onClose={() => setOpenTermsAndConditions(false)}
          title={t('auth.termsAndConditions')}
          width={800}
        >
          {parse(latestPolicyData?.content || '')}
        </ActionDialog>
      )}
      {!latestPolicyLoading && selectedPolicyType === PolicyType.privacy && (
        <ActionDialog
          open={openPrivacyPolicy}
          onClose={() => setOpenPrivacyPolicy(false)}
          title={t('auth.privacyPolicy')}
          width={800}
        >
          {parse(latestPolicyData?.content || '')}
        </ActionDialog>
      )}
      {!latestPolicyLoading && (
        <ActionDialog
          open={openExplicitPolicy}
          onClose={() => setOpenExplicitPolicy(false)}
          title={t('auth.explicitConsent')}
          width={800}
        >
          {parse(latestPolicyData?.content || '')}
        </ActionDialog>
      )}
      {!latestPolicyLoading && (
        <ActionDialog
          open={openKvkkPolicy}
          onClose={() => setOpenKvkkPolicy(false)}
          title={t('auth.kvkkPolicy')}
          width={800}
        >
          {parse(latestPolicyData?.content || '')}
        </ActionDialog>
      )}
    </AuthLayout>
  );
};

export default Signup;
