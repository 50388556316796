/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { IHasId } from 'corede-common';
import {
  ITask,
  ITaskChecklistItem,
  ITaskDetailResult,
  ITaskListItemResult,
  TaskStatus,
} from 'corede-common-cocrm';
import ColumnContainer from './TaskList-kanban-columnContainer.component';
import TaskCard from './TaskList-kanban-taskCard.component';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskListQuery } from '../../context/task.api';
import TaskDetailDrawer from '../detailDrawer/TaskDetailDrawer';
import { TaskDeleteDialog } from '../TaskDeleteDialog';
import TaskUpdateDrawerWrapper from '../update/TaskUpdateOverlay';
import { Column, Id, Task } from './TaskList-kanban.components.types';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { OverlayType } from 'components/dialog/ActionDialog';
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';

export interface ITaskListKanbanComponent {
  // task

  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
}

const TaskListKanbanComponent = memo((props: ITaskListKanbanComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();

  const [openTaskDetailDrawer, setOpenTaskDetailDrawer] = useState(false);
  const [openTaskDeleteDialog, setOpenTaskDeleteDialog] = useState(false);
  const [openTaskUpdateDrawer, setOpenTaskUpdateDrawer] = useState(false);

  // kanban

  const [columns, setColumns] = useState<Column[]>(
    Object.values(TaskStatus).map((status) => ({
      id: status,
      title: getTranslatedEnumValue(status),
    })),
  );
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    }),
  );

  const updateTask = (id: Id, content: string) => {
    const updatedTasks = tasks.map((task) => (task.id === id ? { ...task, content } : task));
    setTasks(updatedTasks);
  };

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'Column') {
      setActiveColumn(event.active.data.current.column);
    } else if (event.active.data.current?.type === 'Task') {
      setActiveTask(event.active.data.current.task);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveColumn(null);
    setActiveTask(null);
    const { active, over } = event;
    if (!over) return;

    if (active.id === over.id) return;

    setColumns((prevColumns) => {
      const activeIndex = prevColumns.findIndex((col) => col.id === active.id);
      const overIndex = prevColumns.findIndex((col) => col.id === over.id);
      return arrayMove(prevColumns, activeIndex, overIndex);
    });
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id === over.id) return;

    const activeId = active.id;
    const overId = over.id;

    const isTask = active.data.current?.type === 'Task';
    const isOverTask = over.data.current?.type === 'Task';

    if (isTask && isOverTask) {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        const overIndex = prevTasks.findIndex((task) => task.id === overId);

        prevTasks[activeIndex].status = prevTasks[overIndex].status;
        return arrayMove(prevTasks, activeIndex, overIndex);
      });
    } else if (isTask && over.data.current?.type === 'Column') {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        prevTasks[activeIndex].status = overId;
        return [...prevTasks];
      });
    }
  };

  // queries
  const { data: awaitingFeedbackTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.awaitingFeedback],
      },
      pagination: {
        page: 1,
        pageSize: 20,
      }
    },
  });
  const { data: completedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.completed],
      },
    },
  });
  const { data: inProgressTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.inProgress],
      },
    },
  });
  const { data: notStartedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.notStarted],
      },
    },
  });
  const { data: testedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.tested],
      },
    },
  });

  // effects

  useEffect(() => {
    if (
      awaitingFeedbackTasks ||
      completedTasks ||
      inProgressTasks ||
      testedTasks ||
      notStartedTasks
    ) {
      const transformTasks = (tasks: ITaskListItemResult[] | undefined = []) =>
        tasks.map((task) => ({
          id: task._id,
          status: task.status,
          subject: task.subject,
          description: task.description,
          checklistTotal: task.checklist?.length ?? 0,
          checklistCompleted:
            task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
          documentCount: task.documents?.length ?? 0,
          assignees: task.assignees ?? [],
          dueDate: task.dueDate ?? undefined,
        }));

      const combinedTasks = [
        ...transformTasks(awaitingFeedbackTasks?.data),
        ...transformTasks(completedTasks?.data),
        ...transformTasks(inProgressTasks?.data),
        ...transformTasks(testedTasks?.data),
        ...transformTasks(notStartedTasks?.data),
      ];

      setTasks(combinedTasks);
    }
  }, [awaitingFeedbackTasks, completedTasks, inProgressTasks, testedTasks, notStartedTasks]);

  // custom views

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: 2,
        padding: 2,
        overflowX: 'auto',
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <SortableContext items={columnsId}>
            {columns.map((col) => (
              <ColumnContainer
                key={col.id}
                column={col}
                tasks={tasks.filter((task) => task.status === col.id)}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            ))}
          </SortableContext>
        </Stack>

        {createPortal(
          <DragOverlay>
            {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                tasks={tasks.filter((task) => task.status === activeColumn.id)}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            )}
            {activeTask && (
              <TaskCard
                task={activeTask}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>

      <Box sx={{ width: '100%', height: '10px' }} />

      <TaskDetailDrawer
        setSelectedTask={setSelectedTask}
        open={openTaskDetailDrawer}
        setOpen={setOpenTaskDetailDrawer}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
      />

      <TaskUpdateDrawerWrapper
        open={openTaskUpdateDrawer}
        setOpen={setOpenTaskUpdateDrawer}
        targetId={selectedTask?.relatedEntity?._id ?? ''}
        targetEntityRef={selectedTask?.relatedEntityRef}
        item={selectedTask}
        overlayType={OverlayType.drawer}
      />

      <TaskDeleteDialog
        open={openTaskDeleteDialog}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        setItem={setSelectedTask}
        key={selectedTask?._id}
        onClose={() => {
          setSelectedTask(undefined);
          setOpenTaskDeleteDialog(false);
        }}
      />
    </Box>
  );
});

export default TaskListKanbanComponent;
