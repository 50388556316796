/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  ITicketUpdateFilterInput,
  ITicketUpdateInput,
  TicketTargetType,
} from 'corede-common-cocrm';
import { validateUpdateSupportInput } from '../../validations/update.validation';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { useTicketDetailQuery, useTicketUpdateMutation } from '../../context/support.api';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertTicketComponent } from '../UpsertTicket.component';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';
import ActionDrawer from 'components/drawer/ActionDrawer';

export interface ITicketUpdateOverlay {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  ticketId: string;
  targetId?: string;
  targetType?: TicketTargetType;
  overlayType: OverlayType;
}

export const TicketUpdateOverlay = memo((props: ITicketUpdateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries
  const { data: userDetailOwnData } = useUserDetailOwnQuery({}); // TODO: we are only using id of the user. Therefore we should be able to get current user somewhere
  // queries
  const {
    data: ticketDetailData,
    isLoading: ticketDetailLoading,
    error: ticketDetailError,
  } = useTicketDetailQuery(
    {
      input: {
        _id: props.ticketId ?? '',
      },
    },
    {
      skip: !props.ticketId,
    },
  );

  // mutations
  const [
    ticketUpdate,
    { data: ticketUpdateData, isLoading: ticketUpdateLoading, error: ticketUpdateError },
  ] = useTicketUpdateMutation();

  // constants
  const [initialValues, setInitialValues] = useState<
    IGraphqlVariables<ITicketUpdateInput, ITicketUpdateFilterInput>
  >({
    filter: {
      _id: props.ticketId || '',
    },
    input: {
      assigneeIds: ticketDetailData?.assignees?.map((item) => item._id) ?? [],
      category: ticketDetailData?.category,
      contactEmail: ticketDetailData?.contactEmail,
      contactName: ticketDetailData?.contactName,
      departmentId: ticketDetailData?.department?._id,
      priority: ticketDetailData?.priority,
      subject: ticketDetailData?.subject,
      tags: ticketDetailData?.tags,
    },
  });

  // form setup
  const ticketUpdateUseForm = useForm<
    IGraphqlVariables<ITicketUpdateInput, ITicketUpdateFilterInput>
  >({
    // defaultValues: initialValues,
    values: initialValues,
    resolver: yupResolver(validateUpdateSupportInput),
    mode: 'onChange',
  });

  const onSubmit = async (
    values: IGraphqlVariables<ITicketUpdateInput, ITicketUpdateFilterInput>,
  ) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values.input,
    });
    await ticketUpdate({
      filter: values.filter,
      input: transformedValues,
    });
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (ticketUpdateData) {
      enqueueSnackbar(t('crm.calendar.ticket.updateSuccess'), {
        variant: 'success',
      });
      ticketUpdateUseForm.reset();
      props.setOpen(false);
    }
  }, [ticketUpdateData, ticketUpdateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketUpdateError as IBaseError, currentLanguage);
  }, [ticketUpdateError]);

  // useEffects.init

  useEffect(() => {
    if (ticketDetailData) {
      setInitialValues({
        filter: {
          _id: ticketDetailData._id,
        },
        input: {
          assigneeIds: ticketDetailData?.assignees?.map((item) => item._id) ?? [],
          category: ticketDetailData?.category,
          contactEmail: ticketDetailData?.contactEmail,
          contactName: ticketDetailData?.contactName,
          departmentId: ticketDetailData?.department?._id,
          priority: ticketDetailData?.priority,
          subject: ticketDetailData?.subject,
          tags: ticketDetailData?.tags,
        },
      });
    }
  }, [ticketDetailData]);

  const commonContent = (
    <UpsertTicketComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={ticketUpdateLoading || loading}
      useForm={ticketUpdateUseForm}
      targetId={props?.targetId ?? ticketDetailData?.target?._id}
      targetType={props?.targetType ?? ticketDetailData?.targetType}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDrawer
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          title={t('crm.calendar.ticket.update')}
          handleSubmit={ticketUpdateUseForm.handleSubmit(onSubmit)}
          disabled={ticketUpdateLoading || loading || !ticketUpdateUseForm.formState.isValid}
          loading={ticketUpdateLoading || loading}
          buttonTitle={t('crm.calendar.ticket.update')}
        >
          {commonContent}
        </ActionDrawer>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.calendar.ticket.update')}
          handleClick={ticketUpdateUseForm.handleSubmit(onSubmit)}
          disabled={ticketUpdateLoading || loading || !ticketUpdateUseForm.formState.isValid}
          loading={ticketUpdateLoading || loading}
          buttonTitle={t('crm.calendar.ticket.update')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
});

export default TicketUpdateOverlay;
