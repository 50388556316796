import { Cancel, Delete, DeleteOutline } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import { EntityDeleteDialog } from 'components/entityDeleteDialog/EntityDeleteDialog';
import { fileTypeForIcon } from 'components/uploadFile/UploadDocument';
import { IFileListItemResult, TaskTargetType } from 'corede-common-cocrm';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileDeleteMutation, useFileDetailQuery } from '../../context/document.api';
import { IBaseError } from 'corede-common';

type IFileDetailDrawer = {
  openFileDetailDrawer: boolean;
  setOpenFileDetailDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  file: IFileListItemResult;
  handleDownload: () => Promise<void>;
};

const FileDetailDrawer = (props: IFileDetailDrawer) => {
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    data: fileDetailData,
    isLoading: fileDetailLoading,
    error: fileDetailError,
  } = useFileDetailQuery(
    {
      input: {
        _id: props.file._id,
      },
    },
    {
      skip: !props.file._id || !props.openFileDetailDrawer,
    },
  );

  const [
    fileDelete,
    { data: fileDeleteData, isLoading: fileDeleteLoading, error: fileDeleteError },
  ] = useFileDeleteMutation();

  return (
    <ActionDrawer
      open={props.openFileDetailDrawer}
      setOpen={props.setOpenFileDetailDrawer}
      title={t('disk.document.document.detail')}
      size="small"
      buttonTitle={t('download')}
      handleSubmit={() => props.handleDownload()}
      disabled={false}
      loading={false}
    >
      <Grid container spacing={2} sx={{ mb: 2, p: 2 }}>
        <Grid item xs={12}>
          <Stack direction={'column'} gap={2} alignItems={'flex-start'}>
            <Stack
              direction={'row'}
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
            >
              <img
                src={fileTypeForIcon(fileDetailData?.mimeType)}
                alt="file"
                style={{ height: '70px', objectFit: 'contain' }}
              />
              <IconButton
                color="secondary"
                sx={{ mr: 2 }}
                onClick={() => props.setOpenFileDetailDrawer(false)}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Stack>
            <Typography fontSize={'16px'} fontWeight={600}>
              {fileDetailData?.customName}
            </Typography>
            <Divider sx={{ width: '100%' }} />
            <Typography fontSize={'16px'} fontWeight={600}>
              {t('disk.document.document.properties')}
            </Typography>
            <Stack direction="column" gap={1} mb={1}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="14px" sx={{ minWidth: '80px', fontWeight: 'bold' }}>
                  {t('disk.document.document.size')}:
                </Typography>
                <Typography fontSize="14px">
                  {(fileDetailData?.size ?? 0 / 1024).toFixed(2)} MB
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="14px" sx={{ minWidth: '80px', fontWeight: 'bold' }}>
                  {t('crm.type')}:
                </Typography>
                <Typography fontSize="14px">
                  {typeof fileDetailData?.type === 'string'
                    ? fileDetailData?.type.slice(0, 1).toUpperCase()
                    : ''}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="14px" sx={{ minWidth: '80px', fontWeight: 'bold' }}>
                  {t('crm.createdAt')}:
                </Typography>
                <Typography fontSize="14px">
                  {moment(fileDetailData?.createdAt).format('DD MMM YYYY HH:mm')}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="14px" sx={{ minWidth: '80px', fontWeight: 'bold' }}>
                  {t('disk.document.document.downloadCount')}:
                </Typography>
                <Typography fontSize="14px">{fileDetailData?.downloadCount}</Typography>
              </Stack>
            </Stack>
            <DetailDrawerTargetComponent
              targetId={fileDetailData?.entity?._id ?? ''}
              targetType={(fileDetailData?.entityModel as TaskTargetType) ?? ''}
            />
          </Stack>
        </Grid>

        <EntityDeleteDialog
          open={openDeleteDialog}
          itemId={fileDetailData?._id ?? ''}
          deleteMutation={fileDelete}
          deleteMutationData={{
            data: fileDeleteData,
            isLoading: fileDeleteLoading,
            error: fileDeleteError as IBaseError,
          }}
          dialog={{
            title: t('disk.document.document.delete'),
            successMessage: t('disk.document.document.deletedSuccessfully'),
            content: t('disk.document.document.deleteConfirm'),
          }}
          onClose={() => setOpenDeleteDialog(false)}
        />
      </Grid>
    </ActionDrawer>
  );
};

export default FileDetailDrawer;
