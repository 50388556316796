/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Update } from '@mui/icons-material';
import { Iconify } from 'components';
import { ICustomerDetailResult, CustomerType } from 'corede-common-cocrm';
import { convertEnums } from 'localization';
import { Language } from 'corede-common';
import { t } from 'i18next';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';

interface ICustomerDetailTabInfoSectionComponent
  extends DefaultTabComponentProps<ICustomerDetailResult> {
  customerData: ICustomerDetailResult | undefined;
  setOpenCustomerUpdateDrawer: (value: boolean) => void;
  tabKey: 'info';
  name: 'crm.info';
}

export const CustomerDetailTabInfoSectionComponent = (
  props: ICustomerDetailTabInfoSectionComponent,
) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const localizedCustomerTypes = convertEnums(CustomerType);
  const localizedLanguages = convertEnums(Language);

  const companyData = [
    {
      title: t('crm.website'),
      value: props.customerData?.website,
      icon: 'fluent-mdl2:website',
    },
  ];

  const localizationData = [
    {
      title: t('crm.country'),
      value: props.customerData?.country,
      icon: 'clarity:map-marker-line',
    },
    {
      title: t('crm.city'),
      value: props.customerData?.city,
      icon: 'solar:city-broken',
    },
    {
      title: t('crm.state'),
      value: props.customerData?.state,
      icon: 'mage:location',
    },
    {
      title: t('crm.language'),
      value:
        localizedLanguages[
          (props.customerData as any)?.language as keyof typeof localizedLanguages
        ],
      icon: 'tabler:language',
    },
  ];

  const billingInformationData = [
    {
      title: t('crm.companyName'),
      value: props.customerData?.primaryBillingInfo?.fullName,
      icon: 'ic:round-home-work',
    },
    {
      title: t('crm.phone'),
      value: props.customerData?.primaryBillingInfo?.phone,
      icon: 'mdi-light:phone',
    },
    {
      title: t('crm.address'),
      value: props.customerData?.primaryBillingInfo?.address,
      icon: 'entypo:address',
    },
    {
      title: t('crm.city'),
      value: props.customerData?.primaryBillingInfo?.city,
      icon: 'solar:city-broken',
    },
    {
      title: t('crm.country'),
      value: props.customerData?.primaryBillingInfo?.country,
      icon: 'clarity:map-marker-line',
    },
    {
      title: t('crm.vat'),
      value: props.customerData?.primaryBillingInfo?.vat,
      icon: 'hugeicons:taxes',
    },
    {
      title: t('crm.zip'),
      value: props.customerData?.primaryBillingInfo?.zip,
      icon: 'tabler:zip',
    },
  ];

  return (
    <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
      <Grid item xs={12} md={4}>
        <Stack
          direction={'column'}
          p={3}
          gap={1.5}
          sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
        >
          <Stack direction={'column'} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
                {t('crm.about')}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 'auto',
                  height: 32,
                  fontSize: { xs: '13px', md: '13px' },
                  lineHeight: 1,
                  borderRadius: '24px',
                }}
                onClick={() => props.setOpenCustomerUpdateDrawer(true)}
              >
                <Update sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
                {t('crm.update')}
              </Button>
            </Stack>

            <Divider sx={{ mt: 1 }} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.email')}
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.customerData?.email}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.phoneNumber')}
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.customerData?.phone}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.type')}
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {
                localizedCustomerTypes[
                  props.customerData?.type as keyof typeof localizedCustomerTypes
                ]
              }
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.company')}
          </Typography>

          <Grid container spacing={1}>
            {companyData.map((item) => (
              <Grid item xs={6} md={3}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.localization')}
          </Typography>

          <Grid container spacing={1}>
            {localizationData.map((item) => (
              <Grid item xs={6} md={3}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.billingInformation')}
          </Typography>

          <Grid container spacing={1}>
            {billingInformationData.map((item) => (
              <Grid item xs={6} md={3}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>

      {/* TODO: Talk wit BE */}
      {/* {props.customerData?.otherBillingInfoList &&
        props.customerData?.otherBillingInfoList?.length > 0 &&
        props.customerData?.otherBillingInfoList.map((item, index) => (
          <Grid key={index} item xs={12}>
            <DetailBox title={t('crm.otherBillingInformation')}>
              <Grid item display="flex" flexDirection="column" gap={1}>
                {[
                  t('crm.fullName'),
                  t('crm.phone'),
                  t('crm.address'),
                  t('crm.city'),
                  t('crm.country'),
                  t('crm.vat'),
                  t('crm.zip'),
                ].map((label, index) => (
                  <Box display="flex" alignItems="center" minHeight={35} key={index}>
                    <Typography fontSize={'14px'} fontWeight={600}>
                      {label}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item display="flex" flexDirection="column" gap={1}>
                {[
                  item.fullName,
                  item.phone,
                  item.address,
                  item.city,
                  item.country,
                  item.vat,
                  item.zip,
                ].map((value, index) => (
                  <Box display="flex" alignItems="center" minHeight={35} key={index}>
                    <Typography fontSize={'14px'} fontWeight={400}>
                      : {value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </DetailBox>
          </Grid>
        ))} */}
    </Grid>
  );
};
