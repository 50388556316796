/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useLeadStatusDeleteMutation } from "../context/leadStatus.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const LeadStatusDeleteDialog = memo(
  (props: {
    open: boolean;
    itemId: string;
    onClose: () => void;
    setItem: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      leadStatusDelete,
      {
        data: leadStatusDeleteData,
        isLoading: leadStatusDeleteIsLoading,
        error: leadStatusDeleteError,
      },
    ] = useLeadStatusDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.itemId}
        setItem={props.setItem}
        deleteMutation={leadStatusDelete}
        deleteMutationData={{
          data: leadStatusDeleteData,
          isLoading: leadStatusDeleteIsLoading,
          error: leadStatusDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.lead.leadStatus.delete"),
          successMessage: t("crm.lead.leadStatus.deletedSuccessfully"),
          content: t("crm.lead.leadStatus.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
