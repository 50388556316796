/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Stack,
  IconButton,
  TextField,
} from '@mui/material';
import { AuthLayout } from '../layouts';
import { useResetPasswordAfterRequestMutation } from '../context';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import LoginCo from 'assets/images/login-co.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from '../routes/routes';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables, IResetPasswordInput, unknownError } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { Turnstile } from '@marsidev/react-turnstile';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateResetPasswordInput } from '../validations/resetPassword.validation';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getResetPasswordAfterRequest, { data, isLoading, error }] =
    useResetPasswordAfterRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [visibility, setVisibility] = useState<string[]>([]);
  const token = new URLSearchParams(useLocation().search).get('token');
  const currentLanguage = getCurrentLanguage();
  const ref = useRef<any>();
  const [turnstileToken, setTurnstileToken] = useState<string>('');

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter((item) => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  const resetPasswordForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(validateResetPasswordInput),
    defaultValues: {
      input: {
        token: token,
        password: '',
      },
    },
    reValidateMode: 'onChange',
  });

  const handleResetPassword = async (values: IGraphqlVariables<IResetPasswordInput>) => {
    try {
      await getResetPasswordAfterRequest({
        input: {
          token: token,
          password: values.input?.password,
        },
        headers: { authorization: turnstileToken },
      });
    } catch (error) {
      if (ref.current) {
        ref?.current?.reset();
      }
      DefaultErrorHandlerUseEffect(error as IBaseError, currentLanguage);
    }
  };

  useEffect(() => {
    if (error) {
      ref?.current?.reset();
      enqueueSnackbar(
        (error as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t('auth.passwordResetSuccess'), { variant: 'success' });
      navigate(routes.auth.login);
    }
  }, [data, enqueueSnackbar, navigate]);

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <AuthLayout>
      <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontSize={14} fontWeight={400} textAlign={'center'} color={'GrayText'} py={2}>
          {/* Optional Intro Text */}
        </Typography>
        <Grid
          item
          xs={12}
          display={'flex'}
          height={'100vh'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t('auth.logoAlt')} width={120} />
            <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} pt={2}>
              <Typography fontSize={32} fontWeight={500} textAlign={'center'}>
                {t('auth.resetPasswordTitle')}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} pb={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={'center'}>
                {t('auth.enterNewPassword')}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              sx={{ width: '100%' }}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <form
                onSubmit={resetPasswordForm.handleSubmit(handleResetPassword)}
                style={{ width: '100%' }}
              >
                <Box display={'flex'} flexDirection={'column'} gap={1.5} alignItems={'center'}>
                  <Controller
                    name="input.password"
                    control={resetPasswordForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.password"
                        placeholder={t('auth.password')}
                        fullWidth
                        type={visibility.includes('password') ? 'text' : 'password'}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => handleChangeVisibility('password')}>
                              {visibility.includes('password') ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                        helperText={
                          getNestedValue(
                            resetPasswordForm.formState.touchedFields,
                            'input.password',
                          ) &&
                          getNestedValue(resetPasswordForm.formState.errors, 'input.password')
                            ?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                            mb: 1,
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="input.passwordAgain"
                    control={resetPasswordForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.passwordAgain"
                        placeholder={t('auth.passwordAgain')}
                        fullWidth
                        type={visibility.includes('passwordAgain') ? 'text' : 'password'}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => handleChangeVisibility('passwordAgain')}>
                              {visibility.includes('passwordAgain') ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        helperText={
                          getNestedValue(
                            resetPasswordForm.formState.touchedFields,
                            'input.passwordAgain',
                          ) &&
                          getNestedValue(resetPasswordForm.formState.errors, 'input.passwordAgain')
                            ?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                          },
                        }}
                      />
                    )}
                  />
                  <Turnstile
                    ref={ref}
                    siteKey={process.env.REACT_APP_CLOUDFLARE_KEY as string}
                    style={{
                      borderRadius: '14px',
                    }}
                    onError={(e) => {
                      console.log(e, 'error');
                    }}
                    onExpire={(e) => {
                      console.log(e, 'expired');
                    }}
                    onSuccess={(e) => {
                      console.log(e, 'solved');
                      setTurnstileToken(e);
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => resetPasswordForm.handleSubmit(handleResetPassword)()}
                    disabled={!!isLoading || !resetPasswordForm.formState?.isValid}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {isLoading ? <CircularProgress size="1rem" /> : t('auth.resetPassword')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        <Typography
          fontSize={14}
          fontWeight={400}
          textAlign={'center'}
          color={'GrayText'}
          py={2}
          sx={{ cursor: 'pointer' }}
        >
          {t('auth.alreadyHaveAccount')}{' '}
          <span style={{ color: '#000' }} onClick={() => navigate(routes.auth.login)}>
            {' '}
            {t('auth.login')}
          </span>
        </Typography>
      </Stack>
    </AuthLayout>
  );
};

export default ResetPassword;
