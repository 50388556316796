
import appointmentLocale from "../subdomains/appointment/locales/tr";
import reminderLocale from "../subdomains/reminder/locales/tr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reminder: {
    ...reminderLocale,
  },
  appointment: {
    ...appointmentLocale,
  }
};

export default locale;

