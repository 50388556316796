const locale = {
  title: 'Dashboard',
  description:
    'Activate Your Subscription Switch to a suitable subscription plan to use all the features of the CRM system and grow your business',
  featuresTitle: 'With subscription:',
  features1: 'Unlimited access to all your data',
  features2: 'Custom reporting tools',
  features3: 'More storage',
  features4: '24/7 technical support',
  buttonTitle: 'Explore Subscription Plans',
  trialExpired: 'Trial plan is expired. Please choose another plan.',
  mainFailed: 'Your plan is not activated. Please control your billing information.',
  mainCompleted: 'Your plan is completed. Please choose another plan.',
  welcome: 'Welcome ',
  platformValue: 'Platform Value',
  dealsAmount: 'Deals Amount',
  deals: 'Deals',
  byRefererCategory: 'by referer category',
  revenue: 'Revenue',
  leads: 'Leads',
  wl: 'W/L',
  winLoss: 'Win/Loss',
  averageMonthly: 'Average Monthly',
  monthly: 'Monthly',
  bestDeal: 'Best Deal',
  sales: 'Sales',
  topSales: 'Top Sales',
  salesStreak: 'Sales Streak',
  topReview: 'Top Review',
  workWithPlatforms: 'Work with platforms',
  salesDynamics: 'Sales Dynamics',
  timeframe: 'Timeframe',
  bestDetal: 'Best Deal',
  winRate: 'Win Rate',
  prevMonth: 'previous month',
  vsPrevMonth: 'vs previous month',
  details: 'Details',
  filters: 'Filters',
};

export default locale;
