/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import { EstimateDetailDrawerHeaderStatusComponent } from './EstimateDetailDrawer-rightPanel-header-status.component';
// import { EstimateDetailDrawerBaseInfoComponent } from './EstimateDetailDrawer-rightPanel-header-baseInfo.component';

export interface IEstimateDetailDrawerRightPanelHeaderComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const EstimateDetailDrawerRightPanelHeaderComponent = (
  props: IEstimateDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <Stack
      direction={'column'}
      gap={1}
      width={'100%'}
      sx={{
        borderBottom: '2px solid',
        borderColor: 'grey.400',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <EstimateDetailDrawerHeaderStatusComponent estimateDetailData={props.estimateDetailData} />
      {/* <EstimateDetailDrawerBaseInfoComponent estimateDetailData={props.estimateDetailData} /> */}
    </Stack>
  );
};
