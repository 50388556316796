/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useNoteDeleteMutation } from "../context/note.api";

export const NoteDeleteDialog = memo(
  (props: { open: boolean; noteId: string; onClose: () => void; setNote: Dispatch<any> }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      noteDelete,
      {
        data: noteDeleteData,
        isLoading: noteDeleteIsLoading,
        error: noteDeleteError,
      },
    ] = useNoteDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.noteId}
        setItem={props.setNote}
        deleteMutation={noteDelete}
        deleteMutationData={{
          data: noteDeleteData,
          isLoading: noteDeleteIsLoading,
          error: noteDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.note.note.delete"),
          successMessage: t("crm.note.note.deletedSuccessfully"),
          content: t("crm.note.note.deleteContent"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
