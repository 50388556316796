import leadLocale from "../subdomains/lead/locales/tr";
import leadForm from "../subdomains/leadForm/locales/tr";
import leadStatus from "../subdomains/leadStatus/locales/tr";
import leadSource from "../subdomains/leadSource/locales/tr";

const locale = {

  lead: {
    ...leadLocale,
  },
  leadForm: {
    ...leadForm,
  },
  leadStatus: {
    ...leadStatus,
  },
  leadSource: {
    ...leadSource,
  },
};

export default locale;
