

import { ProjectsDomainLayout } from "../layouts";
import { ProjectsRouter } from "../subdomains/project/routes/ProjectsRouter";
import { projectsDomainRoutes } from "./projects.base.route";
import { Route, Routes } from "react-router-dom";


export const ProjectsDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsDomainLayout />}>
        <Route index element={<ProjectsRouter />} />
        <Route path="*" element={<ProjectsRouter />} />
      </Route>
    </Routes>
  );
};
