import { useState, lazy, Suspense } from "react";
import { ICustomerDetailDrawerProps } from "./CustomerDetailDrawer";

const CustomerDetailDrawer = lazy(() => import("./CustomerDetailDrawer"));

const CustomerDetailDrawerWrapper = ({
  open,
  setOpen,
  selectedCustomer,
  sideNavigationProps,
}: ICustomerDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <CustomerDetailDrawer
            open={open}
            setOpen={setOpen}
            selectedCustomer={selectedCustomer}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default CustomerDetailDrawerWrapper;
