/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Avatar, Typography, Grid, InputLabel } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { compressImageAndGetUrls } from 'utils/imageUpload';
import UploadImage from 'assets/icons/UploadImage.png';
import { IFileMetadata, unknownError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import {
  Male1,
  Male2,
  Male3,
  Male4,
  Male5,
  Male6,
  Male7,
  Male8,
  Male9,
  Male10,
  Female1,
  Female2,
  Female3,
  Female4,
  Female5,
  Female6,
  Female7,
  Female8,
  Female9,
  Female10,
} from 'assets/images/avatars';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

const maleImageData = [Male1, Male2, Male3, Male4, Male5, Male6, Male7, Male8, Male9, Male10];
const femaleImageData = [
  Female1,
  Female2,
  Female3,
  Female4,
  Female5,
  Female6,
  Female7,
  Female8,
  Female9,
  Female10,
];

interface IUploadImageComponent {
  currentImage?: string;
  withAvatarPack?: boolean;
  setLoading?: (loading: boolean) => void;
  useImageCreateMutation?: any;
  useImageAssignMutation?: any;
  isAssignAuto?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  setImageFile?: (imageFile: IFileMetadata | undefined) => void;
}

export const UploadImageComponent = (props: IUploadImageComponent) => {
  const { t } = useTranslation();
  const [selectedImageFile, setSelectedImageFile] = useState<File | Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const currentLanguage = getCurrentLanguage();

  const [imageCreate, { isLoading: imageCreateLoading, error: imageCreateError }] =
    props.useImageCreateMutation();
  const [
    imageAssign,
    { data: imageAssignData, isLoading: imageAssignLoading, error: imageAssignError },
  ] = props.useImageAssignMutation();

  const handleImageChange = async ({ e, file }: { e?: any; file?: File }) => {
    const imageFile = file ? file : e?.target?.files?.[0];

    if (imageFile) {
      setSelectedImageFile(imageFile);
    }
  };

  const handleAvatarClick = async (avatar: any) => {
    const response = await fetch(avatar);
    const blob = await response.blob();
    const file = new File([blob], 'avatar.png', { type: blob.type });
    await handleImageChange({ file: file });
  };

  const handleSaveLogo = async () => {
    setLoading(true);
    try {
      // Görüntüyü sıkıştır
      const compressedImages = await compressImageAndGetUrls({
        file: selectedImageFile as File,
        quality: 0.6,
        maxWidth: 1000,
        thumbnailQuality: 0.5,
        thumbnailMaxWidth: 200,
      });

      if (!compressedImages) return;

      const { compressedImageFile, compressedImageThumbnail } = compressedImages;

      // Görüntü oluşturma fonksiyonu
      const createImage = async () => {
        const input = {
          tags: [''],
          size: (compressedImageFile as any)?.size * 0.001,
          mimeType: (compressedImageFile as any)?.type,
        };

        return await imageCreate({
          input: input,
        });
      };

      // Görüntü oluşturma ve yükleme
      const response = await createImage();

      if (response?.data?.presignedUrl) {
        const { uploadPresignedUrl, uploadThumbnailPresignedUrl } = response.data.presignedUrl;

        // Yükleme yapılandırmaları
        const uploadConfig = {
          method: 'PUT',
          maxBodyLength: Infinity,
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': (compressedImageFile as any)?.type,
          },
          data: compressedImageFile,
        };

        const uploadThumbnailConfig = {
          ...uploadConfig,
          url: uploadThumbnailPresignedUrl,
          data: compressedImageThumbnail,
        };

        // Dosyaları yükle
        await axios({ ...uploadConfig, url: uploadPresignedUrl });
        await axios(uploadThumbnailConfig);
      }

      if (!response?.data?.fileMetadata) return;

      props.setImageFile?.(response.data.fileMetadata);
      props.isAssignAuto && props.setOpen?.(false);
      props.isAssignAuto && setLoading(false);
      // Görüntüyü bağla

      !props.isAssignAuto &&
        (await imageAssign({
          input: { file: response?.data?.fileMetadata },
        }));
    } catch (error) {
      console.error('Error saving logo:', error);
      enqueueSnackbar(
        (error as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    props.setLoading && props.setLoading(imageAssignLoading);
  }, [imageAssignLoading]);

  useEffect(() => {
    if (imageAssignData) {
      enqueueSnackbar(t('imageUploadSuccess'), { variant: 'success' });
      props.setOpen && props.setOpen(false);
    }
  }, [imageAssignData]);

  return (
    <ActionDialog
      open={props.open || false}
      setOpen={props.setOpen}
      title={t('account.profile.profile.changeImage')}
      buttonTitle={t('account.profile.profile.change')}
      loading={imageCreateLoading || imageAssignLoading || loading}
      handleClick={() => handleSaveLogo()}
      width={400}
    >
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
        <Box
          onClick={() => document.getElementById('profile-image-input')?.click()}
          sx={{
            width: 'calc(100% - 20px)',
            border: '1px dashed',
            borderColor: 'primary.light',
            borderRadius: '16px',
            p: 1,
            flexGrow: 1,
            cursor: 'pointer',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{ width: '100%', height: '100%' }}
          >
            <Avatar
              sx={{
                width: selectedImageFile || props.currentImage ? '60px' : '40px',
                height: selectedImageFile || props.currentImage ? '60px' : '40px',
                padding: selectedImageFile || props.currentImage ? '0px' : '10px',
                backgroundColor: 'background.default',
                objectFit: 'cover',
                resize: 'cover',
              }}
              src={
                selectedImageFile
                  ? URL.createObjectURL(selectedImageFile)
                  : props.currentImage
                    ? props.currentImage
                    : UploadImage
              }
            />
            <Typography variant="body2"> {t('uploadPicture')}</Typography>
          </Stack>
        </Box>
        <input
          id="profile-image-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => handleImageChange({ e })}
        />

        {props.withAvatarPack && (
          <Grid container spacing={1} p={1}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Grid item xs={2.4} key={index}>
                <Box
                  onClick={() => handleAvatarClick(femaleImageData[index])}
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '20px',
                    border: '2px solid',
                    borderColor: 'background.default',
                    boxShadow: 2,
                    cursor: 'pointer',
                    ':hover': { boxShadow: 6 },
                  }}
                >
                  <img
                    style={{ width: '40px', height: '40px', borderRadius: '20px' }}
                    src={femaleImageData[index]}
                    alt="MyAvatar"
                  />
                </Box>
              </Grid>
            ))}
            {Array.from({ length: 10 }).map((_, index) => (
              <Grid item xs={2.4} key={index}>
                <Box
                  onClick={() => handleAvatarClick(maleImageData[index])}
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '20px',
                    border: '2px solid',
                    borderColor: 'background.default',
                    boxShadow: 2,
                    cursor: 'pointer',
                    ':hover': { boxShadow: 6 },
                  }}
                >
                  <img
                    style={{ width: '40px', height: '40px', borderRadius: '20px' }}
                    src={maleImageData[index]}
                    alt="MyAvatar"
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </ActionDialog>
  );
};
