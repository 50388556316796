/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ITaskDetailResult } from 'corede-common-cocrm';
import { TaskDetailDrawerLeftPanelHeaderTagsComponent } from './TaskDetailDrawer-leftPanel.header.tags.component';
import { TaskDetailDrawerLeftPanelActionsComponent } from './TaskDetailDrawer-leftPanel-header-actions.component';

export interface ITaskDetailDrawerLeftPanelHeaderComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const TaskDetailDrawerLeftPanelHeaderComponent = (
  props: ITaskDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* TAGS */}
      {downLg ? null : (
        <TaskDetailDrawerLeftPanelHeaderTagsComponent status={props.taskDetailData?.status} />
      )}

      {/* Task Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.taskDetailData?.subject ?? ''}
      </Typography>

      <TaskDetailDrawerLeftPanelActionsComponent
        taskDetailData={props.taskDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
