import { Autocomplete, CircularProgress, InputLabel, TextField } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DefaultObjectAutocompleteInput = (props: {
  inputName: `input.${string}` | `filter.${string}`;
  control: Control<any>;
  options: { name: string; _id: string }[];
  label: string;
  isRequired?: boolean;
  errors: FieldErrors<any>;
  touchedFields: Partial<Readonly<any>>;
  isLoading?: boolean;
  onAddNewOption?: () => void;
  disableClearable?: boolean;
  disableAddNewOption?: boolean;
  key?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            id={field.name}
            fullWidth
            size="small"
            disableClearable={props.disableClearable}
            disabled={props.disabled}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            options={[
              ...props.options,
              ...(props.onAddNewOption && !props.disableAddNewOption
                ? [{ _id: 'add', name: 'Add new option' }]
                : []),
            ]}
            getOptionLabel={(option) => option.name}
            renderOption={(autocompleteProps, option) => (
              <li
                {...autocompleteProps}
                className={`${autocompleteProps.className} ${
                  option._id === 'add' ? 'highlight' : ''
                }`}
              >
                {option.name}
              </li>
            )}
            loading={props.isLoading}
            value={
              field.value
                ? props.options.find((opt) => opt._id === field.value) || {
                    _id: '',
                    name: '',
                  }
                : { _id: '', name: '' }
            }
            onChange={(e, value) => {
              if (value?._id === 'add' && props.onAddNewOption) {
                props.onAddNewOption();
              } else {
                field.onChange(value?._id ?? '');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!getNestedValue(props.errors, props.inputName)}
                helperText={
                  getNestedValue(props.touchedFields, props.inputName) &&
                  getNestedValue(props.errors, props.inputName)?.message
                }
                value={field.value ? field.value : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {props.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </>
  );
};
