/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Fade,
  Grid,
  Grow,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FilledButton } from 'components';
import {
  useUserProfileImageAssignMutation,
  useUserProfileImageCreateMutation,
} from 'apps/account/domains/profile/subdomains/profile';
import { Gender, IFileMetadata, IGraphqlVariables, unknownError } from 'corede-common';
import { UploadImageComponent } from 'components/uploadFile/UploadImage';
import { Controller, useForm } from 'react-hook-form';
import { IUserUpdateOwnInput } from 'corede-common-cocrm';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateUpdateProfileInput } from '../validations/update.validation';
import { useUserDetailOwnQuery, useUserUpdateOwnMutation } from 'apps/auth/context';
import ActionDialog from 'components/dialog/ActionDialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { convertEnums, getCurrentLanguage } from 'localization';
import { RefreshOutlined } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCountryListQuery, useLazyStateListQuery } from 'apps/auth/context';

const List = () => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<IFileMetadata | undefined>(undefined);
  const [openChangeImage, setOpenChangeImage] = useState(false);
  const currentLanguage = getCurrentLanguage();
  const { data: userDetailData } = useUserDetailOwnQuery({});
  const [
    userProfileImageAssign,
    {
      data: userProfileImageAssignData,
      isLoading: userProfileImageAssignLoading,
      error: userProfileImageAssignError,
    },
  ] = useUserProfileImageAssignMutation();
  const [
    userUpdateOwn,
    { data: userUpdateOwnData, isLoading: userUpdateOwnLoading, error: userUpdateOwnError },
  ] = useUserUpdateOwnMutation();
  const localizedGenders = convertEnums(Gender);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { data: countryListData } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData }] = useLazyStateListQuery({});

  const handleSave = async () => {
    await userProfileImageAssign({
      input: {
        file: imageFile as any,
      },
    });
  };

  const initialValues = useMemo(
    () => ({
      input: {
        name: '',
        surname: '',
        birthDate: undefined,
        gender: undefined,
        country: undefined,
        city: undefined,
      },
    }),
    [],
  );

  const { control, handleSubmit, formState, setValue, watch } = useForm<
    IGraphqlVariables<IUserUpdateOwnInput>
  >({
    defaultValues: initialValues,
    resolver: yupResolver(validateUpdateProfileInput),
    mode: 'onSubmit',
  });

  const onSubmit = async (values: IGraphqlVariables<IUserUpdateOwnInput>) => {
    userUpdateOwn({
      input: values.input,
    });
  };

  useEffect(() => {
    if (userDetailData) {
      setValue('input.name', userDetailData?.name);
      setValue('input.surname', userDetailData?.surname);
      setValue('input.birthDate', userDetailData?.birthDate);
      setValue('input.phoneNumber', userDetailData?.phoneNumber);
      setValue('input.gender', userDetailData?.gender);
      setValue('input.country', userDetailData?.country);
      setValue('input.city', userDetailData?.city);
    }
  }, [userDetailData]);

  useEffect(() => {
    if (userDetailData?.country) {
      getStateList({
        input: {
          filter: {
            country_name: userDetailData?.country,
          },
        },
      });
    }
  }, [userDetailData?.country]);

  useEffect(() => {
    if (watch('input.country')) {
      getStateList({
        input: {
          filter: {
            country_name: watch('input.country'),
          },
        },
      });
    }
  }, [watch('input.country')]);

  useEffect(() => {
    if (userUpdateOwnData) {
      enqueueSnackbar(t('account.profile.profile.updateProfileSuccess'), { variant: 'success' });
    }
  }, [userUpdateOwnData]);

  useEffect(() => {
    if (userProfileImageAssignData) {
      enqueueSnackbar(t('account.profile.profile.updateProfilePictureSuccess'), {
        variant: 'success',
      });
      setOpenChangeImage(false);
    }
  }, [userProfileImageAssignData]);

  useEffect(() => {
    if (userUpdateOwnError) {
      enqueueSnackbar(
        (userUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [userUpdateOwnError]);

  useEffect(() => {
    if (userProfileImageAssignError) {
      enqueueSnackbar(
        (userProfileImageAssignError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [userProfileImageAssignError]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={5} pl={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <Grid item xs={12}>
          <HeaderBase title={t('account.profile.profile.myAccount')} />

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid container spacing={3} alignItems="stretch" py={2}>
                <Grow in={true} timeout={500}>
                  <Grid item xs={12} md={4} xl={3} sx={{ display: 'flex' }} width={'100%'}>
                    <Card
                      sx={{
                        border: '1px solid',
                        bgcolor: 'background.thirdWithBlur',
                        borderColor: 'primary.lighter',
                        py: 10,
                        width: '100%',
                      }}
                    >
                      <Stack
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={2}
                        width={'100%'}
                      >
                        <InputLabel
                          htmlFor="input.name"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small', cursor: 'pointer' }}
                        >
                          {t('account.profile.profile.profilePicture')}
                        </InputLabel>
                        <Box
                          sx={{
                            border: '1px dashed',
                            borderColor: 'primary.light',
                            borderRadius: '55px',
                            position: 'relative',
                            cursor: 'pointer',
                          }}
                          onClick={() => setOpenChangeImage(true)}
                        >
                          <Avatar
                            src={userDetailData?.profileImage?.thumbnailPublicUrl}
                            sx={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '55px',
                              border: '5px solid',
                              borderColor: 'background.secondary',
                            }}
                          />
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                </Grow>
                <Grow in={true} timeout={300}>
                  <Grid item xs={12} md={8} xl={9} sx={{ display: 'flex' }}>
                    <Card
                      sx={{
                        border: '1px solid',
                        bgcolor: 'background.thirdWithBlur',
                        borderColor: 'primary.lighter',
                        py: 4,
                        px: 4,
                      }}
                    >
                      <Stack
                        direction={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                        height={'100%'}
                      >
                        <Grid container spacing={2}>
                          <Fade in={true} timeout={1000}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="input.name"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.name')}
                              </InputLabel>
                              <Controller
                                name="input.name"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="input.name"
                                    size="small"
                                    fullWidth
                                    onChange={(e) => {
                                      setValue(
                                        'input.name',
                                        e.target.value.charAt(0).toUpperCase() +
                                          e.target.value.slice(1),
                                      );
                                    }}
                                    error={!!formState.errors.input?.name}
                                    helperText={formState.errors.input?.name?.message}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={1200}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="input.surname"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.surname')}
                              </InputLabel>
                              <Controller
                                name="input.surname"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="input.surname"
                                    size="small"
                                    fullWidth
                                    onChange={(e) => {
                                      setValue(
                                        'input.surname',
                                        e.target.value.charAt(0).toUpperCase() +
                                          e.target.value.slice(1),
                                      );
                                    }}
                                    error={!!formState.errors.input?.surname}
                                    helperText={formState.errors.input?.surname?.message}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={1400}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="input.birthDate"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.birthDate')}
                              </InputLabel>
                              <Controller
                                name="input.birthDate"
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    {...field}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => field.onChange(date)}
                                    maxDate={moment().subtract(18, 'years')}
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        error: !!formState.errors.input?.birthDate,
                                        helperText: formState.errors.input?.birthDate?.message,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={1600}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="gender"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.gender')}
                              </InputLabel>
                              <Controller
                                name="input.gender"
                                control={control}
                                render={({ field }) => (
                                  <Autocomplete
                                    noOptionsText={t('noOptionsText')}
                                    id="gender"
                                    size="small"
                                    fullWidth
                                    isOptionEqualToValue={(option, value) =>
                                      option._id === value._id
                                    }
                                    options={Object.values(Gender).map((gender) => ({
                                      name: localizedGenders[gender as keyof typeof Gender],
                                      _id: gender,
                                    }))}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                      field.value
                                        ? {
                                            name: localizedGenders[
                                              field.value as keyof typeof Gender
                                            ],
                                            _id: field.value,
                                          }
                                        : null
                                    }
                                    onChange={(e, value) => {
                                      field.onChange(value?._id);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={1800}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="input.country"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.country')}
                              </InputLabel>
                              <Controller
                                name="input.country"
                                control={control}
                                render={({ field }) => (
                                  <Autocomplete
                                    noOptionsText={t('noOptionsText')}
                                    options={[...(countryListData?.data || [])]}
                                    size="small"
                                    fullWidth
                                    id="input.country"
                                    isOptionEqualToValue={(option, value) =>
                                      option.name === value?.name
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        field.onChange(newValue.name);
                                      } else {
                                        field.onChange('');
                                      }
                                    }}
                                    value={
                                      field.value
                                        ? countryListData?.data?.find(
                                            (country) => country.name === field.value,
                                          ) || null
                                        : null
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={2000}>
                            <Grid item xs={12} md={6}>
                              <InputLabel
                                htmlFor="input.city"
                                sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                              >
                                {t('account.profile.profile.city')}
                              </InputLabel>
                              <Controller
                                name="input.city"
                                control={control}
                                render={({ field }) => (
                                  <Autocomplete
                                    noOptionsText={t('noOptionsText')}
                                    options={[...(stateListData?.data || [])]}
                                    size="small"
                                    fullWidth
                                    id="input.city"
                                    isOptionEqualToValue={(option, value) =>
                                      option.name === value?.name
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        field.onChange(newValue.name);
                                      } else {
                                        field.onChange('');
                                      }
                                    }}
                                    value={
                                      field.value
                                        ? stateListData?.data?.find(
                                            (city) => city.name === field.value,
                                          ) || null
                                        : null
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                )}
                              />
                            </Grid>
                          </Fade>
                          <Fade in={true} timeout={2200}>
                            <Grid item xs={12} mt={1}>
                              <Stack direction="row" gap={1} justifyContent="flex-end">
                                <FilledButton
                                  leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                                  color="primary"
                                  loading={userUpdateOwnLoading}
                                  title={t('account.profile.profile.update')}
                                  onClick={handleSubmit(onSubmit)}
                                />
                              </Stack>
                            </Grid>
                          </Fade>
                        </Grid>
                      </Stack>
                    </Card>
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Grid>

          <UploadImageComponent
            open={openChangeImage}
            setOpen={setOpenChangeImage}
            currentImage={userDetailData?.profileImage?.thumbnailPublicUrl}
            withAvatarPack={true}
            setLoading={setUploadLoading}
            useImageAssignMutation={useUserProfileImageAssignMutation}
            useImageCreateMutation={useUserProfileImageCreateMutation}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default List;
