/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProposalDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';

export interface IProposalDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IProposalDetailResult> {
  proposalDetailData: IProposalDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ProposalDetailDrawerTabInfoComponent = (
  props: IProposalDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const totalPrice = props.proposalDetailData?.totalPrice;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ px: 2, py: 1, mt: 1, position: 'relative' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {t('crm.preSale.proposal.productInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          {props.proposalDetailData?.products?.length ? (
            <Grid container spacing={2}>
              {props.proposalDetailData.products.map((product: any, index: number) => (
                <Grid key={index} item xs={12}>
                  <Box sx={{ border: '1px solid #ddd', borderRadius: 2, padding: 2 }}>
                    {/* Ürün Adı */}
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ marginBottom: 3 }}>
                      <Icon icon="mdi:package-variant" width={20} />
                      <Typography variant="body1" fontWeight="bold">
                        {product.name || t('crm.preSale.proposal.productNameUnknown')}
                      </Typography>
                    </Stack>

                    {/* Fiyat ve Miktar */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon icon="mdi:cash" width={20} />
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {t('crm.preSale.proposal.price')}
                            </Typography>
                            <Typography variant="body2">
                              {product.price ? `${product.price} ${product.currency || ''}` : '-'}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon icon="mdi:tag" width={20} />
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {t('crm.preSale.proposal.discountedPrice')}
                            </Typography>
                            <Typography variant="body2">
                              {product.discountedPrice
                                ? `${product.discountedPrice} ${product.currency || ''}`
                                : '-'}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon icon="mdi:cart-outline" width={20} />
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {t('crm.preSale.proposal.quantity')}
                            </Typography>
                            <Typography variant="body2">{product.quantity || '-'}</Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon icon="mdi:percent" width={20} />
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {t('crm.preSale.proposal.tax')}
                            </Typography>
                            <Typography variant="body2">
                              {product.tax ? `${product.tax}%` : '-'}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon icon="mdi:calculator-variant" width={20} />
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {t('crm.preSale.proposal.finalPrice')}
                            </Typography>
                            <Typography variant="body2">
                              {product.finalPrice
                                ? `${product.finalPrice} ${product.currency || ''}`
                                : '-'}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {t('crm.preSale.proposal.noProducts')}
            </Typography>
          )}
        </Box>
      </Stack>
      <Stack direction={'column'} sx={{ px: 2, py: 1, mb: 2, position: 'relative' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {t('crm.preSale.proposal.financialInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          {totalPrice ? (
            <Grid container spacing={2}>
              {/* Currency */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:currency-usd" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.currency')}
                    </Typography>
                    <Typography variant="body2">
                      {props.proposalDetailData?.currency || '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Sub Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:calculator-variant" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.subTotal')}
                    </Typography>
                    <Typography variant="body2">
                      {totalPrice.subTotal
                        ? `${totalPrice.subTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Discount */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:tag-outline" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.discount')}
                    </Typography>
                    <Typography variant="body2">
                      {totalPrice.discount
                        ? `-${totalPrice.discount} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Tax Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:percent" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.taxTotal')}
                    </Typography>
                    <Typography variant="body2">
                      {totalPrice.taxTotal
                        ? `${totalPrice.taxTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Shipping Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:truck-outline" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.shippingTotal')}
                    </Typography>
                    <Typography variant="body2">
                      {totalPrice.shippingTotal
                        ? `${totalPrice.shippingTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon icon="mdi:cash-multiple" width={20} />
                  <Box>
                    <Typography variant="body2" fontWeight="bold">
                      {t('crm.preSale.proposal.total')}
                    </Typography>
                    <Typography variant="body2">
                      {totalPrice.total
                        ? `${totalPrice.total} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {t('crm.preSale.proposal.noFinancialData')}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
