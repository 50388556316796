const locale = {
  // alanlar
  users: "Kullanıcıları Hatırlat",
  date: "Hatırlatma Tarihi",
  title: "Hatırlatmaları Listele",
  detail: "Hatırlatma Detayı",
  sharedList: 'Paylasılan Hatırlatmalar',
  privateList: 'Özel Hatırlatmalarım',
  ownList: 'Hatırlatmalarım',
  allList: 'Bütün Hatırlatmalar',

  // işlemler
  create: "Hatırlatma Oluştur",
  add: "Hatırlatma Ekle",
  update: "Hatırlatma Güncelle",
  delete: "Hatırlatma Sil",

  // durum metinleri
  createSuccess: "Hatırlatma başarıyla oluşturuldu",
  updateSuccess: "Hatırlatma başarıyla güncellendi",
  deletedSuccessfully: "Hatırlatma başarıyla silindi",
  deleteContent: "Bu hatırlatmayı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  emptyState1: "Henüz eklenmiş bir hatırlatma yok.",
  emptyState2: "Faturalama sürecinizi kolaylaştırmak ve müşterilerinizle net anlaşmalar sağlamak için tüm hatırlatma detaylarını burada depolayın ve yönetin.",
};

export default locale;