/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITicketDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { TicketDetailDrawerHeaderComponent } from './TicketDrawer-header.component';
import { TicketDetailDrawerContentComponent } from './TicketDrawer-content.component';
import { Stack } from '@mui/material';
import { useTicketDetailQuery } from '../../context/support.api';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';

type TTicketEntity = (ITicketDetailResult & IHasId<number>) | undefined;

export interface ITicketDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  ticket: TTicketEntity;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const TicketDetailDrawer = memo((props: ITicketDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [ticket, setTicket] = useState<TTicketEntity>(undefined);

  const {
    data: ticketDetailData,
    isLoading: ticketDetailLoading, // TODO: is loading state for detail page
    error: ticketDetailError,
  } = useTicketDetailQuery(
    {
      input: {
        _id: props.ticket?._id ?? '',
      },
    },
    {
      skip: !props.ticket,
    },
  );

  useEffect(() => {
    setTicket({
      ...ticketDetailData!,
      id: props.ticket?.id ?? 0,
    });
  }, [ticketDetailData]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketDetailError as IBaseError, currentLanguage);
  }, [ticketDetailError]);

  return (
    <DefaultDetailDrawer<ITicketDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={ticket!}
      widthResponsive={{ xs: 'calc(90dvw)', sm: 400, md: 400, lg: 400 }}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.tickets'),
            }
      }
    >
      <Stack direction={{ xs: 'column' }} width={'100%'} p={2}>
        <TicketDetailDrawerHeaderComponent ticketDetailData={ticket} />
        <TicketDetailDrawerContentComponent ticketDetailData={ticket} />
      </Stack>
    </DefaultDetailDrawer>
  );
});

export default TicketDetailDrawer;
