import { useState, lazy, Suspense, Dispatch, SetStateAction } from 'react';

const ServiceUpdateDrawer = lazy(() => import('./ServiceUpdateDrawer'));

const ServiceUpdateDrawerWrapper = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  productId: string;
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ServiceUpdateDrawer
            open={props.open}
            setOpen={props.setOpen}
            productId={props.productId}
          />
        </Suspense>
      )}
    </>
  );
};

export default ServiceUpdateDrawerWrapper;
