
import expenseLocale from "../subdomains/expense/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  expense: {
    ...expenseLocale,
  },
};

export default locale;
