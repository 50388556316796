import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IReminder, IReminderListItemResult } from 'corede-common-cocrm';
import { Stack, Box, Typography, Divider, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getTranslatedEnumValue } from 'localization';
import moment from 'moment';
import parse from 'html-react-parser';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

interface IReminderListItemProps {
  reminder: IReminderListItemResult;
  setOpenReminderUpdateDialog: (value: boolean) => void;
  setOpenReminderDeleteDialog: (value: boolean) => void;
  setSelectedReminder: React.Dispatch<React.SetStateAction<TListGridSelectedEntity<IReminder>>>;
}

const ITEM_HEIGHT = 48;

const ReminderListItemComponent = (props: IReminderListItemProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction={'column'} gap={2}>
      <Stack
        direction={'column'}
        alignItems={'flex-start'}
        gap={1}
        sx={{
          borderRadius: '24px',
          border: '1px solid',
          borderColor: 'grey.300',
          px: 3,
          py: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box>
            <Typography variant={'h5'} sx={{ fontWeight: 500 }}>
              {props.reminder.title}
            </Typography>
            <Typography variant={'subtitle2'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.reminder.remindUsers?.map((user) => user.name + ' ' + user.surname).join(', ')}
            </Typography>
          </Box>

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                props.setOpenReminderUpdateDialog(true);
                props.setSelectedReminder(props.reminder as TListGridSelectedEntity<IReminder>);
                handleClose();
              }}
            >
              {t('crm.calendar.reminder.update')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setOpenReminderDeleteDialog(true);
                props.setSelectedReminder(props.reminder as TListGridSelectedEntity<IReminder>);
                handleClose();
              }}
            >
              {t('crm.calendar.reminder.delete')}
            </MenuItem>
          </Menu>
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
        >
          <Stack direction={'row'} gap={1.5}>
            <Box
              sx={{
                bgcolor: 'warning.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 1.5,
                py: 0.25,
              }}
            >
              <Typography variant={'subtitle2'} sx={{ fontWeight: 500, color: 'warning.main' }}>
                {getTranslatedEnumValue(props.reminder.priority)}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                bgcolor: 'info.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 1.5,
                py: 0.25,
              }}
            >
              <Typography variant={'subtitle2'} sx={{ fontWeight: 500, color: 'info.main' }}>
                High
              </Typography>
            </Box> */}
          </Stack>

          <Typography variant={'subtitle2'} sx={{ fontWeight: '500', color: 'text.secondary' }}>
            {moment(props.reminder.remindDate).format('DD.MM.YYYY HH:mm')}
          </Typography>
        </Stack>

        <Typography variant={'subtitle2'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {parse(props.reminder.content ?? '')}
        </Typography>

        <Divider sx={{ width: '100%' }} />

        {props.reminder?.tags?.length > 0 &&
          props.reminder?.tags?.map((tag) => (
            <Box
              sx={{
                width: 'fit-content',
                bgcolor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 1.5,
                py: 0.25,
              }}
            >
              <Typography variant={'subtitle2'} sx={{ fontWeight: 500, color: 'secondary.main' }}>
                {tag}
              </Typography>
            </Box>
          ))}
      </Stack>
    </Stack>
  );
};

export default ReminderListItemComponent;
