import { Route, Routes } from 'react-router-dom';

import TicketList from '../pages/list/TicketList';

import { SupportLayout } from '../layouts/SupportLayout';

export const SupportRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SupportLayout />}>
        <Route index element={<TicketList />} />
      </Route>
    </Routes>
  );
};
