/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip, Stack, Typography, IconButton } from '@mui/material';
import { Iconify } from 'components';
import { TOpenDrawerCallback } from 'components/baseDataGrid/infra/types';
import { useTranslation } from 'react-i18next';

export interface IDefaultDetailDrawerUpperPanelComponent {
  sideNavigation?: {
    index: number;
    count: number;
    recordsTranslation?: string;
  };
  setOpen: TOpenDrawerCallback;
}

export const DefaultDetailDrawerUpperPanelComponent = (
  props: IDefaultDetailDrawerUpperPanelComponent,
) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        height: 84,
        borderBottom: '1px solid',
        borderColor: 'secondary.main',
        px: 2,
        py: 2.5,
      }}
    >
      {props.sideNavigation && (
        <Typography variant="h5">
          {props.sideNavigation.index} {t('crm.of')} {props.sideNavigation.count}{' '}
          {props.sideNavigation.recordsTranslation ?? t('crm.records')}
        </Typography>
      )}
      <Tooltip title={t('webNotification.close')}>
        <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
          <Iconify icon={'material-symbols:close'} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
