import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import * as yup from 'yup';

export const validateCreateInvoiceInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    billingInfo: yup.object().shape({
      // TODO add field validations for create
    }),
  }),
});
