/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  ITask,
  ITaskDetailResult,
  ITaskListInput,
  TaskPriority,
  TaskStatus,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useTaskListQuery, useTaskUpdateMutation } from '../../context/task.api';
import TaskDetailDrawer from '../detailDrawer/TaskDetailDrawer';
import { TaskDeleteDialog } from '../TaskDeleteDialog';
import TaskUpdateDrawerWrapper from '../update/TaskUpdateOverlay';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import {
  representString,
  representDateAsString,
  representArrayOfObjectAsString,
  representBooleanAsString,
  representHtmlAsString,
} from 'utils/representationHelper';
import { OverlayType } from 'components/dialog/ActionDialog';
import { useDepartmentListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';

export interface ITaskListGridComponent {
  // task

  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
}

const TaskListGridComponent = memo((props: ITaskListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();

  const [openTaskDetailDrawer, setOpenTaskDetailDrawer] = useState(false);
  const [openTaskDeleteDialog, setOpenTaskDeleteDialog] = useState(false);
  const [openTaskUpdateDrawer, setOpenTaskUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [taskListFilter, setTaskListFilter] = useState<IGraphqlVariables<ITaskListInput>>({
    input: {
      filter: {
        businessTypes: undefined,
        isJunk: undefined,
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = taskListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = taskListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: taskListData,
    isLoading: taskListLoading,
    isFetching: taskListFetching,
    error: taskListError,
    refetch,
  } = useTaskListQuery(taskListFilter);

  const { data: departmentListData } = useDepartmentListQuery({});
  // used for direct update in the list rows
  const [taskUpdate, { isLoading: taskUpdateLoading, error: taskUpdateError }] =
    useTaskUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [taskListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskListError as IBaseError, currentLanguage);
  }, [taskListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskUpdateError as IBaseError, currentLanguage);
  }, [taskUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(taskListFilter?.input?.filter);
  const isJunkUndefined = (taskListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = taskListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    taskListData?.data?.length === 0 &&
    !taskListLoading &&
    !taskListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={undefined}
      setSelectedEntity={() => null}
      listFilter={taskListFilter}
      setListFilter={setTaskListFilter}
      listData={taskListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {taskListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={taskListData?.data?.map((task, index) => ({
                id:
                  ((taskListFilter.input?.pagination?.page || 1) - 1) *
                    (taskListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: task?._id,
                subject: representString(task?.subject),
                description: representHtmlAsString({ data: task?.description, trimLength: 20 }),
                startDate: representDateAsString(task?.startDate),
                dueDate: representDateAsString(task?.dueDate),
                assignees: representArrayOfObjectAsString({ dataList: task?.assignees }),
                followers: representArrayOfObjectAsString({ dataList: task?.followers }),
                // checklist
                department: task?.department,
                priority: representString(task.priority),
                tags: representArrayOfObjectAsString({ dataList: task?.tags }),
                hourlyRate: representString(task?.hourlyRate?.toFixed(2)),
                hourlyRateCurrency: representString(task?.hourlyRateCurrency),
                isBillable: representBooleanAsString({
                  data: task.isBillable,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                isRecurrent: representBooleanAsString({
                  data: task.isRecurrent,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                repeatDays: representString(task?.repeatDays.toFixed(0)),
                totalCycle: representString(task?.totalCycle.toFixed(0)),
                status: task?.status,
              }))}
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: '#',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'subject',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.subject'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      width: 200,
                      field: 'description',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.description'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      // icon: 'mdi:checkbox-marked-circle',
                      name: t('crm.status'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `status`,
                      isArrayFilter: true,
                    },
                  },
                  data: TaskStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'startDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.startDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'dueDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.dueDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'assignees',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.assignees'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'followers',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.followers'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'department',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      icon: 'mdi:source-fork',
                      name: t('crm.department'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `departments`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: departmentListData?.data,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'priority',
                      editable: true,
                      filterable: true,
                      width: 90,
                    },
                    headerConfig: {
                      icon: 'mdi:folder-outline',
                      name: t('crm.priority'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `priorities`,
                      isArrayFilter: true,
                    },
                  },
                  data: TaskPriority,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'tags',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.tags'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'hourlyRate',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.hourlyRate'),
                    },
                  },
                },

                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'hourlyRateCurrency',
                      editable: true,
                      filterable: true,
                      width: 90,
                    },
                    headerConfig: {
                      icon: 'mdi:folder-outline',
                      name: t('crm.task.task.hourlyRateCurrency'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `currencies`,
                      isArrayFilter: true,
                    },
                  },
                  data: Currency,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'isBillable',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.isBillable'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'isRecurrent',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.isRecurrent'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'repeatDays',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.repeatDays'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'totalCycle',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.task.task.totalCycle'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenTaskDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenTaskUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenTaskDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={taskListLoading || taskUpdateLoading || taskListFetching}
              listFilter={{
                filterInput: taskListFilter,
                setFilterInput: setTaskListFilter,
              }}
              update={{
                updateQuery: taskUpdate,
              }}
              count={taskListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  department: false,
                  tags: false,
                  isBillable: false,
                  isRecurrent: false,
                  repeatDays: false,
                  totalCycle: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.task.task.emptyState1')}
              content2={t('crm.task.task.emptyState2')}
              button1={{
                title: t('crm.task.task.create'),
                onClick: () => (props?.setOpenTaskCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <TaskDetailDrawer
        setSelectedTask={setSelectedTask}
        open={openTaskDetailDrawer}
        setOpen={setOpenTaskDetailDrawer}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: taskListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: taskListData,
            listFilter: taskListFilter,
            setListFilter: setTaskListFilter,
            selectedEntity: selectedTask,
            setSelectedEntity: setSelectedTask,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <TaskUpdateDrawerWrapper
        open={openTaskUpdateDrawer}
        setOpen={setOpenTaskUpdateDrawer}
        targetId={selectedTask?.relatedEntity?._id ?? ''}
        targetEntityRef={selectedTask?.relatedEntityRef}
        item={selectedTask}
        overlayType={OverlayType.drawer}
      />

      <TaskDeleteDialog
        open={openTaskDeleteDialog}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        setItem={setSelectedTask}
        key={selectedTask?._id}
        onClose={() => {
          setSelectedTask(undefined);
          setOpenTaskDeleteDialog(false);
        }}
      />
    </DefaultListGridComponent>
  );
});

export default TaskListGridComponent;
