/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { getTranslatedEnumValue } from 'localization';
import { enumValues } from 'corede-common';
import { useTranslation } from 'react-i18next';

export interface IDefaultEnumInputComponent<T extends FieldValues> {
  enum: object;
  control: Control;
  fieldKey: Path<T>;
  label: string;
}

/**
 * add buttons to upper left of the drawer to navigate to prev and next record
 */
export const DefaultEnumInputComponent = <T extends FieldValues>(
  props: IDefaultEnumInputComponent<T>,
) => {
  const { t } = useTranslation();

  return (
    <>
      <InputLabel htmlFor={props.fieldKey} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
      </InputLabel>
      <Controller
        name={props.fieldKey}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            id={props.fieldKey}
            size="small"
            fullWidth
            isOptionEqualToValue={(option, value) => option._id === value._id}
            options={enumValues(props.enum).map((enumValue) => ({
              name: getTranslatedEnumValue(enumValue),
              _id: enumValue,
            }))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} />}
            value={
              field.value
                ? {
                    name: getTranslatedEnumValue(field.value),
                    _id: field.value,
                  }
                : null
            }
            onChange={(e, value) => {
              field.onChange(value?._id);
            }}
          />
        )}
      />
    </>
  );
};
