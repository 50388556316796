/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Iconify } from 'components';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import DetailBox from 'components/box/DetailBox';
import { ICustomerDetailResult } from 'corede-common-cocrm';
import { t } from 'i18next';
import { memo } from 'react';

interface ICustomerDetailTabContactInfoSectionComponentProps
  extends DefaultTabComponentProps<ICustomerDetailResult> {
  customerData: ICustomerDetailResult | undefined;
  tabKey: 'contactInformation';
  name: 'crm.contactInformation';
}

export const CustomerDetailTabContactInfoSectionComponentProps = memo(
  (props: ICustomerDetailTabContactInfoSectionComponentProps) => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const contactData = [
      {
        title: t('crm.firstName'),
        value: props.customerData?.primaryContact?.firstName,
        icon: 'clarity:map-marker-line',
      },
      {
        title: t('crm.lastName'),
        value: props.customerData?.primaryContact?.lastName,
        icon: 'solar:city-broken',
      },
      {
        title: t('crm.email'),
        value: props.customerData?.primaryContact?.email,
        icon: 'mage:location',
      },
      {
        title: t('crm.phone'),
        value: props.customerData?.primaryContact?.phone,
        icon: 'tabler:language',
      },
      {
        title: t('crm.position'),
        value: props.customerData?.primaryContact?.position,
        icon: 'tabler:language',
      },
    ];

    return (
      <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
        <Grid item xs={12} md={6}>
          <Stack
            direction={'column'}
            p={2.5}
            sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
          >
            <Typography
              variant={'caption'}
              sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
            >
              {t('crm.company')}
            </Typography>
            <Grid container spacing={1}>
              {contactData.map((item) => (
                <Grid item xs={12} md={6}>
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: 'secondary.extraLight',
                        borderRadius: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                    </Box>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                        {item.value ?? '-'}
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {item.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>

        {/* {props.customerData?.otherContactList &&
          props.customerData?.otherContactList?.length > 0 &&
          props.customerData?.otherContactList.map((item, index) => (
            <Grid key={index} item xs={12}>
              <DetailBox title={t('crm.otherContactList')}>
                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[
                    t('crm.firstName'),
                    t('crm.lastName'),
                    t('crm.email'),
                    t('crm.phone'),
                    t('crm.position'),
                  ].map((label, index) => (
                    <Box display="flex" alignItems="center" minHeight={35} key={index}>
                      <Typography fontSize={'14px'} fontWeight={600}>
                        {label}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[item.firstName, item.lastName, item.email, item.phone, item.position].map(
                    (value, index) => (
                      <Box display="flex" alignItems="center" minHeight={35} key={index}>
                        <Typography fontSize={'14px'} fontWeight={400}>
                          : {value}
                        </Typography>
                      </Box>
                    ),
                  )}
                </Grid>
              </DetailBox>
            </Grid>
          ))} */}
      </Grid>
    );
  },
);
