/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import EmptyState from 'components/emptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import {
  useFileListOwnQuery,
  useFileListQuery,
  useUserDocumentAddManyMutation,
  useUserDocumentCreateManyMutation,
} from '../context/document.api';
import FilesGridList from './list/FilesGridList';
import { CloudUpload } from '@mui/icons-material';
import { UploadDocumentComponent } from 'components/uploadFile/UploadDocument';
import { useEffect, useState } from 'react';
import { OutlinedButton } from 'components';
import FilesGridSkeleton from './list/FilesGridSkeleton';
import { DocumentFolder, IFileListInput } from 'corede-common-cocrm';
import { FileContentType, FileExtension, IBaseError, IGraphqlVariables } from 'corede-common';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { getCurrentLanguage } from 'localization';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import FilesFilter from './list/FilesFilter.component';

const MyDocuments = () => {
  const { t } = useTranslation();
  const [openDocumentCreateDialog, setOpenDocumentCreateDialog] = useState(false);

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [fileListFilter, setFileListFilter] = useState<IFileListInput>({
    filter: {
      types: [FileContentType.document],
      hasEntity: true,
      customName: undefined,
      entityModels: undefined,
      extensions: undefined,
      tags: undefined,
    },
    pagination: {
      page: 1,
      pageSize: 12,
    },
  });

  const { data: fileListData, error: fileListError } = useFileListOwnQuery({
    input: fileListFilter,
  });

  const isEmpty = fileListData && fileListData?.count === 0;

  useEffect(() => {
    if (fileListError) {
      DefaultErrorHandlerUseEffect(fileListError as IBaseError, getCurrentLanguage());
    }
  }, [fileListError]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={5} pl={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{ fontSize: { xs: '20px', md: '24px' }, fontWeight: 'bold' }}>
          {t('disk.document.document.myDocuments')}
        </Typography>
{/* 
        <Box visibility={fileListData && fileListData?.count > 0 ? 'visible' : 'hidden'}>
          <OutlinedButton
            title={t('upload')}
            onClick={() => setOpenDocumentCreateDialog(true)}
            leftIcon={<CloudUpload sx={{ mr: 1, opacity: 0.7 }} />}
          />
        </Box> */}
      </Stack>
      <FilesFilter fileListFilter={fileListFilter} setFileListFilter={setFileListFilter} />
      <Box height={20} />
      {fileListData ? (
        !isEmpty ? (
          <FilesGridList fileData={fileListData} />
        ) : (
          <Grid item xs={12}>
            <EmptyState
              content1={t('disk.document.document.emptyStateContent1')}
              content2={t('disk.document.document.emptyStateContent2')}
              button1={{
                onClick: () => setOpenDocumentCreateDialog(true),
                title: t('upload'),
                leftIcon: <CloudUpload sx={{ mr: 1, opacity: 0.7 }} />,
              }}
            />
          </Grid>
        )
      ) : (
        <FilesGridSkeleton />
      )}

      <UploadDocumentComponent
        entityId={userDetailOwnData?._id || ''}
        open={openDocumentCreateDialog}
        setOpen={setOpenDocumentCreateDialog}
        useDocumentAddManyMutation={useUserDocumentAddManyMutation}
        useDocumentCreateManyMutation={useUserDocumentCreateManyMutation}
      />
    </Stack>
  );
};

export default MyDocuments;
