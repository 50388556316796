import { Grid } from '@mui/material';
import { memo, useState,useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useInvoiceListQuery } from '../../context/invoice.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import InvoiceListComponent from './InvoiceList-grid.component';
import InvoiceCreateWrapper from '../create/InvoiceCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';
// import {InvoiceCreateDrawer} from '../InvoiceCreateDrawer';

const InvoiceList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openInvoiceCreateDrawer, setOpenInvoiceCreateDrawer] = useState(false);

  // queries
  const { data: invoiceListData } = useInvoiceListQuery({});

  let totalInvoiceCount = invoiceListData?.count ?? 0;
  let isEmpty = totalInvoiceCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenInvoiceCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.sale.invoice.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.sale.invoice.create`),
          onClick: () => setOpenInvoiceCreateDrawer(true),
        }}
      />

      <InvoiceListComponent setOpenInvoiceCreateDrawer={setOpenInvoiceCreateDrawer} />

      <InvoiceCreateWrapper open={openInvoiceCreateDrawer} setOpen={setOpenInvoiceCreateDrawer} />
    </Grid>
  );
});

export default InvoiceList;
