/* eslint-disable react-hooks/exhaustive-deps */
import { useProposalDetailQuery } from '../../context/proposal.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IProposal, IProposalDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ProposalDetailDrawerLeftPanelComponent } from './ProposalDetailDrawer-leftPanel.component';
import { ProposalDetailDrawerRightPanelComponent } from './ProposalDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IProposalDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedProposal: IProposalDetailResult & IHasId<number>;
  setSelectedProposal: Dispatch<SetStateAction<TListGridSelectedEntity<IProposal>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ProposalDetailDrawer = memo((props: IProposalDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: proposalDetailData, error: proposalDetailError } = useProposalDetailQuery(
    props?.selectedProposal?._id
      ? {
          input: {
            _id: props?.selectedProposal?._id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalDetailError as IBaseError, currentLanguage);
  }, [proposalDetailError]);

  return (
    <DefaultDetailDrawer<IProposalDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedProposal}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.preSale.proposal.proposals'),
            }
      }
    >
      <ProposalDetailDrawerLeftPanelComponent
        proposalDetailData={proposalDetailData}
        setOpen={props.setOpen}
      />
      <ProposalDetailDrawerRightPanelComponent proposalDetailData={proposalDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ProposalDetailDrawer;
