const locale = {
  leadStatuses: "Lead Statuses",
  title: "Lead Statuses",
  create: "Create Status",
  delete: "Delete Status",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Lead status deleted successfully",
  createLeadStatusSuccess: "Lead status created successfully",
  update: "Update Status",
  updateLeadStatusSuccess: "Lead status updated successfully",
  selectColor: "Select Color",
  selectIcon: "Select Icon",
};

export default locale;
