import userLocale from "../subdomains/user/locales/en";
import departmentLocale from "../subdomains/department/locales/en";
import permissionLocale from "../subdomains/permission/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  user: {
    ...userLocale,
  },
  department: {
    ...departmentLocale,
  },
  permission: {
    ...permissionLocale
  }
};

export default locale;
