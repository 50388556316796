const locale = {
  product: 'Products',
  title: 'Products',
  create: 'Create Product',
  update: 'Update Product',
  delete: 'Delete Product',
  deleteConfirm: 'Are you sure you want to delete this status: ',
  deletedSuccessfully: 'Product Product deleted successfully',
  createProduct: 'Create Product',
  createSuccess: 'Product Product created successfully',
  updateSuccess: 'Product updated successfully',
  name: 'Name',
  icon: 'Icon',
  showMore: 'Show More',
  detail: 'Product Detail',
  type: 'Type',
  image: 'Image',
  sku: 'Sku',
  description: 'Description',
  currency: 'Currency',
  price: 'Price',
  priceType: 'PriceType',
  discountedPrice: 'Discounted Price',
  unit: 'Unit',
  categoryId: 'Category',
  natTaxRate: 'National Tax Rate',
  intTaxRate: 'International Tax Rate',
  unitPrice: 'Unit Price',
  categories: 'Categories',

  emptyState1: 'No products recorded yet.',
  emptyState2:
    'Manage and review any products here to keep your records accurate and customer needs met.',
};

export default locale;
