import {
  ICustomerCategoryListInput, ICustomerCategoryListResult, ICustomerCategoryListRequest, ICustomerCategoryListResponse, customerCategoryListQuery, ICustomerCategoryListItemResult,
  ICustomerCategoryListOwnInput, ICustomerCategoryListOwnResult, ICustomerCategoryListOwnRequest, ICustomerCategoryListOwnResponse, customerCategoryListOwnQuery, ICustomerCategoryListOwnItemResult,
  ICustomerCategoryDetailInput, ICustomerCategoryDetailResult, ICustomerCategoryDetailRequest, ICustomerCategoryDetailResponse, customerCategoryDetailQuery,
  customerCategoryListCountQuery, ICustomerCategoryListCountRequest, ICustomerCategoryListCountResponse,


  ICustomerCategoryCreateInput, ICustomerCategoryCreateResult, ICustomerCategoryCreateRequest, ICustomerCategoryCreateResponse, customerCategoryCreateQuery,
  ICustomerCategoryUpdateInput, ICustomerCategoryUpdateResult, ICustomerCategoryUpdateRequest, ICustomerCategoryUpdateResponse, customerCategoryUpdateQuery, ICustomerCategoryUpdateFilterInput,
  ICustomerCategoryDeleteInput, ICustomerCategoryDeleteResult, ICustomerCategoryDeleteRequest, ICustomerCategoryDeleteResponse, customerCategoryDeleteQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { customerCategoryTags } from "./customerCategory.tags";
import { ICount, IGraphqlVariables } from "corede-common";

export const customerCategoriesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    customerCategoryList: builder.query<
      ICustomerCategoryListResult,
      IGraphqlVariables<ICustomerCategoryListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryListRequest,
        ICustomerCategoryListResponse,
        ICustomerCategoryListResult,
        ICustomerCategoryListInput
      >({
        query: customerCategoryListQuery,
        providesTags: [customerCategoryTags.customerCategories, customerCategoryTags.customerCategory],
      })),

    customerCategoryListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryListCountRequest,
        ICustomerCategoryListCountResponse,
        ICount,
        undefined
      >({
        query: customerCategoryListCountQuery,
        providesTags: [customerCategoryTags.customerCategories],
      })
    ),

    customerCategoryListOwn: builder.query<
      ICustomerCategoryListOwnResult,
      IGraphqlVariables<ICustomerCategoryListOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryListOwnRequest,
        ICustomerCategoryListOwnResponse,
        ICustomerCategoryListOwnResult,
        ICustomerCategoryListOwnInput
      >({
        query: customerCategoryListOwnQuery,
        providesTags: [customerCategoryTags.customerCategories, customerCategoryTags.customerCategory],
      })),

    customerCategoryDetail: builder.query<
      ICustomerCategoryDetailResult,
      IGraphqlVariables<ICustomerCategoryDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryDetailRequest,
        ICustomerCategoryDetailResponse,
        ICustomerCategoryDetailResult,
        ICustomerCategoryDetailInput
      >({
        query: customerCategoryDetailQuery,
        providesTags: [customerCategoryTags.customerCategories, customerCategoryTags.customerCategory],
      })),


    // mutation

    customerCategoryCreate: builder.mutation<
      ICustomerCategoryCreateResult,
      IGraphqlVariables<ICustomerCategoryCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryCreateRequest,
        ICustomerCategoryCreateResponse,
        ICustomerCategoryCreateResult,
        ICustomerCategoryCreateInput
      >({
        query: customerCategoryCreateQuery,
        invalidatesTags: [customerCategoryTags.customerCategories, customerCategoryTags.customerCategory],
      })),

    customerCategoryUpdate: builder.mutation<
      ICustomerCategoryUpdateResult,
      IGraphqlVariables<ICustomerCategoryUpdateInput, ICustomerCategoryUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryUpdateRequest,
        ICustomerCategoryUpdateResponse,
        ICustomerCategoryUpdateResult,
        ICustomerCategoryUpdateInput,
        ICustomerCategoryUpdateFilterInput
      >({
        query: customerCategoryUpdateQuery,
        invalidatesTags: [customerCategoryTags.customerCategories, customerCategoryTags.customerCategory],
      })),

    customerCategoryDelete: builder.mutation<
      ICustomerCategoryDeleteResult,
      IGraphqlVariables<ICustomerCategoryDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCategoryDeleteRequest,
        ICustomerCategoryDeleteResponse,
        ICustomerCategoryDeleteResult,
        ICustomerCategoryDeleteInput
      >({
        query: customerCategoryDeleteQuery,
        invalidatesTags: [customerCategoryTags.customerCategories],
      })),


  }),
});

export const {

  useCustomerCategoryListQuery,
  useLazyCustomerCategoryListQuery,
  useCustomerCategoryListCountQuery,
  useLazyCustomerCategoryListCountQuery,
  useCustomerCategoryListOwnQuery,
  useLazyCustomerCategoryListOwnQuery,
  useCustomerCategoryDetailQuery,
  useLazyCustomerCategoryDetailQuery,

  useCustomerCategoryCreateMutation,
  useCustomerCategoryUpdateMutation,
  useCustomerCategoryDeleteMutation,

} = customerCategoriesApi;
