const locale = {
  services: 'Hizmetler',
  title: 'Hizmetler',

  create: 'Hizmet Oluştur',
  update: 'Hizmet Güncelle',
  delete: 'Hizmet sil',
  deleteConfirm: 'Hizmeti silmek istediğinizden emin misiniz?',
  deletedSuccessfully: 'Hizmet silme başarılı',
  createSuccess: 'Hizmet oluşturma başarılı',
  updateSuccess: 'Hizmet güncelleme başarılı',
  name: 'Ad',
  showMore: 'Daha fazla göster',

  detail: 'Hizmet Detayı',
  type: 'Tip',
  image: 'Resim',
  sku: 'Sku',
  description: 'Detay',
  currency: 'Para Birimi',
  price: 'Fiyat',
  priceType: 'Fiyat Tipi',
  discountedPrice: 'İndirim Uygulanmış Fiyat',
  unit: 'Birim',
  categoryId: 'Kategori',
  natTaxRate: 'Ulusal Vergi Miktarı',
  intTaxRate: 'Uluslararası Vergi Miktarı',

  categories: 'Kategoriler',
  rate: 'Oran',
  rateType: 'Oran Tipi',
  discountedRate: 'İndirimli Oran',
  emptyState1: 'Henüz bir hizmet kaydedilmedi.',
  emptyState2: 'Hemen aşağıdaki butonu kullanarak hizmet oluşturabilirsiniz.',
};

export default locale;
