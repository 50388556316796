/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { IBaseError } from 'corede-common';
import { IBaseProductCategoryEntity } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useProductCategoryDeleteMutation } from '../../context/productCategory.api';
import ActionDialog from 'components/dialog/ActionDialog';

export const DeleteProductCategoryDialog = memo(
  (prop: { open: boolean; item: IBaseProductCategoryEntity | undefined; onClose: () => void }) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries

    // mutations
    const [
      userDelete,
      { data: userDeleteData, isLoading: userDeleteIsLoading, error: userDeleteError },
    ] = useProductCategoryDeleteMutation();

    // constants

    // // action handlers
    const handleDelete = (_id: string) => {
      userDelete({ input: { _id: _id } });
    };

    // useEffects.success
    useEffect(() => {
      if (userDeleteData) {
        prop?.onClose();
        enqueueSnackbar(t('crm.productAndService.productCategory.deletedSuccessfully'), {
          variant: 'success',
        });
      }
    }, [userDeleteData]);

    // // useEffects.error
    useEffect(() => {
      DefaultErrorHandlerUseEffect(userDeleteError as IBaseError, currentLanguage);
    }, [userDeleteError, currentLanguage]);

    // useEffects.init

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.productAndService.productCategory.delete')}
        buttonTitle={t('delete')}
        buttonColor="error"
        handleClick={() => handleDelete(prop?.item?._id as string)}
        disabled={userDeleteIsLoading}
        loading={userDeleteIsLoading}
      >
        <Grid item xs={12}>
          <Typography mt={2}>
            {t('crm.productAndService.productCategory.deleteConfirm')} {prop?.item?.name}
          </Typography>
        </Grid>
      </ActionDialog>
    );
  },
);
