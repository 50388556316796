const locale = {
  categories: 'Ürün Kategorileri',
  title: 'Ürün Kategorileri',
  create: 'Kategori Oluştur',
  delete: 'Kategori Sil',
  deleteConfirm: 'Kategoriyi silmek istediğinize emin misiniz: ',
  deletedSuccessfully: 'Kategori başarıyla silindi!',
  createStatus: 'Kategori Ekle',
  categoryCreateSuccess: 'Kategori başarıyla eklendi!',
  name: 'Kategori Adı',
  color: 'Kategori Rengi',
  icon: 'Kategori Simgesi',
  showMore: 'Daha fazla',
  createCategory: 'Kategori Ekle',
  update: 'Güncelle',
  updateCategory: 'Kategori Güncelle',
  categoryUpdateSuccess: 'Kategori başarıyla güncellendi!',
  en: 'İngilizce',
  tr: 'Türkçe',
  createSuccess: 'Kategori başarıyla oluşturuldu',
  updateSuccess: 'Kategori başarıyla güncellendi',
  actions: 'Aksiyon',
  createdAt: 'Oluşturulma Tarihi',
  emptyState1: 'Kategori bulunamadı.',
  emptyState2: 'Lütfen yeni bir kategori oluşturun.',
  createProductCategory: 'Kategori Oluştur',
};

export default locale;
