/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Divider,
} from '@mui/material';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { generateAutocompleteTranslatedOptions, getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  ILeadSourceResult,
  ILeadStatusResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from 'corede-common-cocrm';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import 'react-quill/dist/quill.snow.css';
import {
  BusinessCenterOutlined,
  ExpandMore,
  LanguageOutlined,
  LocalOfferOutlined,
  NoteOutlined,
} from '@mui/icons-material';
import ActionDrawer from 'components/drawer/ActionDrawer';
import { Iconify } from 'components';
import {
  useCountryListQuery,
  useLazyCityListQuery,
  useLazyStateListQuery,
} from 'apps/auth/context';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

import { UseFormReturn } from 'react-hook-form';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultPhoneTextFieldInput } from 'components/form/DefaultPhoneTextFieldInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import { DefaultSwitchInput } from 'components/form/DefaultSwitchInput';
import { DefaultNameAutocompleteInput } from 'components/form/DefaultNameAutocompleteInput';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { useLeadSourceListQuery } from '../../leadSource';
import { LeadSourcesCreateDialog } from '../../leadSource/pages';
import { useLeadStatusListQuery } from '../../leadStatus';
import { LeadStatusCreateDialog } from '../../leadStatus/pages';
import { MyTab } from 'components';

export interface IUpsertLeadComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  buttonTitle: string;
  loading?: boolean;

  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
  onSubmit: (values: IGraphqlVariables<any, any>) => Promise<void>;

  leadStatusList?: ILeadStatusResult[];
  leadSourceList?: ILeadSourceResult[];
}

export const UpsertLeadComponent = memo((props: IUpsertLeadComponentProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [expandedPanels, setExpandedPanels] = useState<string[]>([]);
  const [expandedAll, setExpandedAll] = useState(false);

  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);
  const tabList = [
    {
      title: t('crm.company'),
      value: 'company',
    },
    {
      title: t('crm.localization'),
      value: 'localization',
    },
    {
      title: t('crm.lead.lead.tracking'),
      value: 'tracking',
    },
    {
      title: t('crm.noteLabel'),
      value: 'noteLabel',
    },
  ];

  const tabListLabels = [
    t('crm.company'),
    t('crm.localization'),
    t('crm.lead.lead.tracking'),
    t('crm.noteLabel'),
  ];
  const [selectedTab, setSelectedTab] = useState(t('crm.company'));

  // queries
  const {
    data: leadStatusListDataFromApi,
    isLoading: leadStatusListLoading,
    error: leadStatusListError,
  } = useLeadStatusListQuery(
    {},
    {
      skip: !!props.leadStatusList, // Skip the query if props data exists
    },
  );
  const leadStatusList: ILeadStatusResult[] | undefined =
    props.leadStatusList || leadStatusListDataFromApi?.data;

  const {
    data: leadSourceListDataFromApi,
    isLoading: leadSourceListLoading,
    error: leadSourceListError,
  } = useLeadSourceListQuery(
    {},
    {
      skip: !!props.leadSourceList, // Skip the query if props data exists
    },
  );
  const leadSourceList: ILeadSourceResult[] | undefined =
    props.leadSourceList || leadSourceListDataFromApi?.data;

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListQuery({});

  const { data: countryListData, isLoading: countryListLoading } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData, isLoading: stateListLoading }] =
    useLazyStateListQuery({});
  const [getCityList, { data: cityListData, isLoading: cityListLoading }] = useLazyCityListQuery(
    {},
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        setExpandedPanels((prev) => [...prev, panel]);
      } else {
        setExpandedPanels((prev) => prev.filter((p) => p !== panel));
      }
    };

  const handleExpandAll = () => {
    if (expandedAll) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(['panel1', 'panel2', 'panel3', 'panel4']);
    }
    setExpandedAll(!expandedAll);
  };

  // useEffects.success

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadStatusListError as IBaseError, currentLanguage);
  }, [leadStatusListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadSourceListError as IBaseError, currentLanguage);
  }, [leadSourceListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(userListError as IBaseError, currentLanguage);
  }, [userListError]);

  // useEffects.init

  useEffect(() => {
    if (leadStatusList) {
      props.useForm.setValue('input.statusId', leadStatusList ? leadStatusList[0]?._id : '');
    }
  }, [leadStatusList]);

  useEffect(() => {
    if (leadSourceList) {
      props.useForm.setValue('input.sourceId', leadSourceList ? leadSourceList[0]?._id : '');
    }
  }, [leadSourceList]);

  useEffect(() => {
    if (props.useForm.watch('input.country')) {
      getStateList({
        input: {
          filter: {
            country_name: props.useForm.watch('input.country'),
          },
        },
      });
    }
  }, [props.useForm.watch('input.country')]);

  useEffect(() => {
    if (props.useForm.watch('input.state') && props.useForm.watch('input.country')) {
      getCityList({
        input: {
          filter: {
            country_name: props.useForm.watch('input.country'),
            state_name: props.useForm.watch('input.state'),
          },
        },
      });
    }
  }, [props.useForm.watch('input.state')]);

  return (
    <ActionDrawer
      open={props.open}
      setOpen={props.setOpen}
      size="medium"
      backgroundColor={'background.default'}
      title={
        <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {props.title}
          </Typography>

          <Tooltip title={t('webNotification.close')}>
            <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
              <Iconify icon={'material-symbols:close'} />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      handleSubmit={props.useForm.handleSubmit(props.onSubmit)}
      disabled={props.loading || !props.useForm.formState.isValid}
      loading={props.loading}
      buttonTitle={props.buttonTitle}
    >
      <Grid container p={2} spacing={1}>
        <Grid item xs={6} p={0}>
          <DefaultEnumAutocompleteInput
            inputName="input.businessType"
            label={t('crm.lead.lead.businessType')}
            isRequired
            options={generateAutocompleteTranslatedOptions(LeadBusinessType)}
            enum={LeadBusinessType}
            control={props.useForm.control}
            disableClearable
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultTextFieldInput
            inputName="input.fullName"
            control={props.useForm.control}
            label={t('crm.fullName')}
            isRequired
            isFirstLetterCapitalize
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultTextFieldInput
            inputName="input.email"
            control={props.useForm.control}
            label={t('crm.email')}
            isRequired
            type="email"
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultPhoneTextFieldInput
            inputName="input.phoneNumber"
            control={props.useForm.control}
            label={t('crm.phoneNumber')}
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultEnumAutocompleteInput
            inputName="input.qualification"
            label={t('crm.lead.lead.qualification')}
            options={generateAutocompleteTranslatedOptions(LeadQualification)}
            enum={LeadQualification}
            control={props.useForm.control}
            disableClearable
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultObjectAutocompleteInput
            inputName="input.sourceId"
            control={props.useForm.control}
            options={leadSourceList ?? []}
            label={t('crm.source')}
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
            isLoading={leadSourceListLoading}
            onAddNewOption={() => setLeadSourceCreateOpen(true)}
            disableClearable
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultObjectAutocompleteInput
            inputName="input.statusId"
            control={props.useForm.control}
            options={leadStatusList ?? []}
            label={t('crm.status')}
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
            isLoading={leadStatusListLoading}
            onAddNewOption={() => setLeadStatusCreateOpen(true)}
            disableClearable
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultEnumAutocompleteInput
            inputName="input.type"
            label={t('crm.type')}
            options={generateAutocompleteTranslatedOptions(LeadType)}
            enum={LeadType}
            control={props.useForm.control}
            disableClearable
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultArrayAutocompleteInput
            inputName="input.tags"
            control={props.useForm.control}
            label={t('crm.tags')}
            options={[]}
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
            placeholder={t('crm.tagsPlaceholder')}
          />
        </Grid>

        <Grid item xs={6} p={0}>
          <DefaultSwitchInput
            inputName="input.isPublic"
            control={props.useForm.control}
            label={t('crm.lead.lead.justOwn')}
            description={t('crm.lead.lead.justOwn')}
            color="secondary"
          />
        </Grid>

        <Grid item xs={12}>
          <MyTab labels={tabListLabels} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Divider sx={{ mb: 3 }} />

          {selectedTab === t('crm.company') && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  inputName="input.company"
                  control={props.useForm.control}
                  label={t('crm.company')}
                  isFirstLetterCapitalize
                  errors={props.useForm.formState.errors}
                  touchedFields={props.useForm.formState.touchedFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  inputName="input.sector"
                  control={props.useForm.control}
                  label={t('crm.sector')}
                  isFirstLetterCapitalize
                  errors={props.useForm.formState.errors}
                  touchedFields={props.useForm.formState.touchedFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  inputName="input.position"
                  control={props.useForm.control}
                  label={t('crm.position')}
                  isFirstLetterCapitalize
                  errors={props.useForm.formState.errors}
                  touchedFields={props.useForm.formState.touchedFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  inputName="input.website"
                  control={props.useForm.control}
                  label={t('crm.website')}
                  isFirstLetterCapitalize
                  errors={props.useForm.formState.errors}
                  touchedFields={props.useForm.formState.touchedFields}
                />
              </Grid>
            </Grid>
          )}

          {selectedTab === t('crm.localization') && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.country"
                  control={props.useForm.control}
                  options={countryListData?.data ?? []}
                  label={t('crm.country')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.state"
                  control={props.useForm.control}
                  options={stateListData?.data ?? []}
                  label={t('crm.state')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.city"
                  control={props.useForm.control}
                  options={cityListData?.data ?? []}
                  label={t('crm.city')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultEnumAutocompleteInput
                  inputName="input.language"
                  label={t('crm.language')}
                  options={generateAutocompleteTranslatedOptions(Language)}
                  enum={Language}
                  control={props.useForm.control}
                />
              </Grid>
            </Grid>
          )}

          {selectedTab === t('crm.lead.lead.tracking') && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DefaultArrayObjectAutocompleteInput
                  inputName="input.assignedIds"
                  control={props.useForm.control}
                  label={t('crm.assignees')}
                  options={userListData?.data ?? []}
                  loading={userListLoading}
                />
              </Grid>
            </Grid>
          )}

          {selectedTab === t('crm.noteLabel') && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DefaultRichTextQuillInput
                  inputName="input.note"
                  control={props.useForm.control}
                  label={t('crm.noteLabel')}
                  placeholder={t('crm.noteLabel')}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <LeadStatusCreateDialog open={leadStatusCreateOpen} setOpen={setLeadStatusCreateOpen} />

      <LeadSourcesCreateDialog open={leadSourceCreateOpen} setOpen={setLeadSourceCreateOpen} />
    </ActionDrawer>
  );
});
