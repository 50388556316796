/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery, Button } from '@mui/material';
import { ILeadDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { viewLeadRoute } from '../../../../routes/lead.base.route';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { LeadDetailDrawerLeftPanelAssignedsComponent } from './LeadDetailDrawer-leftPanel-assigned.component';
import { LeadDetailDrawerLeftPanelContactDetailComponent } from './LeadDetailDrawer-leftPanel-contactDetail.component';
import { LeadDetailDrawerLeftPanelHeaderComponent } from './LeadDetailDrawer-leftPanel-header.component';
import { LeadDetailDrawerLeftPanelProposalComponent } from './LeadDetailDrawer-leftPanel-proposal.component';

export interface ILeadDetailDrawerLeftPanelComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeadDetailDrawerLeftPanelComponent = (
  props: ILeadDetailDrawerLeftPanelComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <DefaultDetailDrawerLeftPanelComponent<ILeadDetailResult>
      entityData={props.leadDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <LeadDetailDrawerLeftPanelHeaderComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <LeadDetailDrawerLeftPanelContactDetailComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
      />

      {/* TODO: Proposals Sent */}
      <LeadDetailDrawerLeftPanelProposalComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />

      {/* Assigned List */}
      <LeadDetailDrawerLeftPanelAssignedsComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Button
        variant="contained"
        color="secondary"
        sx={{
          width: { xs: '100%', sm: '200px', md: '200px', lg: '100%' },
          height: 32,
          fontSize: { xs: '13px', md: '13px' },
          lineHeight: 1,
          borderRadius: '24px',
        }}
        onClick={() => navigate(viewLeadRoute(props.leadDetailData?._id ?? ''))}
      >
        {t('crm.lead.lead.detail')}
      </Button>

      {/* {downLg ? null : (
        <Stack
          direction={"column"}
          position={"absolute"}
          bottom={10}
          left={10}
          gap={0.5}
          alignItems={"flex-end"}
          maxWidth={"200px"}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {t("crm.createdBy")}
            {NameFactory.getFullName({
              name: props.leadDetailData?.createdBy?.name,
              surname: props.leadDetailData?.createdBy?.surname,
            })}
            . {moment(props.leadDetailData?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Stack>
      )} */}
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
