/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { ILeadDetailResult } from 'corede-common-cocrm';
import { useLeadStatusListQuery } from '../../../leadStatus';

interface ILeadDetailDrawerHeaderStatusComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
}

export const LeadDetailDrawerStatusComponent = (
  props: ILeadDetailDrawerHeaderStatusComponentProps,
) => {
  const downSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const { data: leadStatusListData } = useLeadStatusListQuery({
    input: {
      pagination: {
        sort: {
          index: 1,
        },
      },
    },
  });

  const LeadStatus = leadStatusListData?.data;

  return downSm ? null : (
    <Stack direction="row" gap={'2px'} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
      {LeadStatus?.map((status, index) => (
        <Box
          key={status?.index}
          sx={{
            backgroundColor:
              status?._id === props.leadDetailData?.status?._id
                ? 'secondary.main'
                : 'secondary.extraLight',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            py: 1,
            px: 3,
            ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
            ...(index === Object.entries(LeadStatus).length - 1 && {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            }),
            flexBasis: `calc(100% / ${Object.entries(LeadStatus).length})`,
          }}
        >
          <Typography
            fontSize={'12px'}
            sx={{
              color: status?._id === props.leadDetailData?.status?._id ? 'white' : 'secondary.main',
              px: 1,
            }}
          >
            {status.name}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
