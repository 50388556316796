/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, IconButton, Divider } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

export interface IDefaultDetailDrawerSideNavigationComponent {
  index: number;
  count: number;
  handleNavigate: Function;
  rightWidthResponsive?: { xs: string | number; sm: string | number; md: number; lg: number };
}

/**
 * add buttons to upper left of the drawer to navigate to prev and next record
 */
export const DefaultDetailDrawerSideNavigationComponent = (
  props: IDefaultDetailDrawerSideNavigationComponent,
) => {
  return (
    <Stack
      direction="column"
      sx={{
        position: 'fixed',
        bgcolor: 'background.primary',
        borderRadius: 10,
        top: { xs: '75px', md: '10px' },
        // right: "1010px",
        right: {
          xs: `calc(100% - 232px)`,
          sm: `calc(100% - 232px)`,
          md: `${(props.rightWidthResponsive?.md ?? 0) + 10}px`,
          lg: `${(props.rightWidthResponsive?.lg ?? 0) + 10}px`,
        },
        zIndex: 1450,
      }}
    >
      <IconButton
        size="small"
        sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        disabled={Number(props.index) === 1}
        onClick={() => props.handleNavigate('back')}
      >
        <ArrowUpward />
      </IconButton>
      <Divider sx={{ width: '100%' }} />
      <IconButton
        size="small"
        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        disabled={Number(props.index) === props.count}
        onClick={() => props.handleNavigate('next')}
      >
        <ArrowDownward />
      </IconButton>
    </Stack>
  );
};
