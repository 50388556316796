
export const supportDomainRoutes = {
  base: "ticket",
  subdomains: {
    support: {
      base: "ticket",
    },
  },
};


export const baseSupportRoute = (): string => `/${supportDomainRoutes.base}/`;
export const listSupportRoute = (): string =>
  `/${supportDomainRoutes.base}/${supportDomainRoutes.subdomains.support.base}`;

