import {
  // queries
  customerContactListQuery,
  ICustomerContactListInput,
  ICustomerContactListRequest,
  ICustomerContactListResponse,
  ICustomerContactListResult,
  customerContactAddQuery,
  ICustomerCustomerContactAddResponse,
  ICustomerContactAddResult,
  ICustomerContactAddInput,
  ICustomerContactAddFilterInput,
  customerContactUpdateQuery,
  ICustomerCustomerContactUpdateRequest,
  ICustomerCustomerContactAddRequest,
  ICustomerContactUpdateResult,
  ICustomerCustomerContactUpdateResponse,
  ICustomerContactUpdateInput,
  ICustomerContactUpdateFilterInput,
  customerContactDeleteQuery,
  ICustomerContactDeleteInput,
  ICustomerContactDeleteResult,
  ICustomerCustomerContactDeleteRequest,
  ICustomerCustomerContactDeleteResponse,
  // mutations
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { contactsTags } from './contact.tags';
import { IGraphqlVariables } from 'corede-common';

export const customerContactsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    customerContactList: builder.query<
      ICustomerContactListResult,
      IGraphqlVariables<ICustomerContactListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerContactListRequest,
        ICustomerContactListResponse,
        ICustomerContactListResult,
        ICustomerContactListInput
      >({
        query: customerContactListQuery,
        providesTags: [contactsTags.customerContactTags],
      }),
    ),

    // mutations

    customerContactAdd: builder.mutation<
      ICustomerContactAddResult,
      IGraphqlVariables<ICustomerContactAddInput, ICustomerContactAddFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCustomerContactAddRequest,
        ICustomerCustomerContactAddResponse,
        ICustomerContactAddResult,
        ICustomerContactAddInput,
        ICustomerContactAddFilterInput
      >({
        query: customerContactAddQuery,
        invalidatesTags: [contactsTags.customerContactTags],
      }),
    ),

    customerContactUpdate: builder.mutation<
      ICustomerContactUpdateResult,
      IGraphqlVariables<ICustomerContactUpdateInput, ICustomerContactUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCustomerContactUpdateRequest,
        ICustomerCustomerContactUpdateResponse,
        ICustomerContactUpdateResult,
        ICustomerContactUpdateInput,
        ICustomerContactUpdateFilterInput
      >({
        query: customerContactUpdateQuery,
        invalidatesTags: [contactsTags.customerContactTags],
      }),
    ),

    customerContactDelete: builder.mutation<
      ICustomerContactDeleteResult,
      IGraphqlVariables<ICustomerContactDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCustomerContactDeleteRequest,
        ICustomerCustomerContactDeleteResponse,
        ICustomerContactDeleteResult,
        ICustomerContactDeleteInput
      >({
        query: customerContactDeleteQuery,
        invalidatesTags: [contactsTags.customerContactTags],
      }),
    ),
  }),
});

export const {
  useCustomerContactListQuery,
  useLazyCustomerContactListQuery,

  useCustomerContactAddMutation,
  useCustomerContactUpdateMutation,
  useCustomerContactDeleteMutation,
} = customerContactsApi;
