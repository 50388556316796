import {
  IBaseGraphqlError,
  IRegisterInput,
  IRegisterResult,
  IRegisterResponse,
  IRegisterRequest,
  ILogoutInput,
  ILogoutResult,
  IRequestResetPasswordInput,
  IRequestResetPasswordResult,
  IConfirmRegistrationInput,
  IConfirmRegistrationResult,
  IResendRegistrationByUserConfirmationInput,
  IResendRegistrationByUserConfirmationResult,
  IResetPasswordInput,
  IResetPasswordResult,
  IResetPasswordAfterRequestInput,
  IResetPasswordAfterRequestResult,
  IConfirmRegistrationByUserInput,
  IConfirmRegistrationByUserResult,
  IResendRegistrationByUserConfirmationRequest,
  IResendRegistrationConfirmationInput,
  logoutQuery,
  requestResetPasswordQuery,
  confirmRegistrationQuery,
  resendRegistrationByUserConfirmationQuery,
  resetPasswordQuery,
  resetPasswordAfterRequestQuery,
  confirmRegistrationByUserQuery,
  resendRegistrationConfirmationQuery,
  ILogoutRequest,
  ILogoutResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  registerQuery,
  IRequestResetPasswordRequest,
  IRequestResetPasswordResponse,
  IResetPasswordAfterRequestResponse,
  IResetPasswordAfterRequestRequest,
  IConfirmRegistrationByUserRequest,
  IConfirmRegistrationByUserResponse,
  IGraphqlVariables,
  IResentRegistrationByUserConfirmationResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
  IResendRegistrationConfirmationRequest,
  IResendRegistrationConfirmationResult,
  IResentRegistrationConfirmationResponse,
} from 'corede-common';
import {
  IUserDetailOwnInput,
  IUserDetailOwnResponse,
  IUserDetailOwnResult,
  userDetailOwnQuery,
  userUpdateOwnQuery,
  IUserUpdateOwnInput,
  IUserUpdateOwnResult,
  IUserUpdateOwnResponse,
  IUserUpdateOwnRequest,
  ILoginInput,
  ILoginResponse,
  ILoginResult,
  ILoginRequest,
  loginQuery,
  IOrganizationCreateOwnInput,
  IOrganizationCreateOwnResult,
  IOrganizationCreateOwnResponse,
  IOrganizationCreateOwnRequest,
  organizationCreateOwnQuery,
  IUserDetailResult,
  IUserDetailInput,
  IUserDetailRequest,
  IUserDetailResponse,
  userDetailQuery,
  ILatestPolicyInput,
  ILatestPolicyRequest,
  ILatestPolicyResponse,
  ILatestPolicyResult,
  latestPolicyQuery,
  createContactFormForUserQuery,
  ICreateContactFormInput,
  ICreateContactFormResult,
  ICreateContactFormForUserResponse,
  ICreateContactFormForUserRequest,
  IOrganizationDetailOwnRequest,
  IOrganizationDetailOwnResponse,
  IOrganizationDetailResult,
  estimateDetailPublicQuery,
  IEstimateDetailPublicRequest,
  IEstimateDetailPublicResponse,
  IEstimateDetailPublicResult,
  IEstimateDetailPublicInput,
  IUserDetailOwnRequest,
  countryListQuery,
  ICountryListRequest,
  ICountryListResponse,
  ICountryListResult,
  ICountryListInput,
  stateListQuery,
  IStateListRequest,
  IStateListResponse,
  IStateListResult,
  IStateListInput,
  cityListQuery,
  ICityListRequest,
  ICityListResponse,
  ICityListResult,
  ICityListInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { print } from 'graphql';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { organizationTags } from 'apps/crm/domains/02-organizationalChart/subdomains/user/context/user.tags';

const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    userDetailOwn: builder.query<IUserDetailOwnResult, IGraphqlVariables<IUserDetailOwnInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDetailOwnRequest,
        IUserDetailOwnResponse,
        IUserDetailOwnResult,
        IUserDetailOwnInput
      >({
        query: userDetailOwnQuery,
        providesTags: ['user', organizationTags.user],
      }),
    ),

    userDetail: builder.query<IUserDetailResult, IGraphqlVariables<IUserDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDetailRequest,
        IUserDetailResponse,
        IUserDetailResult,
        IUserDetailInput
      >({
        query: userDetailQuery,
        providesTags: ['user', organizationTags.user],
      }),
    ),

    latestPolicy: builder.query<ILatestPolicyResult, IGraphqlVariables<ILatestPolicyInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILatestPolicyRequest,
        ILatestPolicyResponse,
        ILatestPolicyResult,
        ILatestPolicyInput
      >({
        query: latestPolicyQuery,
        providesTags: [],
      }),
    ),

    estimateDetailPublic: builder.query<
      IEstimateDetailPublicResult,
      IGraphqlVariables<IEstimateDetailPublicInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDetailPublicRequest,
        IEstimateDetailPublicResponse,
        IEstimateDetailPublicResult,
        IEstimateDetailPublicInput
      >({
        query: estimateDetailPublicQuery,
        providesTags: [],
      }),
    ),

    countryList: builder.query<ICountryListResult, IGraphqlVariables<ICountryListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICountryListRequest,
        ICountryListResponse,
        ICountryListResult,
        ICountryListInput
      >({
        query: countryListQuery(),
        providesTags: [],
      }),
    ),

    stateList: builder.query<IStateListResult, IGraphqlVariables<IStateListInput, undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IStateListRequest,
        IStateListResponse,
        IStateListResult,
        IStateListInput
      >({
        query: stateListQuery(),
        providesTags: [],
      }),
    ),

    cityList: builder.query<ICityListResult, IGraphqlVariables<ICityListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICityListRequest,
        ICityListResponse,
        ICityListResult,
        ICityListInput
      >({
        query: cityListQuery(),
        providesTags: [],
      }),
    ),

    // mutations

    login: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILoginRequest,
        ILoginResponse,
        ILoginResult,
        ILoginInput
      >({
        query: loginQuery,
      }),
    ),

    register: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRegisterRequest,
        IRegisterResponse,
        IRegisterResult,
        IRegisterInput
      >({
        query: registerQuery,
      }),
    ),

    logout: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILogoutRequest,
        ILogoutResponse,
        ILogoutResult,
        ILogoutInput
      >({
        query: logoutQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),

    requestResetPassword: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRequestResetPasswordRequest,
        IRequestResetPasswordResponse,
        IRequestResetPasswordResult,
        IRequestResetPasswordInput
      >({
        query: requestResetPasswordQuery,
      }),
    ),

    confirmRegistration: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationRequest,
        IConfirmRegistrationResponse,
        IConfirmRegistrationResult,
        IConfirmRegistrationInput
      >({
        query: confirmRegistrationQuery,
      }),
    ),

    resetPasswordAfterRequest: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordAfterRequestRequest,
        IResetPasswordAfterRequestResponse,
        IResetPasswordAfterRequestResult,
        IResetPasswordAfterRequestInput
      >({
        query: resetPasswordAfterRequestQuery,
      }),
    ),

    registrationByUser: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationByUserRequest,
        IConfirmRegistrationByUserResponse,
        IConfirmRegistrationByUserResult,
        IConfirmRegistrationByUserInput
      >({
        query: confirmRegistrationByUserQuery,
      }),
    ),

    organizationCreateOwn: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCreateOwnRequest,
        IOrganizationCreateOwnResponse,
        IOrganizationCreateOwnResult,
        IOrganizationCreateOwnInput
      >({
        query: organizationCreateOwnQuery,
        invalidatesTags: ['organization'],
      }),
    ),

    createContactForm: builder.mutation<
      ICreateContactFormResult,
      IGraphqlVariables<ICreateContactFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateContactFormForUserRequest,
        ICreateContactFormForUserResponse,
        ICreateContactFormResult,
        ICreateContactFormInput
      >({
        query: createContactFormForUserQuery,
        invalidatesTags: [],
      }),
    ),

    userUpdateOwn: builder.mutation<
      IUserUpdateOwnResult,
      IGraphqlVariables<IUserUpdateOwnInput, undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserUpdateOwnRequest,
        IUserUpdateOwnResponse,
        IUserUpdateOwnResult,
        IUserUpdateOwnInput
      >({
        query: userUpdateOwnQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),

    resendRegistrationByUserConfirmation: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResendRegistrationByUserConfirmationRequest,
        IResentRegistrationByUserConfirmationResponse,
        IResendRegistrationByUserConfirmationResult,
        IResendRegistrationByUserConfirmationInput
      >({
        query: resendRegistrationByUserConfirmationQuery,
      }),
    ),

    resetPassword: builder.mutation<IResetPasswordResult, IGraphqlVariables<IResetPasswordInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordRequest,
        IResetPasswordResponse,
        IResetPasswordResult,
        IResetPasswordInput
      >({
        query: resetPasswordQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),

    confirmRegistrationByUser: builder.mutation<
      IConfirmRegistrationByUserResult,
      IGraphqlVariables<IConfirmRegistrationByUserInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationByUserRequest,
        IConfirmRegistrationByUserResponse,
        IConfirmRegistrationByUserResult,
        IConfirmRegistrationByUserInput
      >({
        query: confirmRegistrationByUserQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),

    resendRegistrationConfirmation: builder.mutation<
      IResendRegistrationConfirmationResult,
      IGraphqlVariables<IResendRegistrationConfirmationInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResendRegistrationConfirmationRequest,
        IResentRegistrationConfirmationResponse,
        IResendRegistrationConfirmationResult,
        IResendRegistrationConfirmationInput
      >({
        query: resendRegistrationConfirmationQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),
  }),
});

export const {
  useUserDetailOwnQuery,
  useLazyUserDetailOwnQuery,
  useUserDetailQuery,
  useLazyUserDetailQuery,
  useLatestPolicyQuery,
  useLazyLatestPolicyQuery,
  useCountryListQuery,
  useLazyCountryListQuery,
  useStateListQuery,
  useLazyStateListQuery,
  useCityListQuery,
  useLazyCityListQuery,

  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useConfirmRegistrationMutation,
  useResendRegistrationByUserConfirmationMutation,
  useResetPasswordAfterRequestMutation,
  useResetPasswordMutation,
  useConfirmRegistrationByUserMutation,
  useResendRegistrationConfirmationMutation,
  useUserUpdateOwnMutation,
  useRegistrationByUserMutation,
  useOrganizationCreateOwnMutation,
  useCreateContactFormMutation,
} = authApi;
