import { IUser } from 'corede-common';
import { IUserListItemResult } from 'corede-common-cocrm';
import { useState, lazy, Suspense, Dispatch, SetStateAction } from 'react';

const UserUpdateDialog = lazy(() => import('./UserUpdateDialog'));

const UserUpdateDialogWrapper = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedUser: IUserListItemResult | undefined;
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <UserUpdateDialog
            open={props.open}
            setOpen={props.setOpen}
            selectedUser={props.selectedUser}
          />
        </Suspense>
      )}
    </>
  );
};

export default UserUpdateDialogWrapper;
