/* eslint-disable react-hooks/exhaustive-deps */
import { ILeadDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { LeadDetailDrawerRightPanelTabsComponent } from './LeadDetailDrawer-rightPanel.tabs.component';
import { LeadDetailDrawerStatusComponent } from './LeadDetailDrawer-rightPanel-status.component';

export interface ILeadDetailDrawerRightPanelComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
}

export const LeadDetailDrawerRightPanelComponent = (
  props: ILeadDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      {/* <LeadDetailDrawerRightPanelHeaderComponent
        leadDetailData={props.leadDetailData}
      /> */}

      {/* Status Section */}
      <LeadDetailDrawerStatusComponent leadDetailData={props.leadDetailData} />
      {/* Tabs Section */}
      <LeadDetailDrawerRightPanelTabsComponent leadDetailData={props.leadDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
