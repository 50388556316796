/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Stack,
  Skeleton,
  Box,
  Checkbox,
  TextField,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  FormControlLabel,
  styled,
  Chip,
  Divider,
  Tooltip,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import {
  usePermissionListQuery,
  useRoleListQuery,
  useRoleCreateMutation,
  useRoleUpdateMutation,
  useRoleDeleteMutation,
} from '../context/permissions.api';
import { MyTab, OutlinedButton } from 'components';
import {
  AddRounded,
  ArrowForwardIosSharp,
  DeleteOutlineRounded,
  ExpandCircleDown,
  ExpandMore,
  InfoOutlined,
  Update,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { unknownError } from 'corede-common';
import { IRoleListItemResult, RoleType } from 'corede-common-cocrm';

interface IPermission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
  domain: string;
  subdomain: string;
}

const List = ({ type }: { type?: string }) => {
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [roleName, setRoleName] = useState<string>('');
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});

  const {
    data: roleListData,
    isLoading: roleListLoading,
    error: roleListError,
  } = useRoleListQuery({});
  const {
    data: permissionListData,
    isLoading: permissionListLoading,
    error: permissionListError,
  } = usePermissionListQuery({
    input: {
      filter: {
        actions: ['manage', 'view', 'import', 'export'],
      },
    },
  } as any);
  const {
    data: permissionListAllData,
    isLoading: permissionListAllLoading,
    error: permissionListAllError,
  } = usePermissionListQuery({
    input: {
      filter: {
        actions: ['import', 'export', 'list', 'create', 'update', 'delete', 'detail'],
      },
    },
  } as any);

  const [
    roleCreate,
    { data: roleCreateData, isLoading: roleCreateLoading, error: roleCreateError },
  ] = useRoleCreateMutation();
  const [
    roleUpdate,
    { data: roleUpdateData, isLoading: roleUpdateLoading, error: roleUpdateError },
  ] = useRoleUpdateMutation();
  const [
    roleDelete,
    { data: roleDeleteData, isLoading: roleDeleteLoading, error: roleDeleteError },
  ] = useRoleDeleteMutation();

  const roles =
    roleListData?.data?.filter(
      (role: IRoleListItemResult) => role.type !== RoleType.organizationDefault,
    ) || [];
  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: IPermission[]) => {
    return permissions.reduce(
      (acc, permission) => {
        if (!acc[permission.subject]) {
          acc[permission.subject] = [];
        }
        acc[permission.subject].push(permission);
        return acc;
      },
      {} as Record<string, IPermission[]>,
    );
  };

  const groupByDomain = (permissions: IPermission[]) => {
    return permissions.reduce(
      (acc, permission) => {
        if (!acc[permission.domain]) {
          acc[permission.domain] = [];
        }
        acc[permission.domain].push(permission);
        return acc;
      },
      {} as Record<string, IPermission[]>,
    );
  };

  const mainGroupedPermissions = groupByDomain(permissions as IPermission[]);
  const groupedPermissions = groupBySubject(permissions as IPermission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as IPermission[]);

  const handleAddRole = () => {
    roleCreate({
      input: {
        name: roleName,
        permissionIds: selectedPermissionIds,
      },
    });
  };

  const handleRoleUpdate = () => {
    roleUpdate({
      input: {
        name: roleName,
        permissionIds: selectedPermissionIds,
      },
      filter: {
        _id: selectedRoleId,
      },
    });
  };

  const handleRoleDelete = () => {
    roleDelete({
      input: {
        _id: selectedRoleId,
      },
    });
  };

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: downMd ? 0 : theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const handleChangeCheckbox = useCallback(
    (props: { permission: IPermission; checkState: boolean; relatedPermissionIds: string[] }) => {
      let newSelectedPermissions = [...selectedPermissionIds];

      if (props.checkState !== true) {
        newSelectedPermissions = newSelectedPermissions.filter(
          (id) => id !== props.permission._id && !props.relatedPermissionIds.includes(id),
        );
      } else {
        newSelectedPermissions = [
          ...new Set([
            ...newSelectedPermissions,
            props.permission._id,
            ...props.relatedPermissionIds,
          ]),
        ];
      }
      console.log(props);


      setSelectedPermissionIds(newSelectedPermissions);
    },
    [selectedPermissionIds],
  );

  console.log(selectedPermissionIds)

  // SUCCESS

  useEffect(() => {
    if (roleCreateData) {
      enqueueSnackbar(t('crm.organizationalChart.permission.roleCreatedSuccess'), {
        variant: 'success',
      });
      setRoleName(roles[0]?.name ?? '');
    }
  }, [roleCreateData]);

  useEffect(() => {
    if (roleUpdateData) {
      enqueueSnackbar(t('crm.organizationalChart.permission.roleUpdatedSuccess'), {
        variant: 'success',
      });
      setRoleName(roles[0]?.name ?? '');
    }
  }, [roleUpdateData]);

  useEffect(() => {
    if (roleDeleteData) {
      enqueueSnackbar(t('crm.organizationalChart.permission.roleDeletedSuccess'), {
        variant: 'success',
      });
      setRoleName(roles[0]?.name ?? '');
    }
  }, [roleDeleteData]);

  // ERRORS

  useEffect(() => {
    if (permissionListError) {
      enqueueSnackbar(
        (permissionListError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [permissionListError]);

  useEffect(() => {
    if (roleListError) {
      enqueueSnackbar(
        (roleListError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [roleListError]);

  useEffect(() => {
    if (roleCreateError) {
      enqueueSnackbar(
        (roleCreateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [roleCreateError]);

  useEffect(() => {
    if (roleUpdateError) {
      enqueueSnackbar(
        (roleUpdateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [roleUpdateError]);

  useEffect(() => {
    if (roleDeleteError) {
      enqueueSnackbar(
        (roleDeleteError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [roleDeleteError]);

  useEffect(() => {
    if (permissionListAllError) {
      enqueueSnackbar(
        (permissionListAllError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [permissionListAllError]);

  // INITIAL

  useEffect(() => {
    if (roleListData) {
      setRoleName(roles[0]?.name ?? '');
      setSelectedRoleName(roles[0]?.name ?? '');
      setSelectedRoleId(
        roleListData?.data?.find((role: IRoleListItemResult) => role.name === roles[0]?.name)
          ?._id ?? '',
      );
    }
  }, [roleListData]);

  useEffect(() => {
    if (selectedRoleName && roleListData) {
      setRoleName(
        selectedRoleName !== t('crm.organizationalChart.permission.addRole')
          ? selectedRoleName
          : '',
      );
      setSelectedRoleId(
        roleListData?.data?.find((role: IRoleListItemResult) => role.name === selectedRoleName)
          ?._id ?? '',
      );
    }
  }, [selectedRoleName, roleListData]);

  useEffect(() => {
    if (selectedRoleName && roleListData) {
      setSelectedPermissionIds(
        roleListData?.data
          ?.find((role: IRoleListItemResult) => role.name === selectedRoleName)
          ?.permissions.map((permission: any) => permission._id) ?? [],
      );
    }
  }, [selectedRoleName, roleListData]);

  return (
    <Grid item xs={12} p={{ xs: 0, md: type === 'onlyAdd' ? 1 : 3 }}>
      {type !== 'onlyAdd' && (
        <HeaderBase title={t('crm.organizationalChart.permission.permissions')} />
      )}
      <Box sx={{ mt: 2 }} />
      {type !== 'onlyAdd' && (
        <MyTab
          selectedTab={selectedRoleName}
          labels={[...roles, { name: t('crm.organizationalChart.permission.addRole') }].map(
            (role) => role.name,
          )}
          setSelectedTab={setSelectedRoleName}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        {!(
          roleListLoading ||
          permissionListLoading ||
          roleCreateLoading ||
          roleUpdateLoading ||
          roleDeleteLoading ||
          permissionListAllLoading
        ) ? (
          <Box sx={{ width: '100%' }}>
            <Stack direction={'row'} justifyContent={'flex-end'} sx={{ width: '100%', mb: 1 }}>
              <OutlinedButton
                title={expandAll ? t('collapseAll') : t('expandAll')}
                onClick={() => {
                  setExpandAll(!expandAll);
                  const newExpandedState = Object.keys(mainGroupedPermissions).reduce(
                    (acc, domain) => {
                      acc[domain] = !expandAll;
                      return acc;
                    },
                    {} as Record<string, boolean>,
                  );
                  setExpandedAccordions(newExpandedState);
                }}
                leftIcon={
                  expandAll ? (
                    <ExpandCircleDown
                      sx={{ mr: 1, transform: 'rotate(180deg)', transition: 'all 0.3s' }}
                    />
                  ) : (
                    <ExpandCircleDown sx={{ mr: 1, transition: 'all 0.3s' }} />
                  )
                }
              />
            </Stack>
            {Object.keys(mainGroupedPermissions)
              .reverse()
              .map((domain, index) => (
                <Accordion
                  key={domain}
                  expanded={expandedAccordions[domain] || false}
                  className="accordion-domain"
                  onChange={() => {
                    setExpandedAccordions((prevState) => ({
                      ...prevState,
                      [domain]: !prevState[domain],
                    }));
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel-${domain}-content`}
                    id={`panel-${domain}-header`}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      alignItems="center"
                      justifyContent={'space-between'}
                      sx={{ width: '100%' }}
                    >
                      <Typography fontSize={'large'} fontWeight={'bold'}>
                        {getTranslatedEnumValue(domain)}
                      </Typography>
                      {!downLg && (
                        <Stack direction="row" spacing={1}>
                          {mainGroupedPermissions[domain]
                            .reduce((uniqueSubdomains: string[], permission: IPermission) => {
                              if (!uniqueSubdomains.includes(permission.subdomain)) {
                                uniqueSubdomains.push(permission.subdomain);
                              }
                              return uniqueSubdomains;
                            }, [])
                            .map((subdomain) => (
                              <Chip
                                key={subdomain}
                                label={getTranslatedEnumValue(subdomain)}
                                // size="small"
                                sx={{ fontWeight: 'bold', opacity: 0.7 }}
                                variant="outlined"
                                color="primary"
                              />
                            ))}
                        </Stack>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{}}>
                    {!downLg && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={'flex-end'}
                        sx={{ width: '100%' }}
                      >
                        <Stack direction={'row'} width={'170px'} alignItems={'center'}>
                          <Typography fontSize={'small'} fontWeight={'bold'}>
                            {t('organizationLevel')}
                          </Typography>
                          <Tooltip title={t('organizationLevelDesc')}>
                            <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                          </Tooltip>
                        </Stack>
                        <Stack direction={'row'} width={'170px'} alignItems={'center'}>
                          <Typography fontSize={'small'} fontWeight={'bold'}>
                            {t('departmentLevel')}
                          </Typography>
                          <Tooltip title={t('departmentLevelDesc')}>
                            <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                          </Tooltip>
                        </Stack>
                        <Stack direction={'row'} width={'170px'} alignItems={'center'}>
                          <Typography fontSize={'small'} fontWeight={'bold'}>
                            {t('userLevel')}
                          </Typography>
                          <Tooltip title={t('userLevelDesc')}>
                            <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                          </Tooltip>
                        </Stack>
                      </Stack>
                    )}
                    {Object.keys(groupedPermissions)
                      .filter((subject) => groupedPermissions[subject][0]?.domain === domain)
                      .map((subject) => (
                        <Accordion key={subject} expanded={false}>
                          <AccordionSummary expandIcon={null}>
                            <Stack
                              direction={{ xs: 'column', lg: 'row' }}
                              alignItems="center"
                              justifyContent={'space-between'}
                              sx={{ width: '100%' }}
                            >
                              <Typography fontSize={'medium'} fontWeight={'bold'}>
                                {getTranslatedEnumValue(subject)}
                              </Typography>
                              {downLg && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent={'center'}
                                  sx={{ width: '100%' }}
                                >
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    <Typography
                                      fontSize={'10px'}
                                      textAlign={'center'}
                                      fontWeight={'bold'}
                                      sx={{ opacity: 0.5 }}
                                    >
                                      {t('organization')}
                                    </Typography>
                                  </Stack>
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    <Typography
                                      fontSize={'10px'}
                                      textAlign={'center'}
                                      fontWeight={'bold'}
                                      sx={{ opacity: 0.5 }}
                                    >
                                      {t('department')}
                                    </Typography>
                                  </Stack>
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    <Typography
                                      fontSize={'10px'}
                                      textAlign={'center'}
                                      fontWeight={'bold'}
                                      sx={{ opacity: 0.5 }}
                                    >
                                      {t('user')}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              )}
                              {downLg && (
                                <Divider
                                  sx={{
                                    width: { xs: '80%', sm: '300px' },
                                    mr: { xs: '0px', sm: '50px' },
                                    mt: 0.5,
                                    mb: 0.5,
                                  }}
                                />
                              )}
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent={'flex-end'}
                              >
                                <Stack direction="row">
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    {groupedPermissions[subject]
                                      .filter((permission) => permission.actionScope === 'org')
                                      .map((permission) => {
                                        const isManage = permission.action === 'manage';
                                        const isView = permission.action === 'view';
                                        const relatedPermissions = isManage
                                          ? ['create', 'delete', 'update']
                                          : isView
                                            ? ['list', 'detail']
                                            : [];

                                        const relatedPermissionIds =
                                          groupedPermissionsAll[subject]
                                            ?.filter(
                                              (p) =>
                                                relatedPermissions.includes(p.action) &&
                                                p.actionScope === permission.actionScope,
                                            )
                                            .map((p) => p._id) || [];

                                        const allRelatedChecked = relatedPermissionIds.every((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const someRelatedChecked = relatedPermissionIds.some((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const checkState =
                                          isManage || isView
                                            ? allRelatedChecked
                                              ? true
                                              : someRelatedChecked
                                                ? 'indeterminate'
                                                : false
                                            : selectedPermissionIds.includes(permission._id);

                                        return (
                                          <FormControlLabel
                                            key={permission._id}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            control={
                                              <Checkbox
                                                id={permission._id}
                                                checked={checkState === true}
                                                indeterminate={checkState === 'indeterminate'}
                                                onChange={(event) => {
                                                  handleChangeCheckbox({
                                                    checkState: event.target.checked,
                                                    permission: permission,
                                                    relatedPermissionIds: relatedPermissionIds,
                                                  });
                                                }}
                                              />
                                            }
                                            label={getTranslatedEnumValue(permission.action)}
                                            sx={{
                                              ml: { xs: 0, sm: '10px' },
                                              '.MuiFormControlLabel-label': {
                                                fontSize: { xs: '11px', md: 'small' },
                                                fontWeight: 'bold',
                                              },
                                            }}
                                          />
                                        );
                                      })}
                                  </Stack>
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    {groupedPermissions[subject]
                                      .filter((permission) => permission.actionScope === 'dep')
                                      .map((permission) => {
                                        const isManage = permission.action === 'manage';
                                        const isView = permission.action === 'view';
                                        const relatedPermissions = isManage
                                          ? ['create', 'delete', 'update']
                                          : isView
                                            ? ['list', 'detail']
                                            : [];

                                        const relatedPermissionIds =
                                          groupedPermissionsAll[subject]
                                            ?.filter(
                                              (p) =>
                                                relatedPermissions.includes(p.action) &&
                                                p.actionScope === permission.actionScope,
                                            )
                                            .map((p) => p._id) || [];

                                        const allRelatedChecked = relatedPermissionIds.every((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const someRelatedChecked = relatedPermissionIds.some((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const checkState =
                                          isManage || isView
                                            ? allRelatedChecked
                                              ? true
                                              : someRelatedChecked
                                                ? 'indeterminate'
                                                : false
                                            : selectedPermissionIds.includes(permission._id);

                                        return (
                                          <FormControlLabel
                                            key={permission._id}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            control={
                                              <Checkbox
                                                id={permission._id}
                                                checked={checkState === true}
                                                indeterminate={checkState === 'indeterminate'}
                                                onChange={() => {
                                                  let newSelectedPermissions = [
                                                    ...selectedPermissionIds,
                                                  ];

                                                  if (checkState === true) {
                                                    newSelectedPermissions =
                                                      newSelectedPermissions.filter(
                                                        (id) =>
                                                          id !== permission._id &&
                                                          !relatedPermissionIds.includes(id),
                                                      );
                                                  } else {
                                                    newSelectedPermissions = [
                                                      ...new Set([
                                                        ...newSelectedPermissions,
                                                        permission._id,
                                                        ...relatedPermissionIds,
                                                      ]),
                                                    ];
                                                  }

                                                  setSelectedPermissionIds(newSelectedPermissions);
                                                }}
                                              />
                                            }
                                            label={getTranslatedEnumValue(permission.action)}
                                            sx={{
                                              ml: { xs: 0, sm: '10px' },
                                              '.MuiFormControlLabel-label': {
                                                fontSize: { xs: '11px', md: 'small' },
                                                fontWeight: 'bold',
                                              },
                                            }}
                                          />
                                        );
                                      })}
                                  </Stack>
                                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                    {groupedPermissions[subject]
                                      .filter((permission) => permission.actionScope === 'own')
                                      .map((permission) => {
                                        const isManage = permission.action === 'manage';
                                        const isView = permission.action === 'view';
                                        const relatedPermissions = isManage
                                          ? ['create', 'delete', 'update']
                                          : isView
                                            ? ['list', 'detail']
                                            : [];

                                        const relatedPermissionIds =
                                          groupedPermissionsAll[subject]
                                            ?.filter(
                                              (p) =>
                                                relatedPermissions.includes(p.action) &&
                                                p.actionScope === permission.actionScope,
                                            )
                                            .map((p) => p._id) || [];

                                        const allRelatedChecked = relatedPermissionIds.every((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const someRelatedChecked = relatedPermissionIds.some((id) =>
                                          selectedPermissionIds.includes(id),
                                        );
                                        const checkState =
                                          isManage || isView
                                            ? allRelatedChecked
                                              ? true
                                              : someRelatedChecked
                                                ? 'indeterminate'
                                                : false
                                            : selectedPermissionIds.includes(permission._id);

                                        return (
                                          <FormControlLabel
                                            key={permission._id}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            control={
                                              <Checkbox
                                                id={permission._id}
                                                checked={checkState === true}
                                                indeterminate={checkState === 'indeterminate'}
                                                onChange={() => {
                                                  let newSelectedPermissions = [
                                                    ...selectedPermissionIds,
                                                  ];

                                                  if (checkState === true) {
                                                    newSelectedPermissions =
                                                      newSelectedPermissions.filter(
                                                        (id) =>
                                                          id !== permission._id &&
                                                          !relatedPermissionIds.includes(id),
                                                      );
                                                  } else {
                                                    newSelectedPermissions = [
                                                      ...new Set([
                                                        ...newSelectedPermissions,
                                                        permission._id,
                                                        ...relatedPermissionIds,
                                                      ]),
                                                    ];
                                                  }

                                                  setSelectedPermissionIds(newSelectedPermissions);
                                                }}
                                              />
                                            }
                                            label={getTranslatedEnumValue(permission.action)}
                                            sx={{
                                              ml: { xs: 0, sm: '10px' },
                                              '.MuiFormControlLabel-label': {
                                                fontSize: { xs: '11px', md: 'small' },
                                                fontWeight: 'bold',
                                              },
                                            }}
                                          />
                                        );
                                      })}
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* Burada subject'e ait alt permission'ları göstereceğiz */}
                            {/* <Stack direction="row" spacing={4}>
                            <Stack width={"150px"}>
                              {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'org').map((permission) => (
                                <FormControlLabel
                                  key={permission._id}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      onChange={() => {
                                        const isSelected = selectedPermissionIds.includes(permission._id);
                                        let newSelectedPermissions = isSelected
                                          ? selectedPermissionIds.filter((id) => id !== permission._id)
                                          : [...selectedPermissionIds, permission._id];

                                        setSelectedPermissionIds(newSelectedPermissions);
                                      }}
                                    />
                                  }
                                  label={`${permission.action} (org)`}
                                />
                              ))}
                            </Stack>
                            <Stack width={"150px"}>
                              {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'dep').map((permission) => (
                                <FormControlLabel
                                  key={permission._id}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      onChange={() => {
                                        const isSelected = selectedPermissionIds.includes(permission._id);
                                        let newSelectedPermissions = isSelected
                                          ? selectedPermissionIds.filter((id) => id !== permission._id)
                                          : [...selectedPermissionIds, permission._id];

                                        setSelectedPermissionIds(newSelectedPermissions);
                                      }}
                                    />
                                  }
                                  label={`${permission.action} (dep)`}
                                />
                              ))}
                            </Stack>
                          </Stack> */}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              ))}

            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <TextField
                required
                size="small"
                placeholder={t('crm.organizationalChart.permission.roleName')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={roleName}
                onChange={(e) =>
                  setRoleName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                }
              />
              {(selectedRoleName === t('crm.organizationalChart.permission.addRole') ||
                selectedRoleName === '') && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: 42, mt: '16px', mb: '8px', pl: 1, pr: 2 }}
                  onClick={handleAddRole}
                  disabled={
                    roleCreateLoading ||
                    !roleName ||
                    selectedPermissionIds?.length === 0 ||
                    roleName === t('crm.organizationalChart.permission.addRole')
                  }
                >
                  {roleCreateLoading ? (
                    <CircularProgress size={'1rem'} />
                  ) : (
                    <>
                      <AddRounded sx={{ mr: 1 }} />
                      {t('crm.organizationalChart.permission.addRole')}
                    </>
                  )}
                </Button>
              )}
              {selectedRoleName !== t('crm.organizationalChart.permission.addRole') &&
                selectedRoleName !== '' && (
                  <Stack direction={'row'} gap={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ height: 42, mt: '16px', mb: '8px', pl: 2, pr: 2 }}
                      onClick={handleRoleUpdate}
                      disabled={
                        roleUpdateLoading || !roleName || selectedPermissionIds?.length === 0
                      }
                    >
                      {roleCreateLoading ? (
                        <CircularProgress size={'1rem'} />
                      ) : (
                        <>
                          <Update sx={{ mr: { xs: 0, md: 1 } }} />
                          {!downMd && t('crm.organizationalChart.permission.updateRole')}
                        </>
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      sx={{ height: 42, mt: '16px', mb: '8px', pl: 2, pr: 2 }}
                      onClick={handleRoleDelete}
                      disabled={roleDeleteLoading}
                    >
                      {roleDeleteLoading ? (
                        <CircularProgress size={'1rem'} />
                      ) : (
                        <>
                          <DeleteOutlineRounded sx={{ mr: { xs: 0, md: 1 } }} />
                          {!downMd && t('crm.organizationalChart.permission.deleteRole')}
                        </>
                      )}
                    </Button>
                  </Stack>
                )}
            </Stack>
          </Box>
        ) : (
          <Stack
            direction="column"
            justifyContent="space-between"
            width={'100%'}
            alignItems={'center'}
            p={2}
            gap={1.5}
          >
            {[1, 2, 3, 4, 5].map((key) => (
              <Skeleton
                key={key}
                variant="rectangular"
                width={'100%'}
                height={90}
                sx={{ borderRadius: 1 }}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default List;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));
