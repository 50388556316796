

import { commonRoutes } from "routes/route.common";

export const organizationalChartDomainRoutes = {
  base: "organizational-chart",
  subdomains: {
    user: {
      base: "user",
    },
    department: {
      base: "department",
    },
    permission: {
      base: "permission",
    }
    // add subdomain route names here
  },
};

// Organization routes
export const baseOrganizationalChartRoute = (): string => `/${organizationalChartDomainRoutes.base}/`;
export const listUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/`;
export const viewUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${id}`;
export const createUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.create}`;
export const updateUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${id}/${commonRoutes.update}`;
export const rolesUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.roles}`;
export const userListUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.userList}`;
export const listDepartmentsRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.department.base}`;
export const listPermissionsRoute = ({ redirect }: { redirect?: string }): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.permission.base}${redirect ? `?redirect=${redirect}` : ''}`;

// add subdomain routes here
