import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateIndividualBillingInfoInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    name: ValidationHelper.RequiredString(getTranslatedText('nameIsRequired')),
    surname: ValidationHelper.RequiredString(getTranslatedText('surnameIsRequired')),
    email: ValidationHelper.RequiredEmail(
      getTranslatedText('emailIsInvalid'),
      getTranslatedText('emailIsRequired'),
    ),
    phone: ValidationHelper.RequiredString(getTranslatedText('phoneNumberIsRequired')),
    identityNumber: ValidationHelper.RequiredString(getTranslatedText('identityNumberIsRequired')),
    country: ValidationHelper.RequiredString(getTranslatedText('countryIsRequired')),
    state: ValidationHelper.RequiredString(getTranslatedText('stateIsRequired')),
    city: ValidationHelper.RequiredString(getTranslatedText('cityIsRequired')),
    zip: ValidationHelper.RequiredString(getTranslatedText('zipIsRequired')),
    address: ValidationHelper.RequiredString(getTranslatedText('addressIsRequired')),
  }),
});

export const validateBusinessBillingInfoInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    name: ValidationHelper.RequiredString(getTranslatedText('nameIsRequired')),
    surname: ValidationHelper.RequiredString(getTranslatedText('surnameIsRequired')),
    email: ValidationHelper.RequiredEmail(
      getTranslatedText('emailIsInvalid'),
      getTranslatedText('emailIsRequired'),
    ),
    phone: ValidationHelper.RequiredString(getTranslatedText('phoneNumberIsRequired')),
    country: ValidationHelper.RequiredString(getTranslatedText('countryIsRequired')),
    state: ValidationHelper.RequiredString(getTranslatedText('stateIsRequired')),
    city: ValidationHelper.RequiredString(getTranslatedText('cityIsRequired')),
    zip: ValidationHelper.RequiredString(getTranslatedText('zipIsRequired')),
    address: ValidationHelper.RequiredString(getTranslatedText('addressIsRequired')),
    taxOffice: ValidationHelper.RequiredString(getTranslatedText('taxOfficeIsRequired')),
    vat: ValidationHelper.RequiredString(getTranslatedText('vatIsRequired')),
    companyName: ValidationHelper.RequiredString(getTranslatedText('companyNameIsRequired')),
  }),
});
