/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid } from '@mui/material';
import { IBaseError } from 'corede-common';
import { IBaseUserEntity } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useUserDeleteMutation } from '../../context/user.api';
import ActionDialog from 'components/dialog/ActionDialog';
import { useUserDetailOwnQuery } from 'apps/auth/context';

export const UserDeleteDialog = memo(
  (prop: { open: boolean; item: IBaseUserEntity | undefined; onClose: () => void }) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries

    const { data: userDetailOwnData } = useUserDetailOwnQuery({});

    // mutations
    const [
      userDelete,
      { data: userDeleteData, isLoading: userDeleteIsLoading, error: userDeleteError },
    ] = useUserDeleteMutation();

    // constants

    // // action handlers
    const handleDelete = (_id: string) => {
      userDelete({ input: { _id: _id } });
    };

    // useEffects.success
    useEffect(() => {
      if (userDeleteData) {
        prop?.onClose();
        enqueueSnackbar(t('crm.organizationalChart.user.deletedSuccessfully'), {
          variant: 'success',
        });
      }
    }, [userDeleteData]);

    // // useEffects.error
    useEffect(() => {
      DefaultErrorHandlerUseEffect(userDeleteError as IBaseError, currentLanguage);
    }, [userDeleteError, currentLanguage]);

    // useEffects.init

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.organizationalChart.user.removeAccount')}
        buttonTitle={t('remove')}
        buttonColor="error"
        handleClick={() => handleDelete(prop?.item?._id as string)}
        disabled={userDeleteIsLoading || userDetailOwnData?._id === prop?.item?._id}
        loading={userDeleteIsLoading}
        width={400}
      >
        <Grid item xs={12}>
          {userDetailOwnData?._id !== prop?.item?._id ? (
            <Typography>
              {t('crm.organizationalChart.user.deleteConfirm')}
              {prop?.item?.name} {prop?.item?.surname}"?
            </Typography>
          ) : (
            <Typography>{t('crm.organizationalChart.user.notDeleteOwnConfirm')}</Typography>
          )}
        </Grid>
      </ActionDialog>
    );
  },
);
