import { Autocomplete, Chip, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DefaultArrayAutocompleteInput = (props: {
  inputName: `input.${string}`;
  control: Control<any>;
  label: string;
  isRequired?: boolean;
  options?: any;
  errors: FieldErrors<any>;
  touchedFields: Partial<Readonly<any>>;
  placeholder?: string;
}) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            multiple
            freeSolo
            size="small"
            id={field.name}
            options={props.options || []}
            value={Array.isArray(field.value) ? field.value : []}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              field.onChange(newValue);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ',') {
                event.preventDefault();
                const inputValueTrimmed = inputValue.trim();
                if (inputValueTrimmed !== '') {
                  field.onChange([
                    ...(Array.isArray(field.value) ? field.value : []),
                    inputValueTrimmed,
                  ]);
                  setInputValue('');
                }
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip key={key} size="small" variant="outlined" label={option} {...tagProps} />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={props.placeholder || 'Add tags...'}
                error={!!getNestedValue(props.errors, props.inputName)}
                helperText={
                  getNestedValue(props.touchedFields, props.inputName) &&
                  getNestedValue(props.errors, props.inputName)?.message
                }
              />
            )}
          />
        )}
      />
    </>
  );
};
