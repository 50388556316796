import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { generateBaseColDef } from "../infra/GridColDefFactory";
import { onlyStringEqualsOperator } from "../infra/GridOperatorFactory";
import { Stack } from "@mui/material";
import { Iconify } from "components/iconify";

export const BaseTextGridColumnDef = (params: {
  config: IGridColConfigInput;
}): GridColDef => {
  const baseColDef = generateBaseColDef({
    config: params.config,
  });
  baseColDef.filterOperators = onlyStringEqualsOperator;
  
  return baseColDef;
};
