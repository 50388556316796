/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Avatar, Typography, Grid, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { convertEnums } from 'localization';
import { Language } from 'corede-common';
import { useTranslation } from 'react-i18next';
import { ILeadDetailResult } from 'corede-common-cocrm';
import { OutlinedButton, Iconify } from 'components';
import parse from 'html-react-parser';
import { LeadContactAddDialog } from '../leadContact/LeadContact-add.dialog';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';

export interface ILeadDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<ILeadDetailResult> {
  leadDetailData: ILeadDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const LeadDetailDrawerTabInfoComponent = (props: ILeadDetailDrawerTabInfoComponentProps) => {
  const { t } = useTranslation();
  const localizedLanguages = convertEnums(Language);
  const [openAddLeadContact, setOpenAddLeadContact] = useState(false);

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Stack direction={'column'} py={2.5}>
        <Typography
          variant={'caption'}
          sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
        >
          {t('crm.company')}
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="ic:round-home-work" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.company ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.companyName')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="iconoir:position" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.position ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.position')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="mingcute:sector-line" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.sector ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.sector')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="fluent-mdl2:website" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.website ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.website')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {/* Localization */}
      <Stack direction={'column'} pb={2.5}>
        <Typography
          variant={'caption'}
          sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
        >
          {t('crm.localization')}
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify
                  icon="clarity:map-marker-line"
                  width={18}
                  sx={{ color: 'secondary.main' }}
                />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.country ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.country')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="solar:city-broken" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.city ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.city')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="mage:location" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.leadDetailData?.state ?? '-'}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.state')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="tabler:language" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {localizedLanguages[
                    (props.leadDetailData as any)?.language as keyof typeof localizedLanguages
                  ] ?? ''}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {t('crm.language')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {/* Last Contact */}
      <Stack direction={'column'} pb={2.5}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.lead.lead.lastContact')}
          </Typography>

          {props.leadDetailData?.lastContact && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: 'auto',
                height: 32,
                fontSize: { xs: '13px', md: '13px' },
                lineHeight: 1,
                borderRadius: '24px',
              }}
              onClick={() => setOpenAddLeadContact(true)}
            >
              <Icons.Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
              {t('crm.lead.lead.addContact')}
            </Button>
          )}
        </Stack>
        {props.leadDetailData?.lastContact ? (
          <Box
            sx={{
              width: '100%',
              border: '2px solid',
              borderColor: 'grey.400',
              borderRadius: 2,
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Stack direction={'column'} gap={1}>
                  <Typography fontSize={'12px'} fontWeight={600} color={'secondary.main'}>
                    {moment(props.leadDetailData?.lastContact?.contactedDate).format('dddd')}
                  </Typography>
                  <Typography fontSize={'15px'} fontWeight={500} color={'text.primary'}>
                    {moment(props.leadDetailData?.lastContact?.contactedDate).format(
                      'DD MMMM YYYY',
                    )}
                  </Typography>
                  <Typography fontSize={'12px'} fontWeight={500} color={'text.secondary'}>
                    {moment(props.leadDetailData?.lastContact?.contactedDate).format('HH:mm')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} sx={{ borderLeft: '1px solid', borderColor: 'grey.400', pl: 2 }}>
                <Stack direction={'column'} gap={1}>
                  <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                    <Icons.Circle color="secondary" sx={{ fontSize: '10px', ml: 0.5 }} />
                    <Typography fontSize={'12px'} fontWeight={400}>
                      {parse(props.leadDetailData?.lastContact?.note ?? '')}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                    <Avatar
                      alt={props.leadDetailData?.fullName}
                      src={
                        props?.leadDetailData?.lastContact?.contacted?.profileImage
                          ?.thumbnailPublicUrl
                      }
                      sx={{ width: 15, height: 15 }}
                    />
                    <Typography fontSize={'12px'} fontWeight={400}>
                      {props.leadDetailData?.lastContact?.contacted?.name}{' '}
                      {props.leadDetailData?.lastContact?.contacted?.surname}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              textAlign={'center'}
              color={'text.secondary'}
            >
              {t('crm.lead.lead.emptyStateContactContent1')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: 'auto',
                height: 40,
                fontSize: { xs: '13px', md: '13px' },
                lineHeight: 1,
                borderRadius: '24px',
              }}
              onClick={() => setOpenAddLeadContact(true)}
            >
              <Icons.Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
              {t('crm.lead.lead.addContact')}
            </Button>
          </Box>
        )}
      </Stack>

      {/* Appointments */}
      <Stack direction={'column'} pb={2.5}>
        <Typography
          variant={'caption'}
          sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
        >
          {t('crm.appointments')}
        </Typography>
        {false ? (
          <Box
            sx={{
              width: '100%',
              border: '2px solid',
              borderColor: 'grey.400',
              borderRadius: 2,
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
            }}
          >
            {/* <Grid container>
                    <Grid item xs={4}>
                        <Stack direction={"column"} gap={1}>
                            <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                Monday
                            </Typography>
                            <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                September 3, 2024
                            </Typography>
                            <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                10 AM | 10.30 AM
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={8} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                        <Stack direction={"column"} gap={1}>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    On site estimate with <strong>User One</strong>
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    4517 Bornova, İzmir Türkiye
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Avatar alt={props.leadDetailData?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    Selçuk Madra
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid> */}
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              textAlign={'center'}
              color={'text.secondary'}
            >
              {t('crm.noAppointments')}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: 'auto',
                height: 40,
                fontSize: { xs: '13px', md: '13px' },
                lineHeight: 1,
                borderRadius: '24px',
              }}
              disabled
              onClick={() => setOpenAddLeadContact(true)}
            >
              {t('crm.makeAnAppointment')}
            </Button>
          </Box>
        )}
      </Stack>
      <LeadContactAddDialog
        open={openAddLeadContact}
        onClose={() => setOpenAddLeadContact(false)}
        lead={props?.leadDetailData}
      />
    </Box>
  );
};
