/* eslint-disable react-hooks/exhaustive-deps */
import { useLeadDetailQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { ILeadDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { LeadDetailDrawerLeftPanelComponent } from './LeadDetailDrawer-leftPanel.component';
import { LeadDetailDrawerRightPanelComponent } from './LeadDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { Theme, useMediaQuery } from '@mui/material';

export interface ILeadDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedLead: ILeadDetailResult & IHasId<number>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const LeadDetailDrawer = memo((props: ILeadDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // queries
  const { data: leadDetailData, error: leadDetailError } = useLeadDetailQuery(
    props?.selectedLead?._id
      ? {
          input: {
            _id: props?.selectedLead?._id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadDetailError as IBaseError, currentLanguage);
  }, [leadDetailError]);

  return (
    <DefaultDetailDrawer<ILeadDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedLead}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.lead.lead.leads'),
            }
      }
    >
      <LeadDetailDrawerLeftPanelComponent leadDetailData={leadDetailData} setOpen={props.setOpen} />
      {!downSm && <LeadDetailDrawerRightPanelComponent leadDetailData={leadDetailData} />}
    </DefaultDetailDrawer>
  );
});

export default LeadDetailDrawer;
