import { AddCircle } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { INote, INoteListItemResult, NoteTargetType } from 'corede-common-cocrm';
import { t } from 'i18next';
import { useState } from 'react';
import {
  representDateTimeAsString,
  representHtmlAsString,
  representString,
} from 'utils/representationHelper';
import { NoteDeleteDialog } from '../pages/NoteDeleteDialog';
import { useNoteListQuery, useNoteUpdateMutation } from '../context/note.api';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IEntity } from 'corede-common';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import NoteCreateOverlay from '../pages/create/NoteCreateOverlay';
import { OverlayType } from 'components/dialog/ActionDialog';
import NoteUpdateOverlay from '../pages/update/NoteUpdateOverlay';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import NoteListItemComponent from './NoteListItem';

export interface INotesListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  targetId: string;
  targetEntityRef: NoteTargetType;
  targetDisplayName: string;
  tabKey: 'notes';
  name: 'crm.notes';
}

const NoteListTabComponent = (props: INotesListTabSectionComponent) => {
  const [openNoteCreateDialog, setOpenNoteCreateDialog] = useState(false);
  const [openNoteUpdateDialog, setOpenNoteUpdateDialog] = useState(false);
  const [openNoteDeleteDialog, setOpenNoteDeleteDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState<INoteListItemResult>();

  const { data: noteListData, isLoading: noteListLoading } = useNoteListQuery(
    {
      input: {
        filter: {
          targetIds: [props.targetId],
          targetType: props.targetEntityRef,
        },
      },
    },
    {
      skip: !props.targetId,
    },
  );

  const [noteUpdateQuery] = useNoteUpdateMutation();

  return (
    <DefaultTabContainer
      title={''}
      rightButton={
        noteListData && noteListData?.count > 0
          ? {
              title: t('crm.addNote'),
              onClick: () => setOpenNoteCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
            }
          : undefined
      }
    >

      {noteListData && noteListData?.count === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no task yet."
            />
          }
          content1={t('crm.emptyStateNoteList1')}
          button1={{
            leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
            title: t('crm.addNote'),
            onClick: () => setOpenNoteCreateDialog(true),
          }}
        />
      )}

      {noteListData && noteListData?.count > 3 && (
        <BaseDataGrid
          rows={noteListData?.data?.map((note, index) => ({
            id: index + 1,
            _id: note?._id,
            title: representString(note?.title),
            content: representHtmlAsString({ data: note?.content, trimLength: 15 }),
            createdAt: representDateTimeAsString(note?.createdAt),
            target: note?.target,
            targetType: note?.targetType,
          }))}
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                  align: 'center',
                },
                headerConfig: {
                  name: '#',
                  align: 'center',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'title',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.title'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'content',
                  editable: false,
                  filterable: false,
                  width: 250,
                },
                headerConfig: {
                  name: t('crm.content'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'createdAt',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.createdAt'),
                },
              },
            },
          ]}
          actionColumn={{
            width: 70,
            defaultActions: {
              edit: {
                clickConfig: {
                  setSelectedRow: setSelectedNote,
                  setOpenAction: setOpenNoteUpdateDialog,
                },
              },
              delete: {
                clickConfig: {
                  setSelectedRow: setSelectedNote,
                  setOpenAction: setOpenNoteDeleteDialog,
                },
              },
            },
            actionHeaderName: t('crm.actions'),
          }}
          loading={noteListLoading}
          listFilter={{
            filterInput: {},
            setFilterInput: () => null,
          }}
          count={noteListData?.count}
          config={{
            columnVisibilityModel: {},
            features: [],
          }}
          update={{
            updateQuery: noteUpdateQuery,
          }}
          disableColumnFilter={true}
          hideFooterPagination={true}
        />
      )}

      {noteListData && noteListData?.count > 0 && noteListData?.count < 4 && (
        <Stack direction={'column'} gap={1.5} width={'100%'}>
          {noteListData?.data?.map((note, index) => {
            return <NoteListItemComponent key={index} note={note} />;
          })}
        </Stack>
      )}

      <NoteCreateOverlay
        open={openNoteCreateDialog}
        setOpen={setOpenNoteCreateDialog}
        targetId={props.targetId}
        targetType={props.targetEntityRef}
        overlayType={OverlayType.dialog}
      />

      <NoteUpdateOverlay
        open={openNoteUpdateDialog}
        setOpen={setOpenNoteUpdateDialog}
        selectedNoteId={selectedNote?._id}
        overlayType={OverlayType.dialog}
      />

      <NoteDeleteDialog
        open={openNoteDeleteDialog}
        onClose={() => {
          setSelectedNote(undefined);
          setOpenNoteDeleteDialog(false);
        }}
        noteId={selectedNote?._id ?? ''}
        setNote={setSelectedNote}
      />
    </DefaultTabContainer>
  );
};

export default NoteListTabComponent;
