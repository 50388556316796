const locale = {
  readAllSuccess: 'Tüm bildirimler okundu!',
  title: 'Bildirimler',
  markAllAsRead: 'Tümünü okundu olarak işaretle.',
  close: 'Kapat',
  noNotifications1: 'Bildirim yok',
  noNotifications2: 'Henüz hiçbir bildirim almadınız.',
  refresh: 'Yenile'
};

export default locale;
