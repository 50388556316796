import { DemoDomainLayout } from "../layouts";
import {
  Payment,
  Menu,
  LeadPlus,
  Marketing,
  Notification,
  Window,
  Community,
  User,
  Crm,
  Integrations,
  LegalAgreements,
} from "../subdomains/settings/pages";
import { DemoRouter } from "../subdomains/settings/routes/DemoRouter";
import { basePaymentRoute, demoDomainRoutes } from "./demo.base.route";
import { Route, Routes } from "react-router-dom";

export const DemoDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DemoDomainLayout />}>
        <Route index element={<DemoRouter />} />
        <Route path="*" element={<DemoRouter />} />
        <Route path={demoDomainRoutes.payment} element={<Payment />} />
        <Route
          path={demoDomainRoutes.integrations}
          element={<Integrations />}
        />
        <Route
          path={demoDomainRoutes.legalAgreements}
          element={<LegalAgreements />}
        />
        <Route path={demoDomainRoutes.menu} element={<Menu />} />
        <Route path={demoDomainRoutes.leadPlus} element={<LeadPlus />} />
        <Route path={demoDomainRoutes.marketing} element={<Marketing />} />
        <Route
          path={demoDomainRoutes.notification}
          element={<Notification />}
        />
        <Route path={demoDomainRoutes.window} element={<Window />} />
        <Route path={demoDomainRoutes.community} element={<Community />} />
        <Route path={demoDomainRoutes.crm} element={<Crm />} />
        <Route path={demoDomainRoutes.user} element={<User />} />
      </Route>
    </Routes>
  );
};
