import { Add } from '@mui/icons-material';
import { Tabs, Tab, styled, Box } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

interface IStyledTabs {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  iconPosition?: string;
}

interface IStyledTab {
  label: string | React.ReactElement;
  icon?: React.ReactElement | JSX.Element;
  iconPosition?: 'start' | 'end';
  component?: React.ElementType;
  href?: string;
}

interface IMyTab {
  labels: string[];
  selectedTab?: string;
  setSelectedTab?: (role: string) => void;
  type?: string;
  counts?: any[];
  icons?: React.ReactElement[];
}

const StyledTabs = styled((props: IStyledTabs) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 60,
    width: '100%',
    // marginTop: -7,
    // marginBottom: 7,
    backgroundColor: '#F43F5E',
  },
});

const StyledTab = styled((props: IStyledTab) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '40px',
  // maxWidth: '120px',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: 0.4,
  marginRight: theme.spacing(1),
  paddingLeft: 6,
  paddingRight: 6,
  minHeight: 50,
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const StyledTabs2 = styled((props: IStyledTabs) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 60,
    width: '100%',
    // marginTop: -7,
    // marginBottom: 7,
    backgroundColor: '#F43F5E',
  },
});

const StyledTabs3 = styled((props: IStyledTabs) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#F43F5E',
  },
});

const StyledTab2 = styled((props: IStyledTab) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '40px',
  // maxWidth: '120px',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 0.4,
  marginRight: theme.spacing(4),
  paddingLeft: 6,
  paddingRight: 6,
  minHeight: 50,
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const StyledTab3 = styled((props: IStyledTab) => <Tab disableRipple {...props} />)(({ theme }) => ({
  flexGrow: 1,
  textTransform: 'none',
  minWidth: '40px',
  // maxWidth: '120px',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 0.4,
  // marginRight: theme.spacing(4),
  // paddingLeft: 6,
  // paddingRight: 6,
  minHeight: 50,
  color: theme.palette.text.secondary,
  paddingLeft: 22,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-selected div': {
    fontWeight: '700 !important',
  },
  '&.Mui-selected .count-badge': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const createCountBadge = (count: number) => {
  if (count > 0) {
    return (
      <Box
        className="count-badge"
        sx={{
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          fontSize: '12px',
          lineHeight: '22px',
          backgroundColor: 'text.secondary',
          color: 'background.default',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2px',
        }}
      >
        {count}
      </Box>
    );
  }
  return null;
};

export const MyTab = ({ labels, setSelectedTab, type, selectedTab, counts, icons }: IMyTab) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(
      labels.indexOf(selectedTab ?? labels[0]) > -1 ? labels.indexOf(selectedTab ?? labels[0]) : 0,
    );
  }, [selectedTab, labels, counts]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (setSelectedTab) {
      setSelectedTab(labels[newValue]);
    }
  };

  if (type === 'settings') {
    return (
      <StyledTabs2 value={value} onChange={handleChange} aria-label="styled tabs example">
        {labels.map((label: string) => (
          <StyledTab2 key={label} label={label} />
        ))}
      </StyledTabs2>
    );
  }

  if (type === 'notifications') {
    return (
      <StyledTabs3 value={value} onChange={handleChange} aria-label="styled tabs example">
        {labels.map((label: string, index: number) => (
          <StyledTab3
            key={index}
            label={
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    fontSize: '15px',
                    lineHeight: '22px',
                    fontWeight: '400',
                  }}
                >
                  {label}
                  {counts && counts[index] > 0 && createCountBadge(counts[index])}
                </div>
              </>
            }
          />
        ))}
      </StyledTabs3>
    );
  }

  return (
    <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
      {labels.includes('Add') ? (
        <StyledTab
          key="Add"
          label="Add Role"
          icon={<Add />}
          iconPosition="start"
          sx={{
            border: '2px solid #eee',
            borderRadius: '14px',
            padding: '0 5px',
            maxHeight: '10px',
            height: '0px',
          }}
        />
      ) : (
        labels
          .filter((label) => label !== 'Add')
          .map((label: string, index: number) => (
            <StyledTab
              key={label}
              label={label}
              icon={icons ? icons[index] : undefined}
              iconPosition="start"
            />
          ))
      )}
    </StyledTabs>
  );
};
