import { Grid } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReminderListComponent from './ReminderList-grid.component';
import { DefaultTabsForListComponent } from 'apps/crm/components/tabs/DefaultTabs.forList.component';
import { ReminderTargetType } from 'corede-common-cocrm';
import { useUserDetailOwnQuery } from 'apps/auth/context';

export type TDefaultTargetDataForCreate = {
  targetType?: ReminderTargetType;
  targetId?: string;
};

const ReminderList = memo(() => {
  // general
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  useEffect(() => {
    setUserId(userDetailOwnData?._id);
    setOrganizationId(userDetailOwnData?.organization?._id);
  }, [userDetailOwnData]);

  // useEffects.error

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <DefaultTabsForListComponent>
        <ReminderListComponent
          tabKey="shared"
          name="crm.calendar.reminder.sharedList"
          targetType={ReminderTargetType.Organization}
          targetId={organizationId}
          canCreate={true}
        />
        <ReminderListComponent
          tabKey="private"
          name="crm.calendar.reminder.privateList"
          targetType={ReminderTargetType.User}
          targetId={userId}
          canCreate={true}
        />
        <ReminderListComponent
          tabKey="own"
          name="crm.calendar.reminder.ownList"
          createdById={userId}
          canCreate={false}
        />
        <ReminderListComponent
          tabKey="all"
          name="crm.calendar.reminder.allList"
          canCreate={false}
        />
      </DefaultTabsForListComponent>
    </Grid>
  );
});

export default ReminderList;
