/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  ITicket,
  ITicketDetailResult,
  ITicketListInput,
  TicketPriority,
  TicketStatus,
  TicketTargetType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useTicketListQuery, useTicketUpdateMutation } from '../../context/support.api';
import TicketDetailDrawer from '../detailDrawer/TicketDetailDrawer';
import { TicketDeleteDialog } from '../TicketDeleteDialog';
import TicketUpdateDrawerWrapper from '../update/TicketUpdateOverlay';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import {
  representString,
  representDateAsString,
  representArrayOfObjectAsString,
  representBooleanAsString,
  representHtmlAsString,
} from 'utils/representationHelper';
import { OverlayType } from 'components/dialog/ActionDialog';
import { useDepartmentListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';

export interface ITicketListGridComponent {
  // ticket

  setOpenTicketCreateDrawer?: (value: boolean) => void | undefined;
}

const TicketListGridComponent = memo((props: ITicketListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedTicket, setSelectedTicket] = useState<TListGridSelectedEntity<ITicket>>();

  const [openTicketDetailDrawer, setOpenTicketDetailDrawer] = useState(false);
  const [openTicketDeleteDialog, setOpenTicketDeleteDialog] = useState(false);
  const [openTicketUpdateDrawer, setOpenTicketUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [ticketListFilter, setTicketListFilter] = useState<IGraphqlVariables<ITicketListInput>>({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = ticketListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = ticketListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: ticketListData,
    isLoading: ticketListLoading,
    isFetching: ticketListFetching,
    error: ticketListError,
    refetch,
  } = useTicketListQuery(ticketListFilter);

  const { data: departmentListData } = useDepartmentListQuery({});
  // used for direct update in the list rows
  const [ticketUpdate, { isLoading: ticketUpdateLoading, error: ticketUpdateError }] =
    useTicketUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [ticketListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketListError as IBaseError, currentLanguage);
  }, [ticketListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketUpdateError as IBaseError, currentLanguage);
  }, [ticketUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(ticketListFilter?.input?.filter);
  const isJunkUndefined = (ticketListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = ticketListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    ticketListData?.data?.length === 0 &&
    !ticketListLoading &&
    !ticketListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={undefined}
      setSelectedEntity={() => null}
      listFilter={ticketListFilter}
      setListFilter={setTicketListFilter}
      listData={ticketListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {ticketListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={ticketListData?.data?.map((ticket, index) => ({
                id:
                  ((ticketListFilter.input?.pagination?.page || 1) - 1) *
                    (ticketListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: ticket?._id,
                subject: representString(ticket?.subject),
                assignees: representArrayOfObjectAsString({ dataList: ticket?.assignees }),
                department: ticket?.department,
                priority: representString(ticket.priority),
                tags: representArrayOfObjectAsString({ dataList: ticket?.tags }),
                status: ticket?.status,
              }))}
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: '#',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'subject',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.support.support.subject'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      // icon: 'mdi:checkbox-marked-circle',
                      name: t('crm.status'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `status`,
                      isArrayFilter: true,
                    },
                  },
                  data: TicketStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'assignees',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.assignees'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'department',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      icon: 'mdi:source-fork',
                      name: t('crm.department'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `departments`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: departmentListData?.data,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'priority',
                      editable: true,
                      filterable: true,
                      width: 90,
                    },
                    headerConfig: {
                      icon: 'mdi:folder-outline',
                      name: t('crm.priority'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `priorities`,
                      isArrayFilter: true,
                    },
                  },
                  data: TicketPriority,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'tags',
                      editable: true,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.tags'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedTicket,
                      setOpenAction: setOpenTicketDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedTicket,
                      setOpenAction: setOpenTicketUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedTicket,
                      setOpenAction: setOpenTicketDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={ticketListLoading || ticketUpdateLoading || ticketListFetching}
              listFilter={{
                filterInput: ticketListFilter,
                setFilterInput: setTicketListFilter,
              }}
              update={{
                updateQuery: ticketUpdate,
              }}
              count={ticketListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  department: false,
                  tags: false,
                  isBillable: false,
                  isRecurrent: false,
                  repeatDays: false,
                  totalCycle: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.support.support.emptyState1')}
              content2={t('crm.support.support.emptyState2')}
              button1={{
                title: t('crm.support.support.create'),
                onClick: () => (props?.setOpenTicketCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <TicketDetailDrawer
        ticket={selectedTicket as ITicketDetailResult & IHasId<number>}
        open={openTicketDetailDrawer}
        setOpen={setOpenTicketDetailDrawer}
        sideNavigationProps={{
          count: ticketListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: ticketListData,
            listFilter: ticketListFilter,
            setListFilter: setTicketListFilter,
            selectedEntity: selectedTicket,
            setSelectedEntity: setSelectedTicket,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <TicketUpdateDrawerWrapper
        open={openTicketUpdateDrawer}
        setOpen={setOpenTicketUpdateDrawer}
        targetId={selectedTicket?.target?._id ?? ''}
        targetType={selectedTicket?.targetType}
        ticketId={selectedTicket?._id ?? ''}
        overlayType={OverlayType.drawer}
      />

      <TicketDeleteDialog
        open={openTicketDeleteDialog}
        setTicket={setSelectedTicket}
        ticketId={selectedTicket?._id ?? ''}
        key={selectedTicket?._id}
        onClose={() => {
          setSelectedTicket(undefined);
          setOpenTicketDeleteDialog(false);
        }}
      />
    </DefaultListGridComponent>
  );
});

export default TicketListGridComponent;
