/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useEstimateDeleteMutation } from "../../context/estimate.api";

export const EstimateDeleteDialog = memo(
  (props: { open: boolean; estimateId: string; onClose: () => void; setEstimate: Dispatch<any> }) => {
    // general
    const { t } = useTranslation();
    // mutations
    const [
      estimateDelete,
      {
        data: estimateDeleteData,
        isLoading: estimateDeleteIsLoading,
        error: estimateDeleteError,
      },
    ] = useEstimateDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.estimateId}
        setItem={props.setEstimate}
        deleteMutation={estimateDelete}
        deleteMutationData={{
          data: estimateDeleteData,
          isLoading: estimateDeleteIsLoading,
          error: estimateDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.preSale.estimate.delete"),
          successMessage: t("crm.preSale.estimate.deletedSuccessfully"),
          content: t("crm.preSale.estimate.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
