/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  IInvoiceReturn,
  IInvoiceReturnListInput,
  InvoiceReturnResolveReason,
  InvoiceReturnStatus,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useInvoiceReturnListQuery,
  useInvoiceReturnUpdateMutation,
} from '../../context/return.api';
// import Delete from '../Delete';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';

export interface IReturnListGridComponent {
  // return
  setOpenReturnCreateDrawer?: (value: boolean) => void | undefined;
}

const ReturnListGridComponent = memo((props: IReturnListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedReturn, setSelectedReturn] = useState<TListGridSelectedEntity<IInvoiceReturn>>();
  const [openReturnDetailDrawer, setOpenReturnDetailDrawer] = useState(false);
  const [openReturnDeleteDialog, setOpenReturnDeleteDialog] = useState(false);
  const [openReturnUpdateDrawer, setOpenReturnUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [returnListFilter, setReturnListFilter] = useState<
    IGraphqlVariables<IInvoiceReturnListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = returnListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = returnListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: returnListData,
    isLoading: returnListLoading,
    isFetching: returnListFetching,
    error: returnListError,
    refetch,
  } = useInvoiceReturnListQuery(returnListFilter);

  // used for direct update in the list rows
  const [returnUpdate, { isLoading: returnUpdateLoading, error: returnUpdateError }] =
    useInvoiceReturnUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setReturnListLoading && props.setReturnListLoading(returnListLoading);
  // }, [returnListLoading]);
  useEffect(() => {
    refetch();
  }, [returnListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(returnListError as IBaseError, currentLanguage);
  }, [returnListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(returnUpdateError as IBaseError, currentLanguage);
  }, [returnUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(returnListFilter?.input?.filter);
  const isJunkUndefined = (returnListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = returnListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    returnListData?.data?.length === 0 &&
    !returnListLoading &&
    !returnListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedReturn}
      setSelectedEntity={setSelectedReturn}
      listFilter={returnListFilter}
      setListFilter={setReturnListFilter}
      listData={returnListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {returnListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                returnListData?.data?.map((returnItem, index) => ({
                  id:
                    ((returnListFilter.input?.pagination?.page || 1) - 1) *
                      (returnListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: returnItem._id,
                  invoiceId: returnItem.invoice.invoiceId || '-',
                  status: returnItem.status || '-',
                  refundAmount: returnItem.refundAmount || '-',
                  resolveReason: returnItem.resolveReason || '-',
                  disapproveReason: returnItem.disapproveReason || false,
                  isOpen: returnItem.isOpen || false,
                  createdAt: representDateAsString(returnItem.createdAt) || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'invoiceId',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.invoiceId'),
                      icon: 'mdi:file-document-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.status'),
                      icon: 'mdi:currency-usd-circle-outline',
                    },
                    filterConfig: {
                      customFieldName: `status`,
                    },
                  },
                  data: InvoiceReturnStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'refundAmount',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.refundAmount'),
                      icon: 'mdi:file-document-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'resolveReason',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.resolveReason'),
                      icon: 'mdi:currency-usd-circle-outline',
                    },
                    filterConfig: {
                      customFieldName: `resolveReason`,
                    },
                  },
                  data: InvoiceReturnResolveReason,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'disapproveReason',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.disapproveReason'),
                      icon: 'mdi:file-document-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'isOpen',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.isOpen'),
                      icon: 'mdi:file-document-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'createdAt',
                      filterable: false,
                      editable: false,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.createdAt'),
                      icon: 'mdi:clock-time-eight-outline',
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedReturn,
                      setOpenAction: setOpenReturnDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedReturn,
                      setOpenAction: setOpenReturnUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedReturn,
                      setOpenAction: setOpenReturnDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={returnListLoading || returnUpdateLoading || returnListFetching}
              listFilter={{
                filterInput: returnListFilter,
                setFilterInput: setReturnListFilter,
              }}
              update={{
                updateQuery: returnUpdate,
              }}
              count={returnListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  sector: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.sale.return.emptyState1')}
              content2={t('crm.sale.return.emptyState2')}
              button1={{
                title: t('crm.sale.return.create'),
                onClick: () => (props?.setOpenReturnCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      {/* <ReturnDetailDrawerWrapper
        open={openReturnDetailDrawer}
        setOpen={setOpenReturnDetailDrawer}
        selectedReturn={selectedReturn as IReturnDetailResult & IHasId<number>} // TODO:
        sideNavigationProps={{
          count: returnListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: returnListData,
            listFilter: returnListFilter,
            setListFilter: setReturnListFilter,
            selectedEntity: selectedReturn,
            setSelectedEntity: setSelectedReturn,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      /> */}

      {/* <ReturnUpdateDrawerWrapper
        open={openReturnUpdateDrawer}
        setOpen={setOpenReturnUpdateDrawer}
        selectedReturn={selectedReturn}
      /> */}

      {/* <Delete
        open={openReturnDeleteDialog}
        // returnId={selectedReturn?._id ?? ''}
        item={selectedReturn}
        // setReturn={setSelectedReturn}
        onClose={() => {
          setSelectedReturn(undefined);
          setOpenReturnDeleteDialog(false);
        }}
        key={selectedReturn?._id}
      /> */}
    </DefaultListGridComponent>
  );
});

export default ReturnListGridComponent;
