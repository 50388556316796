/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IEstimateDetailResult, EstimateStatus } from 'corede-common-cocrm';
import * as Icons from '@mui/icons-material';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';

interface IEstimateDetailDrawerHeaderStatusComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

export const EstimateDetailDrawerHeaderStatusComponent = (
  props: IEstimateDetailDrawerHeaderStatusComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return downLg ? null : (
    <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
      {Object.entries(EstimateStatus).map(([key, value], index) => (
        <Box
          key={key}
          sx={{
            backgroundColor:
              value === props.estimateDetailData?.status ? 'secondary.main' : 'primary.light',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            padding: '4px 16px',
            pl: index !== 0 ? 3 : 1,
            ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
            ...(index === Object.entries(EstimateStatus).length - 1 && {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            }),
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: index === Object.entries(EstimateStatus).length - 1 ? 'none' : 'block',
              top: "15%",
              right: -7,
              width: 0,
              height: "3px",
              borderTop: '12px solid transparent',
              borderBottom: '12px solid transparent',
              borderLeft: `8px solid`,
              borderLeftColor: 'background.secondary',
              zIndex: 2,
            }}
          />
          {/* <SvgIcon
            component={(Icons as any)[status.icon]}
            sx={{ fontSize: '16px', color: 'white', mr: 1 }}
          /> */}
          <Typography
            fontSize={'12px'}
            sx={{ color: 'white', textTransform: 'capitalize', px: 1 }}
          >
            {getTranslatedEnumValue(value as EstimateStatus)}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
