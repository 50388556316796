import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { IGraphqlVariables } from 'corede-common';

import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DefaultNameAutocompleteInput = (props: {
  inputName: `input.${string}`;
  control: Control<any>;
  label: string;
  options: { name: string }[];
  isRequired?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  errors?: FieldErrors<IGraphqlVariables<any, any>>;
  touchedFields?: Partial<Readonly<any>>;
}) => {
  const { t } = useTranslation();
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };
  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            options={props.options}
            size="small"
            fullWidth
            id={props.inputName}
            loading={props.isLoading}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              field.onChange(newValue?.name || '');
            }}
            onBlur={() => {
              field.onBlur();
            }}
            value={
              field.value
                ? props.options.find((option) => option.name === field.value) || null
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={props.placeholder || 'Select an option'}
                error={!!getNestedValue(props.errors, props.inputName)}
                helperText={
                  getNestedValue(props.touchedFields, props.inputName) &&
                  getNestedValue(props.errors, props.inputName)?.message
                }
              />
            )}
          />
        )}
      />
    </>
  );
};
