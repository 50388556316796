const locale = {
  // fields
  users: 'Bildirilecek Kullanıcılar',
  date: 'Hatırlatma Tarihi',
  title: 'Notları Listele',
  detail: 'Not Detayı',
  sharedList: 'Paylaşılan Notlar',
  privateList: 'Özel Notlarım',
  ownList: 'Notlarım',
  allList: 'Bütün Notlar',

  // actions
  create: 'Not Oluştur',
  add: 'Not Ekle',
  update: 'Not Güncelle',
  delete: 'Not Sil',

  // situational text
  tagsPlaceholder: "Lütfen etiket ekleyin ve ardından Enter'a basın",
  createSuccess: 'Not oluşturuldu',
  updateSuccess: 'Not başarıyla güncellendi.',
  deletedSuccessfully: 'Not silindi',
  deleteContent: 'Bu notu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  emptyState1: 'Henüz not eklenmedi.',
  emptyState2:
    "CRM'nizdeki önemli detayları ve konuşmaları takip etmek için notlarınızı burada oluşturun ve düzenleyin.",

    createdBy: "Notu oluşturan: ",
};

export default locale;
