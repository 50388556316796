
import appointmentLocale from "../subdomains/appointment/locales/en";
import reminderLocale from "../subdomains/reminder/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reminder: {
    ...reminderLocale,
  },
  appointment: {
    ...appointmentLocale,
  }
};

export default locale;
