const locale = {
  contacts: 'İletişim',
  title: 'İletişim Listesi',
  createContact: 'Kişi Oluştur',
  columnId: '#',
  columnName: 'Ad',
  columnIcon: 'Simge',
  columnDepartment: 'Departman',
  columnParentCategory: 'Üst Kategori',
  columnActions: 'İşlemler',
  emptyStateContent1: 'Kişi bulunamadı',
  emptyStateContent2: 'Lütfen yeni bir kişi oluşturun',
  selectIconTitle: 'Bir Simge Seç',
  searchIcons: 'Simgeleri Ara',
  relatedContacts: 'İlgili Kişiler',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Kişi Oluştur',
  contactNameLabel: 'Kişi Adı',
  contactEmailLabel: 'Kişi E-posta',
  contactPhoneLabel: 'Kişi Telefon',
  contactTypeLabel: 'Kişi Türü',
  contactPictureLabel: 'Kişi Resmi',
  departmentLabel: 'Departman',
  categoryLabel: 'Kategori',
  contactLanguageLabel: 'Kişi Dili',
  countryLabel: 'Ülke',
  stateLabel: 'Şehir',
  cityLabel: 'İlçe',
  uploadPictureText: 'Bir resim yükleyin',
  createContactButton: 'Kişi Oluştur',
  createSuccess: 'Kişi başarıyla oluşturuldu',
  delete: 'Sil',
  deleteConfirm: 'Bu kişiyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Kişi başarıyla silindi',
  cancel: 'İptal',
  id: '#',
  firstName: 'Ad',
  lastName: 'Soyad',
  email: 'E-posta',
  type: 'Tür',
  phone: 'Telefon',
  position: 'Pozisyon',
  language: 'Dil',
  update: 'Kişiyi Güncelle',
  updateContactButton: 'Kişiyi Güncelle',
  customerId: 'Bir Müşteri Seçin',
  primary: 'Birincil',
  customer: 'Müşteri',
  actions: 'İşlemler',
  create: 'Kişi Oluştur',
  emptyState1: 'Burada kişi bulunamadı.',
  emptyState2: 'Hemen kişi oluşturabilir veya içe aktarabilirsiniz.',
  deletePrimaryError: 'Birincil iletişim bilgisi silinemez.',
  updateSuccess: 'Kişi başarıyla güncellendi.',
};

export default locale;
