

import { ExpensesDomainLayout } from "../layouts";
import { ExpensesRouter } from "../subdomains/expense/routes/ExpensesRouter";
import { expensesDomainRoutes } from "./expenses.base.route";
import { Route, Routes } from "react-router-dom";

export const ExpensesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpensesDomainLayout />}>
        <Route index element={<ExpensesRouter />} />
        <Route path="*" element={<ExpensesRouter />} />
      </Route>
    </Routes>
  );
};
