import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateLoginInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    email: ValidationHelper.RequiredEmail(
      getTranslatedText('emailIsInvalid'),
      getTranslatedText('emailIsRequired'),
    ),
    password: ValidationHelper.RequiredPassword(
      getTranslatedText('passwordIsInvalid'),
      getTranslatedText('passwordIsRequired'),
    ),
  }),
});
