import { DocumentDomainLayout } from '../layouts';
import { DocumentRouter } from '../subdomains/document/routes/DocumentRouter';
import { documentDomainRoutes } from './document.base.route';
import { Route, Routes } from 'react-router-dom';

export const DocumentDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DocumentDomainLayout />}>
        <Route index element={<DocumentRouter />} />
        <Route path="*" element={<DocumentRouter />} />
      </Route>
    </Routes>
  );
};
