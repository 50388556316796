
import returnLocale from "../subdomains/return/locales/tr";
import paymentLocale from "../subdomains/payment/locales/tr";
import invoiceLocale from "../subdomains/invoice/locales/tr";

const locale = {

  invoice: {
    ...invoiceLocale,
  },
  payment: {
    ...paymentLocale,
  },
  return: {
    ...returnLocale,
  },
};

export default locale;

