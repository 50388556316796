const locale = {
  products: "Ürünler",
  title: "Ürünler",

  create: "Ürün Oluştur",
  update: "Ürün Güncelle",
  delete: "Ürün sil",
  deleteConfirm: "Ürünü silmek istediğinizden emin misiniz?",
  deletedSuccessfully: "Ürün silme başarılı",
  createSuccess: "Ürün oluşturma başarılı",
  updateSuccess: "Ürünü güncelleme başarılı",
  name: "Ad",
  showMore: "Daha fazla göster",

  detail: "Ürün Detayı",
  type: "Tip",
  image: "Resim",
  sku: "Sku",
  description: "Detay",
  currency: "Para Birimi",
  price: "Fiyat",
  priceType: "Fiyat Tipi",
  discountedPrice: "İndirim Uygulanmış Fiyat",
  unit: "Birim",
  categoryId: "Kategori",
  natTaxRate: "Ulusal Vergi Miktarı",
  intTaxRate: "Uluslararası Vergi Miktarı",
  unitPrice: "Birim Fiyatı",
  categories: "Kategoriler",

  emptyState1: "Henuz bir ürün kaydedilmedi.",
  emptyState2: "Yeni Ürünler oluşturmak için aşağıdaki butonu kullanabilirsiniz.",
};

export default locale;
