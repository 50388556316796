

import { commonRoutes } from "routes/route.common";

export const leadDomainRoutes = {
  base: "lead",
  subdomains: {
    lead: {
      base: "leads",
    },
    form: {
      base: "forms",
    },
    status: {
      base: "statuses"
    },
    source: {
      base: "sources"
    }
  },
};


export const baseLeadRoute = (): string => `/${leadDomainRoutes.base}/`;
export const listLeadRoute = (): string => `/${leadDomainRoutes.base}`;
export const viewLeadRoute = (id: string): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${id}`;
export const createLeadRoute = (): string =>
  `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${commonRoutes.create}`;
export const updateLeadRoute = (id: string): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${id}/${commonRoutes.update}`;
export const importLeadRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${commonRoutes.import}`

export const listFormRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.form.base}`
export const listStatusRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.status.base}`;
export const listSourceRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.source.base}`;


