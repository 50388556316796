import { useState, lazy, Suspense } from "react";
import { IReminderDetailDrawerProps } from "./ReminderDetailDrawer";

const ReminderDetailDrawer = lazy(() => import("./ReminderDetailDrawer"));

const ReminderDetailDrawerWrapper = ({
  open,
  setOpen,
  reminder: selectedReminder,
  sideNavigationProps,
}: IReminderDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ReminderDetailDrawer
            open={open}
            setOpen={setOpen}
            reminder={selectedReminder}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default ReminderDetailDrawerWrapper;
