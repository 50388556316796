/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { EstimateDetailDrawerLeftPanelHeaderComponent } from './EstimateDetailDrawer-leftPanel-header.component';
import { EstimateDetailDrawerLeftPanelContactDetailComponent } from './EstimateDetailDrawer-leftPanel-contactDetail.component';
import { EstimateDetailDrawerLeftPanelAssignedsComponent } from './EstimateDetailDrawer-leftPanel-assigned.component';

export interface IEstimateDetailDrawerLeftPanelComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EstimateDetailDrawerLeftPanelComponent = (
  props: IEstimateDetailDrawerLeftPanelComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <DefaultDetailDrawerLeftPanelComponent<IEstimateDetailResult>
      entityData={props.estimateDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <EstimateDetailDrawerLeftPanelHeaderComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <EstimateDetailDrawerLeftPanelContactDetailComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <EstimateDetailDrawerLeftPanelAssignedsComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />

      {downLg ? null : (
        <Stack
          direction={'column'}
          position={'absolute'}
          bottom={10}
          left={10}
          gap={0.5}
          alignItems={'flex-end'}
          maxWidth={'200px'}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.updatedAt')}: {moment(props.estimateDetailData?.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Stack>
      )}
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
