import { ArrowBackIos, ViewKanbanOutlined, ViewTimelineOutlined } from '@mui/icons-material';
import {
  Stack,
  Typography,
  Box,
  Button,
  Theme,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

type IHeaderBase = {
  toggleButton?: {
    value: string;
    action: any;
  };
  showBackButton?: boolean;
  rightButton?: {
    visibility?: boolean;
    icon?: JSX.Element;
    title?: string;
    onClick?: () => void;
    disabled?: boolean;
  };
  rightButton2?: {
    visibility?: boolean;
    icon?: JSX.Element;
    title?: string;
    onClick?: () => void;
    disabled?: boolean;
  };
  title?: string | JSX.Element;
  description?: string | JSX.Element;
};

export const HeaderBase = (props: IHeaderBase) => {
  const navigate = useNavigate();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack
      direction={downMd ? 'column-reverse' : 'row'}
      justifyContent="space-between"
      alignItems={downMd ? 'flex-end' : 'center'}
      mb={2}
      sx={{ width: '100%' }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        gap={2}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Stack direction={'row'} alignItems="center" sx={{ width: '100%' }} gap={1}>
          {props.showBackButton && (
            <IconButton
              onClick={() => navigate(-1)}
              color="primary"
              sx={{
                pl: 1,
                border: '1px solid',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
              }}
            >
              <ArrowBackIos color="primary" sx={{ pl: 0.5, mr: -0.5, fontSize: '14px' }} />
            </IconButton>
          )}
          <Stack direction={'column'} gap={1}>
            <Typography variant={'h4'} sx={{ fontWeight: 500 }}>
              {props.title}
            </Typography>

            {props.description}
          </Stack>
        </Stack>
        {props.toggleButton && (
          <Box display="flex" alignItems="center">
            <ToggleButtonGroup
              value={props?.toggleButton?.value}
              sx={{ ml: 2 }}
              size="small"
              exclusive
              onChange={(_, value) => props?.toggleButton?.action(value)}
            >
              <ToggleButton value="list">
                <ViewTimelineOutlined />
              </ToggleButton>
              <ToggleButton value="kanban">
                <ViewKanbanOutlined />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Stack>
      <Stack direction="row" alignItems={'center'} gap={1} mb={{ xs: 1, md: 0 }}>
        {props.rightButton && props.rightButton.visibility && (
          <Button
            variant="contained"
            color="primary"
            disabled={props.rightButton?.disabled}
            sx={{
              width: 'auto',
              height: downMd ? 28 : 40,
              fontSize: { xs: '13px', md: '13px' },
              lineHeight: 1,
              borderRadius: '24px',
            }}
            onClick={() => props.rightButton?.onClick?.()}
          >
            {props.rightButton?.icon}
            {props.rightButton?.title}
          </Button>
        )}
        {props.rightButton2 && props.rightButton2.visibility && (
          <Button
            variant="outlined"
            color="primary"
            disabled={props.rightButton2?.disabled}
            sx={{
              width: 'auto',
              height: downMd ? 28 : 40,
              fontSize: { xs: '13px', md: '13px' },
              lineHeight: 1,
              borderRadius: '24px',
            }}
            onClick={() => props.rightButton2?.onClick?.()}
          >
            {props.rightButton2?.icon}
            {props.rightButton2?.title}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
