import proposalLocale from "../subdomains/proposal/locales/en";
import estimateLocale from "../subdomains/estimate/locales/en";

const locale = {
  estimate: {
    ...estimateLocale,
  },
  proposal: {
    ...proposalLocale,
  },
};

export default locale;
