/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ITaskDetailResult, TaskTargetType } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';
import { DetailDrawerAsigneesComponent } from './TaskDetailDrawer-rightPanel-infoSection-asignees.component';
import { DetailDrawerChecklistComponent } from './TaskDetailDrawer-rightPanel-infoSection-checklist.component';

export interface ITaskDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<ITaskDetailResult> {
  taskDetailData: ITaskDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const TaskDetailDrawerTabInfoComponent = (props: ITaskDetailDrawerTabInfoComponentProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Stack direction={'column'} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: 'relative' }}>
        <DetailDrawerTargetComponent
          targetType={props.taskDetailData?.relatedEntityRef as TaskTargetType}
          targetId={props.taskDetailData?.relatedEntity?._id ?? ''}
        />
      </Stack>

      {/* Task Detail */}
      <Stack direction={'column'} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: 'relative' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: 'text.primary' }}>
          {t('crm.task.task.detail')}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={'column'}
              mt={1}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              sx={{ width: '100%', zIndex: 1 }}
            >
              <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-start'}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: 'secondary.main',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icons.TaskRounded sx={{ color: 'primary.lighter', fontSize: 'medium' }} />
                </Box>
                <Stack direction={'column'}>
                  <Typography fontSize={'13px'} fontWeight={600} color={'text.primary'}>
                    {props.taskDetailData?.subject ?? '-'}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack
                  direction={'row'}
                  gap={2}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  ></Box>
                  <Stack direction={'column'}>
                    <Typography fontSize={'12px'} fontWeight={400} color={'text.secondary'}>
                      {props.taskDetailData?.description ?? '-'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <DetailDrawerAsigneesComponent taskDetailData={props.taskDetailData} />
      <DetailDrawerChecklistComponent taskDetailData={props.taskDetailData} />
    </Box>
  );
};
