import {
  SwipeableDrawer,
  Stack,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  size?: 'small' | 'medium' | 'large';
  title: string | JSX.Element;
  children: React.ReactNode;
  handleSubmit?: () => void;
  buttonTitle: string;
  disabled?: boolean;
  loading?: boolean;
  backgroundColor?: string;
};

const ActionDrawer = ({ backgroundColor = 'background.firstWithBlur', ...props }: Props) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const isNewDrawerStyles = ['small', 'medium'].includes(props.size as string);

  return (
    <SwipeableDrawer
      open={props.open}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      anchor={'right'}
      elevation={downMd ? 0 : 16}
      ModalProps={{
        onBackdropClick: () => props.setOpen(false),
        BackdropProps: { invisible: downMd },
      }}
    >
      <Stack
        direction={'column'}
        justifyContent="space-between"
        height="100%"
        width={
          downMd
            ? '100%'
            : props.size
              ? props.size === 'small'
                ? 400
                : props.size === 'medium'
                  ? 400
                  : props.size === 'large'
                    ? 900
                    : 600
              : 600
        }
        bgcolor={backgroundColor}
        sx={{
          backdropFilter: 'blur(5px)',
          height: downMd ? 'calc(100% - 64px)' : '100%',
        }}
      >
        <Grid
          container
          spacing={3}
          bgcolor={backgroundColor}
          sx={{
            borderBottom: 1,
            borderColor: 'secondary.main',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
          m={0}
          width={'100%'}
        >
          <Grid
            item
            xs={12}
            width={'100%'}
            pr={isNewDrawerStyles ? '24px !important' : 0}
            pb={isNewDrawerStyles ? '18px !important' : 3}
          >
            <Stack
              pl={0}
              pb={0}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width={'100%'}
            >
              {typeof props.title === 'string' ? (
                <Typography variant="h5" gutterBottom>
                  {props.title}
                </Typography>
              ) : (
                <Box width={'100%'}>{props.title}</Box>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={isNewDrawerStyles ? 0 : 3} sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <Grid item xs={12}>
            <Stack
              direction="column"
              gap={1}
              p={isNewDrawerStyles ? 0 : 3}
              pt={isNewDrawerStyles ? 0 : 5}
              pb={5}
            >
              <Grid
                container
                gap={isNewDrawerStyles ? 0 : 2}
                sx={
                  isNewDrawerStyles
                    ? {
                        margin: '0 !important',
                        width: '100% !important',
                      }
                    : {}
                }
              >
                {props.children}
                {props.handleSubmit && (
                  <Grid item xs={12} px={2} mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => props.handleSubmit!()}
                      disabled={props?.disabled}
                      sx={{
                        padding: '12px',
                        height: 'auto',
                        lineHeight: 1,
                        borderRadius: '38px',
                      }}
                    >
                      {props.loading ? <CircularProgress size="1rem" /> : props.buttonTitle}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ActionDrawer;
