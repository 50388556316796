import { Add } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import EmptyState from "components/emptyState/EmptyState";


const List = () => {

  const { t } = useTranslation();

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadForm.title`)}
        rightButton={{
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          visibility: true,
          title: t(`crm.lead.leadForm.newForm`),
          onClick: () => null,
          disabled: true
        }}
      />
      <Grid item xs={12} sm={12}>
        <Stack sx={{ height: "400px" }}>
          <EmptyState
            content1={t(`crm.lead.leadForm.emptyStateDesc1`)}
            content2={t(`crm.lead.leadForm.emptyStateDesc2`)}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default List;
