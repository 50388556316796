import leadLocale from '../domains/03-lead/locales/tr';
import dashboardLocale from '../domains/01-dashboard/locales/tr';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/tr';
import customerLocale from '../domains/04-customer/locales/tr';
import pricingLocale from '../domains/16-pricing/locales/tr';
import productsAndServiceLocale from '../domains/08-productAndService/locales/tr';
import preSaleLocale from '../domains/05-preSale/locales/tr';
import saleLocale from '../domains/06-sale/locales/tr';
import calendarLocale from '../domains/12-calendar/locales/tr';
import contractLocale from '../domains/07-contract/locales/tr';
import supportLocale from '../domains/15-support/locales/tr';
import reportLocale from '../domains/13-report/locales/tr';
import taskLocale from '../domains/11-task/locales/tr';
import projectLocale from '../domains/10-project/locales/tr';
import expenseLocale from '../domains/09-expense/locales/tr';
import noteLocale from '../domains/14-note/locales/tr';
import { Edit } from '@mui/icons-material';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  customer: {
    ...customerLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  productAndService: {
    ...productsAndServiceLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },
  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Ön Satış',
  preSales: 'Ön Satışlar',
  contactDetails: 'Detaylar',
  localization: 'Yerelleştirme',
  info: 'Bilgi',
  about: 'Hakkında',
  contact: 'İletişim',
  record: 'Kayıt',
  records: 'Kayıtlar',
  tracking: 'Takip',
  pricingLabel: 'Fiyatlandırma',
  dashboardLabel: 'Gösterge Paneli',
  marketing: 'Pazarlama',
  campaigns: 'Kampanyalar',
  emails: 'E-postalar',
  sms: 'SMS',
  socialMedia: 'Sosyal Medya',
  salesLabel: 'Satışlar',
  businessIntelligence: 'İş Zekası',
  calendarLabel: 'Takvim',
  supportLabel: 'Bilet',
  accountMenu: 'Hesap Menüsü',
  profile: 'Profil',
  myAccount: 'Hesabım',
  logout: 'Çıkış Yap',
  searchPlaceholder: '"Bir şeyler" arayın',

  // --------------------
  // settings related
  // --------------------
  settings: 'Ayarlar',
  english: 'İngilizce',
  turkish: 'Türkçe',
  darkMode: 'Koyu Mod',
  lightMode: 'Açık Mod',
  languageSelectLabel: 'Dil Seç',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Eylemler',
  yes: 'Evet',
  no: 'Hayır',
  importExport: 'İçe/Dışa Aktar',
  view: 'Görüntüle',
  list: 'Listele',
  create: 'Oluştur',
  update: 'Güncelle',
  delete: 'Sil',
  edit: 'Düzenle',
  cancel: 'İptal',
  downloadSuccessful: 'Başarıyla indirildi',
  export: 'Dışa Aktar',
  import: 'İçe Aktar',
  exportData: 'Verileri Dışa Aktar',
  importData: 'Verileri İçe Aktar',
  showMore: 'Daha fazla',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Toplam',
  hasCreatedAt: 'oluşturuldu ',
  createdBy: 'Oluşturan: ',
  updatedBy: 'Güncelleyen: ',
  uploadLogo: 'Logo Yükle',
  selectIconTitle: 'Bir Simge Seç',
  searchIcons: 'Simgeleri Ara',
  uploadPictureText: 'Bir resim yükleyin',
  searchForAnIcon: 'Bir Simge Ara',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Başarıyla silindi',
  deleteEntityContent: 'Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
  deleteEntityTitle: 'Sil',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Organizasyon Oluştur',
  mustCreateOrganizationContent: 'Paneli kullanabilmek için bir organizasyon oluşturmalısınız.',
  mustCreateOrganizationSuccess: 'Organizasyon başarıyla oluşturuldu.',

  // --------------------
  // import export
  // --------------------
  importedError: 'İçe aktarma hatası',
  importedSuccessfully: 'Başarıyla içe aktarıldı',
  downloadExample: 'Örnek İndir',
  downloadFormError: 'Form indirme hatası',
  downloadFormSuccessfully: 'Form başarıyla indirildi',
  downloadExampleFormSuccessfully: 'Örnek form başarıyla indirildi',
  downloadExampleFormError: 'Örnek form indirme hatası',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Tahmin Ekle',
  noEstimate: 'Tahmin yok',
  noEstimateContent1: 'Burada herhangi bir tahmin bulunamadı.',
  additionalInfo: 'Ek Bilgiler',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Teklif Ekle',
  noProposal: 'Teklif yok',
  lastProposal: 'Son Teklif',
  proposalSent: 'Teklif Gönderildi',
  noProposalSent: 'Gönderilmiş teklif yok',
  noProposalContent1: 'Burada herhangi bir teklif bulunamadı.',
  sendEmailOnExpiration: 'Bitiş Tarihi E-postası',

  // --------------------
  // document
  // --------------------
  addDocument: 'Doküman Ekle',
  noDocument: 'Doküman yok',
  noDocumentContent1: 'Burada herhangi bir doküman bulunamadı.',
  testimonialCaseStudies: 'Referans Çalışmalar',
  projectTimeline: 'Proje Zaman Planı',
  executiveSummary: 'Yönetici Özeti',
  costBreakdown: 'Maliyet Ayrımları',
  conclusion: 'Sonuç',
  projectorServiceDesc: 'Proje/Hizmet Açıklaması',

  // --------------------
  // task
  // --------------------
  noTask: 'Görev yok',
  noTaskContent1: 'Burada herhangi bir görev bulunamadı.',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Etkinlik yok',
  addActivity: 'Etkinlik Ekle',
  noActivityContent1: 'Burada herhangi bir etkinlik bulunamadı.',
  noActivityContent2: 'Bu entiteye ait herhangi bir etkinlik bulunamadı.',

  // --------------------
  // note
  // --------------------
  addNote: 'Not Ekle',
  noNote: 'Not yok',
  emptyStateNoteList1: 'Burada herhangi bir not bulunamadı.',
  noteDate: 'Not Tarihi',
  noteUsers: 'Not Kullanıcıları',
  noteTitle: 'Başlık',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Hatırlatıcı Ekle',
  noReminder: 'Hatırlatıcı yok',
  emptyStateReminderList1: 'Burada herhangi bir hatırlatıcı bulunamadı.',
  remindDate: 'Hatırlatma Tarihi',
  remindUsers: 'Hatırlatıcı Kullanıcıları',
  remindTitle: 'Başlık',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Randevu yok',
  makeAnAppointment: 'Randevu Al',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Atananlar',
  noAssignees: 'Atanan Yok',
  addAssignee: 'Atanan Ekle',

  // --------------------
  // follower
  // --------------------
  followers: 'Takipçiler',
  noFollowers: 'Takipçi yok',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Ürün Ekle',
  addProductOrService: 'Ürün veya Hizmet Ekle',
  editProductOrService: 'Ürün veya Hizmeti Düzenle',
  productType: 'Ürün Tipi',
  editProduct: 'Ürün Düzenle',

  // --------------------
  // ticket
  // --------------------
  subject: 'Konu',
  contactName: 'İletişim Adı',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: '/',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leadler',
  leadForms: 'Lead Formları',
  leadStatuses: 'Lead Durumları',
  leadSources: 'Lead Kaynakları',
  customerLabel: 'Müşteri',
  customers: 'Müşteriler',
  estimateLabel: 'Tahmin',
  estimates: 'Tahminler',
  proposalLabel: 'Teklif',
  proposals: 'Teklifler',
  invoiceLabel: 'Fatura',
  invoices: 'Faturalar',
  productsServices: 'Ürünler ve Hizmetler',
  activityLabel: 'Etkinlik',
  activities: 'Etkinlikler',
  noteLabel: 'Not',
  notes: 'Notlar',
  documentLabel: 'Doküman',
  documents: 'Dokümanlar',
  appointmentLabel: 'Randevu',
  appointments: 'Randevular',
  reminderLabel: 'Hatırlatıcı',
  reminders: 'Hatırlatıcılar',
  departmentLabel: 'Departman',
  departments: 'Departmanlar',
  contractLabel: 'Sözleşme',
  contracts: 'Sözleşmeler',
  paymentLabel: 'Ödeme',
  payments: 'Ödemeler',
  organizationalChartLabel: 'Organizasyon Şeması',
  expenseLabel: 'Gider',
  expenses: 'Giderler',
  productAndServiceLabel: 'Ürünler ve Hizmetler',
  projectLabel: 'Proje',
  projects: 'Projeler',
  taskLabel: 'Görev',
  tasks: 'Görevler',
  reportLabel: 'Rapor',
  reports: 'Raporlar',
  billingInformation: 'Fatura Bilgileri',
  otherBillingInformation: 'Diğer Fatura Bilgileri',
  billingInfo: 'Fatura Bilgileri',
  shippingInfo: 'Teslimat Bilgileri',
  contactInformation: 'İletişim Bilgileri',
  otherContactInformation: 'Diğer İletişim Bilgileri',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Renk',
  icon: 'Simge',
  index: 'Dizin',
  title: 'Başlık',
  fullName: 'Tam Ad',
  firstName: 'Ad',
  lastName: 'Soyad',
  surname: 'Soyad',
  convert: 'Dönüştür',
  country: 'Ülke',
  state: 'Şehir',
  city: 'İlçe',
  industry: 'Sektör',
  address: 'Adres',
  website: 'Web Sitesi',
  emailAddress: 'E-posta Adresi',
  name: 'Ad',
  companyName: 'Şirket Adı',
  companyEmail: 'Şirket E-postası',
  companyPhone: 'Şirket Telefonu',
  timezone: 'Zaman Dilimi',
  companyLogo: 'Şirket Logosu',
  language: 'Dil',
  department: 'Departman',
  email: 'E-posta',
  phoneNumber: 'Telefon Numarası',
  type: 'Tür',
  createdAt: 'Oluşturulma Tarihi',
  updatedAt: 'Güncellenme Tarihi',
  prefix: 'Önek',
  tags: 'Etiketler',
  tagsPlaceholder: 'Etiketler',
  company: 'Şirket',
  sector: 'Sektör',
  phone: 'Telefon',
  content: 'İçerik',
  priority: 'Öncelik',
  position: 'Pozisyon',
  status: 'Durum',
  source: 'Kaynak',
  date: 'Tarih',
  expireDate: 'Son Kullanma Tarihi',
  totalPrice: 'Toplam Fiyat',
  currency: 'Para Birimi',
  category: 'Kategori',
  parentCategory: 'Üst Kategori',
  vat: 'Vergi Numarası',
  zip: 'Posta Kodu',
  street: 'Sokak',
  taxOffice: 'Vergi Dairesi',
  target: 'Hedef',
  targetType: 'Hedef Türü',
  description: 'Açıklama',
  birthDate: 'Doğum Tarihi',
  gender: 'Cinsiyet',
  isPrimary: 'Birincil',
  prospectName: 'Aday İsmi',
  prospectAddress: 'Aday Adresi',
  prospectContactName: 'Aday İrtibat',
  prospectType: 'Aday Türü',
  leadValue: 'Potansiyel Değer',
  invoiceId: 'Fatura Numarası',
  paymentId: 'Ödeme Numarası',
  customerName: 'Müşteri Adı',
  customerEmail: 'Müşteri E-postası',
  customerType: 'Müşteri Türü',
  finalPrice: 'Son Fiyat',
  paymentMethod: 'Ödeme Türü',
  confirmationNumber: 'Onay Numarası',
  amoundPaid: 'Ödenecek Tutar',
  refundAmount: 'İade Tutarı',
  isOpen: 'Açık mı',
  resolveReason: 'Çözüm Nedeni',
  disapproveReason: 'Reddetme Nedeni',
  sku: 'Ürün Kodu',
  priceType: 'Fiyat Türü',
  price: 'Fiyat',
  discountedPrice: 'İndirimli Fiyat',
  unit: 'Birim',
  unitPrice: 'Birim Fiyatı',
  natTaxRate: 'Yerel Vergi',
  intTaxRate: 'Uluslararası Vergi',
  selectRoles: 'Roller',
  timeZone: 'Zaman Dilimi',
  prospect: 'Aday',
  prospectTargetEntityRef: 'Aday Hedef Türü',
  prospectTarget: 'Hedef Aday',
  prospectVAT: 'Vergi Numarası',
  prospectPhoneNumber: 'Aday Telefon',
  prospectZIP: 'Aday Posta Kodu',
  contactFirstName: 'İletişim Adı',
  contactLastName: 'İletişim Soyadı',
  contactEmail: 'İletişim E-postası',
  expiration: 'Son Kullanma Tarihi',
  detailedInformation: 'Detaylı Bilgi',
  projectOrServiceDesc: 'Proje/Hizmet Açıklaması',
  scopeOfWork: 'İş Kapsamı',
  assumptionsAndExclusions: 'Varsayımlar ve Hariç Tutmalar',
  paymentTerms: 'Ödeme Koşulları',
  products: 'Ürünler',
  productName: 'Ürün Adı',
  productDescription: 'Ürün Açıklaması',
  productUnit: 'Ürün Birimi',
  quantity: 'Miktar',
  tax: 'Vergi',
  extraInformation: 'Ek Bilgiler',
  offers: 'Teklifler',
  reference: 'Referans',
  allowComments: 'Yorumlara İzin Ver',
  totally: 'Toplam',
  subTotal: 'Ara Toplam',
  taxTotal: 'Vergi Toplamı',
  extraDiscount: 'Ekstra İndirim',
  salesAgents: 'Satış Temsilcileri',
  legal: 'Hukuki',
  useLegalNDA: 'Gizlilik Anlaşması Kullan',
  useLegalTermAndCond: 'Hukuki Şartlar ve Koşulları Kullan',
  signature: 'İmza',
  signerUser: 'İmzalayan Kullanıcı',
  generalInfo: 'Genel Bilgiler',
  startDate: 'Başlangıç Tarihi',
  dueDate: 'Bitiş Tarihi',
  expirationEmailSentDate: 'Son E-Posta Tarihi',
  allowedPaymentMethods: 'Ödeme Türleri',
  sendEmail: 'E-Posta Gönder',
  identityNumber: 'Kimlik Numarası',
  isRecurring: 'Tekrarlayan',
  recurringCount: 'Tekrar Sayısı',
  recurringFrequency: 'Tekrarlayan Frekans',
  recurringDueDate: 'Tekrarlayan Bitiş Tarihi',
  paymentID: 'Ödeme Kimliği',
  paymentPrefix: 'Ödeme Ön Eki',
  paymentDate: 'Ödeme Tarihi',
  amountPaid: 'Ödenen Tutarı',
  invoiceID: 'Fatura Kimliği',
  targetDetail: 'Hedef Detayı',
  productsAndServices: 'Ürünler ve Hizmetler',
  popularSearch: 'Popüler Aramalar',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Lead Oluştur',
  leadList: 'Lead Listesi',
  leadImport: 'Lead İçe Aktar',
  customerCreate: 'Müşteri Oluştur',
  customerList: 'Müşteri Listesi',
  estimateCreate: 'Teklif Oluştur',
  proposalCreate: 'Öneri Oluştur',
  invoiceList: 'Fatura Listesi',
  paymentList: 'Ödeme Listesi',
  returnList: 'İade Listesi',
  contractCreate: 'Sözleşme Oluştur',
  productList: 'Ürün Listesi',
  serviceCreate: 'Hizmet Oluştur',
  expenseList: 'Masraf Listesi',
  projectList: 'Proje Listesi',
  taskCreate: 'Görev Oluştur',
  reminderCreate: 'Hatırlatma Oluştur',
  appointmentCreate: 'Randevu Oluştur',
  noteCreate: 'Not Oluştur',
  ticketList: 'Talep Listesi',
  noResultsFound: "Sonuç bulunamadı",
  trySearching: 'Bunları deneyebilirsiniz',
};

export default locale;
