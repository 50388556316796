

import { ContractsDomainLayout } from "../layouts";
import { ContractsRouter } from "../subdomains/contract/routes/ContractsRouter";
import { contractsDomainRoutes } from "./contracts.base.route";
import { Route, Routes } from "react-router-dom";


export const ContractsDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContractsDomainLayout />}>
        <Route index element={<ContractsRouter />} />
        <Route path="*" element={<ContractsRouter />} />
      </Route>
    </Routes>
  );
};
