import { Grid } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useProductListQuery } from '../../../common/context/productsService.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ServiceListComponent from './ServiceList-grid.component';
import ServiceCreateWrapper from '../create/ServiceCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';

const ProductList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openServiceCreateDrawer, setOpenServiceCreateDrawer] = useState(false);

  // queries
  const { data: productListData, isLoading: productListLoading } = useProductListQuery({});

  let totalProductCount = productListData?.count ?? 0;
  let isEmpty = totalProductCount === 0;


  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenServiceCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.productAndService.service.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.productAndService.service.create`),
          onClick: () => setOpenServiceCreateDrawer(true),
        }}
      />

      <ServiceListComponent setOpenServiceCreateDrawer={setOpenServiceCreateDrawer} />

      <ServiceCreateWrapper open={openServiceCreateDrawer} setOpen={setOpenServiceCreateDrawer} />
    </Grid>
  );
});

export default ProductList;
