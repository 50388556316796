import { IFilter, Language } from "corede-common";
import { t } from "i18next";
import { handleDownloadList } from "apps/crm/util/handleDownload.action";

export const handleDownloadLeadList = (params: {
  filter?: IFilter;
  currentLanguage: Language;
}) => {
  handleDownloadList({
    filePrefix: "leads",
    downloadUrl: "leads/export",
    currentLanguage: params.currentLanguage,
    filter: params.filter,
    successMessage: t("crm.lead.lead.downloadFormSuccessfully"),
  });
};
