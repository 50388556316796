import { IEstimateListItemResult } from 'corede-common-cocrm';
import { Box, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { Iconify } from 'components';

interface IEstimateListItemProps {
  estimate: IEstimateListItemResult;
}

const EstimateListItemComponent = (props: IEstimateListItemProps) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack
      direction="column"
      gap={1.5}
      p={3}
      sx={{
        width: '100%',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: '24px',
        boxSizing: 'border-box',
      }}
    >
      <Stack
        direction={downSm ? 'column' : 'row'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Stack direction={'row'} alignItems={'flex-start'} gap={1.5}>
          <Box
            sx={{
              width: 42,
              height: 42,
              bgcolor: 'secondary.extraLight',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify icon="mingcute:user-star-fill" width={24} sx={{ color: 'secondary.main' }} />
          </Box>

          <Box>
            <Typography variant={downSm ? 'body1' : 'h5'} sx={{ fontWeight: 500 }}>
              Bensu Kayacan
            </Typography>

            <Typography
              variant={downSm ? 'body2' : 'subtitle2'}
              sx={{ fontWeight: 500, color: 'text.secondary' }}
            >
              Customer
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: 'success.extraLight',
              borderRadius: '48px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px',
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 500, color: 'success.main' }}>
              Individual
            </Typography>
          </Box>
        </Stack>

        <Stack alignItems={downSm ? 'flex-end' : 'flex-start'}>
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            <strong style={{ color: 'text.primary' }}>Estimate Date: </strong> 18/8/21
          </Typography>

          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            <strong style={{ color: 'text.primary' }}>Expiration Date: </strong> 18/8/21
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={downSm ? 'column' : 'row'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          px={2}
          py={1}
          gap={1.5}
          sx={{ backgroundColor: downSm ? 'transparent' : '#23202005', borderRadius: '6px' }}
        >
          <Box>
            <Typography variant={downSm ? 'body1' : 'h5'} sx={{ fontWeight: 500 }}>
              Status
            </Typography>

            <Typography
              variant={downSm ? 'body2' : 'subtitle2'}
              sx={{ fontWeight: 500, color: 'text.secondary' }}
            >
              Sales Agent
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: 'tertiary.extraLight',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px',
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 500, color: 'tertiary.main' }}>
              Qualified
            </Typography>
          </Box>
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          gap={5}
          sx={{
            bgcolor: 'secondary.extraLight',
            borderRadius: '60px',
            padding: '8px 24px',
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ color: 'secondary.main' }}>
              $24.8k
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Final Price
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: 'secondary.main',
              borderRadius: '50%',
              width: 46,
              height: 46,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="solar:tag-price-broken"
              width={24}
              sx={{ color: 'secondary.contrastText' }}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EstimateListItemComponent;
