import { useState, lazy, Suspense } from 'react';
import { IReminderUpdateOverlay } from './ReminderUpdateOverlay';

const ReminderCreateOverlay = lazy(() => import('./ReminderUpdateOverlay'));

const ReminderCreateOverlayWrapper = ({
  open,
  setOpen,
  targetId,
  targetType,
  reminderId,
  overlayType,
}: IReminderUpdateOverlay) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ReminderCreateOverlay
            open={open}
            setOpen={setOpen}
            targetId={targetId}
            targetType={targetType}
            reminderId={reminderId}
            overlayType={overlayType}
          />
        </Suspense>
      )}
    </>
  );
};

export default ReminderCreateOverlayWrapper;
