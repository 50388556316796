import { Grid, InputLabel } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export const DefaultDatePickerInput = (props: {
  inputName: `input.${string}`;
  control: Control<any>;
  label: string;
  isRequired?: boolean;
  errors: FieldErrors<any>;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
}) => {
  return (
    <Grid item xs={12}>
      <InputLabel
        htmlFor={props.inputName}
        sx={{ ml: 1, mb: 0.5, fontSize: "small" }}
      >
        {props.label}
        {props.isRequired ? "*" : ""}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <DatePicker
            {...field}
            value={field.value ? moment(field.value) : null}
            onChange={(date) => field.onChange(date)}
            minDate={props.minDate || moment()} 
            maxDate={props.maxDate}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                error: !!props.errors[props.inputName],
                helperText: props.errors[props.inputName]?.message as string,
              },
            }}
          />
        )}
      />
    </Grid>
  );
};
