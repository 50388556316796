import { Grid } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoteListComponent from './NoteList-grid.component';
import { DefaultTabsForListComponent } from 'apps/crm/components/tabs/DefaultTabs.forList.component';
import { NoteTargetType } from 'corede-common-cocrm';
import { useUserDetailOwnQuery } from 'apps/auth/context';

export type TDefaultTargetDataForCreate = {
  targetType?: NoteTargetType;
  targetId?: string;
};

const NoteList = memo(() => {
  // general
  const { t } = useTranslation();
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  useEffect(() => {
    setUserId(userDetailOwnData?._id);
    setOrganizationId(userDetailOwnData?.organization?._id);
  }, [userDetailOwnData]);


  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <DefaultTabsForListComponent>
        <NoteListComponent
          tabKey="shared"
          name="crm.note.note.sharedList"
          targetType={NoteTargetType.Organization}
          targetId={organizationId}
          canCreate={true}
        />
        <NoteListComponent
          tabKey="private"
          name="crm.note.note.privateList"
          targetType={NoteTargetType.User}
          targetId={userId}
          canCreate={true}
        />
        <NoteListComponent
          tabKey="own"
          name="crm.note.note.ownList"
          createdById={userId}
          canCreate={false}
        />
        <NoteListComponent tabKey="all" name="crm.note.note.allList" canCreate={false} />
      </DefaultTabsForListComponent>
    </Grid>
  );
});

export default NoteList;
