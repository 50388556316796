import { useState, lazy, Suspense } from 'react';


const CustomerCreateDrawer = lazy(() => import('./CustomerCreateDrawer'));


const CustomerCreateDrawerWrapper = ({ 
  open, 
  setOpen,
  setOpenDepartments
  }: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <CustomerCreateDrawer 
                    open={open} 
                    setOpen={setOpen}
                    setOpenDepartments={setOpenDepartments}
                     />
                </Suspense>
            )}
        </>
    );
};

export default CustomerCreateDrawerWrapper;