/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@mui/material';
import { IBaseError, IHasId } from 'corede-common';
import {
  IBaseTaskEntity,
  ITask,
  ITaskDetailResult,
  ITaskListItemResult,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
// import { useTaskDeleteMutation } from "../context/tasks.api";
import ActionDialog from 'components/dialog/ActionDialog';
import { useTaskDeleteMutation } from '../context/task.api';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export const TaskDeleteDialog = memo(
  (prop: {
    open: boolean;
    selectedTask: ITaskDetailResult & IHasId<number>;
    setItem: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
    onClose: () => void;
  }) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries

    // mutations
    const [
      taskDelete,
      { data: taskDeleteData, isLoading: taskDeleteIsLoading, error: taskDeleteError },
    ] = useTaskDeleteMutation();

    // constants

    // // action handlers
    const handleDelete = (_id: string) => {
      taskDelete({
        input: {
          _id: _id,
        },
      });
    };

    // useEffects.success
    useEffect(() => {
      if (taskDeleteData) {
        prop?.onClose();
        enqueueSnackbar(t('crm.task.task.deletedSuccessfully'), {
          variant: 'success',
        });
        prop.setItem(undefined);
      }
    }, [taskDeleteData]);

    // // useEffects.error
    useEffect(() => {
      DefaultErrorHandlerUseEffect(taskDeleteError as IBaseError, currentLanguage);
    }, [taskDeleteError, currentLanguage]);

    // useEffects.init

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.task.task.deleteTask')}
        buttonTitle={t('delete')}
        handleClick={() => handleDelete(prop?.selectedTask?._id as string)}
        disabled={taskDeleteIsLoading}
        loading={taskDeleteIsLoading}
        buttonColor="error"
        width={500}
      >
        <Grid container spacing={2} my={2}>
          <Grid item xs={12}>
            <Typography>{t('crm.task.task.deleteTaskContent')}</Typography>
          </Grid>
        </Grid>
      </ActionDialog>
    );
  },
);
