import * as Icons from '@mui/icons-material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { t } from 'i18next';
import { useState } from 'react';
import {
  representArrayOfObjectAsString,
  representBooleanAsString,
  representDateAsString,
  representString,
} from 'utils/representationHelper';
import { ITask, ITaskDetailResult, TaskTargetType } from 'corede-common-cocrm';
import { TaskDeleteDialog } from '../TaskDeleteDialog';
import TaskUpdateDialog from '../update/TaskUpdateOverlay';
import TaskDetailDrawer from '../detailDrawer/TaskDetailDrawer';
import { useTaskListQuery } from '../../context/task.api';
import TaskCreateDialog from '../create/TaskCreateOverlay';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IEntity, IHasId } from 'corede-common';
import { OverlayType } from 'components/dialog/ActionDialog';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { Stack, Typography, Button, useMediaQuery, Theme } from '@mui/material';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import TaskListItemComponent from './TaskListItem';
import { AddCircle } from '@mui/icons-material';

export interface ITaskListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  targetId: string;
  targetEntityRef: TaskTargetType;
  tabKey: 'tasks';
  name: 'crm.tasks';
  overlayType?: OverlayType;
}

const TaskListTabComponent = (props: ITaskListTabSectionComponent) => {
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [openDeleteTask, setOpenDeleteTask] = useState<boolean>(false);
  const [openUpdateTask, setOpenUpdateTask] = useState<boolean>(false);
  const [openDetailTask, setOpenDetailTask] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();

  const { data: taskListData, isLoading: taskListLoading } = useTaskListQuery(
    {
      input: {
        filter: {
          relatedEntityId: props.targetId,
          relatedEntityRef: props.targetEntityRef,
        },
      },
    },
    {
      skip: !props.targetId,
    },
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <DefaultTabContainer
      title={''}
      rightButton={
        taskListData && taskListData?.count > 0
          ? {
              title: t('crm.task.task.create'),
              onClick: () => setOpenCreateTask(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
            }
          : undefined
      }
    >
          {/* <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Upcoming Activity
          </Typography> */}

          {taskListData && taskListData?.count === 0 && (
            <EmptyState
              icon={
                <img
                  src={DocumentEmptyIcon}
                  style={{
                    width: '180px',
                    height: '180px',
                    marginBottom: '30px',
                  }}
                  alt="There is no task yet."
                />
              }
              content1={t('crm.task.task.emptyState1')}
              // content2={t('crm.task.task.emptyState2')}
              button1={{
                leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
                title: t('crm.task.task.addTask'),
                onClick: () => setOpenCreateTask(true),
              }}
            />
          )}

          {taskListData && taskListData?.count > 3 && (
            <BaseDataGrid
              rows={taskListData?.data?.map((task, index) => ({
                id: index + 1,
                _id: task?._id,
                subject: representString(task?.subject),
                description: representString(task?.description),
                startDate: representDateAsString(task?.startDate),
                dueDate: representDateAsString(task?.dueDate),
                assigneeIds: representArrayOfObjectAsString({ dataList: task?.assignees }),
                followerIds: representArrayOfObjectAsString({ dataList: task?.followers }),
                // checklist
                department: representString(task?.department?._id),
                priority: representString(task?.priority),
                tags: representArrayOfObjectAsString({ dataList: task?.tags }),
                hourlyRate: representString(task?.hourlyRate?.toFixed(2)),
                hourlyRateCurrency: representString(task?.hourlyRateCurrency),
                isBillable: representBooleanAsString({
                  data: task.isBillable,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                isRecurrent: representBooleanAsString({
                  data: task.isRecurrent,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                repeatDays: representString(task?.repeatDays.toFixed(0)),
                totalCycle: representString(task?.totalCycle.toFixed(0)),
              }))}
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: '#',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'subject',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.subject'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      width: 200,
                      field: 'description',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.description'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'startDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.startDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'dueDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.dueDate'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenDetailTask,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenUpdateTask,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenDeleteTask,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={taskListLoading}
              listFilter={{
                filterInput: {},
                setFilterInput: () => null,
              }}
              count={taskListData?.count ?? 0}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              update={{
                updateQuery: () => null,
              }}
              disableColumnFilter={true}
              hideFooterPagination={true}
              sortingMode={'client'}
            />
          )}

          {taskListData && taskListData?.count > 0 && taskListData?.count < 4 && (
            <Stack direction={'column'} gap={1.5} sx={{ width: '100%' }}>
              {taskListData?.data?.map((task, index) => {
                return <TaskListItemComponent key={index} task={task} />;
              })}
            </Stack>
          )}

          {/* <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Activity History
          </Typography> */}

          {/* {taskListData && taskListData?.count === 0 && (
            <EmptyState
              icon={
                <img
                  src={DocumentEmptyIcon}
                  style={{
                    width: '180px',
                    height: '180px',
                    marginBottom: '30px',
                  }}
                  alt="There is no task yet."
                />
              }
              content1={t('crm.task.task.emptyState1')}
              content2={t('crm.task.task.emptyState2')}
              button1={{
                leftIcon: <Icons.Add sx={{ mr: 1 }} fontSize="small" />,
                title: t('crm.task.task.addTask'),
                onClick: () => setOpenCreateTask(true),
              }}
            />
          )} */}

          {/* {taskListData && taskListData?.count > 3 && (
            <BaseDataGrid
              rows={taskListData?.data?.map((task, index) => ({
                id: index + 1,
                _id: task?._id,
                subject: representString(task?.subject),
                description: representString(task?.description),
                startDate: representDateAsString(task?.startDate),
                dueDate: representDateAsString(task?.dueDate),
                assigneeIds: representArrayOfObjectAsString({ dataList: task?.assignees }),
                followerIds: representArrayOfObjectAsString({ dataList: task?.followers }),
                // checklist
                department: representString(task?.department?._id),
                priority: representString(task?.priority),
                tags: representArrayOfObjectAsString({ dataList: task?.tags }),
                hourlyRate: representString(task?.hourlyRate?.toFixed(2)),
                hourlyRateCurrency: representString(task?.hourlyRateCurrency),
                isBillable: representBooleanAsString({
                  data: task.isBillable,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                isRecurrent: representBooleanAsString({
                  data: task.isRecurrent,
                  trueValue: t('crm.task.task.yes'),
                  falseValue: t('crm.task.task.no'),
                }),
                repeatDays: representString(task?.repeatDays.toFixed(0)),
                totalCycle: representString(task?.totalCycle.toFixed(0)),
              }))}
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: '#',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'subject',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.subject'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      width: 200,
                      field: 'description',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.description'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'startDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.startDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'dueDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.task.task.dueDate'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenDetailTask,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenUpdateTask,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedTask,
                      setOpenAction: setOpenDeleteTask,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={taskListLoading}
              listFilter={{
                filterInput: {},
                setFilterInput: () => null,
              }}
              count={taskListData?.count ?? 0}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              update={{
                updateQuery: () => null,
              }}
              disableColumnFilter={true}
              hideFooterPagination={true}
              sortingMode={'client'}
            />
          )} */}

          {/* {taskListData && taskListData?.count > 0 && taskListData?.count < 4 && (
            <Stack direction={'column'} gap={1.5}>
              {taskListData?.data?.map((task, index) => {
                return <TaskListItemComponent key={index} task={task} />;
              })}
            </Stack>
          )} */}

      <TaskCreateDialog
        open={openCreateTask}
        setOpen={setOpenCreateTask}
        targetId={props.targetId}
        targetEntityRef={props.targetEntityRef}
        overlayType={props.overlayType ?? OverlayType.dialog}
      />

      <TaskDeleteDialog
        open={openDeleteTask}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        onClose={() => setOpenDeleteTask(false)}
        setItem={setSelectedTask}
      />

      <TaskUpdateDialog
        item={selectedTask}
        open={openUpdateTask}
        setOpen={setOpenUpdateTask}
        targetId={props.targetId}
        targetEntityRef={props.targetEntityRef}
        overlayType={props.overlayType ?? OverlayType.dialog}
      />

      <TaskDetailDrawer
        setSelectedTask={setSelectedTask}
        open={openDetailTask}
        setOpen={setOpenDetailTask}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
      />
    </DefaultTabContainer>
  );
};

export default TaskListTabComponent;
