/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import ActionDialog from 'components/dialog/ActionDialog';
import { IBaseError } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

interface IEntityDeleteDialogProps {
  open: boolean;
  itemId: string;
  setItem?: Dispatch<any>;
  deleteMutation: any;
  deleteMutationData: {
    data: any;
    isLoading: any;
    error: IBaseError;
  };
  onClose: () => void;
  dialog?: {
    title?: string;
    successMessage?: string;
    content?: string;
  };
}

export const EntityDeleteDialog = memo((props: IEntityDeleteDialogProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // constants

  // action handlers
  const handleDelete = (id: string) => {
    props.deleteMutation({ input: { _id: id } });
  };

  // useEffects.success
  useEffect(() => {
    if (props.deleteMutationData.data) {
      props.setItem?.(undefined);
      props?.onClose();
      enqueueSnackbar(props.dialog?.successMessage ?? t('crm.deletedEntitySuccessfully'), {
        variant: 'success',
      });
    }
  }, [props.deleteMutationData.data]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(props.deleteMutationData.error, currentLanguage);
  }, [props.deleteMutationData.error, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={props?.open}
      onClose={props?.onClose}
      title={props.dialog?.title ?? t('crm.deleteEntityTitle')}
      buttonTitle={t('delete')}
      handleClick={() => handleDelete(props?.itemId)}
      disabled={props.deleteMutationData.isLoading}
      loading={props.deleteMutationData.isLoading}
      buttonColor="error"
      width={500}
    >
      <Grid item xs={12}>
        <Typography>{props.dialog?.content ?? t('crm.deleteEntityContent')}</Typography>
      </Grid>
    </ActionDialog>
  );
});
