/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useProductDeleteMutation } from '../../../common/context/productsService.api';
import { IBaseError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { IBaseProductEntity } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';
import { Grid, Stack } from '@mui/material';

export const ServiceDeleteDialog = (prop: {
  open: boolean;
  item: IBaseProductEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [
    productStatusDelete,
    {
      data: productStatusDeleteData,
      isLoading: productStatusDeleteLoading,
      error: productStatusDeleteError,
    },
  ] = useProductDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    productStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (productStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t('crm.productAndService.service.deletedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [productStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(productStatusDeleteError as IBaseError, currentLanguage);
  }, [productStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t('crm.productAndService.product.delete')}
      handleClick={() => handleDelete(prop?.item?._id as string)}
      loading={productStatusDeleteLoading}
      disabled={productStatusDeleteLoading}
      buttonTitle={t('delete')}
      buttonColor="error"
    >
      <Grid item xs={12}>
        {t('crm.productAndService.service.deleteConfirm')} {prop?.item?.name}?
      </Grid>
    </ActionDialog>
  );
};
