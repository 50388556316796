import {
  IProductCategoryListInput,
  IProductCategoryListResult,
  IProductCategoryListRequest,
  IProductCategoryListResponse,
  productCategoryListQuery,
  IProductCategoryUpdateInput,
  IProductCategoryUpdateRequest,
  IProductCategoryUpdateResponse,
  IProductCategoryUpdateResult,
  productCategoryUpdateQuery,
  IProductCategoryUpdateFilterInput,
  IProductCategoryCreateInput,
  IProductCategoryCreateRequest,
  IProductCategoryCreateResponse,
  IProductCategoryCreateResult,
  productCategoryCreateQuery,
  IProductCategoryDeleteInput,
  IProductCategoryDeleteRequest,
  IProductCategoryDeleteResponse,
  IProductCategoryDeleteResult,
  productCategoryDeleteQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';

import { IGraphqlVariables } from 'corede-common';
import { productCategoryTags } from './productCategory.tags';

export const productCategoryListApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    productCategoryList: builder.query<
      IProductCategoryListResult,
      IGraphqlVariables<IProductCategoryListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductCategoryListRequest,
        IProductCategoryListResponse,
        IProductCategoryListResult,
        IProductCategoryListInput
      >({
        query: productCategoryListQuery,
        providesTags: [
          productCategoryTags.productCategoryList,
          productCategoryTags.productCategory,
        ],
      }),
    ),

    // mutation
    productCategoryUpdate: builder.mutation<
      IProductCategoryUpdateResult,
      IGraphqlVariables<IProductCategoryUpdateInput, IProductCategoryUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductCategoryUpdateRequest,
        IProductCategoryUpdateResponse,
        IProductCategoryUpdateResult,
        IProductCategoryUpdateInput,
        IProductCategoryUpdateFilterInput
      >({
        query: productCategoryUpdateQuery,
        invalidatesTags: [
          productCategoryTags.productCategory,
          productCategoryTags.productCategoryList,
        ],
      }),
    ),

    productCategoryCreate: builder.mutation<
      IProductCategoryCreateResult,
      IGraphqlVariables<IProductCategoryCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductCategoryCreateRequest,
        IProductCategoryCreateResponse,
        IProductCategoryCreateResult,
        IProductCategoryCreateInput
      >({
        query: productCategoryCreateQuery,
        invalidatesTags: [
          productCategoryTags.productCategory,
          productCategoryTags.productCategoryList,
        ],
      }),
    ),

    productCategoryDelete: builder.mutation<
      IProductCategoryDeleteResult,
      IGraphqlVariables<IProductCategoryDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductCategoryDeleteRequest,
        IProductCategoryDeleteResponse,
        IProductCategoryDeleteResult,
        IProductCategoryDeleteInput
      >({
        query: productCategoryDeleteQuery,
        invalidatesTags: [
          productCategoryTags.productCategoryList,
        ],
      }),
    ),
  }),
});

export const {
  useProductCategoryListQuery,
  useLazyProductCategoryListQuery,

  useProductCategoryUpdateMutation,
  useProductCategoryCreateMutation,
  useProductCategoryDeleteMutation,
} = productCategoryListApi;
