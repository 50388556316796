import { IProductCategory } from 'corede-common-cocrm';
import { useState, lazy, Suspense } from 'react';

const ProductCategoryUpdateDrawer = lazy(() => import('../update/ProductCategoryUpdateDrawer'));

const CategoryUpdateWraper = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRow: IProductCategory;
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ProductCategoryUpdateDrawer
            open={props.open}
            setOpen={props.setOpen}
            selectedRow={props.selectedRow}
          />
        </Suspense>
      )}
    </>
  );
};

export default CategoryUpdateWraper;
