/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { InvoiceDetailDrawerHeaderStatusComponent } from './InvoiceDetailDrawer-rightPanel-header-status.component';
// import { InvoiceDetailDrawerBaseInfoComponent } from './InvoiceDetailDrawer-rightPanel-header-baseInfo.component';

export interface IInvoiceDetailDrawerRightPanelHeaderComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const InvoiceDetailDrawerRightPanelHeaderComponent = (
  props: IInvoiceDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <Stack
      direction={'column'}
      gap={1}
      width={'100%'}
      sx={{
        borderBottom: '2px solid',
        borderColor: 'grey.400',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <InvoiceDetailDrawerHeaderStatusComponent invoiceDetailData={props.invoiceDetailData} />
      {/* <InvoiceDetailDrawerBaseInfoComponent invoiceDetailData={props.invoiceDetailData} /> */}
    </Stack>
  );
};
