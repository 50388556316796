/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
} from "@mui/icons-material";
import {
  Box,
  Card,
} from "@mui/material";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import EmptyState from "components/emptyState/EmptyState";
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from "corede-common";
import {
  ILeadStatus,
  ILeadStatusesInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import {
  useLeadStatusListQuery,
  useLeadStatusUpdateMutation,
} from "../../context/leadStatus.api";
import { LeadStatusDeleteDialog } from "../LeadStatusDeleteDialog";
import LeadStatusUpdateDrawerWrapper from "../update/LeasStatusUpdateDrawer.wrapper";
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from "apps/crm/components/list/DefaultList-grid.component";
import {
  IPendingNavigation,
} from "apps/crm/util/handleSideNavigationNavigate.action";

export interface ILeadStatusListGridComponent {
  // leadStatus
  setOpenLeadStatusCreateDrawer?: (value: boolean) => void | undefined;
}

const LeadStatusListGridComponent = memo((props: ILeadStatusListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedLeadStatus, setSelectedLeadStatus] = useState<TListGridSelectedEntity<ILeadStatus>>();
  const [openLeadStatusDeleteDialog, setOpenLeadStatusDeleteDialog] = useState(false);
  const [openLeadStatusUpdateDrawer, setOpenLeadStatusUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] =
    useState<IPendingNavigation | null>(null);

  const [leadStatusListFilter, setLeadStatusListFilter] = useState<
    IGraphqlVariables<ILeadStatusesInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10, sort: { "index": 1  } },
    },
  });

  // queries
  const {
    data: leadStatusListData,
    isLoading: leadStatusListLoading,
    isFetching: leadStatusListFetching,
    error: leadStatusListError,
    refetch,
  } = useLeadStatusListQuery(leadStatusListFilter);

  // used for direct update in the list rows
  const [leadStatusUpdate, { isLoading: leadStatusUpdateLoading, error: leadStatusUpdateError }] =
    useLeadStatusUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setLeadStatusListLoading && props.setLeadStatusListLoading(leadStatusListLoading);
  // }, [leadStatusListLoading]);
  useEffect(() => {
    refetch();
  }, [leadStatusListFilter]);


  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadStatusListError as IBaseError, currentLanguage);
  }, [leadStatusListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadStatusUpdateError as IBaseError,
      currentLanguage
    );
  }, [leadStatusUpdateError]);

  const isEmptyState =
    leadStatusListData?.data?.length === 0 &&
    !leadStatusListLoading &&
    !leadStatusListFetching &&
    isNullOrUndefinedOrEmptyObject(leadStatusListFilter?.input?.filter);

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedLeadStatus}
      setSelectedEntity={setSelectedLeadStatus}
      listFilter={leadStatusListFilter}
      setListFilter={setLeadStatusListFilter}
      listData={leadStatusListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card
        sx={{ bgcolor: "background.secondWithBlur", p: "0px", height: "100%" }}
      >
        {leadStatusListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                leadStatusListData?.data?.map((leadStatus, index) => ({
                  id: leadStatus.index,
                  index: leadStatus.index,
                  _id: leadStatus._id,
                  name: leadStatus.name,
                  icon: leadStatus.icon,
                  color: leadStatus.color,
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "id",
                      disableColumnMenu: true,
                      filterable: false,
                      width: 90,
                      align: "center",
                    },
                    headerConfig: {
                      name: "Index",
                      align: "center",
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "name",
                      filterable: true,
                      editable: true,
                      width: 200,
                    },
                    headerConfig: {
                      icon: "radix-icons:text",
                      name: t("crm.name"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.icon,
                    column: {
                      field: "icon",
                      editable: false,
                      filterable: false,
                      align: "left",
                      width: 100,
                    },
                    headerConfig: {
                      icon: "tdesign:icon",
                      name: t("crm.icon"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.color,
                    column: {
                      field: "color",
                      editable: false,
                      filterable: false,
                      align: "left",
                      width: 100,
                    },
                    headerConfig: {
                      icon: "ic:outline-color-lens",
                      name: t("crm.color"),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadStatus,
                      setOpenAction: setOpenLeadStatusUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadStatus,
                      setOpenAction: setOpenLeadStatusDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t("crm.actions"),
              }}
              loading={leadStatusListLoading || leadStatusUpdateLoading || leadStatusListFetching}
              listFilter={{
                filterInput: leadStatusListFilter,
                setFilterInput: setLeadStatusListFilter,
              }}
              update={{
                updateQuery: leadStatusUpdate,
              }}
              count={leadStatusListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  leadStatusValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t("crm.lead.leadStatus.emptyState1")}
              content2={t("crm.lead.leadStatus.emptyState2")}
              button1={{
                title: t("crm.lead.leadStatus.createLeadStatus"),
                onClick: () =>
                  (props?.setOpenLeadStatusCreateDrawer ?? (() => { }))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: "100%", height: "10px" }} />

      <LeadStatusUpdateDrawerWrapper
        open={openLeadStatusUpdateDrawer}
        setOpen={setOpenLeadStatusUpdateDrawer}
        selectedLeadStatus={selectedLeadStatus}
      />

      <LeadStatusDeleteDialog
        open={openLeadStatusDeleteDialog}
        itemId={selectedLeadStatus?._id ?? ""}
        setItem={setSelectedLeadStatus}
        onClose={() => {
          setSelectedLeadStatus(undefined);
          setOpenLeadStatusDeleteDialog(false);
        }}
        key={selectedLeadStatus?._id}
      />
    </DefaultListGridComponent>
  );
});

export default LeadStatusListGridComponent;
