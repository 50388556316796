import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateUpdateDemoInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      // TODO: add field validations for update
    }),
  });



export const validateGeneralFormInput =
  ValidationHelper.BuildObjectValidationSchema({
    // input: ValidationHelper.BuildObjectValidationSchema({
    //   email: ValidationHelper.RequiredEmail(
    //     getTranslatedText("emailIsInvalid"),
    //     getTranslatedText("emailIsRequired")
    //   ),
    //   website: ValidationHelper.NotRequiredWebSite(),
    //   socialMedias : ValidationHelper.BuildObjectValidationSchema({
    //     facebook: ValidationHelper.NotRequiredWebSite(),
    //     x: ValidationHelper.NotRequiredWebSite(),
    //     linkedin: ValidationHelper.NotRequiredWebSite(),
    //     instagram: ValidationHelper.NotRequiredWebSite(),
    //     youtube: ValidationHelper.NotRequiredWebSite(),
    //   })
    // }),
  });