import { ExpandCircleDown, MoreVert, Visibility } from '@mui/icons-material';
import { Avatar, Card, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { IFileFolderListItemResult, IFileFolderListResult } from 'corede-common-cocrm';
import EmptyState from 'components/emptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import FolderIcon from 'assets/icons/ic-folder.svg';
import { FileTargetType } from 'components/uploadFile/UploadDocument';
import { useOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const FolderGridList = (props: {
  folderData: IFileFolderListResult | undefined;
  setSelectedFolder: (folder: FileTargetType) => void;
}) => {
  const [showFiles, setShowFiles] = useState(true);

  return (
    <Grid container width={'100%'} mt={2}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'column'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
              <Typography fontSize={'16px'} fontWeight={600}>
                {'Folders'}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
                {props.folderData?.folders?.length} folders
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <IconButton color="primary" size="small" onClick={() => setShowFiles(!showFiles)}>
              <ExpandCircleDown
                fontSize="medium"
                sx={{ transform: showFiles ? 'rotate(0deg)' : 'rotate(180deg)' }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid container mt={2} mb={4} spacing={2} sx={{ display: showFiles ? 'flex' : 'none' }}>
        {props.folderData && props.folderData?.folders?.length > 0 ? (
          props.folderData?.folders.map((folder, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <FolderGridListCard folder={folder} setSelectedFolder={props.setSelectedFolder} />
            </Grid>
          ))
        ) : (
          <EmptyState content1={'No files found'} content2={'No files found'} />
        )}
      </Grid>
    </Grid>
  );
};

export default FolderGridList;

export const FolderGridListCard = (props: {
  folder: IFileFolderListItemResult;
  setSelectedFolder: (folder: FileTargetType) => void;
}) => {
  const { t } = useTranslation();
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const { data: organizationDetailOwnData } = useOrganizationDetailOwnQuery({});

  const toggleMenu = (event?: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorElMenu(event ? event.currentTarget : null);

  const handleShow = async () => {
    toggleMenu();
    props.setSelectedFolder(props.folder?.folder as FileTargetType);
  };

  return (
    <Card
      onDoubleClick={handleShow}
      sx={{
        m: 0,
        p: 2,
        bgcolor: 'background.secondWithBlur',
        ':hover': {
          boxShadow: '0px 32px 32px rgba(0, 0, 0, 0.11)',
        },
      }}
    >
      <Stack direction={'column'} gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <img src={FolderIcon} alt="folder" style={{ width: '30px', height: '30px' }} />
          <IconButton
            id="'document-button'"
            aria-controls={openMenu ? 'document-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={toggleMenu}
            color="inherit"
            size="small"
          >
            <MoreVert fontSize="medium" />
          </IconButton>
          <Menu
            id="document-menu"
            open={openMenu}
            anchorEl={anchorElMenu}
            onClose={() => toggleMenu()}
            MenuListProps={{
              'aria-labelledby': 'document-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleShow()}>
              <Stack direction={'row'} alignItems={'center'}>
                <Visibility fontSize="small" sx={{ mr: 1 }} />
                <Typography fontSize={'15px'} fontWeight={600}>
                  {t('show')}
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
        <Stack height={'20px'}>
          <Typography
            fontSize={'14px'}
            fontWeight={600}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
            }}
          >
            {props.folder.folder?.toUpperCase()}
          </Typography>
        </Stack>
        <Stack>
          <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
            {Number(props.folder?.size / 1024).toFixed(2)} mb · {props.folder?.fileCount} files
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
          <Avatar
            src={organizationDetailOwnData?.logo?.thumbnailPublicUrl ?? ''}
            sx={{ width: '25px', height: '25px' }}
          />
          <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
            {organizationDetailOwnData?.name}
          </Typography>
        </Stack>
      </Stack>

      {/* <EntityDeleteDialog
        open={openDeleteDialog}
        itemId={props.file._id}
        deleteMutation={fileDelete}
        deleteMutationData={{
          data: fileDeleteData,
          isLoading: fileDeleteLoading,
          error: fileDeleteError as IBaseError,
        }}
        dialog={{
          title: t('disk.document.document.delete'),
          successMessage: t('disk.document.document.deletedSuccessfully'),
          content: t('disk.document.document.deleteConfirm'),
        }}
        onClose={() => setOpenDeleteDialog(false)}
      /> */}
    </Card>
  );
};
