
import projectLocale from "../subdomains/project/locales/tr";


const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  project: {
    ...projectLocale,
  },
};

export default locale;

