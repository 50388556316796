import { useState, lazy, Suspense } from "react";
import { IPaymentDetailDrawerProps } from "./PaymentDetailDrawer";

const PaymentDetailDrawer = lazy(() => import("./PaymentDetailDrawer"));

const PaymentDetailDrawerWrapper = ({
  open,
  setOpen,
  selectedPayment,
  sideNavigationProps,
}: IPaymentDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <PaymentDetailDrawer
            open={open}
            setOpen={setOpen}
            selectedPayment={selectedPayment}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default PaymentDetailDrawerWrapper;
