import { Route, Routes } from 'react-router-dom';

import { MyDocuments, PrivateDocuments, SharedDocuments, AllDocuments } from '../pages';

import { DocumentLayout } from '../layouts/DocumentLayout';
import { documentDomainRoutes } from '../../../routes/document.base.route';

export const DocumentRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DocumentLayout />}>
        <Route index element={<SharedDocuments />} />
        <Route path="*" element={<SharedDocuments />} />
        <Route path={documentDomainRoutes.subdomains.shared.base} element={<SharedDocuments />} />
        <Route path={documentDomainRoutes.subdomains.my.base} element={<MyDocuments />} />
        <Route path={documentDomainRoutes.subdomains.private.base} element={<PrivateDocuments />} />
        <Route path={documentDomainRoutes.subdomains.all.base} element={<AllDocuments />} />
      </Route>
    </Routes>
  );
};
