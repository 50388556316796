const locale = {
  permissions: "Permissions",
  update: "Update",
  delete: "Delete",
  view: "View",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Organization deleted successfully",
  info: "You can add users to your team from this section.",
  name: "Name",
  email: "Email",
  surname: "Surname",
  viewProfile: "View Profile",
  roles: "Roles",
  editChart: "Edit Chart",
  createRole: "Create Role",
  rolesInfo: "You can create roles and assign permissions to them.",
  create: "Create",
  new: "New",
  roleName: "Role Name",
  addRole: "Add",
  updateRole: "Update",
  deleteRole: "Delete",
  roleCreatedSuccess: "Role created successfully",
  roleUpdatedSuccess: "Role updated successfully",
  roleDeletedSuccess: "Role deleted successfully",
  errorFetchingPermissions: "Error fetching permissions",
  errorFetchingRoles: "Error fetching roles",
  errorCreatingRole: "Error creating role",
  errorUpdatingRole: "Error updating role",
  errorDeletingRole: "Error deleting role",
  subject: "Subject",
  capabilities: "Capabilities",
  allPermissions: "All Permissions",
  all: "All",
  advancedOptions: "Advanced Options",
  id: "ID",
  emailAddress: "Email Address",
  status: "Status",
  emailVerified: "Email Verified",
  phoneNumber: "Phone Number",
  country: "Country",
  city: "City",
  lastLoginDate: "Last Login Date",
  createdAt: "Created At",
  actions: "Actions",
  addNewRole: "Add New Role",
  yes: "Yes",
  no: "No",
  details: "Details",
  jobTitle: "Job Title",
  deleteMessage: "Are you sure you want to delete this user?",
  cancel: "Cancel",
  userDeleteSuccess: "User deleted successfully",
  userDeleteError: "User delete failed",
  updateChartSuccessfully: "Organization chart updated successfully",
  address: "Address",
  birthDate: "Birth Date",
  gender: "Gender",
  language: "Language",
  description: "Description",
  rolesAndPermissions: "Roles & Permissions",
  ownPermissionsAlert: "You can't change your own permissions.",
  action: "Action",
  actionScope: "Action Scope",
  userDetail: "User Detail",
  updateSuccess: "Permission updated successfully",
};

export default locale;
