import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateSignupInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    name: ValidationHelper.RequiredString(getTranslatedText('nameIsRequired')),
    surname: ValidationHelper.RequiredString(getTranslatedText('surnameIsRequired')),
    email: ValidationHelper.RequiredEmail(
      getTranslatedText('emailIsInvalid'),
      getTranslatedText('emailIsRequired'),
    ),
    password: ValidationHelper.RequiredPassword(
      getTranslatedText('passwordIsInvalid'),
      getTranslatedText('passwordIsRequired'),
    ),
    passwordAgain: ValidationHelper.RequiredPasswordAgain(
      getTranslatedText('passwordAgainIsInvalid'),
    ),
    isChecked: ValidationHelper.RequiredBooleanTrue(
      getTranslatedText('isCheckedIsRequired'),
    ),
    isChecked2: ValidationHelper.RequiredBooleanTrue(
      getTranslatedText('isCheckedIsRequired'),
    ),
    
  }),
});
