const locale = {
  returns: "İadeler",
  return: "İade",
  returnConversions: "İade Dönüştürme",
  title: "İadeler",
  create: "İade Ekle",
  update: "İade Güncelle",
  delete: "İade Sil",
  deleteConfirm: "Bu iadeyi silmek istediginizden emin misiniz?",
  createSuccess: "İade basarıyla eklendi.",
  updateSuccess: "İade basarıyla guncellendi.",
  deleteSuccess: "İade silindi.",
  emptyState1: "Henüz herhangi bir iade kaydedilmedi.",
  emptyState2: "Kayıtlarınızı doğru tutmak ve müşteri ihtiyaçlarını karşılamak için herhangi bir ürün veya hizmet iadesini burada yönetin ve inceleyin."
};

export default locale;
