import { useState, lazy, Suspense } from 'react';
import { INoteCreateOverlay } from './NoteCreateOverlay';

const NoteCreateOverlay = lazy(() => import('./NoteCreateOverlay'));

const NoteCreateDrawerWrapper = ({
  open,
  setOpen,
  targetId,
  targetType,
  overlayType,
}: INoteCreateOverlay) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <NoteCreateOverlay
            open={open}
            setOpen={setOpen}
            targetType={targetType}
            targetId={targetId}
            overlayType={overlayType}
          />
        </Suspense>
      )}
    </>
  );
};

export default NoteCreateDrawerWrapper;
