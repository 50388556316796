/* eslint-disable react-hooks/exhaustive-deps */
import EstimateListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/estimate/components/EstimateListTabComponent';
import ProposalListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/proposal/components/ProposalListTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  EstimateProspectTargetEntityRef,
  IEstimateDetailResult,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { EstimateDetailDrawerTabInfoComponent } from './EstimateDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useEstimateDocumentAddManyMutation,
  useEstimateDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { EstimateDetailDrawerTabAdditionalInfoComponent } from './EstimateDetailDrawer-tab-additionalInfoSection.component';
// import {
//   useEstimateDocumentCreateManyMutation,
//   useEstimateDocumentAddManyMutation,
// } from 'apps/disk/domains/document/subdomains/document';

export interface IEstimateDetailDrawerRightPanelTabsComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const EstimateDetailDrawerRightPanelTabsComponent = (
  props: IEstimateDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <DefaultTabsComponent<IEstimateDetailResult> entityData={props.estimateDetailData}>
      <EstimateDetailDrawerTabInfoComponent
        estimateDetailData={props.estimateDetailData}
        tabKey="info"
        name="crm.info"
      />

      <EstimateDetailDrawerTabAdditionalInfoComponent
        estimateDetailData={props.estimateDetailData}
        tabKey="additionalInfo"
        name="crm.additionalInfo"
      />

      <TaskListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.Estimate}
        name="crm.tasks"
        tabKey="tasks"
      />

      <DocumentListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useEstimateDocumentCreateManyMutation}
        useDocumentAddManyMutation={useEstimateDocumentAddManyMutation}
      />

      <NoteListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Estimate}
        targetDisplayName={props.estimateDetailData?.estimateId.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
      />

      <ReminderListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Estimate}
        targetDisplayName={props.estimateDetailData?.estimateId.toString() ?? ''}
        tabKey="reminders"
        name="crm.reminders"
      />

      <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      />
    </DefaultTabsComponent>
  );
};
