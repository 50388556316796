import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { IBaseGridColumnParams } from 'components/baseDataGrid/infra/IBaseGridColumnParams';
import { NoteTargetType } from 'corede-common-cocrm';
import { TFunction } from 'i18next';

export function noteListColumnData(params: {
  t: TFunction<'translation', undefined>;
  containsTargetType: boolean;
}): IBaseGridColumnParams[] {
  const targetTypeColumn: IBaseGridColumnParams[] = [];
  if (params.containsTargetType) {
    targetTypeColumn.push({
      config: {
        baseGridColType: BaseGridColType.enum,
        column: {
          field: 'targetType',
          width: 160,
          editable: false,
        },
        headerConfig: {
          icon: 'fluent:window-location-target-20-regular',
          name: params.t('crm.targetType'),
        },
      },
      data: NoteTargetType,
    });
  }

  const columns: IBaseGridColumnParams[] = [
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'id',
          disableColumnMenu: true,
          filterable: false,
          width: 40,
          align: 'center',
        },
        headerConfig: {
          name: '#',
          align: 'center',
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'title',
          filterable: true,
          editable: true,
        },
        headerConfig: {
          icon: 'radix-icons:text',
          name: params.t('crm.title'),
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'content',
          // width: 260,
          editable: false,
          sortable: false,
        },
        headerConfig: {
          icon: 'mingcute:content-ai-line',
          name: params.t('crm.content'),
        },
      },
    },
    ...targetTypeColumn,
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'createdAt',
          width: 160,
          sortable: true,
          filterable: false,
          editable: false,
        },
        headerConfig: {
          icon: 'uiw:date',
          name: params.t('crm.createdAt'),
        },
      },
    },
  ];

  return columns;
}
