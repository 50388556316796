/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import {
  IUserDetailResult,
  IEstimateListInput,
  IEstimate,
  EstimateProspectTargetEntityRef,
  EstimateStatus,
  ProposalType,
} from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { useEstimateListQuery, useEstimateUpdateMutation } from '../context/estimate.api';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { Currency, IGraphqlVariables } from 'corede-common';
import { EstimateDeleteDialog } from '../pages/delete/EstimateDeleteDialog';
import EstimateUpdateDrawerWrapper from '../pages/update/EstimateUpdateWrapper';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { AddCircle } from '@mui/icons-material';
import EstimateCreateDrawerWrapper from '../pages/create/EstimateCreateDrawer.wrapper';

interface IAssociatedEstimatesTabSectionComponent
  extends DefaultTabComponentProps<IUserDetailResult> {
  tabKey: 'associatedEstimates';
  name: 'crm.organizationalChart.user.associatedEstimates';
}

export const AssociatedEstimatesTab = (props: IAssociatedEstimatesTabSectionComponent) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);
  const [selectedEstimate, setSelectedEstimate] = useState<TListGridSelectedEntity<IEstimate>>();
  const [openEstimateDetailDrawer, setOpenEstimateDetailDrawer] = useState(false);
  const [openEstimateDeleteDialog, setOpenEstimateDeleteDialog] = useState(false);
  const [openEstimateUpdateDrawer, setOpenEstimateUpdateDrawer] = useState(false);
  const [openEstimateCreateDrawer, setOpenEstimateCreateDrawer] = useState(false);
  const [estimateListFilter, setEstimateListFilter] = useState<
    IGraphqlVariables<IEstimateListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const currentPage = estimateListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = estimateListFilter?.input?.pagination?.pageSize ?? 10;
  const {
    data: estimateListData,
    isLoading: estimateListLoading,
    isFetching: estimateListFetching,
    error: estimateListError,
  } = useEstimateListQuery(
    {
      input: {
        filter: {
          signerUserId: id ?? '',
        },
      },
    },
    {
      skip: !id,
    },
  );

  const [estimateUpdate, { isLoading: estimateUpdateLoading, error: estimateUpdateError }] =
    useEstimateUpdateMutation();

  return (
    <DefaultTabContainer title={t('crm.organizationalChart.user.associatedEstimates')}>
      {estimateListData ? (
        estimateListData?.count > 0 ? (
          <BaseDataGrid
            rows={
              estimateListData?.data?.map((estimate, index) => ({
                id:
                  ((estimateListFilter.input?.pagination?.page || 1) - 1) *
                    (estimateListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: estimate._id,
                prospectName: estimate.prospectName || '-',
                prospectAddress: estimate.prospectCity + ' ' + estimate.prospectCountry || '-',
                prospectContactName:
                  estimate.prospectContactFirstName + ' ' + estimate.prospectContactLastName || '-',
                date: representDateAsString(estimate.date) || '-',
                expireDate: representDateAsString(estimate.expireDate) || '-',
                currency: estimate.currency || '-',
                totalPrice: estimate.totalPrice.total || '-',
                status: estimate.status || '-',
                prospectType: estimate.prospectType || '-',
                language: estimate.language || '-',
                createdAt: representDateAsString(estimate.createdAt) || '-',
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'prospectName',
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.prospectName'),
                    icon: 'mdi:account-outline',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'prospectAddress',
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.prospectAddress'),
                    icon: 'mdi:map-marker-outline',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'prospectContactName',
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.prospectContactName'),
                    icon: 'mdi:account-circle-outline',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'date',
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    name: t('crm.date'),
                    icon: 'mdi:calendar-outline',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'expireDate',
                    editable: true,
                    width: 180,
                  },
                  headerConfig: {
                    name: t('crm.expireDate'),
                    icon: 'mdi:calendar-clock-outline',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'currency',
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.currency'),
                    icon: 'mdi:cash-multiple',
                  },
                  filterConfig: {
                    customFieldName: `currency`,
                  },
                },
                data: Currency,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'totalPrice',
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.totalPrice'),
                    icon: 'mdi:currency-usd',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'status',
                    editable: false,
                    filterable: true,
                  },
                  headerConfig: {
                    name: t('crm.status'),
                    icon: 'mdi:check-circle-outline',
                  },
                  filterConfig: {
                    customFieldName: `status`,
                    isArrayFilter: true,
                  },
                },
                data: EstimateStatus,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'prospectType',
                    editable: false,
                    filterable: true,
                  },
                  headerConfig: {
                    name: t('crm.prospectType'),
                    icon: 'mdi:format-list-bulleted',
                  },
                  filterConfig: {
                    customFieldName: `prospectType`,
                    isArrayFilter: true,
                  },
                },
                data: EstimateProspectTargetEntityRef,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'language',
                    width: 100,
                    editable: true,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.language'),
                    icon: 'mdi:translate',
                  },
                  filterConfig: {
                    customFieldName: `language`,
                  },
                },
                data: ProposalType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'createdAt',
                    filterable: false,
                    editable: false,
                    width: 180,
                  },
                  headerConfig: {
                    name: t('crm.createdAt'),
                    icon: 'mdi:clock-outline',
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: {
                  clickConfig: {
                    setSelectedRow: setSelectedEstimate,
                    setOpenAction: setOpenEstimateDetailDrawer,
                  },
                },
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedEstimate,
                    setOpenAction: setOpenEstimateUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedEstimate,
                    setOpenAction: setOpenEstimateDeleteDialog,
                  },
                },
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={estimateListLoading || estimateUpdateLoading || estimateListFetching}
            listFilter={{
              filterInput: estimateListFilter,
              setFilterInput: setEstimateListFilter,
            }}
            disableColumnFilter
            update={{
              updateQuery: estimateUpdate,
            }}
            count={estimateListData?.count ?? 0}
            config={{
              columnVisibilityModel: {
                prospectAddress: false,
                sector: false,
              },
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState content1={t('crm.preSale.estimate.emptyState1')} />
        )
      ) : null}
      {/* <EstimateDetailDrawerWrapper
        open={openEstimateDetailDrawer}
        setOpen={setOpenEstimateDetailDrawer}
        selectedEstimate={selectedEstimate as IEstimateDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: estimateListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: estimateListData,
            listFilter: estimateListFilter,
            setListFilter: setEstimateListFilter,
            selectedEntity: selectedEstimate,
            setSelectedEntity: setSelectedEstimate,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      /> */}

      <EstimateCreateDrawerWrapper
        open={openEstimateCreateDrawer}
        setOpen={setOpenEstimateCreateDrawer}
      />

      <EstimateUpdateDrawerWrapper
        open={openEstimateUpdateDrawer}
        setOpen={setOpenEstimateUpdateDrawer}
        selectedEstimate={selectedEstimate}
      />

      <EstimateDeleteDialog
        open={openEstimateDeleteDialog}
        estimateId={selectedEstimate?._id ?? ''}
        setEstimate={setSelectedEstimate}
        onClose={() => {
          setSelectedEstimate(undefined);
          setOpenEstimateDeleteDialog(false);
        }}
        key={selectedEstimate?._id}
      />
    </DefaultTabContainer>
  );
};
