import settingsLocale from "../domains/settings/locales/en";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Request for Quote",
  settingsLabel: "Settings",
  paymentSettings: "Payment",
  menuSettings: "Menu Settings",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Window",
  communitySettings: "Community",
  notificationSettings: "Notification",
  organizationSettings: "Organization",
  myOrganization: "My Organization",
  generalSettings: "General",
  moduleSettings: "Module Settings",
  userSettings: "User Settings",
  integrations: "Integrations",
  legalAgreements: "Legal Agreements",
};

export default locale;
