const locale = {
  // actions
  create: 'Oluştur',
  update: 'Müşteri Güncelle',
  customerDetail: 'Müşteri Detayı',
  view: 'Müşteri Görüntüle',
  list: 'Müşterileri Listele',
  viewMüşteri: 'Görüntüle',
  createMüşteri: 'Müşteri Oluştur',
  addNewRole: 'Yeni Rol Ekle',
  tagsPlaceholder: 'Etiket ekle',
  listInfo: 'Tüm müşterilerin listesi.',
  weeklyReports: 'Haftalık Raporlar',

  customerListTitle: 'Müşteri Listesi',

  title: 'Müşteriler',
  customers: 'Müşteriler',
  headerTitle: 'Müşteriler',

  columnParentCategory: 'Üst Kategori',
  columnActions: 'Aksiyon',
  emptyState1: 'Müşteriler bulunamadı',
  emptyState2: 'Lütfen yeni bir müşteri oluşturun',

  relatedCustomers: 'İlgili Müşteriler',
  importCustomer: 'Müşteri İçe Aktar',
  importTitle: 'Müşterileri İçe Aktar',
  createCustomerTitle: 'Müşteri Oluştur',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Müşteri Adı',
  customerEmailLabel: 'Müşteri E-posta',
  customerPhoneLabel: 'Müşteri Telefonu',
  customerTypeLabel: 'Müşteri Türü',
  customerPictureLabel: 'Müşteri Resmi',

  customerLanguageLabel: 'Müşteri Dili',
  uploadPictureText: 'Resim yükle',
  createCustomerButton: 'Müşteri Oluştur',
  createSuccess: 'Müşteri başarıyla oluşturuldu',
  deleteConfirm: 'Bu müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Müşteri başarıyla silindi',

  selectIconTitle: 'Bir Simge Seç',
  searchIcons: 'Simgeleri Ara',
  searchForAnIcon: 'Bir simge ara',

  //accordions
  primaryContact: 'Birincil İletişim',
  primaryBillingInfo: 'Birincil Fatura Bilgisi',
  primaryShippingInfo: 'Birincil Teslimat Bilgisi',
  detailInformation: 'Detaylı Bilgi',
  address: 'Adres',

  // labels
  welcome: 'Hoş geldiniz',
  description: 'Bu hafta liderlikteki ilerlemeniz muhteşem. Devam edelim.',
};

export default locale;
