
import { CustomerDomainLayout } from "../layouts";
import { ContactRouter } from "../subdomains/contact/routes/ContactRouter";
import { CustomerCategoriesRouter } from "../subdomains/customerCategory/routes/CustomerCategoriesRouter";
import { CustomersRouter } from "../subdomains/customer/routes/CustomersRouter";
import { customerDomainRoutes } from "./customer.base.route";
import { Route, Routes } from "react-router-dom";

export const CustomerDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerDomainLayout />}>
        <Route index element={<CustomersRouter />} />
        <Route
          path={`${customerDomainRoutes.subdomains.customer.base}/*`}
          element={<CustomersRouter />}
        />
        <Route
          path={`${customerDomainRoutes.subdomains.contact.base}/*`}
          element={<ContactRouter />}
        />
        <Route
          path={`${customerDomainRoutes.subdomains.customerCategory.base}/*`}
          element={<CustomerCategoriesRouter />}
        />
      </Route>
    </Routes>
  );
};
