/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';
import { ICustomerContactListItemResult } from 'corede-common-cocrm';

export const CustomerContactDetailDialog = memo(
  (prop: {
    open: boolean;
    item: ICustomerContactListItemResult | undefined;
    onClose: () => void;
  }) => {
    // general
    const { t } = useTranslation();

    // queries

    // constants

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.lead.lead.contactDetail')}
        buttonTitle={t('add')}
        buttonColor="secondary"
        width={550}
        buttonCancelTitle={t('close')}
      >
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent={'flex-start'} gap={2}>
            <Avatar
              alt={prop.item?.customer?.name}
              src={prop.item?.customer?.image?.thumbnailPublicUrl}
            />
            <Typography fontSize={'16px'} fontWeight={600}>
              {prop.item?.customer?.name}
            </Typography>
          </Stack>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container sx={{ mt: 2 }}>
                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[t('crm.firstName'), t('crm.lastName'), t('crm.email')].map((label, index) => (
                    <Box display="flex" alignItems="center" minHeight={35} key={index}>
                      <Typography fontSize={'14px'} fontWeight={600}>
                        {label}
                      </Typography>
                    </Box>
                  ))}
                </Grid>

                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[prop.item?.firstName, prop.item?.lastName, prop.item?.email].map(
                    (value, index) => (
                      <Box display="flex" alignItems="center" minHeight={35} key={index}>
                        <Typography fontSize={'14px'} fontWeight={400}>
                          : {value}
                        </Typography>
                      </Box>
                    ),
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container sx={{ mt: 2 }}>
                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[t('crm.phone'), t('crm.position'), t('crm.isPrimary')].map((label, index) => (
                    <Box display="flex" alignItems="center" minHeight={35} key={index}>
                      <Typography fontSize={'14px'} fontWeight={600}>
                        {label}
                      </Typography>
                    </Box>
                  ))}
                </Grid>

                <Grid item display="flex" flexDirection="column" gap={1}>
                  {[
                    prop.item?.phone,
                    prop.item?.position,
                    prop.item?.isPrimary ? t('yes') : t('no'),
                  ].map((value, index) => (
                    <Box display="flex" alignItems="center" minHeight={35} key={index}>
                      <Typography fontSize={'14px'} fontWeight={400}>
                        : {value}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ActionDialog>
    );
  },
);
