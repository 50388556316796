import {
  Grid,
  Stack,
  Box,
  Typography,
  Skeleton,
  useMediaQuery,
  Theme,
  Divider,
} from '@mui/material';
import { memo } from 'react';
import { useCustomerStatisticsQuery } from '../../context/customer.api';
import { useTranslation } from 'react-i18next';
import { getTranslatedEnumValues } from 'localization';
import { CustomerType } from 'corede-common-cocrm';
import { Iconify } from 'components';

export interface ICustomerListStatisticsView {
  count: number;
  customerListCountLoading: boolean;
}

export const CustomerListStatisticsView = memo((props: ICustomerListStatisticsView) => {
  // general
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // queries

  const { data: customerStatisticsData, isLoading: customerStatisticsLoading } =
    useCustomerStatisticsQuery({
      input: {
        filter: {
          numberOfPastDays: 7,
        },
      },
    });

  const isLoading = props.customerListCountLoading || customerStatisticsLoading;
  const isEmpty = props.count === 0;

  // success useEffects

  //error useEffects

  return !isLoading ? (
    !isEmpty ? (
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item xs={12} md={8}>
          <Stack
            direction={downSm ? 'column' : 'row'}
            alignItems={downSm ? 'flex-start' : 'center'}
            gap={{ xs: 2, md: 4 }}
          >
            <Box>
              <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: downMd ? '32px' : '54px',
                    height: downMd ? '32px' : '54px',
                    borderRadius: '6px',
                    backgroundColor: 'secondary.extraLight',
                  }}
                >
                  <Iconify
                    icon={'material-symbols-light:social-leaderboard-outline-rounded'}
                    sx={{ color: 'secondary.main' }}
                    width={downMd ? 24 : 38}
                  />
                </Box>
                <Box>
                  <Typography variant={downMd ? 'caption' : 'body1'} sx={{ fontWeight: '500' }}>
                    {t(`crm.customer.customer.customers`)}
                  </Typography>

                  <Typography
                    variant={downMd ? 'body2' : 'h4'}
                    color="secondary.main"
                    sx={{ fontWeight: '500' }}
                  >
                    {customerStatisticsData?.contactCountOfAllCustomers ?? 0}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: downMd ? '32px' : '54px',
                    height: downMd ? '32px' : '54px',
                    borderRadius: '6px',
                    backgroundColor: 'warning.extraLight',
                  }}
                >
                  <svg
                    width={downMd ? 24 : 38}
                    height={downMd ? 24 : 38}
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M8.08984 29.9102C6.72422 28.5445 7.62969 25.6797 6.93203 24.0023C6.23438 22.325 3.5625 20.8555 3.5625 19C3.5625 17.1445 6.20469 15.7344 6.93203 13.9977C7.65938 12.2609 6.72422 9.45547 8.08984 8.08984C9.45547 6.72422 12.3203 7.62969 13.9977 6.93203C15.675 6.23438 17.1445 3.5625 19 3.5625C20.8555 3.5625 22.2656 6.20469 24.0023 6.93203C25.7391 7.65938 28.5445 6.72422 29.9102 8.08984C31.2758 9.45547 30.3703 12.3203 31.068 13.9977C31.7656 15.675 34.4375 17.1445 34.4375 19C34.4375 20.8555 31.7953 22.2656 31.068 24.0023C30.3406 25.7391 31.2758 28.5445 29.9102 29.9102C28.5445 31.2758 25.6797 30.3703 24.0023 31.068C22.325 31.7656 20.8555 34.4375 19 34.4375C17.1445 34.4375 15.7344 31.7953 13.9977 31.068C12.2609 30.3406 9.45547 31.2758 8.08984 29.9102Z"
                      fill="#FF9F43"
                    />
                    <path
                      d="M25.5312 15.4375L16.818 23.75L12.4687 19.5937M8.08984 29.9102C6.72422 28.5445 7.62969 25.6797 6.93203 24.0023C6.23437 22.325 3.5625 20.8555 3.5625 19C3.5625 17.1445 6.20469 15.7344 6.93203 13.9977C7.65937 12.2609 6.72422 9.45547 8.08984 8.08984C9.45547 6.72422 12.3203 7.62969 13.9977 6.93203C15.675 6.23437 17.1445 3.5625 19 3.5625C20.8555 3.5625 22.2656 6.20469 24.0023 6.93203C25.7391 7.65937 28.5445 6.72422 29.9102 8.08984C31.2758 9.45547 30.3703 12.3203 31.068 13.9977C31.7656 15.675 34.4375 17.1445 34.4375 19C34.4375 20.8555 31.7953 22.2656 31.068 24.0023C30.3406 25.7391 31.2758 28.5445 29.9102 29.9102C28.5445 31.2758 25.6797 30.3703 24.0023 31.068C22.325 31.7656 20.8555 34.4375 19 34.4375C17.1445 34.4375 15.7344 31.7953 13.9977 31.068C12.2609 30.3406 9.45547 31.2758 8.08984 29.9102Z"
                      stroke="#FF9F43"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
                <Box>
                  <Typography variant={downMd ? 'caption' : 'body1'} sx={{ fontWeight: '500' }}>
                    {getTranslatedEnumValues(CustomerType).map(
                      (item: string, index: number) => `${index !== 0 ? ' /' : ''} ${item}`,
                    )}
                  </Typography>

                  <Typography
                    variant={downMd ? 'body2' : 'h4'}
                    color="warning.main"
                    sx={{ fontWeight: '500' }}
                  >
                    {customerStatisticsData?.countByCustomerType?.countByIndividual ?? 0} /{' '}
                    {customerStatisticsData?.countByCustomerType?.countByBusiness ?? 0} /{' '}
                    {customerStatisticsData?.countByCustomerType?.countByGovernment ?? 0}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Grid>

        {/* TODO: TOTAL WEEKLY REPORTS */}
        <Grid item xs={12} md={4}>
          {downMd && <Divider sx={{ my: { xs: 2, md: 0 } }} />}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pl={downMd ? 0 : 6}
            gap={2}
            sx={{ borderLeft: downMd ? 'none' : '1px solid', borderColor: 'divider' }}
          >
            <Box>
              <Typography variant={downMd ? 'body1' : 'h5'} sx={{ fontWeight: '500' }}>
                {t(`crm.customer.customer.customers`)}
              </Typography>

              <Typography variant={downMd ? 'caption' : 'body2'}>
                {t(`crm.customer.customer.weeklyReports`)}
              </Typography>
              <Typography variant={downMd ? 'body2' : 'h4'} sx={{ fontWeight: '500' }}>
                0%
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2px 10px',
                  borderRadius: '4px',
                  backgroundColor: 'success.extraLight',
                  width: 'fit-content',
                }}
              >
                <Typography
                  variant="overline"
                  color="success.main"
                  sx={{ fontWeight: '500', lineHeight: 1 }}
                >
                  +0%
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: downMd ? '88px' : '110px',
                height: downMd ? '88px' : '110px',
                borderRadius: '50%',
                backgroundColor: 'success.extraLight',
              }}
            >
              <Iconify icon={'bi:calendar2-week'} sx={{ color: 'success.main' }} width={38} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    ) : null
  ) : (
    <Grid container spacing={2} my={1}>
      <Grid item xs={12} md={8}>
        <Stack direction={'row'} alignItems={'center'} gap={{ xs: 2, md: 4 }}>
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: '16px' }} />
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: '16px' }} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ borderRadius: '16px' }} />
      </Grid>
    </Grid>
  );
});
