const locale = {
  tasks: "Tasks",
  task: "Task",
  taskConversions: "Task Conversions",
  title: "Tasks",
  addTask: "Add Task",
  emptyState1: "No tasks assigned yet.",
  emptyState2: "Create tasks here to organize your work, set priorities, and stay on track with your CRM activities.",
  create: "Create Task",
  subject: "Subject",
  description: "Description",
  startDate: "Start Date",
  dueDate: "Due Date",
  assigneeIds: "Assignees",
  followerIds: "Followers",
  checklist: "Checklist",
  department: "Department",
  priority: "Priority",
  tags: "Tags",
  hourlyRate: "Hourly Rate",
  hourlyRateCurrency: "Hourly Rate Currency",
  isBillable: "Billable",
  isRecurrent: "Recurrent",
  repeatDays: "Repeat Days",
  totalCycle: "Total Cycle",
  addChecklistItem: "Add Checklist Item",
  assignee: "Assignee",
  createTaskSuccess: "Task successfully created.",
  deleteTask: "Delete Task",
  deleteTaskContent: "Are you sure you want to delete this task. This action cannot be undone.",
  deletedSuccessfully: "Task deleted successfully",
  updateTask: "Update Task",
  updateSuccess: "Task successfully updated.",

  detail: "Task Detail",
  subtask: "Subtask",

  addSubtask: "Add Subtask",
  updateStatus: "Update Status",

  createdBy: "Task created by ",
};

export default locale;






 

