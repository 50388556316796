/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import DetailBox from 'components/box/DetailBox';
import EmptyState from 'components/emptyState/EmptyState';
import { IEntity } from 'corede-common';
import { DefaultTabComponentProps } from './DefaultTabs.component';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';

interface IDefaultEmptyTabComponent extends DefaultTabComponentProps<IEntity> {
  title: string;
  emptyStateContent: string;
}

export const DefaultEmptyTabComponent = (props: IDefaultEmptyTabComponent) => {
  return (
    <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12}>
        <DetailBox title={props.title} type="drawer">
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" height={'100%'} mb={2}>
              <EmptyState
                icon={
                  <img
                    src={DocumentEmptyIcon}
                    style={{
                      width: '180px',
                      height: '180px',
                      marginBottom: '30px',
                    }}
                    alt="There is no proposal yet."
                  />
                }
                content1={props.emptyStateContent}
              />
            </Stack>
          </Grid>
        </DetailBox>
      </Grid>
    </Grid>
  );
};
