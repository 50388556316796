const locale = {
  leadStatuses: "Lead Statuses",
  title: "Lead Statüleri",
  create: "Statü Oluştur",
  delete: "Statü Sil",
  deleteConfirm: "Statüyü silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Statü başarıyla silindi!",
  update: "Güncelle",
  updateStatus: "Statü Güncelle",
  updateLeadStatusSuccess: "Statü başarıyla güncellendi!",
  createLeadStatusSuccess: "Statü başarıyla eklendi!",
  selectColor: "Renk Seçiniz",
  selectIcon: "Ikon Seçiniz",
};

export default locale;
