import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Box, Button, Typography, IconButton, Stack, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Column, Id, Task } from './TaskList-kanban.components.types';
import { useSortable, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskCard from './TaskList-kanban-taskCard.component';
import { MoreVert } from '@mui/icons-material';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { ITask } from 'corede-common-cocrm';

interface Props {
  column: Column;
  tasks: Task[];
  updateTask: (id: Id, content: string) => void;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  setOpenTaskDetailDrawer: Dispatch<SetStateAction<boolean>>;
}

const ColumnContainer: React.FC<Props> = ({ column, tasks, updateTask, setSelectedTask, setOpenTaskDetailDrawer }) => {
  const [editMode, setEditMode] = useState(false);

  const tasksIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: { type: 'Column', column },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Box
        ref={setNodeRef}
        sx={{
          width: 250,
          height: 700,
          border: '2px solid',
          borderColor: 'primary.main',
          bgcolor: '#FF7D7D0D',
          borderRadius: 2,
        }}
        style={style}
      />
    );
  }

  return (
    <Paper
      ref={setNodeRef}
      elevation={3}
      sx={{
        width: 250,
        height: 700,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0px 0px 45px rgba(0, 0, 0, 0.05)',
      }}
      style={style}
    >
      {/* Column Header */}
      <Box
        {...attributes}
        {...listeners}
        sx={{
          color: 'primary.contrastText',
          bgcolor: '#FF7D7D0D',
          py: 2,
          px: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'grab',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!editMode && <Typography variant="body1">{column.title}</Typography>}
          <Typography
            variant="body2"
            sx={{
              bgcolor: 'secondary.extraLight',
              color: 'secondary.main',
              padding: '4px 8px',
              borderRadius: 1,
            }}
          >
            {tasks.length}
          </Typography>
        </Stack>
        {/* <IconButton sx={{ color: 'primary.main' }}>
          <MoreVert />
        </IconButton> */}
      </Box>

      {/* Tasks */}
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          overflowY: 'auto',
          bgcolor: '#FF7D7D0D',
        }}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              updateTask={updateTask}
              setSelectedTask={setSelectedTask}
              setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
            />
          ))}
        </SortableContext>
      </Box>

      {/* Column Footer */}
    </Paper>
  );
};

export default ColumnContainer;
