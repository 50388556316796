/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import { EstimateDetailDrawerLeftPanelHeaderTagsComponent } from './EstimateDetailDrawer-leftPanel.header.tags.component';
import { EstimateDetailDrawerLeftPanelActionsComponent } from './EstimateDetailDrawer-leftPanel-header-actions.component';

export interface IEstimateDetailDrawerLeftPanelHeaderComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const EstimateDetailDrawerLeftPanelHeaderComponent = (
  props: IEstimateDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* TAGS */}
      {downLg ? null : (
        <EstimateDetailDrawerLeftPanelHeaderTagsComponent
          status={props.estimateDetailData?.status}
        />
      )}

      {/* Estimate Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.estimateDetailData?.estimateId ?? ''}
      </Typography>

      <EstimateDetailDrawerLeftPanelActionsComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
