const locale = {
  // fields
  users: 'Note Users',
  date: 'Note Date',
  title: 'List Notes',
  detail: 'Note Detail',
  sharedList: "Shared Notes",
  privateList: "Private Notes",
  ownList: "My Notes",
  allList: "All Notes",

  // actions
  create: 'Create Note',
  add: 'Add Note',
  update: 'Update Note',
  delete: 'Delete Note',

  // situational text
  tagsPlaceholder: 'Please add tags and press enter',
  createSuccess: 'Note created successfully',
  updateSuccess: 'Note updated successfully',
  deletedSuccessfully: 'Note deleted successfully',
  deleteContent: 'Are you sure you want to delete this note. This action cannot be undone.',
  emptyState1: 'No notes added yet.',
  emptyState2:
    'Create and organize your notes here to keep track of important details and conversations in your CRM.',

  createdBy: "Note created by",
};

export default locale;
