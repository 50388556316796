const locale = {
  diskTitle: "Disk",
  detail: "File Detail",
  documentPicture: "Document Picture",
  name: "Name",
  surname: "Surname",
  birthDate: "Birth Date",
  gender: "Gender",
  update: "Update",
  changeImage: "Change Image",
  change: "Change",
  updateDocumentSuccess: "Document updated successfully",
  updateDocumentPictureSuccess: "Document picture updated successfully",
  country: "Country",
  city: "City",
  emptyStateContent1: "No documents found",
  emptyStateContent2: "Please create new document",
  delete: "Delete",
  deleteConfirm: "Are you sure you want to delete this document. This action cannot be undone.",
  deletedSuccessfully: "Document deleted successfully",

  properties: "Properties",
  size: "Size",
  downloadCount: "Download Count",

  // menu
  myDocuments: "My Documents",
  sharedDocuments: "Shared Documents",
  allDocuments: "All Documents",
  privateDocuments: "Private Documents",

  //filter
  search: "Search",
  fileType: "File Type",
  target: "Target",
  tags: "Tags",
};

export default locale;