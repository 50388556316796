const locale = {
  // actions
  create: 'Create',
  update: 'Update Lead',
  detail: 'Lead Detail',
  view: 'View Lead',
  list: 'List Leads',
  addNewRole: 'Add New Role',
  tagsPlaceholder: 'Add tags',
  listInfo: 'List of all leads.',
  weeklyReports: 'Weekly Reports',

  // situational messages
  createdSuccessfully: 'Lead created successfully',
  updatedSuccessfully: 'Lead updated successfully',
  deleteConfirm: 'Are you sure you want to delete? This action cannot be undone.',
  deletedSuccessfully: 'Lead deleted successfully',
  createInfo: 'You can create new leads here.',
  updateLeadSuccess: 'Lead updated successfully',
  createLeadSuccess: 'Lead created successfully',
  emptyState1: 'No leads found here.',
  emptyState2: 'You can create or import leads immediately.',

  // import
  importLead: 'Import Lead',
  importTitle: 'Import Leads',
  importText1: 'You can import leads from this page.',
  importText2: 'You can use the sample form below to publish your leads.',

  // fields and names
  lead: 'Lead',
  leads: 'Leads',
  title: 'List Leads',
  assigneds: 'Assignees',
  qualification: 'Qualification',
  justOwn: 'Just Own',
  isJunk: 'Is Junk',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Junk',
  unJunk: 'Un-Junk',
  prospectName: 'Prospect Name: ',
  businessType: 'Business Type',
  leadType: 'Lead Type',
  leadCount: 'leads',
  leadValue: 'Lead Value',
  tracking: 'Tracking',

  // contact
  addContact: 'Add Contact',
  contactedDate: 'Contacted Date',
  emptyStateContactContent1: 'No contacts found here.',
  contactDetail: 'Contact Detail',
  contactHistory: 'Contact History',
  lastContacted: 'Last Contacted',
  lastContact: 'Last Contact',
  contacted: 'Contacted',

  // statistics
  leadConversions: 'Lead Conversions',

  // lead to customer
  convertToCustomer: 'Convert to Customer',
  customerType: 'Customer Type',
  leadConvertedSuccessfully: 'Lead converted successfully',

  onSiteEstimateWith: 'On site estimate with',

  // labels
  welcome: 'Welcome',
  description: "Your lead progress this week is amazing. Let's continue.",
};

export default locale;
