
import { useState, lazy, Suspense } from 'react';

const LeadCreateDrawer = lazy(() => import('./LeadCreateDrawer'));

const LeadCreateDrawerWrapper = ({ 
    open, 
    setOpen,
 }: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <LeadCreateDrawer 
                    open={open} 
                    setOpen={setOpen} 
                    />
                </Suspense>
            )}
        </>
    );
};

export default LeadCreateDrawerWrapper;
