import {
  useEstimateListQuery,
  useEstimateUpdateMutation,
} from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { EstimateProspectTargetEntityRef, IEstimateListItemResult } from 'corede-common-cocrm';
import { useState } from 'react';
import { representDateAsString, representString } from 'utils/representationHelper';
import { EstimateDeleteDialog } from '../pages/delete/EstimateDeleteDialog';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IEntity } from 'corede-common';
import { useTranslation } from 'react-i18next';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { Box, Stack, Typography } from '@mui/material';
import { Iconify } from 'components';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import EstimateListItemComponent from './EstimateListItem';
import EstimateCreateDrawerWrapper from '../pages/create/EstimateCreateDrawer.wrapper';
import { OverlayType } from 'components/dialog/ActionDialog';

export interface IEstimateListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  prospectTargetId: string;
  prospectTargetEntityRef: EstimateProspectTargetEntityRef;
  tabKey: 'estimates';
  name: 'crm.estimates';
}

const EstimateListTabSectionComponent = (props: IEstimateListTabSectionComponent) => {
  const { t } = useTranslation();

  const [openEstimateCreateDrawer, setOpenEstimateCreateDrawer] = useState(false);
  const [openEstimateDetailDrawer, setOpenEstimateDetailDrawer] = useState(false);
  const [openEstimateDeleteDialog, setOpenEstimateDeleteDialog] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState<IEstimateListItemResult>();

  const { data: estimateListData, isLoading: estimateListLoading } = useEstimateListQuery(
    {
      input: {
        filter: {
          prospectTargetEntityId: props.prospectTargetId,
          prospectTargetEntityRef: props.prospectTargetEntityRef,
        },
      },
    },
    {
      skip: !props.prospectTargetId,
    },
  );

  const [estimateUpdate] = useEstimateUpdateMutation();

  return (
    <DefaultTabContainer title={''}>
      {estimateListData && estimateListData?.count === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no estimate yet."
            />
          }
          content1={t('crm.noEstimateContent1')}
          // content2={t('crm.noEstimate')}
          // button1={{
          //   title: t('crm.preSale.estimate.create'),
          //   onClick: () => {
          //     setOpenEstimateCreateDrawer(true);
          //   },
          //   leftIcon: <Iconify icon={'ion:refresh-outline'} sx={{ mr: 1 }} />,
          // }}
        />
      )}

      {estimateListData && estimateListData?.count > 3 && (
        <BaseDataGrid
          rows={estimateListData?.data?.map((estimate, index) => ({
            id: index + 1,
            prefix: representString(estimate?.prefix),
            createdAt: representDateAsString(estimate?.createdAt),
          }))}
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                },
                headerConfig: {
                  name: '#',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'prefix',
                  editable: false,
                  filterable: false,
                  width: 100,
                },
                headerConfig: {
                  name: t('crm.preSale.estimate.prefix'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'createdAt',
                  editable: false,
                  filterable: false,
                  width: 140,
                },
                headerConfig: {
                  name: t('crm.preSale.estimate.createdAt'),
                },
              },
            },
          ]}
          actionColumn={{
            width: 80,
            defaultActions: {
              view: {
                clickConfig: {
                  setSelectedRow: setSelectedEstimate,
                  setOpenAction: setOpenEstimateDetailDrawer,
                },
              },
              delete: {
                clickConfig: {
                  setSelectedRow: setSelectedEstimate,
                  setOpenAction: setOpenEstimateDeleteDialog,
                },
              },
            },
            actionHeaderName: t('crm.actions'),
          }}
          loading={estimateListLoading}
          listFilter={{
            filterInput: {},
            setFilterInput: () => null,
          }}
          count={estimateListData?.count ?? 0}
          config={{
            columnVisibilityModel: {
              roles: false,
            },
            features: [],
          }}
          update={{
            updateQuery: estimateUpdate,
          }}
          disableColumnFilter={true}
          hideFooterPagination={true}
          sortingMode={'client'}
        />
      )}

      {estimateListData && estimateListData?.count > 0 && estimateListData?.count < 4 && (
        <Stack direction={'column'} gap={1.5} sx={{ width: '100%' }}>
          {estimateListData?.data?.map((estimate, index) => {
            if (index > 3) return null;

            return <EstimateListItemComponent key={index} estimate={estimate} />;
          })}
        </Stack>
      )}

      <EstimateCreateDrawerWrapper
        open={openEstimateCreateDrawer}
        setOpen={setOpenEstimateCreateDrawer}
        estimate={selectedEstimate}
        setEstimate={setSelectedEstimate}
        overlayType={OverlayType.dialog}
      />

      <EstimateDeleteDialog
        open={openEstimateDeleteDialog}
        onClose={() => {
          setSelectedEstimate(undefined);
          setOpenEstimateDeleteDialog(false);
        }}
        estimateId={selectedEstimate?._id ?? ''}
        setEstimate={setSelectedEstimate}
      />
    </DefaultTabContainer>
  );
};

export default EstimateListTabSectionComponent;
