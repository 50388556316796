/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  AccordionSummaryProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ArrowForwardIosSharp,
  Close,
  ExpandMore,
  InfoOutlined,
  Lock,
  LockOpen,
  Update,
} from '@mui/icons-material';
import { usePermissionListQuery } from '../../context/permissions.api';
import {
  useUserPermissionDetailQuery,
  useUserPermissionUpdateMutation,
} from '../../../user/context/user.api';
import DetailBox from 'components/box/DetailBox';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IUserDetailResult } from 'corede-common-cocrm';
import { enqueueSnackbar } from 'notistack';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';

interface IPermission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
  domain: string;
  subdomain: string;
}

interface IUserContactHistoryListTabSectionComponent
  extends DefaultTabComponentProps<IUserDetailResult> {
  tabKey: 'permissions';
  name: 'crm.permissions';
  userData: IUserDetailResult | undefined;
}

export const UserPermissionDetailTab = (props: IUserContactHistoryListTabSectionComponent) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [prohibitedPermissionIds, setProhibitedPermissionIds] = useState<string[]>([]);
  const [openedPermissions, setOpenedPermissions] = useState<any>([]);

  const {
    data: permissionListData,
    isLoading: permissionListLoading,
    error: permissionListError,
  } = usePermissionListQuery({
    input: {
      filter: {
        actions: ['manage', 'view', 'import', 'export'],
      },
    },
  } as any);
  const {
    data: permissionListAllData,
    isLoading: permissionListAllLoading,
    error: permissionListAllError,
  } = usePermissionListQuery({
    input: {
      filter: {
        actions: ['import', 'export', 'list', 'create', 'update', 'delete', 'detail'],
      },
    },
  } as any);
  const {
    data: userPermissionData,
    isLoading: userPermissionLoading,
    isFetching: userPermissionFetching,
    error: userPermissionError,
  } = useUserPermissionDetailQuery({
    input: {
      _id: id || '',
    },
  });

  const [
    userUpdatePermission,
    {
      data: userUpdatePermissionData,
      isLoading: userUpdatePermissionLoading,
      error: userUpdatePermissionError,
    },
  ] = useUserPermissionUpdateMutation();

  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: IPermission[]) => {
    return permissions.reduce(
      (acc, permission) => {
        if (!acc[permission.subject]) {
          acc[permission.subject] = [];
        }
        acc[permission.subject].push(permission);
        return acc;
      },
      {} as Record<string, IPermission[]>,
    );
  };

  const groupByDomain = (permissions: IPermission[]) => {
    return permissions.reduce(
      (acc, permission) => {
        if (!acc[permission.domain]) {
          acc[permission.domain] = [];
        }
        acc[permission.domain].push(permission);
        return acc;
      },
      {} as Record<string, IPermission[]>,
    );
  };

  const mainGroupedPermissions = groupByDomain(permissions as IPermission[]);
  const groupedPermissions = groupBySubject(permissions as IPermission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as IPermission[]);

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: downMd ? 0 : theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const isIncludesRole = (permissionId: IPermission['_id']) => {
    const inRolePermissions = userPermissionData?.rolePermissions.some(
      (p) => p._id === permissionId,
    );
    return inRolePermissions;
  };

  const isProhibited = (permissionId: IPermission['_id']) => {
    const inProhibitedPermissions = userPermissionData?.userProhibitedPermissions.some(
      (p) => p._id === permissionId,
    );
    return inProhibitedPermissions;
  };

  const handleCheckboxChange = (permissionId: IPermission['_id']) => {
    if (!isProhibited(permissionId)) {
      setSelectedPermissionIds((prev) => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds((prev) => [...prev, permissionId]);
          }
          return prev.filter((id) => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter((id) => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      });
    } else {
      setSelectedPermissionIds((prev) => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds((prev) => [...prev, permissionId]);
          }
          return prev.filter((id) => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter((id) => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      });
    }
  };

  const handleUpdatePermissions = () => {
    if (userPermissionData) {
      userUpdatePermission({
        input: {
          permissionIds: selectedPermissionIds.filter((id) => !isIncludesRole(id)),
          prohibitedPermissionIds: prohibitedPermissionIds,
        },
        filter: {
          _id: id || '',
        },
      });
    }
  };

  useEffect(() => {
    if (userUpdatePermissionData) {
      enqueueSnackbar(t('crm.organizationalChart.permission.updateSuccess'), {
        variant: 'success',
      });
    }
  }, [userUpdatePermissionData]);

  useEffect(() => {
    if (userPermissionData) {
      setSelectedPermissionIds(
        userPermissionData?.rolePermissions
          .map((p) => p._id)
          .concat(userPermissionData?.userAllowedPermissions.map((p) => p._id))
          .filter((id) => !userPermissionData?.userProhibitedPermissions.some((p) => p._id === id)),
      );
      setProhibitedPermissionIds(userPermissionData?.userProhibitedPermissions.map((p) => p._id));
    }
  }, [userPermissionData]);

  return (
    <DefaultTabContainer
      title={t('crm.organizationalChart.user.permissions')}
      rightButton={{
        title: t('update'),
        icon: <Update sx={{ mr: 1 }} />,
        onClick: handleUpdatePermissions,
      }}
    >
      <Stack direction={'column'} width={'100%'}>
        {Object.keys(mainGroupedPermissions)
          .reverse()
          .map((domain, index) => (
            <Accordion
              key={domain}
              expanded={expandedAccordions[domain] || false}
              className="accordion-domain"
              onChange={() => {
                setExpandedAccordions((prevState) => ({
                  ...prevState,
                  [domain]: !prevState[domain],
                }));
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel-${domain}-content`}
                id={`panel-${domain}-header`}
              >
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems="center"
                  justifyContent={'space-between'}
                  sx={{ width: '100%' }}
                >
                  <Typography fontSize={'large'} fontWeight={'bold'}>
                    {domain?.slice(0, 1).toUpperCase() + domain?.slice(1)}
                  </Typography>
                  {!downLg && (
                    <Stack direction="row" spacing={1}>
                      {mainGroupedPermissions[domain]
                        .reduce((uniqueSubdomains: string[], permission: IPermission) => {
                          if (!uniqueSubdomains.includes(permission.subdomain)) {
                            uniqueSubdomains.push(permission.subdomain);
                          }
                          return uniqueSubdomains;
                        }, [])
                        .map((subdomain) => (
                          <Chip
                            key={subdomain}
                            label={subdomain?.slice(0, 1).toUpperCase() + subdomain?.slice(1)}
                            // size="small"
                            sx={{ fontWeight: 'bold', opacity: 0.7 }}
                            variant="outlined"
                            color="primary"
                          />
                        ))}
                    </Stack>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{}}>
                {!downLg && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={'flex-end'}
                    sx={{ width: '100%' }}
                  >
                    <Stack direction={'row'} width={'170px'} alignItems={'center'}>
                      <Typography fontSize={'small'} fontWeight={'bold'}>
                        {t('organizationLevel')}
                      </Typography>
                      <Tooltip title={t('organizationLevelDesc')}>
                        <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                      </Tooltip>
                    </Stack>
                    <Stack direction={'row'} width={'150px'} alignItems={'center'}>
                      <Typography fontSize={'small'} fontWeight={'bold'}>
                        {t('departmentLevel')}
                      </Typography>
                      <Tooltip title={t('departmentLevelDesc')}>
                        <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                      </Tooltip>
                    </Stack>
                    <Stack direction={'row'} width={'160px'} alignItems={'center'}>
                      <Typography fontSize={'small'} fontWeight={'bold'}>
                        {t('userLevel')}
                      </Typography>
                      <Tooltip title={t('userLevelDesc')}>
                        <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
                      </Tooltip>
                    </Stack>
                  </Stack>
                )}
                {Object.keys(groupedPermissions)
                  .filter((subject) => groupedPermissions[subject][0]?.domain === domain)
                  .map((subject) => (
                    <Accordion key={subject} expanded={false}>
                      <AccordionSummary expandIcon={null}>
                        <Stack
                          direction={{ xs: 'column', lg: 'row' }}
                          alignItems="center"
                          justifyContent={'space-between'}
                          sx={{ width: '100%' }}
                        >
                          <Typography fontSize={'medium'} fontWeight={'bold'}>
                            {subject?.slice(0, 1).toUpperCase() + subject?.slice(1)}
                          </Typography>
                          {downLg && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent={'center'}
                              sx={{ width: '100%' }}
                            >
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                <Typography
                                  fontSize={'10px'}
                                  textAlign={'center'}
                                  fontWeight={'bold'}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Organization
                                </Typography>
                              </Stack>
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                <Typography
                                  fontSize={'10px'}
                                  textAlign={'center'}
                                  fontWeight={'bold'}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Department
                                </Typography>
                              </Stack>
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                <Typography
                                  fontSize={'10px'}
                                  textAlign={'center'}
                                  fontWeight={'bold'}
                                  sx={{ opacity: 0.5 }}
                                >
                                  User
                                </Typography>
                              </Stack>
                            </Stack>
                          )}
                          {downLg && (
                            <Divider
                              sx={{
                                width: { xs: '80%', sm: '300px' },
                                mr: { xs: '0px', sm: '50px' },
                                mt: 0.5,
                                mb: 0.5,
                              }}
                            />
                          )}
                          <Stack direction="row" alignItems="center" justifyContent={'flex-end'}>
                            <Stack direction="row">
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                {groupedPermissions[subject]
                                  .filter((permission) => permission.actionScope === 'org')
                                  .map((permission) => {
                                    return (
                                      <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        key={permission._id}
                                      >
                                        <FormControlLabel
                                          key={permission._id}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                          control={
                                            <Checkbox
                                              checked={selectedPermissionIds.includes(
                                                permission._id,
                                              )}
                                              disabled={
                                                isIncludesRole(permission._id) &&
                                                !openedPermissions.includes(permission._id)
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(permission._id);
                                              }}
                                              icon={
                                                isIncludesRole(permission._id) ? (
                                                  <Close
                                                    sx={{
                                                      fontSize: '15px',
                                                      m: '2.5px',
                                                      border: '2px solid',
                                                      borderRadius: '3px',
                                                    }}
                                                  />
                                                ) : undefined
                                              }
                                            />
                                          }
                                          label={
                                            permission.action?.slice(0, 1).toUpperCase() +
                                            permission.action?.slice(1)
                                          }
                                          sx={{
                                            ml: { xs: 0, sm: '10px' },
                                            '.MuiFormControlLabel-label': {
                                              fontSize: { xs: '11px', md: 'small' },
                                              fontWeight: 'bold',
                                            },
                                          }}
                                        />
                                        {isIncludesRole(permission._id) && (
                                          <IconButton
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              openedPermissions?.includes(permission._id)
                                                ? setOpenedPermissions(
                                                    openedPermissions.filter(
                                                      (id: any) => id !== permission._id,
                                                    ),
                                                  )
                                                : setOpenedPermissions([
                                                    ...openedPermissions,
                                                    permission._id,
                                                  ]);
                                            }}
                                            sx={{ ml: -2 }}
                                          >
                                            {openedPermissions?.includes(permission._id) ? (
                                              <Lock sx={{ fontSize: '16px', color: 'primary' }} />
                                            ) : (
                                              <LockOpen
                                                sx={{ fontSize: '16px', color: 'primary' }}
                                              />
                                            )}
                                          </IconButton>
                                        )}
                                      </Stack>
                                    );
                                  })}
                              </Stack>
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                {groupedPermissions[subject]
                                  .filter((permission) => permission.actionScope === 'dep')
                                  .map((permission) => {
                                    return (
                                      <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        key={permission._id}
                                      >
                                        <FormControlLabel
                                          key={permission._id}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                          control={
                                            <Checkbox
                                              checked={selectedPermissionIds.includes(
                                                permission._id,
                                              )}
                                              disabled={
                                                isIncludesRole(permission._id) &&
                                                !openedPermissions.includes(permission._id)
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(permission._id);
                                              }}
                                            />
                                          }
                                          label={
                                            permission.action?.slice(0, 1).toUpperCase() +
                                            permission.action?.slice(1)
                                          }
                                          sx={{
                                            ml: { xs: 0, sm: '10px' },
                                            '.MuiFormControlLabel-label': {
                                              fontSize: { xs: '11px', md: 'small' },
                                              fontWeight: 'bold',
                                            },
                                          }}
                                        />
                                        {isIncludesRole(permission._id) && (
                                          <IconButton
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              openedPermissions?.includes(permission._id)
                                                ? setOpenedPermissions(
                                                    openedPermissions.filter(
                                                      (id: any) => id !== permission._id,
                                                    ),
                                                  )
                                                : setOpenedPermissions([
                                                    ...openedPermissions,
                                                    permission._id,
                                                  ]);
                                            }}
                                            sx={{ ml: -2 }}
                                          >
                                            {openedPermissions?.includes(permission._id) ? (
                                              <Lock sx={{ fontSize: '16px', color: 'primary' }} />
                                            ) : (
                                              <LockOpen
                                                sx={{ fontSize: '16px', color: 'primary' }}
                                              />
                                            )}
                                          </IconButton>
                                        )}
                                      </Stack>
                                    );
                                  })}
                              </Stack>
                              <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                                {groupedPermissions[subject]
                                  .filter((permission) => permission.actionScope === 'own')
                                  .map((permission) => {
                                    return (
                                      <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        key={permission._id}
                                      >
                                        <FormControlLabel
                                          key={permission._id}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                          control={
                                            <Checkbox
                                              checked={selectedPermissionIds.includes(
                                                permission._id,
                                              )}
                                              disabled={
                                                isIncludesRole(permission._id) &&
                                                !openedPermissions.includes(permission._id)
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(permission._id);
                                              }}
                                            />
                                          }
                                          label={
                                            permission.action?.slice(0, 1).toUpperCase() +
                                            permission.action?.slice(1)
                                          }
                                          sx={{
                                            ml: { xs: 0, sm: '10px' },
                                            '.MuiFormControlLabel-label': {
                                              fontSize: { xs: '11px', md: 'small' },
                                              fontWeight: 'bold',
                                            },
                                          }}
                                        />
                                        {isIncludesRole(permission._id) && (
                                          <IconButton
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              openedPermissions?.includes(permission._id)
                                                ? setOpenedPermissions(
                                                    openedPermissions.filter(
                                                      (id: any) => id !== permission._id,
                                                    ),
                                                  )
                                                : setOpenedPermissions([
                                                    ...openedPermissions,
                                                    permission._id,
                                                  ]);
                                            }}
                                            sx={{ ml: -2 }}
                                          >
                                            {openedPermissions?.includes(permission._id) ? (
                                              <Lock sx={{ fontSize: '16px', color: 'primary' }} />
                                            ) : (
                                              <LockOpen
                                                sx={{ fontSize: '16px', color: 'primary' }}
                                              />
                                            )}
                                          </IconButton>
                                        )}
                                      </Stack>
                                    );
                                  })}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </AccordionSummary>
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Stack>
    </DefaultTabContainer>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255, .3)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255)',
  }),
}));
