import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { Iconify } from 'components/iconify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchMainDialogComponent } from './SearchMainDialogComponent';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    width: '300px',
    height: '45px',
    backgroundColor: theme.palette.background.secondary,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    fontWeight: 600,
    fontSize: '14px',
  },
}));

export const SearchMainComponent = (props: {}) => {
  const { t } = useTranslation();
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  return (
    <>
      <CustomTextField
        id="outlined-search"
        variant="outlined"
        placeholder={t('crm.searchPlaceholder')}
        sx={{
          bgcolor: 'background.thirdWithBlur',
          '& .MuiInputBase-input': {
            bgcolor: '#00000000',
          },
          '& .MuiOutlinedInput-root': {
            bgcolor: 'background.thirdWithBlur',
          },
        }}
        onClick={() => setOpenSearchDialog(true)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'mynaui:search'} width={24} />
            </InputAdornment>
          ),
        }}
      />

      <SearchMainDialogComponent
        open={openSearchDialog}
        setOpen={setOpenSearchDialog}
      />
    </>
  );
};
