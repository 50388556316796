/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import React from "react";

export interface IDefaultDetailDrawerRightPanelComponentProps {
  children: React.ReactNode;
}

export const DefaultDetailDrawerRightPanelComponent = (
  props: IDefaultDetailDrawerRightPanelComponentProps
) => {
  return (
    <Stack direction={"column"} gap={0} width={"100%"}>
      {props.children}
    </Stack>
  );
};
