/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { IProposalDetailResult } from 'corede-common-cocrm';
import { ProposalDetailDrawerHeaderStatusComponent } from './ProposalDetailDrawer-rightPanel-header-status.component';
// import { ProposalDetailDrawerBaseInfoComponent } from './ProposalDetailDrawer-rightPanel-header-baseInfo.component';

export interface IProposalDetailDrawerRightPanelHeaderComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ProposalDetailDrawerRightPanelHeaderComponent = (
  props: IProposalDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <Stack
      direction={'column'}
      gap={1}
      width={'100%'}
      sx={{
        borderBottom: '2px solid',
        borderColor: 'grey.400',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <ProposalDetailDrawerHeaderStatusComponent proposalDetailData={props.proposalDetailData} />
      {/* <ProposalDetailDrawerBaseInfoComponent proposalDetailData={props.proposalDetailData} /> */}
    </Stack>
  );
};
