import settingsLocale from "../domains/settings/locales/tr";



const locale = {
  settings: {
    ...settingsLocale,
  },
  settingsLabel: "Ayarlar",
  paymentSettings: "Ödeme",
  menuSettings: "Menü Ayarları",
  crmSettings: "CRM",
  leadPlusSettings: "Lead+",
  marketingSettings: "Marketing",
  windowSettings: "Window",
  communitySettings: "Topluluk",
  notificationSettings: "Bildirim",
  myOrganization: "Şirketim",
  generalSettings: "Genel",
  moduleSettings: "Modül Ayarları",
  userSettings: "Kullanıcı Ayarları",
  integrations: "Entegrasyonlar",
  legalAgreements: "Sorumluluk Sözleşmeleri",
};

export default locale;
