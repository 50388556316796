import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateCreatePasswordInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    password: ValidationHelper.RequiredPassword(
      getTranslatedText('passwordIsInvalid'),
      getTranslatedText('passwordIsRequired'),
    ),
    passwordAgain: ValidationHelper.RequiredPasswordAgain(
      getTranslatedText('passwordAgainIsInvalid'),
    ),
  }),
});
