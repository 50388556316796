import { ProfileDomainLayout } from '../layouts';
import { ProfileRouter } from '../subdomains/profile/routes/ProfileRouter';
import { profileDomainRoutes } from './profile.base.route';
import { Route, Routes } from 'react-router-dom';

export const ProfileDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileDomainLayout />}>
        <Route index element={<ProfileRouter />} />
        <Route path="*" element={<ProfileRouter />} />
        <Route
          path={`${profileDomainRoutes.subdomains.profile.base}/*`}
          element={<ProfileRouter />}
        />
      </Route>
    </Routes>
  );
};
