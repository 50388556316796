/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Stack,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { AuthLayout } from '../layouts';
import {
  useLoginMutation,
  setUser,
  useUserDetailOwnQuery,
  useResendRegistrationByUserConfirmationMutation,
} from '../context';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'context';
import { useEffect, useRef, useState } from 'react';
import { Iconify } from 'components';
import LoginCo from 'assets/images/login-co.png';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes/routes';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'localization';
import { IBaseError, IGraphqlVariables, unknownError } from 'corede-common';
import { Turnstile } from '@marsidev/react-turnstile';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateLoginInput } from '../validations/login.validation';
import { ILoginInput } from 'corede-common-cocrm';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const ref = useRef<any>();
  const [token, setToken] = useState<string>('');
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [resendActive, setResendActive] = useState(false);
  const [resendCodeDialog, setResendCodeDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [getLogin, { data, isLoading, error }] = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getResendEmail, { data: resendData, error: resendError }] =
    useResendRegistrationByUserConfirmationMutation();
  const [visibility, setVisibility] = useState<string[]>([]);

  const loginForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(validateLoginInput),
    defaultValues: {
      input: {
        email: '',
        password: '',
      },
    },
    reValidateMode: 'onChange',
  });

  const handleLogin = async (values: IGraphqlVariables<ILoginInput>) => {
    try {
      await getLogin({
        input: {
          email: values.input?.email,
          password: values.input?.password,
        },
        headers: { authorization: token },
      });
    } catch (error) {
      if (ref.current) {
        ref?.current?.reset();
      }
      DefaultErrorHandlerUseEffect(error as IBaseError, currentLanguage);
    }
  };

  const handleResendCode = async () => {
    try {
      await getResendEmail({
        input: { email: email },
        headers: { authorization: token },
      });
    } catch (error) {
      enqueueSnackbar(t('auth.somethingWentWrong'), { variant: 'error' });
    }
  };

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter((item) => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  useEffect(() => {
    if ((error as any)?.error?.name === 'registrationConfirmationIsRequiredError') {
      setResendActive(true);
    }
    if (error) {
      ref?.current?.reset();
      enqueueSnackbar(
        (error as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (resendError) {
      enqueueSnackbar(
        (resendError as any)?.error?.message?.[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [resendError, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t('auth.loginSuccess'), { variant: 'success' });
      dispatch(setUser(data));
    }
  }, [data, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (resendData) {
      enqueueSnackbar(t('auth.resentCodeSuccess'), { variant: 'success' });
      setResendCodeDialog(false);
      navigate(routes.auth.resendConfirmationSuccessfull);
    }
  }, [resendData]);

  useEffect(() => {
    setEmail(loginForm.watch('input.email'));
  }, [loginForm.watch('input.email')]);

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <AuthLayout>
      <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          textAlign={'center'}
          color={'GrayText'}
          py={2}
        ></Typography>
        <Grid
          item
          xs={12}
          display={'flex'}
          height={'100vh'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t('auth.logoAlt')} width={120} />
            <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} py={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={'center'}>
                {t('auth.signinText')}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              sx={{ width: '100%' }}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <form onSubmit={loginForm.handleSubmit(handleLogin)} style={{ width: '100%' }}>
                <Box display={'flex'} flexDirection={'column'} gap={1.5} alignItems={'center'}>
                  <Controller
                    name="input.email"
                    control={loginForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.email"
                        placeholder={t('auth.emailAddress')}
                        fullWidth
                        type={'email'}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        helperText={
                          getNestedValue(loginForm.formState.touchedFields, 'input.email') &&
                          getNestedValue(loginForm.formState.errors, 'input.email')?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                            mb: 1,
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="input.password"
                    control={loginForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="input.password"
                        placeholder={t('auth.password')}
                        fullWidth
                        type={visibility.includes('password') ? 'text' : 'password'}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => handleChangeVisibility('password')}>
                              {visibility.includes('password') ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                        helperText={
                          getNestedValue(loginForm.formState.touchedFields, 'input.password') &&
                          getNestedValue(loginForm.formState.errors, 'input.password')?.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'error.main',
                            fontSize: 9,
                          },
                        }}
                      />
                    )}
                  />

                  {resendActive && (
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      textAlign={'center'}
                      color={'error.light'}
                      py={2}
                      sx={{ cursor: 'pointer' }}
                    >
                      {t('auth.didNotReceiveCode')}{' '}
                      <span
                        style={{ color: '#961650', fontWeight: 600 }}
                        onClick={() => setResendCodeDialog(true)}
                      >
                        {' '}
                        {t('auth.resendCode')}
                      </span>
                    </Typography>
                  )}

                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    textAlign={'center'}
                    py={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(routes.auth.forgotPassword)}
                  >
                    {t('auth.forgotPassword')}
                  </Typography>

                  <Turnstile
                    ref={ref}
                    siteKey={process.env.REACT_APP_CLOUDFLARE_KEY as string}
                    style={{
                      borderRadius: '14px',
                    }}
                    onError={(e) => {
                      console.log(e, 'error');
                    }}
                    onExpire={(e) => {
                      console.log(e, 'expired');
                    }}
                    onSuccess={(e) => {
                      console.log(e, 'solved');
                      setToken(e);
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => loginForm.handleSubmit(handleLogin)()}
                    disabled={!!isLoading || !loginForm.formState?.isValid}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {isLoading ? <CircularProgress size="1rem" /> : t('auth.login')}
                  </Button>
                </Box>
              </form>
            </Box>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              alignItems={'center'}
              width={'100%'}
            >
              <Box bgcolor={'#aaa'} width={'80%'} height={'1px'} sx={{ borderRadius: 1 }} />
              <Typography
                width="100%"
                fontSize={14}
                fontWeight={400}
                color={'GrayText'}
                textAlign={'center'}
              >
                {t('auth.continueWith')}
              </Typography>
              <Box bgcolor={'#aaa'} width={'80%'} height={'1px'} sx={{ borderRadius: 1 }} />
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
            >
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  p: 1.5,
                  color: '#fff',
                  '&:hover': { backgroundColor: 'primary.dark', boxShadow: 2 },
                }}
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`)
                }
              >
                <Iconify icon={'ant-design:google-outlined'} width={24} sx={{ color: '#fff' }} />
              </IconButton>
              {/* <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
              <Iconify icon={"ic:baseline-apple"} width={24} sx={{ color: "#fff" }} />
            </IconButton> */}
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  p: 1.5,
                  color: '#fff',
                  '&:hover': { backgroundColor: 'primary.dark', boxShadow: 2 },
                }}
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/linkedin`)
                }
              >
                <Iconify icon={'ri:linkedin-fill'} width={24} sx={{ color: '#fff' }} />
              </IconButton>
            </Stack>
          </Box>
        </Grid>

        <Typography
          fontSize={14}
          fontWeight={400}
          textAlign={'center'}
          color={'GrayText'}
          py={2}
          sx={{ cursor: 'pointer' }}
        >
          {t('auth.noAccount')}
          <span style={{ color: '#000' }} onClick={() => navigate(routes.auth.signup)}>
            {' '}
            {t('auth.signUp')}
          </span>
        </Typography>
      </Stack>

      <Dialog open={resendCodeDialog} onClose={() => setResendCodeDialog(false)}>
        <DialogTitle sx={{ px: 5, pt: 5 }}>{t('auth.resendCodeTitle')}</DialogTitle>
        <DialogContent sx={{ minWidth: 400, minHeight: 100, px: 5 }}>
          <Stack sx={{ pt: 1 }}>
            <Typography variant="body1" color="textSecondary" mb={4}>
              {t('auth.enterEmailToResend')}
            </Typography>
            <TextField
              name="email"
              label={t('auth.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@gmail.com"
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 5, pb: 3 }}>
          <Button onClick={() => setResendCodeDialog(false)}>{t('auth.close')}</Button>
          <Button onClick={handleResendCode} variant="contained" disabled={!email}>
            {t('auth.send')}
          </Button>
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
};

export default Login;
