import {
  // queries,
  INoteListInput,
  INoteListRequest,
  INoteListResponse,
  INoteListResult,
  noteListQuery,
  INoteListCountRequest,
  INoteListCountResponse,
  noteListCountQuery,
  INoteDetailInput,
  INoteDetailRequest,
  INoteDetailResponse,
  INoteDetailResult,
  noteDetailQuery,

  // mutations
  INoteCreateInput,
  INoteCreateRequest,
  INoteCreateResponse,
  INoteCreateResult,
  noteCreateQuery,
  INoteUpdateInput,
  INoteUpdateRequest,
  INoteUpdateResponse,
  INoteUpdateResult,
  noteUpdateQuery,
  INoteUpdateFilterInput,
  INoteDeleteInput,
  INoteDeleteRequest,
  INoteDeleteResponse,
  INoteDeleteResult,
  noteDeleteQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { noteTags } from './note.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    noteList: builder.query<INoteListResult, IGraphqlVariables<INoteListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteListRequest,
        INoteListResponse,
        INoteListResult,
        INoteListInput
      >({
        query: noteListQuery,
        providesTags: [noteTags.note, noteTags.notes],
      }),
    ),

    noteListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteListCountRequest,
        INoteListCountResponse,
        ICount,
        undefined
      >({
        query: noteListCountQuery,
        providesTags: [noteTags.notes],
      }),
    ),

    noteDetail: builder.query<INoteDetailResult, IGraphqlVariables<INoteDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteDetailRequest,
        INoteDetailResponse,
        INoteDetailResult,
        INoteDetailInput
      >({
        query: noteDetailQuery,
        providesTags: [noteTags.notes],
      }),
    ),
    // mutations

    noteCreate: builder.mutation<INoteCreateResult, IGraphqlVariables<INoteCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteCreateRequest,
        INoteCreateResponse,
        INoteCreateResult,
        INoteCreateInput
      >({
        query: noteCreateQuery,
        invalidatesTags: [noteTags.note, noteTags.note, noteTags.notes],
      }),
    ),

    noteUpdate: builder.mutation<
      INoteUpdateResult,
      IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteUpdateRequest,
        INoteUpdateResponse,
        INoteUpdateResult,
        INoteUpdateInput,
        INoteUpdateFilterInput
      >({
        query: noteUpdateQuery,
        invalidatesTags: [noteTags.note, noteTags.note, noteTags.notes],
      }),
    ),

    noteDelete: builder.mutation<INoteDeleteResult, IGraphqlVariables<INoteDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteDeleteRequest,
        INoteDeleteResponse,
        INoteDeleteResult,
        INoteDeleteInput
      >({
        query: noteDeleteQuery,
        invalidatesTags: [noteTags.notes],
      }),
    ),
  }),
});

export const {
  useNoteListQuery,
  useLazyNoteListQuery,
  useNoteListCountQuery,
  useLazyNoteListCountQuery,
  useNoteDetailQuery,
  useLazyNoteDetailQuery,

  useNoteCreateMutation,
  useNoteUpdateMutation,
  useNoteDeleteMutation,
} = organizationApi;
