/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  IProposal,
  IProposalListInput,
  ProposalType,
  ProposalStatus,
  ProposalProspectTargetEntityRef,
  IProposalDetailResult,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useProposalListQuery, useProposalUpdateMutation } from '../../context/proposal.api';
import { ProposalDeleteDialog } from '../delete/ProposalDeleteDialog';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';
import ProposalUpdateDrawerWrapper from '../update/ProposalUpdateWrapper';
import ProposalDetailDrawer from '../detailDrawer/ProposalDetailDrawer';

export interface IProposalListGridComponent {
  // proposal
  setOpenProposalCreateDrawer?: (value: boolean) => void | undefined;
}

const ProposalListGridComponent = memo((props: IProposalListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedProposal, setSelectedProposal] = useState<TListGridSelectedEntity<IProposal>>();
  const [openProposalDetailDrawer, setOpenProposalDetailDrawer] = useState(false);
  const [openProposalDeleteDialog, setOpenProposalDeleteDialog] = useState(false);
  const [openProposalUpdateDrawer, setOpenProposalUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [proposalListFilter, setProposalListFilter] = useState<
    IGraphqlVariables<IProposalListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = proposalListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = proposalListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: proposalListData,
    isLoading: proposalListLoading,
    isFetching: proposalListFetching,
    error: proposalListError,
    refetch,
  } = useProposalListQuery(proposalListFilter);

  // used for direct update in the list rows
  const [proposalUpdate, { isLoading: proposalUpdateLoading, error: proposalUpdateError }] =
    useProposalUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setProposalListLoading && props.setProposalListLoading(proposalListLoading);
  // }, [proposalListLoading]);
  useEffect(() => {
    refetch();
  }, [proposalListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalListError as IBaseError, currentLanguage);
  }, [proposalListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalUpdateError as IBaseError, currentLanguage);
  }, [proposalUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(proposalListFilter?.input?.filter);
  const isJunkUndefined = (proposalListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = proposalListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    proposalListData?.data?.length === 0 &&
    !proposalListLoading &&
    !proposalListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedProposal}
      setSelectedEntity={setSelectedProposal}
      listFilter={proposalListFilter}
      setListFilter={setProposalListFilter}
      listData={proposalListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {proposalListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                proposalListData?.data?.map((proposal, index) => ({
                  id:
                    ((proposalListFilter.input?.pagination?.page || 1) - 1) *
                      (proposalListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: proposal._id,
                  prospectName: proposal.prospectName || '-',
                  prospectAddress: proposal.prospectCity + ' ' + proposal.prospectCountry || '-',
                  prospectContactName:
                    proposal.prospectContactFirstName + ' ' + proposal.prospectContactLastName ||
                    '-',
                  currency: proposal.currency || '-',
                  totalPrice: proposal.totalPrice.total || '-',
                  status: proposal.status || '-',
                  prospectType: proposal.prospectType || '-',
                  language: proposal.language || '-',
                  createdAt: representDateAsString(proposal.createdAt) || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectName',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectName'),
                      icon: 'mdi:account-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectAddress',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectAddress'),
                      icon: 'mdi:map-marker-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectContactName',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectContactName'),
                      icon: 'mdi:account-circle-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'currency',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.currency'),
                      icon: 'mdi:cash-multiple',
                    },
                    filterConfig: {
                      customFieldName: `currency`,
                    },
                  },
                  data: Currency,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'totalPrice',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.totalPrice'),
                      icon: 'mdi:currency-usd',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.status'),
                      icon: 'mdi:check-circle-outline',
                    },
                    filterConfig: {
                      customFieldName: `status`,
                      isArrayFilter: true,
                    },
                  },
                  data: ProposalStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'prospectType',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.prospectType'),
                      icon: 'mdi:format-list-bulleted',
                    },
                    filterConfig: {
                      customFieldName: `prospectType`,
                      isArrayFilter: true,
                    },
                  },
                  data: ProposalProspectTargetEntityRef,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'language',
                      width: 100,
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.language'),
                      icon: 'mdi:translate',
                    },
                    filterConfig: {
                      customFieldName: `language`,
                    },
                  },
                  data: ProposalType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'createdAt',
                      filterable: false,
                      editable: false,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.createdAt'),
                      icon: 'mdi:clock-outline',
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={proposalListLoading || proposalUpdateLoading || proposalListFetching}
              listFilter={{
                filterInput: proposalListFilter,
                setFilterInput: setProposalListFilter,
              }}
              update={{
                updateQuery: proposalUpdate,
              }}
              count={proposalListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  prospectAddress: false,
                  sector: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.preSale.proposal.emptyState1')}
              content2={t('crm.preSale.proposal.emptyState2')}
              button1={{
                title: t('crm.preSale.proposal.create'),
                onClick: () => (props?.setOpenProposalCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <ProposalDetailDrawer
        open={openProposalDetailDrawer}
        setOpen={setOpenProposalDetailDrawer}
        setSelectedProposal={setSelectedProposal}
        selectedProposal={selectedProposal as IProposalDetailResult & IHasId<number>} // TODO:
        sideNavigationProps={{
          count: proposalListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: proposalListData,
            listFilter: proposalListFilter,
            setListFilter: setProposalListFilter,
            selectedEntity: selectedProposal,
            setSelectedEntity: setSelectedProposal,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <ProposalUpdateDrawerWrapper
        open={openProposalUpdateDrawer}
        setOpen={setOpenProposalUpdateDrawer}
        selectedProposal={selectedProposal}
      />

      <ProposalDeleteDialog
        open={openProposalDeleteDialog}
        proposalId={selectedProposal?._id ?? ''}
        setProposal={setSelectedProposal}
        onClose={() => {
          setSelectedProposal(undefined);
          setOpenProposalDeleteDialog(false);
        }}
        key={selectedProposal?._id}
      />
    </DefaultListGridComponent>
  );
});

export default ProposalListGridComponent;
