import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  baseLeadRoute,
  listFormRoute,
  listLeadRoute,
  listStatusRoute,
  listSourceRoute
} from "../routes/lead.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useLeadDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t("crm.leadLabel"),
    path: baseLeadRoute(),
    children: [
      {
        title: t("crm.leads"),
        path: listLeadRoute(),
        subdomain:  PermissionSubdomain.lead
      },
      {
        title: t("crm.leadForms"),
        path: listFormRoute(),
        // subdomain: PermissionSubdomain.leadForm TODO: add subdomain
      },
      {
        title: t("crm.leadStatuses"),
        path: listStatusRoute(),
        subdomain: PermissionSubdomain.leadStatus
      },
      {
        title: t("crm.leadSources"),
        path: listSourceRoute(),
        subdomain: PermissionSubdomain.leadSource
      }
    ],
  };
};
