
import noteLocale from "../subdomains/note/locales/tr";

const locale = {

  note: {
    ...noteLocale
  }
};

export default locale;

