import { Grid } from "@mui/material";
import { memo, useState } from "react";
import { Add } from "@mui/icons-material";
import { useLeadSourceListCountQuery } from "../../context/leadSource.api";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import LeadSourceListComponent from "./LeadSourceList-grid.component";
import LeadSourceCreateDrawerWrapper from "../create/LeadSourceCreateDrawer.wrapper";

const LeadSourceList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openLeadSourceCreateDrawer, setOpenLeadSourceCreateDrawer] = useState(false);

  const [leadSourceListType, setLeadSourceListType] = useState("list");

  // queries
  const { data: leadSourceListCountData } = useLeadSourceListCountQuery({});

  let totalLeadSourceCount = leadSourceListCountData?.count ?? 0;
  let isEmpty = totalLeadSourceCount === 0;

  // useEffects.error 

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadSource.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.leadSource.create`),
          onClick: () => setOpenLeadSourceCreateDrawer(true),
        }}
      />

      {leadSourceListType === "list" ? (
        <LeadSourceListComponent
          setOpenLeadSourceCreateDrawer={setOpenLeadSourceCreateDrawer}
        />
      ) : (
        null
      )}

      <LeadSourceCreateDrawerWrapper
        open={openLeadSourceCreateDrawer}
        setOpen={setOpenLeadSourceCreateDrawer}
      />
    </Grid>
  );
});

export default LeadSourceList;
