/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProposalStatus, IProposalDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage } from 'localization';
import { useProposalSentMutation } from '../../context/proposal.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import ActionDialog from 'components/dialog/ActionDialog';

export interface IProposalDetailDrawerLeftPanelActionsComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert proposal to customer
 */
export const ProposalDetailDrawerLeftPanelActionsComponent = (
  props: IProposalDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openSure, setOpenSure] = React.useState(false);
  const status = props.proposalDetailData?.status;

  const [
    proposalSent,
    { data: proposalSentData, isLoading: proposalSentLoading, error: proposalSentError },
  ] = useProposalSentMutation();

  const handleSend = () => {
    proposalSent({
      input: {
        _id: props.proposalDetailData?._id ?? '',
      },
    });
  };

  useEffect(() => {
    if (proposalSentData) {
      enqueueSnackbar(t('crm.preSale.proposal.sentSuccessfully'), {
        variant: 'success',
      });
      setOpenSure(false);
    }
  }, [proposalSentData]);

  useEffect(() => {
    if (proposalSentError) {
      DefaultErrorHandlerUseEffect(proposalSentError as IBaseError, currentLanguage);
      setOpenSure(false);
    }
  }, [proposalSentError]);
  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Proposal base actions */}
      <Button
        onClick={() => setOpenSure(true)}
        color="primary"
        variant="contained"
        disabled={status !== ProposalStatus.draft}
        sx={{
          height: 40,
          fontSize: '13px',
          borderRadius: 100,
        }}
      >
        <Icons.RefreshRounded sx={{ mr: 1 }} />
        {t('crm.preSale.proposal.send')}
      </Button>

      <ActionDialog
        open={openSure}
        setOpen={setOpenSure}
        title={t('crm.preSale.proposal.sendSureTitle')}
        buttonTitle={t('crm.preSale.proposal.send')}
        loading={proposalSentLoading}
        handleClick={() => {
          handleSend();
        }}
      >
        <p>{t('crm.preSale.proposal.sendSureDesc')}</p>
      </ActionDialog>
    </Stack>
  );
};
