const locale = {
  payments: "Ödemeler",
  payment: "Ödeme",
  paymentConversions: "Ödeme Dönüştürme",
  title: "Ödemeler",
  create: "Ödeme Ekle",
  update: "Ödeme Güncelle",
  delete: "Ödeme Sil",
  createSuccess: "Ödeme başarıyla eklendi.",
  updateSuccess: "Ödeme başarıyla güncellendi.",
  deleteSuccess: "Ödeme silindi.",
  deleteConfirm: "Bu oüdeme silinmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
  emptyState1: "Henüz ödeme kaydedilmedi.",
  emptyState2: "Finansmanınızı düzenli ve güncel tutmak için faturalarınızın ödemelerini buradan takip edin ve yönetin."
};

export default locale;
