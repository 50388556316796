const locale = {
  supports: "Biletler",
  support: "Bilet",
  title: "Biletler",
  addSupport: "Bilet Ekle",
  emptyStateDesc1: "Henüz hernangi bir destek bileti mevcut değil.",
  emptyStateDesc2: "Zamanında yanıt ve mükemmel hizmet sağlamak için müşteri sorgularını yönetin ve destek isteklerini buradan takip edin."
};

export default locale;



