/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { validateCreateInvoiceInput } from 'apps/crm/domains/06-sale/subdomains/invoice/validations/create.validation';
import { FilledButton, MyTab, OutlinedButton, SettingsTitle } from 'components';
import { IGraphqlVariables, unknownError } from 'corede-common';
import {
  IOrganizationBillingInfoCreateOwnInput,
  IOrganizationCardDetailOwnResult,
  IOrganizationDetailResult,
  ISubscriptionDetailResult,
  OrganizationStatus,
  RecurringInterval,
  SubscriptionStatus,
} from 'corede-common-cocrm';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useOrganizationBillingInfoCreateOwnMutation,
  useOrganizationBillingInfoUpdateOwnMutation,
  useOrganizationCardCreateOwnMutation,
  useOrganizationCardDeleteOwnMutation,
  useOrganizationCardDetailOwnQuery,
  useOrganizationDetailOwnQuery,
} from '../context/setting.api';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import {
  AddOutlined,
  DeleteOutlined,
  PeopleAltOutlined,
  RefreshOutlined,
  StorageOutlined,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import ActionDialog from 'components/dialog/ActionDialog';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLazySubscriptionDetailQuery } from 'apps/crm/domains/16-pricing/subdomains/pricing';
import {
  useCountryListQuery,
  useLazyCityListQuery,
  useLazyStateListQuery,
  useUserDetailOwnQuery,
} from 'apps/auth/context';
import { List } from 'apps/crm/domains/16-pricing/subdomains/pricing/pages';
import BaseChip from 'components/chip/BaseChip';
import EmptyState from 'components/emptyState/EmptyState';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import {
  validateBusinessBillingInfoInput,
  validateIndividualBillingInfoInput,
} from '../validations/billingInfo.validation';
import { DefaultNameAutocompleteInput } from 'components/form/DefaultNameAutocompleteInput';

enum UserType {
  individual = 'individual',
  business = 'business',
}
const Payment = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabLabels, setTabLabels] = useState(['']);
  const [selectedTabName, setSelectedTabName] = useState('');
  const [userType, setUserType] = useState<UserType>(UserType.individual);

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const {
    data: organizationDetailOwnData,
    error: organizationDetailOwnError,
    refetch,
  } = useOrganizationDetailOwnQuery({});
  const { data: organizationCardDetailOwnData } = useOrganizationCardDetailOwnQuery({});
  const [
    organizationBillingInfoCreateOwn,
    {
      data: organizationBillingInfoCreateOwnData,
      isLoading: organizationBillingInfoCreateOwnLoading,
      error: organizationBillingInfoCreateOwnError,
    },
  ] = useOrganizationBillingInfoCreateOwnMutation();
  const [
    organizationBillingInfoUpdateOwn,
    {
      data: organizationBillingInfoUpdateOwnData,
      isLoading: organizationBillingInfoUpdateOwnLoading,
      error: organizationBillingInfoUpdateOwnError,
    },
  ] = useOrganizationBillingInfoUpdateOwnMutation();
  const [
    organizationCardCreateOwn,
    {
      data: organizationCardCreateOwnData,
      isLoading: organizationCardCreateOwnLoading,
      error: organizationCardCreateOwnError,
    },
  ] = useOrganizationCardCreateOwnMutation();
  const [
    organizationCardDeleteOwn,
    {
      data: organizationCardDeleteOwnData,
      isLoading: organizationCardDeleteOwnLoading,
      error: organizationCardDeleteOwnError,
    },
  ] = useOrganizationCardDeleteOwnMutation();
  const [getSubscriptionDetail, { data: subscriptionDetailData }] =
    useLazySubscriptionDetailQuery();
  const { data: countryListData, isLoading: countryListLoading } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData, isLoading: stateListLoading }] =
    useLazyStateListQuery({});
  const [getCityList, { data: cityListData, isLoading: cityListLoading }] = useLazyCityListQuery(
    {},
  );
  const status = organizationDetailOwnData?.status;
  const trialStatus = organizationDetailOwnData?.activeSubscriptions?.trial?.status;
  const mainStatus = organizationDetailOwnData?.activeSubscriptions?.main?.status;

  // Current Plan

  useEffect(() => {
    if (!!organizationDetailOwnData?.activeSubscriptions?.main) {
      getSubscriptionDetail({
        input: {
          _id: organizationDetailOwnData?.activeSubscriptions?.main?._id ?? '',
        },
      });
    }
  }, [organizationDetailOwnData?.activeSubscriptions?.main]);

  // Billing Info

  const billingInfoFormInitialValues = useMemo<IGraphqlVariables<any, any>>(
    () => ({
      input: {
        name: organizationDetailOwnData?.billingInfo?.name ?? '',
        surname: organizationDetailOwnData?.billingInfo?.surname ?? '',
        companyName: organizationDetailOwnData?.billingInfo?.companyName ?? '',
        email: organizationDetailOwnData?.billingInfo?.email ?? '',
        phone: organizationDetailOwnData?.billingInfo?.phone ?? '',
        country: organizationDetailOwnData?.billingInfo?.country ?? '',
        state: organizationDetailOwnData?.billingInfo?.state ?? '',
        city: organizationDetailOwnData?.billingInfo?.city ?? '',
        address: organizationDetailOwnData?.billingInfo?.address ?? '',
        identityNumber: organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
        vat: organizationDetailOwnData?.billingInfo?.vat ?? '',
        taxOffice: organizationDetailOwnData?.billingInfo?.taxOffice ?? '',
        zip: organizationDetailOwnData?.billingInfo?.zip ?? '',
      },
    }),
    [],
  );

  const billingInfoForm = useForm<IGraphqlVariables<IOrganizationBillingInfoCreateOwnInput, any>>({
    defaultValues: billingInfoFormInitialValues,
    resolver: yupResolver(
      userType === UserType.individual
        ? validateIndividualBillingInfoInput
        : validateBusinessBillingInfoInput,
    ),
    mode: 'onChange',
  });

  const billingInfoFormOnSubmit = async (
    values: IGraphqlVariables<IOrganizationBillingInfoCreateOwnInput>,
  ) => {
    organizationDetailOwnData?.billingInfo
      ? organizationBillingInfoUpdateOwn({ input: values.input })
      : organizationBillingInfoCreateOwn({ input: values.input });
  };

  useEffect(() => {
    if (organizationDetailOwnData) {
      billingInfoForm.setValue('input.name', organizationDetailOwnData?.billingInfo?.name ?? '');
      billingInfoForm.setValue(
        'input.surname',
        organizationDetailOwnData?.billingInfo?.surname ?? '',
      );
      billingInfoForm.setValue('input.email', organizationDetailOwnData?.billingInfo?.email ?? '');
      billingInfoForm.setValue('input.phone', organizationDetailOwnData?.billingInfo?.phone ?? '');
      billingInfoForm.setValue(
        'input.identityNumber',
        organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
      );
      billingInfoForm.setValue('input.vat', organizationDetailOwnData?.billingInfo?.vat ?? '');
      billingInfoForm.setValue(
        'input.country',
        organizationDetailOwnData?.billingInfo?.country ?? '',
      );
      billingInfoForm.setValue('input.state', organizationDetailOwnData?.billingInfo?.state ?? '');
      billingInfoForm.setValue('input.city', organizationDetailOwnData?.billingInfo?.city ?? '');
      billingInfoForm.setValue(
        'input.address',
        organizationDetailOwnData?.billingInfo?.address ?? '',
      );
      billingInfoForm.setValue(
        'input.taxOffice',
        organizationDetailOwnData?.billingInfo?.taxOffice ?? '',
      );
      billingInfoForm.setValue('input.zip', organizationDetailOwnData?.billingInfo?.zip ?? '');
      billingInfoForm.setValue(
        'input.companyName',
        organizationDetailOwnData?.billingInfo?.companyName ?? '',
      );
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (organizationDetailOwnData?.billingInfo?.country) {
      getStateList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.billingInfo?.country,
          },
        },
      });
    }
  }, [organizationDetailOwnData?.billingInfo?.country]);

  useEffect(() => {
    if (
      organizationDetailOwnData?.billingInfo?.country &&
      organizationDetailOwnData?.billingInfo?.state
    ) {
      getCityList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.billingInfo?.country,
            state_name: organizationDetailOwnData?.billingInfo?.state,
          },
        },
      });
    }
  }, [
    organizationDetailOwnData?.billingInfo?.country,
    organizationDetailOwnData?.billingInfo?.state,
  ]);

  useEffect(() => {
    if (billingInfoForm.watch('input.country')) {
      getStateList({
        input: {
          filter: {
            country_name: billingInfoForm.watch('input.country'),
          },
        },
      });
    }
  }, [billingInfoForm.watch('input.country')]);

  useEffect(() => {
    if (billingInfoForm.watch('input.country') && billingInfoForm.watch('input.state')) {
      getCityList({
        input: {
          filter: {
            country_name: billingInfoForm.watch('input.country'),
            state_name: billingInfoForm.watch('input.state'),
          },
        },
      });
    }
  }, [billingInfoForm.watch('input.state')]);

  useEffect(() => {
    if (organizationBillingInfoCreateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateBillingInfoSuccess'), {
        variant: 'success',
      });
      refetch();
    }
  }, [organizationBillingInfoCreateOwnData]);

  useEffect(() => {
    if (organizationBillingInfoUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateBillingInfoSuccess'), {
        variant: 'success',
      });
      refetch();
    }
  }, [organizationBillingInfoUpdateOwnData]);

  useEffect(() => {
    if (organizationBillingInfoCreateOwnError) {
      enqueueSnackbar(
        (organizationBillingInfoCreateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationBillingInfoCreateOwnError]);

  useEffect(() => {
    if (organizationBillingInfoUpdateOwnError) {
      enqueueSnackbar(
        (organizationBillingInfoUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationBillingInfoUpdateOwnError]);

  useEffect(() => {
    if (userDetailOwnData) {
      billingInfoForm.setValue('input.email', userDetailOwnData?.email ?? '');
    }
  }, [userDetailOwnData]);

  // Cards

  type FocusedField = 'name' | 'number' | 'expiry' | 'cvc' | undefined;

  const [focused, setFocused] = useState<FocusedField>(undefined);
  const [selectedCardToken, setSelectedCardToken] = useState<string | undefined>(undefined);
  const [openCardDelete, setOpenCardDelete] = useState(false);
  const [openCardAdd, setOpenCardAdd] = useState(false);

  const cardsInitialValues = useMemo(
    () => ({
      input: {
        cardNumber: '',
        cardHolderName: '',
        expireMonth: '',
        expireYear: '',
        cardAlias: '',
      },
    }),
    [],
  );

  const cardsForm = useForm({
    defaultValues: cardsInitialValues,
    resolver: yupResolver(validateCreateInvoiceInput),
    mode: 'onSubmit',
  });

  const cardsOnSubmit = async (values: any) => {
    organizationCardCreateOwn({
      input: {
        cardNumber: values.input.cardNumber,
        cardHolderName: values.input.cardHolderName,
        expireMonth: values.input.expireMonth,
        expireYear: values.input.expireYear,
        cardAlias: values.input.cardAlias,
      },
    });
  };

  const handleDeleteCard = (cardToken: string) => {
    organizationCardDeleteOwn({
      input: {
        cardToken: cardToken,
      },
    });
    setOpenCardDelete(false);
  };

  useEffect(() => {
    if (organizationCardCreateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.createCardSuccess'), { variant: 'success' });
      cardsForm.reset();
    }
  }, [organizationCardCreateOwnData]);

  useEffect(() => {
    if (organizationCardDeleteOwnData) {
      enqueueSnackbar(t('settings.settings.settings.deleteCardSuccess'), { variant: 'success' });
      setOpenCardDelete(false);
      cardsForm.reset();
    }
  }, [organizationCardDeleteOwnData]);

  useEffect(() => {
    if (organizationCardCreateOwnError) {
      enqueueSnackbar(
        (organizationCardCreateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationCardCreateOwnError]);

  useEffect(() => {
    if (organizationCardDeleteOwnError) {
      enqueueSnackbar(
        (organizationCardDeleteOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationCardDeleteOwnError]);

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar(
        (organizationDetailOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationDetailOwnError]);

  //Common

  useEffect(() => {
    switch (status) {
      case 'restricted':
        if (mainStatus || trialStatus) {
          setTabLabels([
            t('settings.settings.settings.currentPlan'),
            t('settings.settings.settings.billingInfo'),
            t('settings.settings.settings.cards'),
          ]);
        } else {
          setTabLabels([
            t('settings.settings.settings.billingInfo'),
            t('settings.settings.settings.cards'),
          ]);
        }
        break;
      case 'active':
        setTabLabels([
          t('settings.settings.settings.currentPlan'),
          t('settings.settings.settings.billingInfo'),
          t('settings.settings.settings.cards'),
          t('settings.settings.settings.invoices'),
        ]);
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case 'billingInfo':
          setSelectedTabName(t('settings.settings.settings.billingInfo'));
          break;
        case 'cards':
          setSelectedTabName(t('settings.settings.settings.cards'));
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab, status]);

  useEffect(() => {
    if (userType === UserType.business) {
      billingInfoForm.setValue('input.identityNumber', '');
    } else if (userType === UserType.individual) {
      billingInfoForm.setValue('input.companyName', '');
      billingInfoForm.setValue('input.vat', '');
      billingInfoForm.setValue('input.taxOffice', '');
    }
  }, [userType]);

  useEffect(() => {
    if (organizationDetailOwnData?.billingInfo?.identityNumber) {
      setUserType(UserType.individual);
    } else if (organizationDetailOwnData?.billingInfo?.companyName) {
      setUserType(UserType.business);
    } else {
      setUserType(UserType.individual);
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (
      billingInfoForm.watch('input.country') &&
      billingInfoForm.watch('input.country') !== 'Turkey'
    ) {
      billingInfoForm.setValue('input.identityNumber', '2222222222');
    } else if (
      billingInfoForm.watch('input.country') &&
      billingInfoForm.watch('input.country') === 'Turkey'
    ) {
      billingInfoForm.setValue(
        'input.identityNumber',
        organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
      );
    }
  }, [billingInfoForm.watch('input.country')]);

  const memoizedTab = useMemo(
    () => (
      <MyTab
        type="settings"
        labels={tabLabels}
        setSelectedTab={setSelectedTabName}
        selectedTab={selectedTabName}
      />
    ),
    [tabLabels, selectedTabName, status],
  );

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          // bgcolor: 'background.secondary',
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        {memoizedTab}
      </Stack>

      {selectedTabName === t('settings.settings.settings.currentPlan') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.currentPlan')} />

          <ActiveSubscription
            subscriptionData={subscriptionDetailData}
            organizationData={organizationDetailOwnData}
            organizationCardData={organizationCardDetailOwnData}
            setSelectedTabName={setSelectedTabName}
          />
        </Grid>
      )}
      {selectedTabName === t('settings.settings.settings.billingInfo') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.billingInfo')} />
          <Grid item xs={12} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                  {t('settings.settings.settings.userType')}
                </InputLabel>
                <Autocomplete
                  noOptionsText={t('noOptionsText')}
                  size="small"
                  options={Object.values(UserType).map((userType) => ({
                    name: getTranslatedEnumValue(userType),
                    _id: userType,
                  }))}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    userType ? { name: getTranslatedEnumValue(userType), _id: userType } : null
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUserType(newValue._id);
                    }
                  }}
                />
              </Grid>

              {userType === UserType.business && (
                <Grid item xs={12} sm={6}>
                  <DefaultTextFieldInput
                    control={billingInfoForm.control}
                    inputName="input.companyName"
                    label={t('settings.settings.settings.companyName')}
                    errors={billingInfoForm.formState.errors}
                    touchedFields={billingInfoForm.formState.touchedFields}
                    isFirstLetterCapitalize
                    isRequired
                  />
                </Grid>
              )}
              {userType === UserType.business && (
                <Grid item xs={12} sm={6}>
                  <DefaultTextFieldInput
                    control={billingInfoForm.control}
                    inputName="input.taxOffice"
                    label={t('settings.settings.settings.taxOffice')}
                    errors={billingInfoForm.formState.errors}
                    touchedFields={billingInfoForm.formState.touchedFields}
                    isFirstLetterCapitalize
                    isRequired
                  />
                </Grid>
              )}
              {userType === UserType.business && (
                <Grid item xs={12} sm={6}>
                  <DefaultTextFieldInput
                    control={billingInfoForm.control}
                    inputName="input.vat"
                    label={t('settings.settings.settings.vat')}
                    errors={billingInfoForm.formState.errors}
                    touchedFields={billingInfoForm.formState.touchedFields}
                    isFirstLetterCapitalize
                    isRequired
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.name"
                  label={t('settings.settings.settings.name')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.surname"
                  label={t('settings.settings.settings.surname')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                />
              </Grid>
{/* 
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.email"
                  label={t('settings.settings.settings.email')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                  disabled
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.phone"
                  label={t('settings.settings.settings.phone')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.country"
                  label={t('settings.settings.settings.country')}
                  control={billingInfoForm.control}
                  options={countryListData?.data ?? []}
                  isRequired
                  isLoading={countryListLoading}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                />
              </Grid>
              {userType === UserType.individual &&
                billingInfoForm.watch('input.country') === 'Turkey' && (
                  <Grid item xs={12} sm={6}>
                    <DefaultTextFieldInput
                      control={billingInfoForm.control}
                      inputName="input.identityNumber"
                      label={t('settings.settings.settings.identityNumber')}
                      errors={billingInfoForm.formState.errors}
                      touchedFields={billingInfoForm.formState.touchedFields}
                      isFirstLetterCapitalize
                      isRequired
                    />
                  </Grid>
                )}
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.state"
                  label={t('settings.settings.settings.state')}
                  control={billingInfoForm.control}
                  options={stateListData?.data ?? []}
                  isRequired
                  isLoading={stateListLoading}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultNameAutocompleteInput
                  inputName="input.city"
                  label={t('settings.settings.settings.city')}
                  control={billingInfoForm.control}
                  options={cityListData?.data ?? []}
                  isRequired
                  isLoading={cityListLoading}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.zip"
                  label={t('settings.settings.settings.zip')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.address"
                  label={t('settings.settings.settings.address')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                  rows={2}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={1}>
            <FilledButton
              leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
              color="secondary"
              loading={
                organizationBillingInfoCreateOwnLoading || organizationBillingInfoUpdateOwnLoading
              }
              title={
                organizationDetailOwnData?.billingInfo
                  ? t('settings.settings.settings.save')
                  : t('settings.settings.settings.save')
              }
              onClick={billingInfoForm.handleSubmit(billingInfoFormOnSubmit)}
            />
          </Grid>
        </Grid>
      )}
      {selectedTabName === t('settings.settings.settings.cards') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle
            selectedTabName={t('settings.settings.settings.cards')}
            rightButton={
              organizationCardDetailOwnData?.cardDetails &&
              organizationCardDetailOwnData?.cardDetails?.length > 0 ? (
                <FilledButton
                  leftIcon={<AddOutlined sx={{ mr: 2 }} />}
                  color="primary"
                  title={t('settings.settings.settings.addCard')}
                  onClick={() => {
                    setOpenCardAdd(true);
                  }}
                />
              ) : undefined
            }
          />

          <Grid item xs={12} lg={9}>
            <Grid container spacing={2}>
              {(!organizationCardDetailOwnData ||
                (organizationCardDetailOwnData?.cardDetails &&
                  organizationCardDetailOwnData?.cardDetails?.length === 0)) &&
                organizationDetailOwnData && (
                  <CardForm
                    cardsForm={cardsForm}
                    cardsOnSubmit={cardsOnSubmit}
                    focused={focused}
                    setFocused={setFocused}
                    organizationCardCreateOwnLoading={organizationCardCreateOwnLoading}
                  />
                )}

              {/* Kartlar listesi */}
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  alignItems={{ xs: 'flex-start', lg: 'center' }}
                  spacing={2}
                >
                  {organizationCardDetailOwnData?.cardDetails &&
                    organizationCardDetailOwnData?.cardDetails?.length > 0 &&
                    organizationCardDetailOwnData?.cardDetails?.map((card, index) => (
                      <Box key={index} position={'relative'}>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 5,
                            left: 5,
                            zIndex: 1,
                            bgcolor: 'error.main',
                            color: 'error.contrastText',
                          }}
                          onClick={() => {
                            setOpenCardDelete(true);
                            setSelectedCardToken(card.cardToken);
                          }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                        <Cards
                          cvc={'XXX'}
                          expiry={`XX/XX`}
                          name={'XXXXX XXXXX'}
                          number={card.binNumber ?? ''}
                        />
                      </Box>
                    ))}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Stack mt={5} direction={'row'} width={'100%'} justifyContent={'center'}>
            <BaseChip
              color="success.main"
              icon="LockOutlined"
              label={t('settings.settings.settings.cardListSecure')}
            />
          </Stack>
        </Grid>
      )}

      {selectedTabName === t('settings.settings.settings.invoices') && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.billingInfo')} />
          <Grid item xs={12}>
            <Grow in={selectedTabName === t('settings.settings.settings.invoices')} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container>
                  <Grid item xs={12} mb={2}>
                    <EmptyState
                      content1={t('settings.settings.settings.noInvoiceContent1')}
                      content2={t('settings.settings.settings.noInvoiceContent2')}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}

      <ActionDialog
        open={openCardAdd}
        onClose={() => setOpenCardAdd(false)}
        title="Add Card"
        width={400}
      >
        <CardForm
          cardsForm={cardsForm}
          cardsOnSubmit={cardsOnSubmit}
          focused={focused}
          setFocused={setFocused}
          organizationCardCreateOwnLoading={organizationCardCreateOwnLoading}
          type={'add'}
        />
      </ActionDialog>

      <ActionDialog
        open={openCardDelete}
        setOpen={setOpenCardDelete}
        title="Delete Card"
        width={400}
        handleClick={() => handleDeleteCard(selectedCardToken as string)}
        loading={organizationCardDeleteOwnLoading}
        buttonTitle="Delete"
        buttonColor="error"
      >
        <Grid item xs={12}>
          <Typography variant="body2">{t('settings.settings.settings.deleteCardDesc')}</Typography>
        </Grid>
      </ActionDialog>
    </Stack>
  );
};

const CardForm = ({
  cardsForm,
  cardsOnSubmit,
  focused,
  setFocused,
  organizationCardCreateOwnLoading,
  type,
}: any) => {
  return (
    <>
      {/* Kart Görseli */}
      <Grid item xs={12} md={type === 'add' ? 12 : 6}>
        <Cards
          cvc={cardsForm.watch('input.cardAlias')}
          expiry={`${cardsForm.watch('input.expireMonth')}/${cardsForm.watch('input.expireYear')}`}
          name={cardsForm.watch('input.cardHolderName')}
          number={cardsForm.watch('input.cardNumber')}
          focused={focused}
        />
      </Grid>

      {/* Kart Bilgisi Formu */}
      <Grid item xs={12} md={type === 'add' ? 12 : 6}>
        <form onSubmit={cardsForm.handleSubmit(cardsOnSubmit)}>
          <Grid container spacing={2}>
            {/* Kart Numarası */}
            <Grid item xs={12}>
              <Controller
                name="input.cardNumber"
                control={cardsForm.control}
                rules={{ required: 'Kart numarası zorunludur' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Kart Numarası"
                    variant="outlined"
                    inputProps={{
                      maxLength: 16,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onFocus={() => setFocused('number')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Kart Sahibi Adı */}
            <Grid item xs={12}>
              <Controller
                name="input.cardHolderName"
                control={cardsForm.control}
                rules={{ required: 'Kart sahibi adı zorunludur' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Kart Sahibi Adı"
                    variant="outlined"
                    onFocus={() => setFocused('name')}
                    onChange={(e) => {
                      const uppercasedValue = e.target.value.toUpperCase();
                      field.onChange(uppercasedValue);
                    }}
                    value={field.value}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Son Kullanma Ayı ve Yılı */}
            <Grid item xs={6}>
              <Controller
                name="input.expireMonth"
                control={cardsForm.control}
                rules={{ required: 'Son kullanma ayı gereklidir' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="(MM)"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onFocus={() => setFocused('expiry')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="input.expireYear"
                control={cardsForm.control}
                rules={{ required: 'Son kullanma yılı gereklidir' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="(YY)"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onFocus={() => setFocused('expiry')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* CVC Alanı */}
            <Grid item xs={6}>
              <Controller
                name="input.cardAlias"
                control={cardsForm.control}
                rules={{ required: 'CVC gereklidir' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="CVC"
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                    onFocus={() => setFocused('cvc')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Gönderim Butonu */}
            {
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={organizationCardCreateOwnLoading}
                >
                  {organizationCardCreateOwnLoading ? <CircularProgress size={'1rem'} /> : null}
                  Kart Oluştur
                </Button>
              </Grid>
            }
          </Grid>
        </form>
      </Grid>
    </>
  );
};

interface IActiveSubscription {
  subscriptionData: ISubscriptionDetailResult | undefined;
  organizationData: IOrganizationDetailResult | undefined;
  organizationCardData: IOrganizationCardDetailOwnResult | undefined;
  setSelectedTabName: (name: string) => void;
}

const ActiveSubscription = ({
  subscriptionData,
  organizationData,
  organizationCardData,
  setSelectedTabName,
}: IActiveSubscription) => {
  const { t } = useTranslation();
  const [openPricingPackages, setOpenPricingPackages] = useState(false);
  const [statusChip, setStatusChip] = useState<{ label: string; color: string } | undefined>(
    undefined,
  );

  useEffect(() => {
    switch (organizationData?.status) {
      case OrganizationStatus.active:
        setStatusChip({
          label: t('crm.pricing.pricing.active'),
          color: 'success',
        });
        break;
      case OrganizationStatus.passive:
        setStatusChip({
          label: t('crm.pricing.pricing.passive'),
          color: 'warning',
        });
        break;
      case OrganizationStatus.restricted:
        if (organizationData?.activeSubscriptions?.main) {
          switch (organizationData?.activeSubscriptions?.main?.status) {
            case SubscriptionStatus.cancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.cancelled'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.expired:
              setStatusChip({
                label: t('crm.pricing.pricing.expired'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.completed:
              setStatusChip({
                label: t('crm.pricing.pricing.completed'),
                color: 'success',
              });
              break;
            case SubscriptionStatus.failed:
              setStatusChip({
                label: t('crm.pricing.pricing.failed'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithCancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithCancelled'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithFailure:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithFailure'),
                color: 'success',
              });
              break;
            default:
              setStatusChip(undefined);
          }
        } else {
          switch (organizationData?.activeSubscriptions?.trial?.status) {
            case SubscriptionStatus.cancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.cancelled'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.expired:
              setStatusChip({
                label: t('crm.pricing.pricing.expired'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.completed:
              setStatusChip({
                label: t('crm.pricing.pricing.completed'),
                color: 'success',
              });
              break;
            case SubscriptionStatus.failed:
              setStatusChip({
                label: t('crm.pricing.pricing.failed'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithCancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithCancelled'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithFailure:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithFailure'),
                color: 'success',
              });
              break;
            default:
              setStatusChip(undefined);
              break;
          }
        }
        break;
      default:
        setStatusChip(undefined);
        break;
    }
  }, [organizationData]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={6} lg={4.5}>
        <Typography fontSize={'15px'} fontWeight={700} pl={2}>
          {t('crm.pricing.pricing.currentPlan')}
        </Typography>
        <Card
          sx={{ border: '1px solid', borderColor: 'primary.light', mt: 1, p: 3, borderRadius: 3 }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'column'} justifyContent={'space-between'} gap={1}>
              <Typography fontWeight={700}>
                {subscriptionData?.paymentProduct?.name ??
                  (organizationData?.activeSubscriptions?.trial
                    ? t('settings.settings.settings.trial')
                    : '')}
              </Typography>
              <Typography fontSize={'large'}>
                {subscriptionData?.recurringInterval === RecurringInterval.monthly &&
                  '$' +
                    subscriptionData?.paymentProduct?.recurring?.monthly?.pricing?.discountedPrice +
                    '/' +
                    t('crm.pricing.pricing.monthly')}
                {subscriptionData?.recurringInterval === RecurringInterval.yearly &&
                  '$' +
                    subscriptionData?.paymentProduct?.recurring?.yearly?.pricing?.discountedPrice +
                    '/' +
                    t('crm.pricing.pricing.annually')}
                {organizationData?.activeSubscriptions?.trial &&
                  t('settings.settings.settings.free')}
              </Typography>
            </Stack>
            <Stack direction={'column'} gap={1}>
              {statusChip && (
                <Chip
                  label={statusChip.label}
                  color={statusChip.color as any}
                  sx={{ height: 40 }}
                />
              )}
              {
                <Box sx={{ visibility: 'hidden' }}>
                  <OutlinedButton
                    title={t('settings.settings.settings.browsePackages')}
                    onClick={() => setOpenPricingPackages(true)}
                  />
                </Box>
              }
            </Stack>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={4.5}>
        <Typography fontSize={'15px'} fontWeight={700} pl={2}>
          &nbsp;
        </Typography>
        <Card
          sx={{ border: '1px solid', borderColor: 'primary.light', mt: 1, p: 3, borderRadius: 3 }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'column'} gap={1} justifyContent={'space-between'}>
              <Typography fontWeight={700}>
                {!organizationData?.activeSubscriptions?.trial
                  ? t('crm.pricing.pricing.renewAt')
                  : t('settings.settings.settings.trialEnds')}
              </Typography>
              <Box height={'20px'} />
              <Typography fontSize={'large'}>
                {!organizationData?.activeSubscriptions?.trial
                  ? moment(subscriptionData?.nextPaymentDate)?.format('DD.MM.YYYY')
                  : moment(organizationData?.activeSubscriptions?.trial?.dueDate)?.format(
                      'DD.MM.YYYY',
                    )}
              </Typography>
            </Stack>
            <Stack direction={'column'} gap={1}>
              <OutlinedButton
                title={t('settings.settings.settings.cancelSubscription')}
                disabled
                onClick={() => null}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} lg={3} />
      <Grid item xs={12} md={4} lg={3} mt={2}>
        <Typography fontSize={'15px'} fontWeight={700} pl={2}>
          {t('crm.pricing.pricing.usageLimits')}
        </Typography>
        <Card
          sx={{ border: '1px solid', borderColor: 'primary.light', mt: 1, p: 3, borderRadius: 3 }}
        >
          <Stack direction={'column'} gap={1}>
            <StorageOutlined sx={{ fontSize: '20px', mb: 2 }} />
            <Typography fontSize={'small'}>{t('crm.pricing.pricing.storageUsage')}</Typography>
            <Typography fontSize={'large'}>
              {(
                (organizationData?.activeSubscriptions?.storageUsageLimit?.current ?? 0) / 1024
              )?.toFixed(0)}{' '}
              /{' '}
              {(
                (organizationData?.activeSubscriptions?.storageUsageLimit?.total ?? 0) / 1024
              )?.toFixed(0)}{' '}
              GB
            </Typography>
            <LinearProgress
              variant="buffer"
              color="secondary"
              value={
                ((organizationData?.activeSubscriptions?.storageUsageLimit?.current ?? 0) /
                  (organizationData?.activeSubscriptions?.storageUsageLimit?.total ?? 0)) *
                100
              }
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} lg={3} mt={2}>
        <Typography fontSize={'15px'} fontWeight={700} pl={2}>
          &nbsp;
        </Typography>
        <Card
          sx={{ border: '1px solid', borderColor: 'primary.light', mt: 1, p: 3, borderRadius: 3 }}
        >
          <Stack direction={'column'} gap={1}>
            <PeopleAltOutlined sx={{ fontSize: '20px', mb: 2 }} />
            <Typography fontSize={'small'}>{t('crm.pricing.pricing.users')}</Typography>
            <Typography fontSize={'large'}>
              {organizationData?.activeSubscriptions?.userUsageLimit?.current} /{' '}
              {organizationData?.activeSubscriptions?.userUsageLimit?.total}{' '}
              {t('crm.pricing.pricing.users')}
            </Typography>
            <LinearProgress
              variant="buffer"
              color="secondary"
              value={
                (Number(organizationData?.activeSubscriptions?.userUsageLimit?.current) /
                  Number(organizationData?.activeSubscriptions?.userUsageLimit?.total)) *
                100
              }
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} lg={3} mt={2}></Grid>

      {!organizationData?.activeSubscriptions?.trial ? (
        <Grid item xs={12} sm={6} lg={4.5} mt={2}>
          <Typography fontSize={'15px'} fontWeight={700} pl={2}>
            {t('crm.pricing.pricing.activeCard')}
          </Typography>
          <Card
            sx={{ border: '1px solid', borderColor: 'primary.light', mt: 1, p: 3, borderRadius: 3 }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack direction={'column'} gap={1}>
                <strong>
                  {organizationCardData?.cardDetails?.find(
                    (card: any) => card.cardToken === subscriptionData?.savedCardToken,
                  )?.cardBankName ?? ''}{' '}
                  {organizationCardData?.cardDetails?.find(
                    (card: any) => card.cardToken === subscriptionData?.savedCardToken,
                  )?.cardFamily ?? ''}
                </strong>
                {organizationCardData?.cardDetails?.find(
                  (card: any) => card.cardToken === subscriptionData?.savedCardToken,
                )?.binNumber ?? ''}{' '}
                XXXX XXXX
                <strong>
                  {organizationCardData?.cardDetails?.find(
                    (card: any) => card.cardToken === subscriptionData?.savedCardToken,
                  )?.cardAssociation ?? ''}
                </strong>
                <OutlinedButton
                  title={t('crm.pricing.pricing.manageCards')}
                  onClick={() => {
                    setSelectedTabName(t('settings.settings.settings.cards'));
                  }}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} lg={4.5} mt={2} />
      )}

      <ActionDialog
        open={openPricingPackages}
        onClose={() => setOpenPricingPackages(false)}
        title={t('settings.settings.settings.browsePackages')}
        width={1000}
      >
        <List />
      </ActionDialog>
    </Grid>
  );
};

export default Payment;
