/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { ITicketDetailResult } from "corede-common-cocrm";
import { t } from "i18next";
import React from "react";

export interface ITicketDetailDrawerHeaderComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
}

/**
 * Container for views in header part of detail view
 */
export const TicketDetailDrawerHeaderComponent = (
  props: ITicketDetailDrawerHeaderComponentProps
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));

  return (
    <Stack
      direction={{ lg: "column" }}
      alignItems={{ xs: "center", lg: "flex-start" }}
      gap={1}
    >
      <Typography
        variant={downLg ? "h5" : "h4"}
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        {t("crm.calendar.ticket.detail")}
      </Typography>
      <Divider sx={{ width: "100%" }} />
    </Stack>
  );
};
