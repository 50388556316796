import { IMenuItem } from 'interfaces/menu-item.interface';
import { useTranslation } from 'react-i18next';
import {
  baseCustomerRoute,
  listCustomerRoute,
  listContactRoute,
  listCustomerCategoryRoute,
} from '../routes/customer.base.route';
import { PermissionSubdomain } from 'corede-common-cocrm';

export const useCustomerDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t('customer'),
    path: baseCustomerRoute(),
    children: [
      {
        itemId: listCustomerRoute(),
        title: t('customers'),
        path: listCustomerRoute(),
        subdomain: PermissionSubdomain.customer,
      },
      {
        itemId: listContactRoute(),
        title: t('contacts'),
        path: listContactRoute(),
      },
      {
        itemId: listCustomerCategoryRoute(),
        title: t('customerCategories'),
        path: listCustomerCategoryRoute(),
        subdomain: PermissionSubdomain.customerCategory,
      },
    ],
  };
};
