import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Id, Task } from './TaskList-kanban.components.types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Iconify } from 'components';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';
import moment from 'moment';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { ITask } from 'corede-common-cocrm';

interface Props {
  task: Task;
  updateTask: (id: Id, content: string) => void;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  setOpenTaskDetailDrawer: Dispatch<SetStateAction<boolean>>;
}

const TaskCard: React.FC<Props> = ({
  task,
  updateTask,
  setSelectedTask,
  setOpenTaskDetailDrawer,
}) => {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: task.id,
    data: { type: 'Task', task },
    disabled: editMode,
  });

  const theme = useTheme();

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.3 : 1,
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: 2,
        height: 'fixed',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        borderRadius: 1,
        boxShadow: isDragging ? '0 0 10px rgba(0, 0, 0, 0.2)' : '0 0 10px rgba(0, 0, 0, 0.2)',
        cursor: editMode ? 'text' : 'grab',
        position: 'relative',
        '&:hover': {
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        },
      }}
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
      onClick={() => {
        setOpenTaskDetailDrawer(true);
        setSelectedTask({
          id: task.id as number,
          _id: task.id as string,
          subject: task.subject,
          description: task.description,
          assignees: task.assignees,
        } as TListGridSelectedEntity<ITask>);
      }}
    >
      {/* {editMode ? (
        <TextareaAutosize
          autoFocus
          value={task.subject}
          placeholder="Task content here"
          onChange={(e) => updateTask(task.id, e.target.value)}
          onBlur={toggleEditMode}
          onKeyDown={(e) => {
            if (e.shiftKey && e.key === 'Enter') toggleEditMode();
          }}
          style={{
            width: '100%',
            height: '90%',
            resize: 'none',
            background: 'transparent',
            border: 'none',
            outline: 'none',
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontFamily: theme.typography.fontFamily,
          }}
        />
      ) : ( */}
      <Typography
        onClick={toggleEditMode}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          whiteSpace: 'pre-wrap',
          flexGrow: 1,
          cursor: 'pointer',
          fontSize: '15px',
          fontWeight: 600,
          color: 'secondary.main',
        }}
      >
        {task.subject}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: 'text.secondary',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
        }}
      >
        {task.description}
      </Typography>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
          <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={0.5}>
            <Iconify icon={'mingcute:checkbox-fill'} width={20} sx={{ color: 'text.primary' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              {task.checklistCompleted}/{task.checklistTotal}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={0.5}>
            <Iconify icon={'mynaui:paperclip-solid'} width={20} sx={{ color: 'text.primary' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{task.documentCount}</Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
          <AvatarGroupBase userList={task.assignees} max={3} width={26} />
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
        <Iconify icon={'solar:calendar-linear'} width={18} sx={{ color: 'text.secondary' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'text.secondary' }}>
          {' '}
          {moment(task.dueDate).calendar(null, {
            sameDay: '[Today], h:mm A',
            nextDay: '[Tomorrow], h:mm A',
            lastDay: '[Yesterday], h:mm A',
            sameElse: 'MMM DD, YYYY, h:mm A',
          })}
        </Typography>
      </Stack>
      {/* )} */}
    </Box>
  );
};

export default TaskCard;
