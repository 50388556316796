import {
  ILeadSourcesInput, ILeadSourcesRequest, ILeadSourcesResponse, ILeadSourcesResult, leadSourcesQuery,
  ICreateLeadSourceInput, ICreateLeadSourceRequest, ICreateLeadSourceResponse, ICreateLeadSourceResult, createLeadSourceQuery,
  IUpdateLeadSourceInput, IUpdateLeadSourceRequest, IUpdateLeadSourceResponse, IUpdateLeadSourceResult, updateLeadSourceQuery, IUpdateLeadSourceFilterInput,
  IDeleteLeadSourceInput, IDeleteLeadSourceRequest, IDeleteLeadSourceResponse, IDeleteLeadSourceResult, deleteLeadSourceQuery,
  ILeadSourceInput,
  ILeadSourceListCountRequest,
  ILeadSourceListCountResponse,
  ILeadSourceRequest,
  ILeadSourceResponse,
  ILeadSourceResult,
  leadSourceListCountQuery,
  leadSourceQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadSourceTags } from "./leadSource.tags";
import { ICount, IGraphqlVariables } from "corede-common";

export const leadSourcesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    leadSourceList: builder.query<
      ILeadSourcesResult,
      IGraphqlVariables<ILeadSourcesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadSourcesRequest,
        ILeadSourcesResponse,
        ILeadSourcesResult,
        ILeadSourcesInput
      >({
        query: leadSourcesQuery,
        providesTags: [leadSourceTags.leadSources],
      })),

      leadSourceDetail: builder.query<
      ILeadSourceResult,
      IGraphqlVariables<ILeadSourceInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadSourceRequest,
        ILeadSourceResponse,
        ILeadSourceResult,
        ILeadSourceInput
      >({
        query: leadSourceQuery,
        providesTags: [leadSourceTags.leadSource],
      })
    ),



    leadSourceListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadSourceListCountRequest,
        ILeadSourceListCountResponse,
        ICount,
        undefined
      >({
        query: leadSourceListCountQuery,
        providesTags: [leadSourceTags.leadSources],
      })
    ),

    // mutation

    leadSourceCreate: builder.mutation<
      ICreateLeadSourceResult,
      IGraphqlVariables<ICreateLeadSourceInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateLeadSourceRequest,
        ICreateLeadSourceResponse,
        ICreateLeadSourceResult,
        ICreateLeadSourceInput
      >({
        query: createLeadSourceQuery,
        invalidatesTags: [leadSourceTags.leadSource, leadSourceTags.leadSources],
      })),

    leadSourceUpdate: builder.mutation<
      IUpdateLeadSourceResult,
      IGraphqlVariables<IUpdateLeadSourceInput, IUpdateLeadSourceFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateLeadSourceRequest,
        IUpdateLeadSourceResponse,
        IUpdateLeadSourceResult,
        IUpdateLeadSourceInput,
        IUpdateLeadSourceFilterInput
      >({
        query: updateLeadSourceQuery,
        invalidatesTags: [leadSourceTags.leadSource, leadSourceTags.leadSources],
      })),


    leadSourceDelete: builder.mutation<
      IDeleteLeadSourceResult,
      IGraphqlVariables<IDeleteLeadSourceInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteLeadSourceRequest,
        IDeleteLeadSourceResponse,
        IDeleteLeadSourceResult,
        IDeleteLeadSourceInput
      >({
        query: deleteLeadSourceQuery,
        invalidatesTags: [leadSourceTags.leadSources],
      })),


  }),
});

export const {
  useLeadSourceListQuery,
  useLazyLeadSourceListQuery,
  useLeadSourceDetailQuery,
  useLazyLeadSourceDetailQuery,
  useLeadSourceListCountQuery,
  useLazyLeadSourceListCountQuery,
  useLeadSourceCreateMutation,
  useLeadSourceUpdateMutation,
  useLeadSourceDeleteMutation,
} = leadSourcesApi;
