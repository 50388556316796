import { Stack, Typography, Grid, Box } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLeadDetailQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useCustomerDetailQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { useEstimateDetailQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalDetailQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceDetailQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { useEffect, useState } from 'react';
import { useUserDetailQuery } from 'apps/auth/context';
import { getTranslatedEnumValue } from 'localization';
import {
  DocumentTargetType,
  NoteTargetType,
  ReminderTargetType,
  TaskTargetType,
  TicketTargetType,
} from 'corede-common-cocrm';
import { useTaskDetailQuery } from 'apps/crm/domains/11-task/subdomains/task';

interface ITargetInfo {
  name?: string;
  type?:
    | TaskTargetType
    | NoteTargetType
    | DocumentTargetType
    | ReminderTargetType
    | TicketTargetType;
  email?: string;
  phone?: string;
}

export const DetailDrawerTargetComponent = (props: {
  targetType:
    | TaskTargetType
    | NoteTargetType
    | DocumentTargetType
    | ReminderTargetType
    | TicketTargetType;
  targetId: string;
}) => {
  const [target, setTarget] = useState<ITargetInfo>({});

  const {
    data: leadDetailData,
    isLoading: leadDetailLoading,
    error: leadDetailError,
  } = useLeadDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Lead),
    },
  );

  const {
    data: customerDetailData,
    isLoading: customerDetailLoading,
    error: customerDetailError,
  } = useCustomerDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Customer),
    },
  );

  const {
    data: estimateDetailData,
    isLoading: estimateDetailLoading,
    error: estimateDetailError,
  } = useEstimateDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Estimate),
    },
  );

  const {
    data: proposalDetailData,
    isLoading: proposalDetailLoading,
    error: proposalDetailError,
  } = useProposalDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Proposal),
    },
  );

  const {
    data: invoiceDetailData,
    isLoading: invoiceDetailLoading,
    error: invoiceDetailError,
  } = useInvoiceDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Invoice),
    },
  );

  const {
    data: userDetailData,
    isLoading: userDetailLoading,
    error: userDetailError,
  } = useUserDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.User),
    },
  );

  const {
    data: taskDetailData,
    isLoading: taskDetailLoading,
    error: taskDetailError,
  } = useTaskDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TicketTargetType.Task),
    },
  );

  useEffect(() => {
    if (leadDetailData && props.targetType === TaskTargetType.Lead) {
      setTarget({
        name: leadDetailData.fullName,
        email: leadDetailData.email,
        phone: leadDetailData.phoneNumber,
        type: TaskTargetType.Lead,
      });
    } else if (customerDetailData && props.targetType === TaskTargetType.Customer) {
      setTarget({
        name: customerDetailData.name,
        email: customerDetailData.email,
        phone: customerDetailData.phone,
        type: TaskTargetType.Customer,
      });
    } else if (estimateDetailData && props.targetType === TaskTargetType.Estimate) {
      setTarget({
        name: estimateDetailData.prefix,
        email: estimateDetailData.prospectContactEmail,
        phone: estimateDetailData.prospectPhoneNumber,
        type: TaskTargetType.Estimate,
      });
    } else if (proposalDetailData && props.targetType === TaskTargetType.Proposal) {
      setTarget({
        name: proposalDetailData.prefix,
        email: proposalDetailData.prospectContactEmail,
        phone: proposalDetailData.prospectPhoneNumber,
        type: TaskTargetType.Proposal,
      });
    } else if (invoiceDetailData && props.targetType === TaskTargetType.Invoice) {
      setTarget({
        name: invoiceDetailData.prefix,
        email: invoiceDetailData.customerContactEmail,
        phone: '-',
        type: TaskTargetType.Invoice,
      });
    } else if (userDetailData && props.targetType === TaskTargetType.User) {
      setTarget({
        name: userDetailData.name + ' ' + userDetailData.surname,
        email: userDetailData.email,
        phone: userDetailData.phoneNumber,
        type: TaskTargetType.User,
      });
    } else if (taskDetailData && props.targetType === TicketTargetType.Task) {
      setTarget({
        name: taskDetailData.subject,
        email: '-',
        phone: '-',
        type: TicketTargetType.Task,
      });
    }
  }, [
    leadDetailData,
    customerDetailData,
    estimateDetailData,
    proposalDetailData,
    invoiceDetailData,
    userDetailData,
    taskDetailData,
    props.targetType,
  ]);

  const { t } = useTranslation();
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: 'text.primary' }}>
          {t('crm.target')}
        </Typography>
        {/* <OutlinedButton title={t('crm.targetDetail')} onClick={() => {}} /> */}
      </Stack>
      <Grid container>
        <Grid item xs={6}>
          <Stack
            direction={'row'}
            gap={2}
            mt={1}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            sx={{ width: '100%', zIndex: 1 }}
          >
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-start'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.main',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icons.PersonOutlined sx={{ color: 'primary.lighter', fontSize: 'medium' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography fontSize={'12px'} fontWeight={600} color={'text.primary'}>
                  {t('crm.name')}
                </Typography>
                <Typography fontSize={'11px'} fontWeight={400} color={'text.secondary'}>
                  {target.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            gap={2}
            mt={2}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            sx={{ width: '100%', zIndex: 1 }}
          >
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'primary.light',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icons.CategoryOutlined sx={{ color: 'primary.lighter', fontSize: 'medium' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography fontSize={'12px'} fontWeight={600} color={'text.primary'}>
                  {t('crm.type')}
                </Typography>
                <Typography fontSize={'11px'} fontWeight={400} color={'text.secondary'}>
                  {getTranslatedEnumValue(target.type as TaskTargetType)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction={'row'}
            gap={2}
            mt={1}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            sx={{ width: '100%', zIndex: 1 }}
          >
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-start'}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.main',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icons.EmailOutlined sx={{ color: 'primary.lighter', fontSize: 'medium' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography fontSize={'12px'} fontWeight={600} color={'text.primary'}>
                  {t('crm.email')}
                </Typography>
                <Typography fontSize={'11px'} fontWeight={400} color={'text.secondary'}>
                  {target.email}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            gap={2}
            mt={2}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            sx={{ width: '100%', zIndex: 1 }}
          >
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'primary.light',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icons.PhoneOutlined sx={{ color: 'primary.lighter', fontSize: 'medium' }} />
              </Box>
              <Stack direction={'column'}>
                <Typography fontSize={'12px'} fontWeight={600} color={'text.primary'}>
                  {t('crm.phone')}
                </Typography>
                <Typography fontSize={'11px'} fontWeight={400} color={'text.secondary'}>
                  {target.phone}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
