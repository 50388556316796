import { useState, lazy, Suspense } from 'react';
import { INoteUpdateOverlay } from './NoteUpdateOverlay';

const NoteUpdateDrawer = lazy(() => import('./NoteUpdateOverlay'));

const NoteUpdateDrawerWrapper = ({
  open,
  setOpen,
  targetId,
  targetType,
  selectedNoteId,
  overlayType,
}: INoteUpdateOverlay) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <NoteUpdateDrawer
            open={open}
            setOpen={setOpen}
            targetId={targetId}
            targetType={targetType}
            selectedNoteId={selectedNoteId}
            overlayType={overlayType}
          />
        </Suspense>
      )}
    </>
  );
};

export default NoteUpdateDrawerWrapper;
