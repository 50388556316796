/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, Tooltip, IconButton } from '@mui/material';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';
import { IBaseError } from 'corede-common';
import { ITaskDetailResult, IUser } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useTaskUpdateMutation } from '../../context/task.api';
import * as Icons from '@mui/icons-material';
import { AddAssigneeDialog } from './TaskDetailDrawer-rightPanel-infoSection-asignees-add.component';

export const DetailDrawerAsigneesComponent = (props: {
  taskDetailData: ITaskDetailResult | undefined;
}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openAssigneeAddDialog, setOpenAssigneeAddDialog] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState<IUser | undefined>(undefined);

  const { data: userListData } = useUserListQuery({});

  const [
    taskUpdate,
    { data: taskUpdateData, isLoading: taskUpdateLoading, error: taskUpdateError },
  ] = useTaskUpdateMutation();

  const filteredUserList = useMemo(() => {
    if (!props.taskDetailData?.assignees) return [];
    return userListData?.data?.filter((user) => {
      return !props.taskDetailData?.assignees?.find(
        (assignedUser) => assignedUser._id === user._id,
      );
    });
  }, [props.taskDetailData?.assignees, userListData?.data]);

  const handleAddAssignee = async () => {
    if (!selectedAssignee) return;
    await taskUpdate({
      input: {
        assigneeIds: [
          ...(props.taskDetailData?.assignees?.map((item) => item._id) ?? []),
          selectedAssignee._id,
        ],
      },
      filter: {
        _id: props.taskDetailData?._id ?? '',
      },
    });
  };

  useEffect(() => {
    if (taskUpdateData) {
      setSelectedAssignee(undefined);
      setOpenAssigneeAddDialog(false);
      enqueueSnackbar(t('crm.task.task.updateSuccess'), { variant: 'success' });
    }
  }, [taskUpdateData]);

  useEffect(() => {
    if (taskUpdateError) {
      DefaultErrorHandlerUseEffect(taskUpdateError as IBaseError, currentLanguage);
    }
  }, [taskUpdateError]);

  return (
    <Stack direction={'column'} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: 'relative' }}>
      <Typography sx={{ fontWeight: 600, fontSize: '16px', color: 'text.primary' }}>
        {t('crm.assignees')}
      </Typography>
      <Stack direction={'row'} mt={1} justifyContent={'flex-start'}>
        <AvatarGroupBase userList={props.taskDetailData?.assignees as IUser[]} max={10} />
        <Tooltip title={t('crm.addAssignee')}>
          <IconButton
            sx={{
              border: '2px solid',
              borderColor: 'primary.light',
              width: 35,
              height: 35,
              ml: 1,
              color: 'primary.main',
            }}
            onClick={() => setOpenAssigneeAddDialog(true)}
          >
            <Icons.Add />
          </IconButton>
        </Tooltip>
      </Stack>

      <AddAssigneeDialog
        openAssigneeAddDialog={openAssigneeAddDialog}
        setOpenAssigneeAddDialog={setOpenAssigneeAddDialog}
        filteredUserList={filteredUserList as IUser[]}
        selectedAssignee={selectedAssignee}
        setSelectedAssignee={setSelectedAssignee}
        taskUpdateLoading={taskUpdateLoading}
        handleAddAssignee={handleAddAssignee}
      />
    </Stack>
  );
};
