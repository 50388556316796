/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from 'corede-common';
import { Dispatch, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityDeleteDialog } from 'components/entityDeleteDialog/EntityDeleteDialog';
import { useProposalDeleteMutation } from '../../context/proposal.api';

export const ProposalDeleteDialog = memo(
  (props: {
    open: boolean;
    proposalId: string;
    onClose: () => void;
    setProposal: Dispatch<any>;
  }) => {
    // general
    const { t } = useTranslation();
    // mutations
    const [
      proposalDelete,
      { data: proposalDeleteData, isLoading: proposalDeleteIsLoading, error: proposalDeleteError },
    ] = useProposalDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.proposalId}
        setItem={props.setProposal}
        deleteMutation={proposalDelete}
        deleteMutationData={{
          data: proposalDeleteData,
          isLoading: proposalDeleteIsLoading,
          error: proposalDeleteError as IBaseError,
        }}
        dialog={{
          title: t('crm.preSale.proposal.delete'),
          successMessage: t('crm.preSale.proposal.deletedSuccessfully'),
          content: t('crm.preSale.proposal.deleteConfirm'),
        }}
        onClose={props.onClose}
      />
    );
  },
);
