/* eslint-disable react-hooks/exhaustive-deps */
import { IProposalDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ProposalDetailDrawerRightPanelHeaderComponent } from './ProposalDetailDrawer-rightPanel-header.component';
import { ProposalDetailDrawerRightPanelTabsComponent } from './ProposalDetailDrawer-rightPanel.tabs.component';

export interface IProposalDetailDrawerRightPanelComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
}

export const ProposalDetailDrawerRightPanelComponent = (
  props: IProposalDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ProposalDetailDrawerRightPanelHeaderComponent proposalDetailData={props.proposalDetailData} />

      {/* Tabs Section */}
      <ProposalDetailDrawerRightPanelTabsComponent proposalDetailData={props.proposalDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
