const locale = {
  projects: "Projeler",
  project: "Proje",
  projectConversions: "Proje Dönüştürme",
  title: "Projeler",
  addProject: "Proje Ekle",
  emptyStateDesc1: "Henüz başlatılmış bir proje yok.",
  emptyStateDesc2: "İlerlemeyi takip etmek, görevler atamak ve ekibinizle işbirliği yapmak için projelerinizi burada oluşturun ve yönetin."
};

export default locale;



 