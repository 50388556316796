import { Outlet } from 'react-router';
import MainLayout from 'layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import { baseAccountRoute, listUsersRoute } from '../domains/profile/routes/profile.base.route';

const AccountLayout = () => {
  const { t } = useTranslation();

  const treeItems = [
    {
      itemId: baseAccountRoute(),
      label: t('account.profile.profile.profile'),
      href: baseAccountRoute(),
      children: [],
    },
  ];

  return (
    <MainLayout treeItems={treeItems}>
      <Outlet />
    </MainLayout>
  );
};

export default AccountLayout;
