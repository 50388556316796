import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { TEnum } from 'components/baseDataGrid/infra/types';
import { IGraphqlVariables } from 'corede-common';
import { getTranslatedEnumValue } from 'localization';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DefaultEnumAutocompleteInput = (props: {
  inputName: `input.${string}`;
  control: Control<IGraphqlVariables<any, any>>;
  options: readonly {
    name: string;
    _id: any;
  }[];
  enum: TEnum;
  label: string;
  isRequired?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            id={field.name}
            key={field.name}
            size="small"
            fullWidth
            isOptionEqualToValue={(option, value) => option._id === value._id}
            options={props.options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} />}
            value={
              field.value ? { name: getTranslatedEnumValue(field.value), _id: field.value } : null
            }
            onChange={(e, value) => {
              field.onChange(value?._id);
            }}
            disableClearable={props.disableClearable}
            disabled={props.disabled}
          />
        )}
      />
    </>
  );
};
