/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  IInvoicePayment,
  IInvoicePaymentDetailResult,
  IInvoicePaymentListInput,
  IInvoicePaymentListItemResult,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useInvoicePaymentListQuery,
  useInvoicePaymentUpdateMutation,
} from '../../context/payment.api';
// import Delete from '../Delete';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';
import PaymentUpdateDrawerWrapper from '../update/PaymentUpdateWrapper';
import { PaymentDeleteDialog } from '../delete/PaymentDeleteDialog';
import PaymentDetailDrawerWrapper from '../detailDrawer/PaymentDetailDrawer.wrapper';

export interface IPaymentListGridComponent {
  // payment
  setOpenPaymentCreateDrawer?: (value: boolean) => void | undefined;
}

const PaymentListGridComponent = memo((props: IPaymentListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedPayment, setSelectedPayment] =
    useState<TListGridSelectedEntity<IInvoicePaymentListItemResult>>();
  const [openPaymentDetailDrawer, setOpenPaymentDetailDrawer] = useState(false);
  const [openPaymentDeleteDialog, setOpenPaymentDeleteDialog] = useState(false);
  const [openPaymentUpdateDrawer, setOpenPaymentUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [paymentListFilter, setPaymentListFilter] = useState<
    IGraphqlVariables<IInvoicePaymentListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = paymentListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = paymentListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: paymentListData,
    isLoading: paymentListLoading,
    isFetching: paymentListFetching,
    error: paymentListError,
    refetch,
  } = useInvoicePaymentListQuery(paymentListFilter);

  // used for direct update in the list rows
  const [paymentUpdate, { isLoading: paymentUpdateLoading, error: paymentUpdateError }] =
    useInvoicePaymentUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setPaymentListLoading && props.setPaymentListLoading(paymentListLoading);
  // }, [paymentListLoading]);
  useEffect(() => {
    refetch();
  }, [paymentListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(paymentListError as IBaseError, currentLanguage);
  }, [paymentListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(paymentUpdateError as IBaseError, currentLanguage);
  }, [paymentUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(paymentListFilter?.input?.filter);
  const isJunkUndefined = (paymentListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = paymentListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    paymentListData?.data?.length === 0 &&
    !paymentListLoading &&
    !paymentListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedPayment}
      setSelectedEntity={setSelectedPayment}
      listFilter={paymentListFilter}
      setListFilter={setPaymentListFilter}
      listData={paymentListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {paymentListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                paymentListData?.data?.map((payment, index) => ({
                  id:
                    ((paymentListFilter.input?.pagination?.page || 1) - 1) *
                      (paymentListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: payment._id,
                  invoice: payment.invoice,
                  paymentId: payment.paymentID || '-',
                  prefix: payment.paymentPrefix || '-',
                  paymentMethod: payment.paymentMethod || '-',
                  date: representDateAsString(payment.paymentDate) || '-',
                  currency: payment.paymentCurrency || '-',
                  invoiceId: payment.invoice.invoiceId || '-',
                  confirmationNumber: payment.confirmationNumber || '-',
                  amountPaid: payment.amountPaid || '-',
                  createdAt: representDateAsString(payment.createdAt) || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'paymentId',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.paymentId'),
                      icon: 'mdi:identifier',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prefix',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.prefix'),
                      icon: 'mdi:label-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'paymentMethod',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.paymentMethod'),
                      icon: 'mdi:credit-card-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'date',
                      editable: true,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.date'),
                      icon: 'mdi:calendar-range',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'currency',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.currency'),
                      icon: 'mdi:currency-usd-circle-outline',
                    },
                    filterConfig: {
                      customFieldName: `currency`,
                    },
                  },
                  data: Currency,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'invoiceId',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.invoiceId'),
                      icon: 'mdi:file-document-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'confirmationNumber',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.confirmationNumber'),
                      icon: 'mdi:check-circle-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'amoundPaid',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.amoundPaid'),
                      icon: 'mdi:cash-check',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'createdAt',
                      filterable: false,
                      editable: false,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.createdAt'),
                      icon: 'mdi:clock-time-eight-outline',
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedPayment,
                      setOpenAction: setOpenPaymentDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedPayment,
                      setOpenAction: setOpenPaymentUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedPayment,
                      setOpenAction: setOpenPaymentDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={paymentListLoading || paymentUpdateLoading || paymentListFetching}
              listFilter={{
                filterInput: paymentListFilter,
                setFilterInput: setPaymentListFilter,
              }}
              update={{
                updateQuery: paymentUpdate,
              }}
              count={paymentListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  sector: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.sale.payment.emptyState1')}
              content2={t('crm.sale.payment.emptyState2')}
              button1={{
                title: t('crm.sale.payment.create'),
                onClick: () => (props?.setOpenPaymentCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <PaymentDetailDrawerWrapper
        open={openPaymentDetailDrawer}
        setOpen={setOpenPaymentDetailDrawer}
        selectedPayment={selectedPayment as IInvoicePaymentDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: paymentListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: paymentListData,
            listFilter: paymentListFilter,
            setListFilter: setPaymentListFilter,
            selectedEntity: selectedPayment,
            setSelectedEntity: setSelectedPayment,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <PaymentUpdateDrawerWrapper
        open={openPaymentUpdateDrawer}
        setOpen={setOpenPaymentUpdateDrawer}
        selectedPayment={selectedPayment}
      />

      <PaymentDeleteDialog
        paymentId={selectedPayment?._id ?? ''}
        invoiceId={selectedPayment?.invoice?._id ?? ''}
        open={openPaymentDeleteDialog}
        onClose={() => {
          setSelectedPayment(undefined);
          setOpenPaymentDeleteDialog(false);
        }}
      />
    </DefaultListGridComponent>
  );
});

export default PaymentListGridComponent;
