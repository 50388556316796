/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { listUsersRoute } from '../../../../routes/organizationalChart.base.route';
import { useUserDetailOwnQuery, useUserDetailQuery } from 'apps/auth/context';
import BgUserDetail from 'assets/images/bg-user-detail.jpg';
import { UserDetailProfileSectionComponent } from './UserDetail-profileSection.component';
import { MyTab } from 'components';
import { UserDetailTabInfoSectionComponent } from './UserDetail-tab-infoSection.component';
import UserUpdateDrawerWrapper from '../update/UserUpdateDialog.wrapper';
import { DefaultDetailTabsComponent } from 'apps/crm/components/detail/DefaultDetail.tabs.component';
import { UserPermissionDetailTab } from '../../../permission/pages/components/UserPermissionDetailTab';
import { AssociatedLeadsTab } from 'apps/crm/domains/03-lead/subdomains/lead/components/AssociatedLeadsTab';
import { AssociatedCustomersTab } from 'apps/crm/domains/04-customer/subdomains/customer/components/AssociatedCustomersTab';
import { AssociatedEstimatesTab } from 'apps/crm/domains/05-preSale/subdomains/estimate/components/AssociatedEstimatesTab';
import { AssociatedProposalsTab } from 'apps/crm/domains/05-preSale/subdomains/proposal/components/AssociatedProposalsTab';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import { NoteTargetType, ReminderTargetType, TaskTargetType } from 'corede-common-cocrm';
import {
  useUserDocumentCreateManyMutation,
  useUserDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import { DefaultEmptyTabComponent } from 'apps/crm/components/tabs/DefaultTab.emptyState.component';

/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface

 */
export const UserDetail = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const { id } = useParams();
  if (!id) {
    navigate(listUsersRoute());
  }

  const tabNamesArray: [string, string][] = [
    ['info', t('crm.info')],
    ['associatedLeads', t('crm.organizationalChart.user.associatedLeads')],
    ['associatedCustomers', t('crm.organizationalChart.user.associatedCustomers')],
    ['associatedEstimates', t('crm.organizationalChart.user.associatedEstimates')],
    ['associatedProposals', t('crm.organizationalChart.user.associatedProposals')],
    ['notes', t('crm.notes')],
    ['reminders', t('crm.reminders')],
    ['tasks', t('crm.tasks')],
    ['documents', t('crm.documentLabel')],
    ['activities', t('crm.activities')],
  ];
  if (userDetailOwnData?._id !== id) {
    tabNamesArray.splice(1, 0, ['permissions', t('crm.organizationalChart.user.permissions')]);
  }

  const tabNames = new Map<string, string>(tabNamesArray);

  const tabKeys = Array.from(tabNames.keys());
  const tabValues = Array.from(tabNames.values());
  const firstSelectedTab = tabNames.get(tabKeys.at(0) ?? '');

  // useStates
  const [selectedTabName, setSelectedTabName] = useState(firstSelectedTab);
  const [openUserUpdateDrawer, setOpenUserUpdateDrawer] = useState(false);

  // queries
  const { data: userData } = useUserDetailQuery({
    input: {
      _id: id || '',
    },
  });

  return (
    <Grid item xs={12}>
      <HeaderBase title={t('crm.organizationalChart.user.detail')} showBackButton={true} />

      <Stack
        direction={'column'}
        mt={2}
        borderRadius={'20px'}
        sx={{ boxShadow: '0px 0px 20px #ccc' }}
      >
        <Box
          width={'100%'}
          component={'img'}
          src={BgUserDetail}
          sx={{
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            height: '200px',
            filter: 'brightness(0.8)',
          }}
        />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={'center'}
          mx={2}
          position={'relative'}
        >
          <UserDetailProfileSectionComponent userData={userData} />

          <Stack width={'100%'} direction={'row'} justifyContent={'flex-end'}>
            <MyTab
              labels={tabValues}
              setSelectedTab={setSelectedTabName}
              selectedTab={selectedTabName}
            />
          </Stack>
        </Stack>
      </Stack>

      <DefaultDetailTabsComponent
        tabNames={tabNames}
        selectedTabName={selectedTabName}
        entityData={userData}
      >
        <UserDetailTabInfoSectionComponent
          userData={userData}
          setOpenUserUpdateDrawer={setOpenUserUpdateDrawer}
          tabKey="info"
          name="crm.info"
        />

        <UserPermissionDetailTab
          userData={userData}
          tabKey="permissions"
          name="crm.permissions"
          entityData={userData}
        />

        <AssociatedLeadsTab
          tabKey="associatedLeads"
          name="crm.organizationalChart.user.associatedLeads"
        />

        <AssociatedCustomersTab
          tabKey="associatedCustomers"
          name="crm.organizationalChart.user.associatedCustomers"
        />

        <AssociatedEstimatesTab
          tabKey="associatedEstimates"
          name="crm.organizationalChart.user.associatedEstimates"
        />

        <AssociatedProposalsTab
          tabKey="associatedProposals"
          name="crm.organizationalChart.user.associatedProposals"
        />

        <NoteListTabComponent
          targetId={userData?._id ?? ''}
          targetEntityRef={NoteTargetType.User}
          targetDisplayName={userData?.name + ' ' + userData?.surname}
          tabKey="notes"
          name="crm.notes"
        />

        <ReminderListTabComponent
          targetId={userData?._id ?? ''}
          targetEntityRef={ReminderTargetType.User}
          targetDisplayName={userData?.name + ' ' + userData?.surname}
          tabKey="reminders"
          name="crm.reminders"
        />

        <TaskListTabComponent
          tabKey="tasks"
          name="crm.tasks"
          targetId={userData?._id ?? ''}
          targetEntityRef={TaskTargetType.User}
          entityData={userData}
          nameTranslation={t('crm.tasks')}
        />

        <DocumentListTabComponent
          targetId={userData?._id ?? ''}
          tabKey={'documents'}
          name={'crm.documents'}
          useDocumentCreateManyMutation={useUserDocumentCreateManyMutation}
          useDocumentAddManyMutation={useUserDocumentAddManyMutation}
        />

        <DefaultEmptyTabComponent
          title={t('crm.activities')}
          emptyStateContent={t('crm.noActivityContent1')}
          tabKey="activities"
          nameTranslation={t('crm.activities')}
        />
      </DefaultDetailTabsComponent>

      <Overlays
        openUserUpdateDrawer={openUserUpdateDrawer}
        setOpenUserUpdateDrawer={setOpenUserUpdateDrawer}
        userData={userData}
      />
    </Grid>
  );
});

const Overlays = ({ openUserUpdateDrawer, setOpenUserUpdateDrawer, userData }: any) => {
  return (
    <>
      <UserUpdateDrawerWrapper
        open={openUserUpdateDrawer}
        setOpen={setOpenUserUpdateDrawer}
        selectedUser={userData}
      />
    </>
  );
};
