

import { ReportsDomainLayout } from "../layouts";
import { ReportsRouter } from "../subdomains/report/routes/ReportsRouter";
import { reportsDomainRoutes } from "./reports.base.route";
import { Route, Routes } from "react-router-dom";

export const ReportsDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportsDomainLayout />}>
        <Route index element={<ReportsRouter />} />
        <Route path="*" element={<ReportsRouter />} />
      </Route>
    </Routes>
  );
};
