import { ExpandMore } from '@mui/icons-material';
import { ITaskListItemResult } from 'corede-common-cocrm';
import {
  Grid,
  Stack,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { Iconify } from 'components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';

interface ITaskListItemProps {
  task: ITaskListItemResult;
}

const TaskListItemComponent = (props: ITaskListItemProps) => {
  const { t } = useTranslation();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        boxShadow: 'none',
        borderRadius: '24px !important',
        border: '1px solid',
        borderColor: 'grey.300',
        padding: '0',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          alignItems: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Grid container pl={1.5} sx={{ width: '100%' }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <Box
                sx={{
                  width: 22,
                  height: 22,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="tabler:activity" width={14} sx={{ color: 'secondary.main' }} />
              </Box>

              <Typography variant={'subtitle2'}>
                <span style={{ color: 'secondary.main' }}>{t('crm.task.task.createdBy')} </span>{' '}
                <strong>
                  {props.task.createdBy?.name} {props.task.createdBy?.surname}{' '}
                </strong>
              </Typography>
            </Stack>
          </Grid>
          {!downSm && (
            <Grid item xs={6} justifyItems={'flex-end'}>
              <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                <Iconify icon="solar:calendar-linear" width={18} sx={{ color: 'text.secondary' }} />

                <Typography
                  variant={'subtitle2'}
                  sx={{ fontWeight: '500', color: 'text.secondary' }}
                >
                  {moment(props.task.dueDate).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 5.5 }}>
        <Grid container pl={1.5} sx={{ width: '100%' }} spacing={2}>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Stack>
                <Typography variant={'subtitle2'} sx={{ fontWeight: '600' }}>
                  {props.task?.subject}
                </Typography>

                <Typography variant={'subtitle2'} sx={{ color: 'text.secondary' }}>
                 {parse(props.task?.description ?? '')}
                </Typography>
              </Stack>

              {downSm && (
                <Stack direction={'row'} alignItems={'center'} gap={0.5} sx={{ ml: 'auto' }}>
                  <Iconify
                    icon="solar:calendar-linear"
                    width={18}
                    sx={{ color: 'text.secondary' }}
                  />

                  <Typography
                    variant={'subtitle2'}
                    sx={{ fontWeight: '500', color: 'text.secondary' }}
                  >
                  {moment(props.task.dueDate).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>

          {/* <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              borderRadius: '24px !important',
              border: '1px solid',
              borderColor: 'grey.300',
              padding: '12px 16px',
            }}
          >
            <Box>Input 1</Box>
            <Divider />
            <Box>Input 2</Box>
            <Divider />
            <Box>Input 3</Box>
          </Stack>
        </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TaskListItemComponent;
