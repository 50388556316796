/* eslint-disable react-hooks/exhaustive-deps */
import EstimateListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/estimate/components/EstimateListTabComponent';
import ProposalListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/proposal/components/ProposalListTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  EstimateProspectTargetEntityRef,
  ILeadDetailResult,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { LeadDetailDrawerTabInfoComponent } from './LeadDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useLeadDocumentCreateManyMutation,
  useLeadDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import React from 'react';
import { Iconify } from 'components';

export interface ILeadDetailDrawerRightPanelTabsComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const LeadDetailDrawerRightPanelTabsComponent = (
  props: ILeadDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={14} />,
    <Iconify icon="teenyicons:cost-estimate-outline" width={14} />,
    <Iconify icon="nimbus:money" width={14} />,
    <Iconify icon="carbon:product" width={14} />,
    <Iconify icon="iconoir:bell" width={14} />,
    <Iconify icon="hugeicons:task-01" width={14} />,
    <Iconify icon="fluent:document-32-regular" width={14} />,
    <Iconify icon="tabler:activity" width={14} />,
  ] as React.ReactElement[];

  return (
    <DefaultTabsComponent<ILeadDetailResult> entityData={props.leadDetailData} icons={icons}>
      <LeadDetailDrawerTabInfoComponent
        leadDetailData={props.leadDetailData}
        tabKey="info"
        name="crm.info"
      />

      <EstimateListTabSectionComponent
        prospectTargetId={props.leadDetailData?._id ?? ''}
        prospectTargetEntityRef={EstimateProspectTargetEntityRef.Lead}
        tabKey="estimates"
        name="crm.estimates"
      />

      <ProposalListTabSectionComponent
        prospectTargetId={props.leadDetailData?._id ?? ''}
        prospectTargetEntityRef={ProposalProspectTargetEntityRef.Lead}
        tabKey="proposals"
        name="crm.proposals"
      />

      <NoteListTabComponent
        targetId={props.leadDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Lead}
        targetDisplayName={props.leadDetailData?.fullName ?? ''}
        tabKey="notes"
        name="crm.notes"
      />

      <ReminderListTabComponent
        targetId={props.leadDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Lead}
        targetDisplayName={props.leadDetailData?.fullName ?? ''}
        tabKey="reminders"
        name="crm.reminders"
      />

      <TaskListTabComponent
        targetId={props.leadDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.Lead}
        name="crm.tasks"
        tabKey="tasks"
      />

      <DocumentListTabComponent
        targetId={props.leadDetailData?._id ?? ''}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useLeadDocumentCreateManyMutation}
        useDocumentAddManyMutation={useLeadDocumentAddManyMutation}
      />

      <DefaultEmptyTabComponent
        // title={t('crm.activities')}
        title={t('')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      />
    </DefaultTabsComponent>
  );
};
