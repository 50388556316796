/* eslint-disable react-hooks/exhaustive-deps */
import { InputLabel, TextField } from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  FieldNamesMarkedBoolean,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";
import { capitalize } from "lodash";

export interface IDefaultTextInputComponent<T extends FieldValues> {
  control: Control;
  setValue: UseFormSetValue<T>;
  touchedFields: FieldNamesMarkedBoolean<T>;
  fieldKey: Path<T>;
  errors: FieldErrors<T>;
  label: string;
  willCapitalizeInput?: boolean;
}

/**
 * add buttons to upper left of the drawer to navigate to prev and next record
 */
export const DefaultTextInputComponent = <T extends FieldValues>(
  props: IDefaultTextInputComponent<T>
) => {
  return (
    <>
      <InputLabel
        htmlFor={props.fieldKey}
        sx={{ ml: 1, mb: 0.5, fontSize: "small" }}
      >
        {props.label}
      </InputLabel>
      <Controller
        name={props.fieldKey}
        control={props.control}
        render={({ field }) => (
          <TextField
            {...field}
            id={props.fieldKey}
            size="small"
            fullWidth
            onChange={(e) => {
              props.setValue(
                props.fieldKey,
                props.willCapitalizeInput
                  ? (capitalize(e.target.value) as PathValue<T, Path<T>>)
                  : (e.target.value as PathValue<T, Path<T>>)
              );
            }}
            error={
              props.errors &&
              props.errors[props.fieldKey] &&
              props.touchedFields &&
              (props.touchedFields as any)[props.fieldKey]
            }
            helperText={
              props.errors &&
              props.errors[props.fieldKey] &&
              props.touchedFields &&
              (props.touchedFields as any)[props.fieldKey]
            }
          />
        )}
      />
    </>
  );
};
