/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useEffect } from 'react';
import HomeForRestricted from './HomeForRestricted';
import HomeForActive from './HomeForActive';
import { useLazyOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const List = () => {
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [getOrganizationDetailOwn, { data: organizationDetailOwn }] =
    useLazyOrganizationDetailOwnQuery();

  const status = organizationDetailOwn?.status;

  useEffect(() => {
    if (userDetailOwnData) {
      getOrganizationDetailOwn({});
    }
  }, [userDetailOwnData]);

  return (
    <>
      {status !== 'active' && organizationDetailOwn && <HomeForRestricted />}
      {status === 'active' && organizationDetailOwn && <HomeForActive />}
    </>
  );
};

export default List;
