import { Route, Routes } from "react-router-dom";

import { List, UserDetail } from "../pages";

import { UsersLayout } from "../layouts/UsersLayout";

export const UsersRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<UserDetail />} />
      </Route>
    </Routes>
  );
};
