/* eslint-disable react-hooks/exhaustive-deps */
import { Add, FileUpload } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
  Language,
} from 'corede-common';
import {
  ICustomer,
  ICustomerDetailResult,
  ICustomerListInput,
  CustomerType,
  ICustomerCategory,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { importCustomerRoute } from '../../../../routes/customer.base.route';
import { useCustomerCategoryListQuery } from '../../../customerCategory';
import { useCustomerListQuery, useCustomerUpdateMutation } from '../../context/customer.api';
import CustomerDetailDrawerWrapper from '../detailDrawer/CustomerDetailDrawer.wrapper';
import { CustomerDeleteDialog } from '../CustomerDeleteDialog';
import CustomerUpdateDrawerWrapper from '../update/CustomerUpdateDrawer.wrapper';
import { CustomerInfoCellComponent } from './CustomerList-grid-customerInfoCell.component';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import CustomerCategoryCreateDrawerWrapper from '../../../customerCategory/pages/create/CustomerCategoryCreateDrawer.wrapper';

export interface ICustomerListComponent {
  // customer
  customerCategoryList?: ICustomerCategory[];

  setOpenCustomerCreateDrawer?: (value: boolean) => void | undefined;
}

const CustomerListComponent = memo((props: ICustomerListComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();

  // states
  const [selectedCustomer, setSelectedCustomer] = useState<TListGridSelectedEntity<ICustomer>>();
  const [openCustomerDetailDrawer, setOpenCustomerDetailDrawer] = useState(false);
  const [openCustomerDeleteDialog, setOpenCustomerDeleteDialog] = useState(false);
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);
  const [customerOpenCategoryCreate, setOpenCustomerCategoryCreate] = useState(false);

  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [customerListFilter, setCustomerListFilter] = useState<
    IGraphqlVariables<ICustomerListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = customerListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = customerListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: customerListData,
    isLoading: customerListLoading,
    isFetching: customerListFetching,
    error: customerListError,
    refetch,
  } = useCustomerListQuery(customerListFilter);

  const { data: customerCategoryListDataFromApi, error: customerCategoryListError } =
    useCustomerCategoryListQuery(
      {},
      {
        skip: !!props.customerCategoryList, // Skip the query if props data exists
      },
    );
  const customerCategoryList: ICustomerCategory[] | undefined =
    props.customerCategoryList || customerCategoryListDataFromApi?.data;

  // used for direct update in the list rows
  const [customerUpdate, { isLoading: customerUpdateLoading, error: customerUpdateError }] =
    useCustomerUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [customerListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerCategoryListError as IBaseError, currentLanguage);
  }, [customerCategoryListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerListError as IBaseError, currentLanguage);
  }, [customerListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerUpdateError as IBaseError, currentLanguage);
  }, [customerUpdateError]);

  const isEmptyState =
    customerListData?.data?.length === 0 &&
    !customerListLoading &&
    !customerListFetching &&
    isNullOrUndefinedOrEmptyObject(customerListFilter?.input?.filter);

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedCustomer}
      setSelectedEntity={setSelectedCustomer}
      listFilter={customerListFilter}
      setListFilter={setCustomerListFilter}
      listData={customerListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '90%' }}>
        {customerListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                customerListData?.data?.map((customer, index) => ({
                  id:
                    ((customerListFilter.input?.pagination?.page || 1) - 1) *
                      (customerListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: customer._id,
                  customerInfo: {
                    name: customer.name ?? '',
                    email: customer.email ?? '',
                    image: customer.image?.thumbnailPublicUrl ?? '',
                  },
                  name: customer.name || '',
                  email: customer.email || '',
                  type: customer.type || '',
                  phone: customer.phone || '',
                  language: customer.language || '',
                  category: customer.category || '',
                  about: customer.about || '',
                  website: customer.website || '',
                  country: customer.country || '',
                  state: customer.state || '',
                  city: customer.city || '',
                  address: customer.address || '',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: '#',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.any,
                    column: {
                      field: 'customerInfo',
                      width: 180,
                    },
                    headerConfig: {
                      // icon: 'radix-icons:person',
                      name: `${t('crm.name')}`,
                    },
                    overrideCell: CustomerInfoCellComponent(),
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'name',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'radix-icons:text',
                      name: t('crm.name'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'email',
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'radix-icons:envelope-closed',
                      name: t('crm.email'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'type',
                      editable: true,
                      width: 90,
                    },
                    headerConfig: {
                      // icon: 'oui:app-users-roles',
                      name: t('crm.type'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `types`,
                      isArrayFilter: true,
                    },
                  },
                  data: CustomerType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'phone',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'mi:call',
                      name: t('crm.phone'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'language',
                      width: 100,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'clarity:language-line',
                      name: t('crm.language'),
                    },
                    filterConfig: {
                      customFieldName: `languages`,
                      isArrayFilter: true,
                    },
                  },
                  data: Language,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'category',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      // icon: 'mdi:source-fork',
                      name: t('crm.category'),
                      creatable: true,
                      createFunc: setOpenCustomerCategoryCreate,
                    },
                    filterConfig: {
                      customFieldName: `categoryIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: customerCategoryList,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'about',
                      width: 140,
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'streamline:web',
                      name: t('crm.about'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'website',
                      width: 140,
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'streamline:web',
                      name: t('crm.website'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'country',
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'bi:geo-alt',
                      name: t('crm.country'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'state',
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'bi:geo',
                      name: t('crm.state'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'city',
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'hugeicons:city-03',
                      name: t('crm.city'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'address',
                      width: 140,
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      // icon: 'streamline:web',
                      name: t('crm.address'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedCustomer,
                      setOpenAction: setOpenCustomerDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedCustomer,
                      setOpenAction: setOpenCustomerUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedCustomer,
                      setOpenAction: setOpenCustomerDeleteDialog,
                    },
                  },
                },
                customCellItems: [],
                actionHeaderName: t('crm.actions'),
              }}
              loading={customerListLoading || customerUpdateLoading || customerListFetching}
              listFilter={{
                filterInput: customerListFilter,
                setFilterInput: setCustomerListFilter,
              }}
              update={{
                updateQuery: customerUpdate,
              }}
              count={customerListData?.count || 0}
              config={{
                columnVisibilityModel: {
                  about: false,
                  longitude: false,
                  latitude: false,
                  organization: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [
                  // <Box display="flex" alignItems="center">
                  //   <Box display="flex" alignItems="center">
                  //     <ToggleButtonGroup
                  //       id="export"
                  //       sx={{ ml: 2 }}
                  //       color="warning"
                  //       size="small"
                  //       exclusive
                  //     >
                  //       <ToggleButton
                  //         value={false}
                  //         onClick={() =>
                  //           handleDownloadCustomerList({
                  //             currentLanguage: currentLanguage,
                  //             filter: customerListFilter?.input?.filter,
                  //           })
                  //         }
                  //       >
                  //         <DownloadOutlined fontSize="small" sx={{ mr: 1 }} />
                  //         <Typography fontWeight={"bold"} fontSize={"12px"}>
                  //           {t("crm.export")}
                  //         </Typography>
                  //       </ToggleButton>
                  //     </ToggleButtonGroup>
                  //   </Box>
                  // </Box>
                ],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.customer.customer.emptyState1')}
              content2={t('crm.customer.customer.emptyState2')}
              button1={{
                title: t('crm.customer.customer.create'),
                onClick: () => (props?.setOpenCustomerCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
              // button2={{
              //   title: t("crm.customer.customer.importCustomer"),
              //   onClick: () => navigate(importCustomerRoute()),
              //   leftIcon: <FileUpload sx={{ mr: 1 }} fontSize="small" />,
              // }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <CustomerDetailDrawerWrapper
        open={openCustomerDetailDrawer}
        setOpen={setOpenCustomerDetailDrawer}
        selectedCustomer={selectedCustomer as ICustomerDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: customerListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: customerListData,
            listFilter: customerListFilter,
            setListFilter: setCustomerListFilter,
            selectedEntity: selectedCustomer,
            setSelectedEntity: setSelectedCustomer,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <CustomerUpdateDrawerWrapper
        open={openCustomerUpdateDrawer}
        setOpen={setOpenCustomerUpdateDrawer}
        selectedCustomer={selectedCustomer}
      />

      <CustomerDeleteDialog
        open={openCustomerDeleteDialog}
        estimateId={selectedCustomer?._id ?? ''}
        setEstimate={setSelectedCustomer}
        onClose={() => {
          setSelectedCustomer(undefined);
          setOpenCustomerDeleteDialog(false);
        }}
        key={selectedCustomer?._id}
      />

      <CustomerCategoryCreateDrawerWrapper
        open={customerOpenCategoryCreate}
        setOpen={setOpenCustomerCategoryCreate}
      />
    </DefaultListGridComponent>
  );
});

export default CustomerListComponent;
