import { InputLabel, TextField } from '@mui/material';
import { IGraphqlVariables } from 'corede-common';
import { HTMLInputTypeAttribute } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

export const DefaultTextFieldInput = (props: {
  inputName: `input.${string}`;
  control: Control<IGraphqlVariables<any, any>>;
  label: string;
  isRequired?: boolean;
  isFirstLetterCapitalize?: boolean;
  errors: FieldErrors<IGraphqlVariables<any, any>>;
  touchedFields: Partial<Readonly<any>>;
  type?: HTMLInputTypeAttribute | undefined;
  multiline?: boolean;
  rows?: number;
  value?: unknown;
  minValue?: number;
  disabled?: boolean;
}) => {
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <TextField
            {...field}
            id={props.inputName}
            disabled={props.disabled}
            fullWidth
            size="small"
            type={props.type || 'text'}
            multiline={props.multiline}
            rows={props.rows || 1}
            value={props.value ?? field.value}
            onChange={(e) =>
              field.onChange(
                props.isFirstLetterCapitalize
                  ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                  : props.type === 'number'
                    ? Number(e.target.value)
                    : e.target.value,
              )
            }
            error={!!getNestedValue(props.errors, props.inputName)}
            helperText={
              getNestedValue(props.touchedFields, props.inputName) &&
              getNestedValue(props.errors, props.inputName)?.message
            }
            inputProps={{
              min: props.type === 'number' ? (props.minValue ?? 0) : undefined,
            }}
          />
        )}
      />
    </>
  );
};
