/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  IEstimate,
  IEstimateListInput,
  ProposalType,
  EstimateStatus,
  EstimateProspectTargetEntityRef,
  IEstimateDetailResult,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useEstimateListQuery, useEstimateUpdateMutation } from '../../context/estimate.api';
import { EstimateDeleteDialog } from '../delete/EstimateDeleteDialog';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';
import EstimateUpdateDrawerWrapper from '../update/EstimateUpdateWrapper';
import EstimateDetailDrawer from '../detailDrawer/EstimateDetailDrawer';

export interface IEstimateListGridComponent {
  // estimate
  setOpenEstimateCreateDrawer?: (value: boolean) => void | undefined;
}

const EstimateListGridComponent = memo((props: IEstimateListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedEstimate, setSelectedEstimate] = useState<TListGridSelectedEntity<IEstimate>>();
  const [openEstimateDetailDrawer, setOpenEstimateDetailDrawer] = useState(false);
  const [openEstimateDeleteDialog, setOpenEstimateDeleteDialog] = useState(false);
  const [openEstimateUpdateDrawer, setOpenEstimateUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [estimateListFilter, setEstimateListFilter] = useState<
    IGraphqlVariables<IEstimateListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = estimateListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = estimateListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: estimateListData,
    isLoading: estimateListLoading,
    isFetching: estimateListFetching,
    error: estimateListError,
    refetch,
  } = useEstimateListQuery(estimateListFilter);

  // used for direct update in the list rows
  const [estimateUpdate, { isLoading: estimateUpdateLoading, error: estimateUpdateError }] =
    useEstimateUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setEstimateListLoading && props.setEstimateListLoading(estimateListLoading);
  // }, [estimateListLoading]);
  useEffect(() => {
    refetch();
  }, [estimateListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(estimateListError as IBaseError, currentLanguage);
  }, [estimateListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(estimateUpdateError as IBaseError, currentLanguage);
  }, [estimateUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(estimateListFilter?.input?.filter);
  const isJunkUndefined = (estimateListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = estimateListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    estimateListData?.data?.length === 0 &&
    !estimateListLoading &&
    !estimateListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedEstimate}
      setSelectedEntity={setSelectedEstimate}
      listFilter={estimateListFilter}
      setListFilter={setEstimateListFilter}
      listData={estimateListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {estimateListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                estimateListData?.data?.map((estimate, index) => ({
                  id:
                    ((estimateListFilter.input?.pagination?.page || 1) - 1) *
                      (estimateListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: estimate._id,
                  prospectName: estimate.prospectName || '-',
                  prospectAddress: estimate.prospectCity + ' ' + estimate.prospectCountry || '-',
                  prospectContactName:
                    estimate.prospectContactFirstName + ' ' + estimate.prospectContactLastName ||
                    '-',
                  date: representDateAsString(estimate.date) || '-',
                  expireDate: representDateAsString(estimate.expireDate) || '-',
                  currency: estimate.currency || '-',
                  totalPrice: estimate.totalPrice.total || '-',
                  status: estimate.status || '-',
                  prospectType: estimate.prospectType || '-',
                  language: estimate.language || '-',
                  createdAt: representDateAsString(estimate.createdAt) || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectName',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectName'),
                      icon: 'mdi:account-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectAddress',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectAddress'),
                      icon: 'mdi:map-marker-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'prospectContactName',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.prospectContactName'),
                      icon: 'mdi:account-circle-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'date',
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.date'),
                      icon: 'mdi:calendar-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'expireDate',
                      editable: true,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.expireDate'),
                      icon: 'mdi:calendar-clock-outline',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'currency',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.currency'),
                      icon: 'mdi:cash-multiple',
                    },
                    filterConfig: {
                      customFieldName: `currency`,
                    },
                  },
                  data: Currency,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'totalPrice',
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.totalPrice'),
                      icon: 'mdi:currency-usd',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.status'),
                      icon: 'mdi:check-circle-outline',
                    },
                    filterConfig: {
                      customFieldName: `status`,
                      isArrayFilter: true,
                    },
                  },
                  data: EstimateStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'prospectType',
                      editable: false,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.prospectType'),
                      icon: 'mdi:format-list-bulleted',
                    },
                    filterConfig: {
                      customFieldName: `prospectType`,
                      isArrayFilter: true,
                    },
                  },
                  data: EstimateProspectTargetEntityRef,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'language',
                      width: 100,
                      editable: true,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.language'),
                      icon: 'mdi:translate',
                    },
                    filterConfig: {
                      customFieldName: `language`,
                    },
                  },
                  data: ProposalType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'createdAt',
                      filterable: false,
                      editable: false,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.createdAt'),
                      icon: 'mdi:clock-outline',
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedEstimate,
                      setOpenAction: setOpenEstimateDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedEstimate,
                      setOpenAction: setOpenEstimateUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedEstimate,
                      setOpenAction: setOpenEstimateDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={estimateListLoading || estimateUpdateLoading || estimateListFetching}
              listFilter={{
                filterInput: estimateListFilter,
                setFilterInput: setEstimateListFilter,
              }}
              update={{
                updateQuery: estimateUpdate,
              }}
              count={estimateListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  prospectAddress: false,
                  sector: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.preSale.estimate.emptyState1')}
              content2={t('crm.preSale.estimate.emptyState2')}
              button1={{
                title: t('crm.preSale.estimate.create'),
                onClick: () => (props?.setOpenEstimateCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <EstimateDetailDrawer
        open={openEstimateDetailDrawer}
        setOpen={setOpenEstimateDetailDrawer}
        selectedEstimate={selectedEstimate as IEstimateDetailResult & IHasId<number>}
        setSelectedEstimate={setSelectedEstimate}
        sideNavigationProps={{
          count: estimateListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: estimateListData,
            listFilter: estimateListFilter,
            setListFilter: setEstimateListFilter,
            selectedEntity: selectedEstimate,
            setSelectedEntity: setSelectedEstimate,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <EstimateUpdateDrawerWrapper
        open={openEstimateUpdateDrawer}
        setOpen={setOpenEstimateUpdateDrawer}
        selectedEstimate={selectedEstimate}
      />

      <EstimateDeleteDialog
        open={openEstimateDeleteDialog}
        estimateId={selectedEstimate?._id ?? ''}
        setEstimate={setSelectedEstimate}
        onClose={() => {
          setSelectedEstimate(undefined);
          setOpenEstimateDeleteDialog(false);
        }}
        key={selectedEstimate?._id}
      />
    </DefaultListGridComponent>
  );
});

export default EstimateListGridComponent;
