

import { PreSalesDomainLayout } from "../layouts";
import { EstimatesRouter } from "../subdomains/estimate/routes/EstimatesRouter";
import { ProposalsRouter } from "../subdomains/proposal/routes/ProposalsRouter";
import { preSalesDomainRoutes } from "./preSales.base.route";
import { Route, Routes } from "react-router-dom";

export const PreSalesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PreSalesDomainLayout />}>
        <Route index element={<EstimatesRouter />} />
        <Route
          path={`${preSalesDomainRoutes.subdomains.estimate.base}/*`}
          element={<EstimatesRouter />} />
        <Route
          path={`${preSalesDomainRoutes.subdomains.proposals.base}/*`}
          element={<ProposalsRouter />} />

      </Route>
    </Routes>
  );
};
