import { ProductsAndServicesDomainLayout } from "../layouts";
import { CategoriesRouter } from "../subdomains/productCategory/routes/CategoriesRouter";
import { ProductsRouter } from "../subdomains/product/routes/ProductsRouter";
import { ServicesRouter } from "../subdomains/service/routes/ServicesRouter";
import { productsAndServicesDomainRoutes } from "./productsAndServices.base.route";
import { Route, Routes } from "react-router-dom";

export const ProductsAndServicesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductsAndServicesDomainLayout />}>
        <Route index element={<ProductsRouter />} />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.product.base}/*`}
          element={<ProductsRouter />}
        />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.service.base}/*`}
          element={<ServicesRouter />}
        />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.category.base}/*`}
          element={<CategoriesRouter />}
        />
      </Route>
    </Routes>
  );
};
