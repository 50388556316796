/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useInvoiceDeleteMutation } from '../context/invoice.api';
import { IBaseError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { IBaseInvoiceEntity } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

const Delete = (prop: {
  open: boolean;
  item: IBaseInvoiceEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [
    invoiceStatusDelete,
    {
      data: invoiceStatusDeleteData,
      isLoading: invoiceStatusDeleteLoading,
      error: invoiceStatusDeleteError,
    },
  ] = useInvoiceDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    invoiceStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (invoiceStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t('crm.sale.invoice.deletedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [invoiceStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(invoiceStatusDeleteError as IBaseError, currentLanguage);
  }, [invoiceStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t('crm.sale.invoice.delete')}
      buttonTitle={t('delete')}
      buttonColor="error"
      handleClick={() => {
        handleDelete(prop?.item?._id ?? '');
      }}
      disabled={invoiceStatusDeleteLoading}
      loading={invoiceStatusDeleteLoading}
    >
      <Grid item xs={12}>
        {t('crm.sale.invoice.deleteConfirm')} {prop?.item?.prefix}?
      </Grid>
    </ActionDialog>
  );
};

export default Delete;
