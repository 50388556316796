/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import {
  IEntity,
  IFilter,
  IGraphqlVariables,
  IHasFilter,
  IHasId,
  IHasPagination,
  IPaginated,
  IPagination,
} from 'corede-common';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { genericMemo } from 'types/generic-memo';
import { handleSideNavigationPageUpdate } from '../../util/handleSideNavigationPageUpdate.action';

interface IPendingNavigation {
  direction: 'next' | 'back';
  page: number;
}

export type TListGridSelectedEntity<T extends IEntity> = (T & IHasId<number>) | undefined;

export interface IDefaultListGridComponentProps<TEntity extends IEntity> {
  children: ReactNode;
  selectedEntity: TListGridSelectedEntity<TEntity>;
  setSelectedEntity: Dispatch<SetStateAction<TListGridSelectedEntity<TEntity>>>;
  listFilter: IGraphqlVariables<IHasPagination<IPagination> & IHasFilter<IFilter>>;
  setListFilter: Dispatch<
    SetStateAction<IGraphqlVariables<IHasPagination<IPagination> & IHasFilter<IFilter>>>
  >;
  listData?: IPaginated<TEntity>;
  pendingNavigation?: IPendingNavigation | null;
  setPendingNavigation?: Dispatch<SetStateAction<IPendingNavigation | null>>;
  listLoading?: boolean;
  listFetching?: boolean;
}

/**
 * Handles basic side navigation navigate logic
 *
 */
export const DefaultListGridComponent = genericMemo(
  <TEntity extends IEntity>(props: IDefaultListGridComponentProps<TEntity>) => {
    // varibles
    const currentPageSize = props.listFilter?.input?.pagination?.pageSize ?? 10;

    // useEffects
    /**
     * moves between next and previous entity in the data in current page
     */
    useEffect(() => {
      if (
        props.pendingNavigation &&
        props.setPendingNavigation &&
        props.listData &&
        props.listData?.data?.length > 0 &&
        !props.listLoading &&
        !props.listFetching &&
        props.listFilter?.input?.pagination?.page === props.pendingNavigation.page
      ) {
        handleSideNavigationPageUpdate({
          pendingNavigation: props.pendingNavigation,
          setPendingNavigation: props.setPendingNavigation,
          setSelectedEntity: props.setSelectedEntity,
          listData: props.listData,
          currentPageSize: currentPageSize,
        });
      }
    }, [props.listData?.data, props.pendingNavigation, props.listFilter?.input?.pagination?.page]);

    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        mt={2}
        width={'100%'}
        height={{ xs: '700px', md: 'calc(100%)' }}
        sx={{ overflow: 'auto' }}
      >
        {props.children}
      </Stack>
    );
  },
);
