/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from "@mui/material";
import { TCellRenderFunc } from "components/baseDataGrid/infra/types";

export function LeadInfoCellComponent(): TCellRenderFunc {
  return (cellParams) => {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
      >
        <Avatar
          alt={cellParams.value.fullName}
          src={"https://loremflickr.com/480/480"}
          sx={{ width: 35, height: 35 }}
        />
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          gap={0.5}
        >
          <Typography fontSize={"12px"} fontWeight={500} color={"text.primary"}>
            {cellParams.value.fullName}
          </Typography>
          <Typography
            fontSize={"10px"}
            fontWeight={500}
            color={"text.secondary"}
          >
            {" "}
            {cellParams.value.email}
          </Typography>
        </Stack>
      </Stack>
    );
  };
}
