import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateForgetPasswordInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    email: ValidationHelper.RequiredEmail(
      getTranslatedText('emailIsInvalid'),
      getTranslatedText('emailIsRequired'),
    ),
  }),
});
