const locale = {
  title: "Müşteri Kategorileri",
  headerTitle: "Müşteri Kategorileri",
  create: "Müşteri Kategorisi Oluştur",
  columnId: "ID",
  columnName: "İsim",
  columnRoles: "Roller",
  columnActions: "İşlemler",
  rolesButton: "Rolleri Düzenle",
  createCustomerCategoryTitle: "Yeni Müşteri Kategorisi Oluştur",
  customerCategoryNameLabel: "Müşteri Kategorisi İsmi",
  rolesLabel: "Roller",
  createCustomerCategoryButton: "Oluştur",
  emptyStateContent1: "Müşteri kategorisi bulunamadı",
  emptyStateContent2:
    "Yeni müşteri kategorileri oluşturmak için aşağıdaki butona tıklayabilirsiniz.",
  addNewRole: "Yeni Rol Ekle",
  deletedSuccessfully: "Müşteri kategorisi başarıyla silindi",
  deleteConfirm: "Bu müşteri kategorisini silmek istediğinizden emin misiniz?",
  delete: "Sil",
  enTranslation: "İngilizce Çeviri",
  trTranslation: "Türkçe Çeviri",
  department: "Departman",
  parentCategory: "Üst Kategori",
  icon: "Simge",
  createCustomerCategorySuccess: "Müşteri kategorisi başarıyla oluşturuldu",
  addDepartment: "Departman Ekle",
  showMore: "Daha Fazla Göster",
  columnIcon: "Simge",
  columnDepartment: "Departman",
  columnParentCategory: "Üst Kategori",
  selectIconTitle: "Bir Simge Seç",
  searchIcons: "Simgeleri Ara",
  update: "Kategori Güncelle",
  updateCustomerCategorySuccess: "Müşteri kategorisi güncellendi",
  actions: "Aksiyon",
  createdAt: "Oluşturulma Tarihi",
  emptyState1: "Müşteri kategorisi bulunamadı",
  emptyState2: "Yeni Müşteri kategorileri oluşturmak için aşağıdaki butona tıklayabilirsiniz.",
};

export default locale;
