/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, Button, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { t } from 'i18next';
import { ILeadDetailResult, LeadType } from 'corede-common-cocrm';
import { convertEnums } from 'localization';

export interface ILeadDetailDrawerLeftPanelContactDetailComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container for views in contact detail section of left panel header
 */
export const LeadDetailDrawerLeftPanelContactDetailComponent = (
  props: ILeadDetailDrawerLeftPanelContactDetailComponentProps,
) => {
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const localizedLeadTypes = convertEnums(LeadType);

  return (
    <Stack direction={'column'} width={'100%'} gap={1.5} my={2}>
      <Stack direction={'column'} width={'100%'}>
        <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
          {t('crm.contactDetails')}
        </Typography>
        <Divider />
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          Full Name:
        </Typography>
        <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {props.leadDetailData?.fullName}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.emailAddress')}:
        </Typography>
        <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {props.leadDetailData?.email}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.phoneNumber')}:
        </Typography>
        <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {props.leadDetailData?.phoneNumber ?? '-'}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.website')}:
        </Typography>
        <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {props.leadDetailData?.website ?? '-'}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.tags')}:
        </Typography>
        <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
          {props.leadDetailData?.tags
            ? props.leadDetailData?.tags?.length > 0
              ? props.leadDetailData?.tags?.map((tag) => tag).join(', ')
              : '-'
            : '-'}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.lead.lead.leadType')}:
        </Typography>
        <Button
          sx={{
            padding: '4px 8px !important',
            height: 'auto',
            borderRadius: 0.5,
            backgroundColor: 'primary.extraLight',
          }}
        >
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, lineHeight: 1, color: 'primary.main' }}
          >
            {localizedLeadTypes[
              (props.leadDetailData as any)?.type as keyof typeof localizedLeadTypes
            ] ?? ''}
          </Typography>
        </Button>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.status')}:
        </Typography>
        <Button
          sx={{
            padding: '4px 8px !important',
            height: 'auto',
            borderRadius: 0.5,
            backgroundColor: 'success.extraLight',
          }}
        >
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, lineHeight: 1, color: 'success.main' }}
          >
            {props.leadDetailData?.status?.name}
          </Typography>
        </Button>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
        <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
          {t('crm.source')}:
        </Typography>
        <Button
          sx={{
            padding: '4px 8px !important',
            height: 'auto',
            borderRadius: 0.5,
            backgroundColor: 'warning.extraLight',
          }}
        >
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, lineHeight: 1, color: 'warning.main' }}
          >
            {props.leadDetailData?.source?.name}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
