import { createTheme, alpha, PaletteColor } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { heIL } from '@mui/x-date-pickers/locales';

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  extraLight: '#212b3614',
  lighter: '#F3F3F3',
  light: '#B7B7B7',
  main: '#111111',
  dark: '#0C0809',
  darker: '#080304',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  extraLight: '#FF7D7D1A',
  lighter: '#f88b9e',
  light: '#f6657e',
  main: '#FF7D7D',
  dark: '#FF7D7D',
  darker: '#922538',
  contrastText: '#FFFFFF',
  inputColor: '#FF7D7D80',
};

const TERTIARY = {
  extraLight: '#7367F029',
  lighter: '#E8F2FD',
  light: '#B9D2F4',
  main: '#7367F0',
  dark: '#425B9D',
  darker: '#192969',
  contrastText: '#FFFFFF',
};

const INFO = {
  extraLight: '#00BAD129',
  lighter: '#CFE4FB',
  light: '#70A1E9',
  main: '#00BAD1',
  dark: '#0C2C83',
  darker: '#041557',
  contrastText: '#FFFFFF',
};

const MAIN = {
  lighter: '#CFE4FB',
  light: '#70A1E9',
  main: '#194EB7',
  dark: '#0C2C83',
  darker: '#041557',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  extraLight: '#28C76F29',
  lighter: '#DDF8D1',
  light: '#80D771',
  main: '#28C76F',
  dark: '#0D591C',
  darker: '#043B19',
  contrastText: '#ffffff',
};

const WARNING = {
  extraLight: '#FF9F4329',
  lighter: '#FCEFC9',
  light: '#EDBE5F',
  main: '#FF9F43',
  dark: '#8D4600',
  darker: '#5E2700',
  contrastText: GREY[100],
};

const ERROR = {
  lighter: '#F9CFD1',
  light: '#DF6D8A',
  main: '#961650',
  dark: '#6C0B4A',
  darker: '#48043E',
  contrastText: '#FFFFFF',
};

const ALTERNATIVE = {
  lighter: '#bbb',
  light: '#aaa',
  main: '#999',
  dark: '#888',
  darker: '#777',
  contrastText: '#FFFFFF',
};

const ALTERNATIVE2 = {
  lighter: '#bbb',
  light: '#aaa',
  main: '#ccc',
  dark: '#888',
  darker: '#777',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  info: INFO,
  main: MAIN,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  alternative: ALTERNATIVE,
  alternative2: ALTERNATIVE2,
  divider: alpha(GREY[500], 0.2),
  action: {
    active: alpha(SECONDARY['main'], 1),
    hover: alpha(SECONDARY['main'], 0.08),
    selected: alpha(SECONDARY['main'], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(SECONDARY['main'], 0.24),
    focusOpacity: 0.24,
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  alternatives: {
    background: {
      default: '#FFFFFF',
    },
  },
};

const getDesignTokens = (mode: 'light' | 'dark') =>
  mode === 'light'
    ? {
        // Light mode specific palette
        primary: {
          extraLight: '#212b3614',
          lighter: '#F3F3F3',
          light: '#959595',
          main: '#111111',
          dark: '#0C0809',
          darker: '#080304',
          contrastText: '#FFFFFF',
        },
        secondary: {
          extraLight: '#FF7D7D1A',
          lighter: '#f88b9e',
          light: '#f6657e',
          main: '#FF7D7D',
          dark: '#FF7D7D',
          darker: '#922538',
          contrastText: '#FFFFFF',
          inputColor: '#FF7D7D80',
        },
        tertiary: {
          extraLight: '#7367F029',
          lighter: '#E8F2FD',
          light: '#B9D2F4',
          main: '#7367F0',
          dark: '#425B9D',
          darker: '#192969',
          contrastText: '#FFFFFF',
        },
        background: {
          paper: '#FFFFFF',
          default: '#FFFFFF',
          primary: '#FFFFFF',
          secondary: '#FFFFFF',
          third: '#FFF',
          firstWithBlur: '#FFFFFFDD',
          secondWithBlur: '#FFFFFF4D',
          thirdWithBlur: '#FFFFFF66',
          fourth: '#DDD',
          drawer: '#F9FAFB',
          alternative: '#fffbfc',
          contrast: GREY[900],
          contrastWithBlur: '#33333333',
        },
        text: {
          primary: GREY[800],
          secondary: GREY[600],
          disabled: GREY[500],
          white: '#FFFFFF',
          default: '#232020',
        },
      }
    : {
        // Dark mode specific palette
        primary: {
          extraLight: '#7367F029',
          lighter: '#1E1E1E',
          light: '#5A5A5A',
          main: '#F5F5F5',
          dark: '#D1D1D1',
          darker: '#B5B5B5',
          contrastText: '#111111',
        },
        secondary: {
          extraLight: '#FF7D7D1A',
          lighter: '#f88b9e',
          light: '#f6657e',
          main: '#FF7D7D',
          dark: '#FF7D7D',
          darker: '#922538',
          contrastText: '#FFFFFF',
          inputColor: '#FF7D7D80',
        },
        tertiary: {
          extraLight: '#7367F029',
          lighter: '#E8F2FD',
          light: '#B9D2F4',
          main: '#7367F0',
          dark: '#425B9D',
          darker: '#192969',
          contrastText: '#FFFFFF',
        },
        background: {
          paper: GREY[800],
          default: GREY[900],
          primary: GREY[900],
          secondary: GREY[800],
          third: GREY[700],
          firstWithBlur: '#33333333',
          secondWithBlur: '#3333334D',
          thirdWithBlur: '#454F5B66',
          contrast: '#F1EEEE',
          contrastWithBlur: '#FFFFFF4D',
        },
        text: {
          primary: GREY[100],
          secondary: GREY[300],
          disabled: GREY[500],
          white: '#000000',
          default: '#FFFFFF',
        },
      };

export const theme = (mode: 'light' | 'dark') =>
  createTheme({
    palette: {
      mode,
      ...COMMON,
      ...getDesignTokens(mode),
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      // fontFamily: 'Public Sans, sans-serif',
      fontFamily: 'Lexend, sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 700,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 700,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      button: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      caption: {
        fontSize: '0.813rem',
        fontWeight: 400,
      },
      overline: {
        fontSize: '0.813rem',
        fontWeight: 700,
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF00',
            borderRadius: 16,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: GREY[200],
            borderRadius: 16,
          },
          input: {
            fontSize: '14px',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 14px 20px -4px rgba(0,0,0,0.1)',
            borderRadius: '12px',
          },
          popper: {
            boxShadow: 'none',
          },
          listbox: {
            fontSize: '14px',
            '& li:empty': {
              height: '0px',
              padding: '0px',
            },
            "& li:has(span:contains('highlight'))": {
              backgroundColor: 'lightblue',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            borderColor: GREY[200],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-alternative': {
              backgroundColor: '#00ff00',
              color: '#fff',
            },
            borderRadius: 16,
            height: 56,
            capitalize: false,
            textTransform: 'none',
            fontSize: '1rem',
            boxShadow: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
            padding: 10,
            borderRadius: 16,
            // maskImage: 'paint(smooth-corners)',
            // WebkitMaskImage: 'paint(smooth-corners)',
            // '--smooth-corners': 25.89,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: mode === 'dark' ? GREY[100] : GREY[800],
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 3,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid',
            borderColor: mode === 'dark' ? '#5A5A5A' : '#B7B7B7',
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: '1px solid',
            borderColor: mode === 'dark' ? '#5A5A5A' : '#B7B7B7',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 20,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            lineHeight: 0,
            marginTop: 8,
            marginBottom: -8,
            fontSize: '10px',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            borderRadius: 6,
            '&.Mui-selected': {
              color: SECONDARY['main'],
              backgroundColor: SECONDARY['extraLight'],
            },
            '&.Mui-selected:hover': {
              color: SECONDARY['main'],
              backgroundColor: SECONDARY['extraLight'],
            },
            '&.Mui-focused': {
              color: SECONDARY['main'],
              backgroundColor: SECONDARY['extraLight'],
            },
            '&.Mui-selected svg': {
              color: SECONDARY['main'],
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-toolbarContainer': {
              padding: '14px 20px',
              gap: '16px',
            },
            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
              height: 'auto',
              textTransform: 'none',
              color: 'inherit',
            },
            '& .MuiToggleButtonGroup-grouped': {
              padding: '4.5px 9px',
            },
            '& .MuiAvatar-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: SECONDARY['main'],
            },
            '&.Mui-selected .MuiTypography-root': {
              color: `${COMMON['common']['white']} !important`,
            },
            '&.Mui-selected svg': {
              color: `${COMMON['common']['white']} !important`,
            },
          },
        },
      },
    },
  });

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary?: {
      lighter?: string;
      light?: string;
      main: string;
      dark?: string;
      darker?: string;
      contrastText?: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
  }
}
