/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { MyTab } from 'components';
import { useTranslation } from 'react-i18next';

/**
 * @property key - will be used as key when creating a map for tabs
 * @property name - the name of the tab translation. This will be displayed as Tab Name after translation applied. Send translation key value.
 * @property nameTranslation - the name of the tab. This will be displayed as Tab Name. Send translated value.
 * @property entityData - the entity of which the the information shown in the tabs are related to
 *
 * @note one of `name` or `nameTranslation` required
 */
export interface DefaultTabComponentProps<T> {
  tabKey: string;
  name?: string;
  nameTranslation?: string;
  entityData?: T;
  icons?: React.ReactElement[];
}

/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface
 * @property entityData - the entity of which the the information shown in the tabs are related to
 */
export interface IDefaultTabsComponentProps<T> {
  children: Array<React.ReactElement<DefaultTabComponentProps<T>>>;
  entityData?: T;
  icons?: React.ReactElement[];
}

/**
 * Container for views in left panel header
 */
export const DefaultTabsComponent = <T,>(props: IDefaultTabsComponentProps<T>) => {
  const { t } = useTranslation();

  const tabNames: Map<string, string> = new Map();

  props.children.forEach((tabComponent) => {
    const name =
      tabComponent.props.nameTranslation ??
      (tabComponent.props.name ? t(tabComponent.props.name) : '');
    tabNames.set(tabComponent.props.tabKey, name);
  });

  const tabKeys = Array.from(tabNames.keys());
  const tabValues = Array.from(tabNames.values());
  const firstSelectedTab = tabNames.get(tabKeys.at(0) ?? '');

  // useStates
  const [selectedTabName, setSelectedTabName] = useState(firstSelectedTab);

  return (
    <Stack>
      <Box>
        <MyTab
          labels={tabValues}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
          icons={props.icons ? props.icons : undefined}
        />
      </Box>

      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: 1,
          borderColor: 'grey.400',
        }}
      />

      {props.children.map((tabComponent) => {
        if (selectedTabName === tabNames.get(tabComponent.props.tabKey)) {
          return tabComponent;
        }
        return null;
      })}
    </Stack>
  );
};
