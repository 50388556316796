const locale = {
  service: 'Services',
  title: 'Services',
  create: 'Create Service',
  update: 'Update Service',
  delete: 'Delete Service',
  deleteConfirm: 'Are you sure you want to delete this status: ',
  deletedSuccessfully: 'Service deleted successfully',
  createService: 'Create Service',
  createSuccess: 'Service created successfully',
  updateSuccess: 'Service updated successfully',
  name: 'Name',
  icon: 'Icon',
  showMore: 'Show More',

  detail: 'Service Detail',
  type: 'Type',
  image: 'Image',
  sku: 'Sku',
  description: 'Description',
  currency: 'Currency',
  price: 'Price',
  priceType: 'PriceType',
  discountedPrice: 'Discounted Price',
  unit: 'Unit',
  categoryId: 'Category',
  natTaxRate: 'National Tax Rate',
  intTaxRate: 'International Tax Rate',

  categories: 'Categories',
  rate: 'Rate',
  rateType: 'Rate Type',
  discountedRate: 'Discounted Rate',
  emptyState1: 'No services recorded yet.',
  emptyState2:
    'Manage and review any service returns here to keep your records accurate and customer needs met.',
};

export default locale;
