const locale = {
  demo: "Demo",
  title: "<highlight>CoCRM</highlight> Window Nedir?",
  description:
    "CoCRM Window, yalnızca CoCRM kullanıcı firmalarına özel olarak tasarlanmış, ürün, hizmet veya kampanyalarıyla ile ilgili doğrudan CoCRM ekosistemi içerisindeki üyelere aktarımlar sağlayabildiği, websitemizi ziyaret eden kişi ve kurumlara özel indirimler tanımlayarak iş fırsatları yakalayabildiği bir iş geliştirme alanıdır.",
  featuresTitle: "CoCRM Window ile;",
  features1:
    "Aylık 1M+ trafiği olan websitemize gelen ziyaretçilere doğrudan teklifler verebilir,",
  features2:
    "Ekosistemimiz içerisinde bulunan firmalara çeşitli kampanya duyuruları yapabilir,",
  features3:
    "Yürüteceğimiz blog kampanyalarında yer alarak ürün ve hizmetleriniz hakkında detaylı bilgiler aktarabilir,",
  features4: "Marka bilinirliğinize %36,2 ek katkı sağlayabilirsiniz!",
  buttonTitle: "Teklif Al",
  formDescription: "Formu doldurarak bizimle iletisime geçebilirsiniz.",
  formTitle: "Fiyat Teklifi İste",
};

export default locale;
