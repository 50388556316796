import { Grid } from "@mui/material";
import { memo, useState } from "react";
import { Add, FileUpload } from "@mui/icons-material";
import { useCustomerContactListQuery } from "../../context/contact.api";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import ContactListGridComponent from "./ContactList-grid.component";
import { useNavigate } from "react-router-dom";
import ContactCreateDrawerWrapper from "../create/ContactCreateDrawer.wrapper";

const ContactList = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openContactCreateDrawer, setOpenContactCreateDrawer] = useState(false);

  const [contactListType, setContactListType] = useState("list");

  // queries
  const { data: contactListCountData, isLoading: contactListCountLoading } =
    useCustomerContactListQuery({});

  let totalContactCount = contactListCountData?.count ?? 0;
  let isEmpty = totalContactCount === 0;

  // useEffects.error 

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.customer.contact.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.customer.contact.create`),
          onClick: () => setOpenContactCreateDrawer(true),
        }}
      />


      {contactListType === "list" ? (
        <ContactListGridComponent
          setOpenContactCreateDrawer={setOpenContactCreateDrawer}
        />
      ) :
        null
      }

      <ContactCreateDrawerWrapper
        open={openContactCreateDrawer}
        setOpen={setOpenContactCreateDrawer}
      />
    </Grid>
  );
});

export default ContactList;
