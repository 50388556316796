/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';

export interface IEstimateDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IEstimateDetailResult> {
  estimateDetailData: IEstimateDetailResult | undefined;
  tabKey: 'additionalInfo';
  name: 'crm.additionalInfo';
}

export const EstimateDetailDrawerTabAdditionalInfoComponent = (
  props: IEstimateDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const totalPrice = props.estimateDetailData?.totalPrice;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';
  const { scopeOfWork, assumptionsAndExclusions, useLegalNDA, useLegalTermAndCond } =
    props.estimateDetailData || {};
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('crm.preSale.estimate.additionalInfoTitle')}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Scope of Work */}
      <Box>
        <Typography variant="body2" fontWeight="bold" sx={{ marginBottom: 1 }}>
          {t('crm.preSale.estimate.scopeOfWork')}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {scopeOfWork || '-'}
        </Typography>
      </Box>

      {/* Assumptions and Exclusions */}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          {t('crm.preSale.estimate.assumptionsAndExclusions')}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {assumptionsAndExclusions || '-'}
        </Typography>
      </Box>

      {/* Legal NDA */}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          {t('crm.preSale.estimate.useLegalNDA')}
        </Typography>
        <Typography variant="body2">
          {useLegalNDA ? t('crm.preSale.estimate.yes') : t('crm.preSale.estimate.no')}
        </Typography>
      </Box>

      {/* Terms and Conditions */}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          {t('crm.preSale.estimate.useLegalTermAndCond')}
        </Typography>
        <Typography variant="body2">
          {useLegalTermAndCond ? t('crm.preSale.estimate.yes') : t('crm.preSale.estimate.no')}
        </Typography>
      </Box>
    </Box>
  );
};
