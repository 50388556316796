/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { generateAutocompleteTranslatedOptions, getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

import { UseFormReturn } from 'react-hook-form';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { NoteTargetType } from 'corede-common-cocrm';
import { useLeadListQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useCustomerListQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { useEstimateListQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceListQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';

export interface IUpsertNoteComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
  targetId?: string;
  targetType?: NoteTargetType;
}

export const UpsertNoteComponent = memo((props: IUpsertNoteComponentProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  // queries
  const {
    data: leadListData,
    isLoading: leadListLoading,
    error: leadListError,
  } = useLeadListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === NoteTargetType.Lead),
    },
  );

  const {
    data: customerListData,
    isLoading: customerListLoading,
    error: customerListError,
  } = useCustomerListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === NoteTargetType.Customer),
    },
  );

  const {
    data: estimateListData,
    isLoading: estimateListLoading,
    error: estimateListError,
  } = useEstimateListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === NoteTargetType.Estimate),
    },
  );

  const {
    data: proposalListData,
    isLoading: proposalListLoading,
    error: proposalListError,
  } = useProposalListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === NoteTargetType.Proposal),
    },
  );

  const {
    data: invoiceListData,
    isLoading: invoiceListLoading,
    error: invoiceListError,
  } = useInvoiceListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === NoteTargetType.Invoice),
    },
  );

  // const { data: taskListData, isLoading: taskListLoading, error: taskListError } = useTaskListQuery({}, {
  //   skip: !(props.useForm.watch("input.targetType") === NoteTargetType.Invoice),
  // }); // TODO after implementing task

  const leadListForOptions: { name: string; _id: string }[] =
    leadListData?.data.map((lead) => ({
      name: lead.fullName,
      _id: lead._id,
    })) ?? [];

  const customerListForOptions: { name: string; _id: string }[] =
    customerListData?.data.map((customer) => ({
      name: customer.name,
      _id: customer._id,
    })) ?? [];

  const estimateListForOptions: { name: string; _id: string }[] =
    estimateListData?.data.map((estimate) => ({
      name: estimate.prospectName,
      _id: estimate._id,
    })) ?? [];

  const proposalListForOptions: { name: string; _id: string }[] =
    proposalListData?.data.map((proposal) => ({
      name: proposal.prospectName,
      _id: proposal._id,
    })) ?? [];

  const invoiceListForOptions: { name: string; _id: string }[] =
    invoiceListData?.data.map((invoice) => ({
      name: invoice.invoiceId.toString(),
      _id: invoice._id,
    })) ?? [];

  // useEffects.success

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadListError as IBaseError, currentLanguage);
  }, [leadListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerListError as IBaseError, currentLanguage);
  }, [customerListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(estimateListError as IBaseError, currentLanguage);
  }, [estimateListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalListError as IBaseError, currentLanguage);
  }, [proposalListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(invoiceListError as IBaseError, currentLanguage);
  }, [invoiceListError]);

  // useEffects.init

  useEffect(() => {
    if (props.useForm.watch('input.targetType')) {
      props.useForm.setValue('input.targetId', props.useForm.watch('input.targetId'));
    }
  }, [props.useForm.watch('input.targetType')]);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: !!props.targetType ? 'none' : 'block',
        }}
      >
        <DefaultEnumAutocompleteInput
          inputName="input.targetType"
          label={t('crm.targetType')}
          options={generateAutocompleteTranslatedOptions(NoteTargetType)}
          enum={NoteTargetType}
          control={props.useForm.control}
          key={props.useForm.watch('input.targetType')}
          disableClearable
          disabled={!!props.targetType}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: !!props.targetId ? 'none' : 'block',
        }}
      >
        <DefaultObjectAutocompleteInput
          key={props.useForm.watch('input.targetType')}
          inputName="input.targetId"
          control={props.useForm.control}
          options={
            props.useForm.watch('input.targetType') === NoteTargetType.Lead
              ? leadListForOptions
              : props.useForm.watch('input.targetType') === NoteTargetType.Customer
                ? customerListForOptions
                : props.useForm.watch('input.targetType') === NoteTargetType.Estimate
                  ? estimateListForOptions
                  : props.useForm.watch('input.targetType') === NoteTargetType.Proposal
                    ? proposalListForOptions
                    : props.useForm.watch('input.targetType') === NoteTargetType.Invoice
                      ? invoiceListForOptions
                      : (customerListForOptions ?? [])
          }
          label={t('crm.target')}
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
          isLoading={
            props.useForm.watch('input.targetType') === NoteTargetType.Lead
              ? leadListLoading
              : props.useForm.watch('input.targetType') === NoteTargetType.Customer
                ? customerListLoading
                : props.useForm.watch('input.targetType') === NoteTargetType.Estimate
                  ? estimateListLoading
                  : props.useForm.watch('input.targetType') === NoteTargetType.Proposal
                    ? proposalListLoading
                    : props.useForm.watch('input.targetType') === NoteTargetType.Invoice
                      ? invoiceListLoading
                      : customerListLoading
          }
          disableClearable
          disableAddNewOption
          disabled={!!props.targetId}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.title"
          control={props.useForm.control}
          label={t('crm.title')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultRichTextQuillInput
          inputName="input.content"
          control={props.useForm.control}
          label={t('crm.content')}
          placeholder={t('crm.content')}
          errors={props.useForm.formState.errors}
        />
      </Grid>
    </Grid>
  );
});
