import { IEntity, IPaginated } from "corede-common";
import { Dispatch, SetStateAction } from "react";
import { TListGridSelectedEntity } from "../components/list/DefaultList-grid.component";

export interface IPendingNavigation {
  direction: "next" | "back";
  page: number;
}

export interface IHandleSideNavigationNavigateParams<T extends IEntity> {
  listData: IPaginated<T>;
  currentPageSize: number;
  setSelectedEntity: Dispatch<SetStateAction<TListGridSelectedEntity<T>>>;
  pendingNavigation: IPendingNavigation;
  setPendingNavigation: Dispatch<SetStateAction<IPendingNavigation | null>>;
}

export function handleSideNavigationPageUpdate<T extends IEntity>(
  params: IHandleSideNavigationNavigateParams<T>
) {
  const { direction, page } = params.pendingNavigation;

  let newSelectedEntity: TListGridSelectedEntity<T> = undefined;
  if (direction === "next") {
    newSelectedEntity = {
      ...params.listData.data[0],
      id: (page - 1) * params.currentPageSize + 1,
    };
  } else if (direction === "back") {
    const lastIndex = params.listData.data.length - 1;
    newSelectedEntity = {
      ...params.listData.data[lastIndex],
      id: (page - 1) * params.currentPageSize + (lastIndex + 1),
    };
  }

  params.setSelectedEntity(newSelectedEntity);
  params.setPendingNavigation(null);
}
