import {
  IPermission,
  PermissionDomain,
  PermissionSubdomain,
} from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import { useDashboardDomainMenuItem } from "../domains/01-dashboard/menu/items";
import { useOrganizationalChartDomainMenuItem } from "../domains/02-organizationalChart/menu/items";
import { useLeadDomainMenuItem } from "../domains/03-lead/menu/items";
import { useCustomerDomainMenuItem } from "../domains/04-customer/menu/items";
import { usePreSalesDomainMenuItem } from "../domains/05-preSale/menu/items";
import { useSalesDomainMenuItem } from "../domains/06-sale/menu/items";
import { useContractsDomainMenuItem } from "../domains/07-contract/menu/items";
import { useExpensesDomainMenuItem } from "../domains/09-expense/menu/items";
import { useProjectsDomainMenuItem } from "../domains/10-project/menu/items";
import { useTasksDomainMenuItem } from "../domains/11-task/menu/items";
import { useCalendarDomainMenuItem } from "../domains/12-calendar/menu/items";
import { useReportsDomainMenuItem } from "../domains/13-report/menu/items";
import { useNotesDomainMenuItem } from "../domains/14-note/menu/items";
import { useSupportDomainMenuItem } from "../domains/15-support/menu/items";
import { useProductsAndServicesDomainMenuItem } from "../domains/08-productAndService/menu/items";
import { IMenuItem } from "interfaces/menu-item.interface";
import {
  domainIsAllowed,
  subdomainIsAllowed,
} from "apps/helper/permission-checker.helper";

export const useCrmMenuItem = (props: {
  userPermissions: IPermission[] | undefined;
}) => {
  const { t } = useTranslation();

  const menuItems = [
    {
      itemId: useDashboardDomainMenuItem().path,
      label: t("crm.dashboardLabel"),
      href: useDashboardDomainMenuItem().path,
      children:
        allowedSubdomainMenuItems(
          useDashboardDomainMenuItem().children,
          props.userPermissions
        ) || [],
      domain: PermissionDomain.user,
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useOrganizationalChartDomainMenuItem().path,
      label: t("crm.organizationalChartLabel"),
      children: allowedSubdomainMenuItems(
        useOrganizationalChartDomainMenuItem().children,
        props.userPermissions
      ),
      domain: PermissionDomain.user,
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useLeadDomainMenuItem().path,
      label: t("crm.leads"),
      children: allowedSubdomainMenuItems(
        useLeadDomainMenuItem().children,
        props.userPermissions
      ),
      domain: PermissionDomain.lead,
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.lead),
    },
    {
      itemId: useCustomerDomainMenuItem().path,
      label: t("crm.customerLabel"),
      children: allowedSubdomainMenuItems(
        useCustomerDomainMenuItem().children,
        props.userPermissions
      ),
      domain: PermissionDomain.customer,
      allowed: domainIsAllowed(
        props.userPermissions,
        PermissionDomain.customer
      ),
    },
    {
      itemId: usePreSalesDomainMenuItem().path,
      label: t("crm.preSales"),
      domain: PermissionDomain.presale,
      children: allowedSubdomainMenuItems(
        usePreSalesDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.presale),
    },
    {
      itemId: useSalesDomainMenuItem().path,
      label: t("crm.salesLabel"),
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useSalesDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useContractsDomainMenuItem().path,
      label: t("crm.contracts"),
      href: useContractsDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useContractsDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useProductsAndServicesDomainMenuItem().path,
      label: t("crm.productAndServiceLabel"),
      domain: PermissionDomain.product,
      children: allowedSubdomainMenuItems(
        useProductsAndServicesDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.product),
    },
    {
      itemId: useExpensesDomainMenuItem().path,
      label: t("crm.expenses"),
      href: useExpensesDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useExpensesDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useProjectsDomainMenuItem().path,
      label: t("crm.projects"),
      href: useProjectsDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useProjectsDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useTasksDomainMenuItem().path,
      label: t("crm.tasks"),
      href: useTasksDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useTasksDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useCalendarDomainMenuItem().path,
      label: t("crm.calendarLabel"),
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useCalendarDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useReportsDomainMenuItem().path,
      label: t("crm.reports"),
      href: useReportsDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useReportsDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useNotesDomainMenuItem().path,
      label: t("crm.notes"),
      href: useNotesDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useNotesDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
    {
      itemId: useSupportDomainMenuItem().path,
      label: t("crm.supportLabel"),
      href: useSupportDomainMenuItem().path,
      domain: PermissionDomain.user,
      children: allowedSubdomainMenuItems(
        useSupportDomainMenuItem().children,
        props.userPermissions
      ),
      allowed: domainIsAllowed(props.userPermissions, PermissionDomain.user),
    },
  ];

  return menuItems.filter((item) => item.allowed);
};

function allowedSubdomainMenuItems(
  children: IMenuItem[] | undefined,
  userPermissions: IPermission[] | undefined
) {
  return children
    ?.filter((child) => subdomainIsAllowed(userPermissions, child.subdomain))
    .map((child) => ({
      itemId: child.path,
      label: child.title,
      href: child.path,
      subdomain: child.subdomain,
    }));
}
