const locale = {
  tasks: "Görevler",
  task: "Görev",
  title: "Görevler",
  addTask: "Görev Ekle",
  emptyState1: "Henüz bir görev eklenmedi.",
  emptyState2: "CRM faaliyetlerinizi takip etmek ve veri odaklı kararlar almak için burada ayrıntılı görevler oluşturun ve görüntüleyin.",
  create: "Görev Ekle",
  subject: "Konu",
  description: "Açıklama",
  startDate: "Başlangıç Tarihi",
  dueDate: "Bitiş Tarihi",
  assigneeIds: "Atanan Kişiler",
  followerIds: "Takip Edenler",
  checklist: "Kontrol Listesi",
  department: "Departman",
  priority: "Öncelik",
  tags: "Etiketler",
  hourlyRate: "Saatlik Ücret",
  hourlyRateCurrency: "Saatlik Para Birimi",
  isBillable: "Faturalanabilir",
  isRecurrent: "Tekrarlayan",
  repeatDays: "Tekrarlanan Günler",
  totalCycle: "Toplam Tekrar Sayısı",
  addChecklistItem: "Kontrol Öğesi Ekle",
  assignee: "Atanan",
  createTaskSuccess: "Görev başarıyla oluşturuldu.",
  deleteTask: "Görevi Sil",
  deleteTaskContent: "Bu görevi silmek istediginizden emin misiniz? Silme islemini geri alamazsiniz.",
  deletedSuccessfully: "Görev silindi.",
  updateTask: "Görevi Güncelle",
  updateSuccess: "Görev başarıyla güncellendi.",
  
  detail: "Görev Detayı",
  subtask: "Alt Görev",

  addSubtask: "Alt Görev Ekle",
  updateStatus: "Durumu Güncelle",

  createdBy: "Görevi oluşturan: ",
};

export default locale;


