/* eslint-disable react-hooks/exhaustive-deps */
import { AlarmOutlined, PeopleOutline, TagOutlined } from '@mui/icons-material';
import { Avatar, Card, Stack, Typography, useMediaQuery } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers/icons';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';
import BaseChip from 'components/chip/BaseChip';
import { NameFactory } from 'corede-common';
import { ITicketDetailResult, TicketTargetType } from 'corede-common-cocrm';
import {} from 'corede-common-cocrm';
import parse from 'html-react-parser';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { representDateTimeAsString } from 'utils/representationHelper';

export interface ITicketDetailDrawerContentComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
}

/**
 * Container for views in content part of detail view
 */
export const TicketDetailDrawerContentComponent = (
  props: ITicketDetailDrawerContentComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack width={'100%'} gap={2} mt={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
        <BaseChip label={props.ticketDetailData?.targetType ?? ''} />
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Typography
            sx={{ fontSize: '14px', color: 'text.primary', fontWeight: 400, textAlign: 'center' }}
          >
            {t('crm.createdBy')}:
          </Typography>
          <Avatar
            src={''}
            sx={{ width: 30, height: 30 }}
          />
          <Typography
            variant={downLg ? 'body1' : 'body2'}
            whiteSpace={'pre-wrap'}
            sx={{ color: 'text.primary' }}
          >
            {NameFactory.getFullName({
              name: props.ticketDetailData?.createdBy?._id,
              surname: props.ticketDetailData?.createdBy?._id,
            })}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'column'} gap={1} mt={2}>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1} mt={1}>
          <AlarmOutlined fontSize="small" sx={{ color: 'text.primary' }} />
          <Typography
            sx={{ fontSize: '14px', color: 'text.primary', fontWeight: 400, textAlign: 'center' }}
          >
            {t('crm.remindDate')}:
          </Typography>
          <Typography
            sx={{ fontSize: '14px', color: 'text.primary', fontWeight: 400, textAlign: 'center' }}
          >
            {moment(props.ticketDetailData?.updatedAt).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          gap={1}
          mt={1}
        >
          <PeopleOutline fontSize="small" sx={{ color: 'text.primary' }} />
          {props.ticketDetailData?.assignees?.map((user, index) => (
            <BaseChip
              key={index}
              label={NameFactory.getFullName({
                name: user?.name,
                surname: user?.surname,
              })}
            />
          ))}
        </Stack>

        <Typography
          sx={{ fontSize: '20px', color: 'text.primary', fontWeight: 600, textAlign: 'center' }}
        >
          {props.ticketDetailData?.subject}
        </Typography>
        <Card sx={{ bgcolor: 'background.secondWithBlur', p: 2, minHeight: '300px' }}>
          <Typography>{parse(props.ticketDetailData?.ticketBody ?? '')}</Typography>
        </Card>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
          <CalendarIcon fontSize="small" sx={{ color: 'text.secondary' }} />
          <Typography
            sx={{ fontSize: '13px', color: 'text.secondary', fontWeight: 400, textAlign: 'center' }}
          >
            {t('crm.createdAt')}:
          </Typography>
          <Typography
            sx={{ fontSize: '13px', color: 'text.secondary', fontWeight: 400, textAlign: 'center' }}
          >
            {representDateTimeAsString(props.ticketDetailData?.createdAt)}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1} mt={1}>
          <TagOutlined fontSize="small" sx={{ color: 'text.primary' }} />
          {props.ticketDetailData?.tags?.map((tag, index) => (
            <BaseChip key={index} label={tag} />
          ))}
        </Stack>
      </Stack>
      <DetailDrawerTargetComponent
        targetType={props.ticketDetailData?.targetType as TicketTargetType}
        targetId={props.ticketDetailData?.target?._id ?? ''}
      />
    </Stack>
  );
};
