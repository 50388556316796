import { useState, lazy, Suspense } from 'react';

const DepartmentUpdateDialog = lazy(() => import('./DepartmentUpdateDialog'));

const DepartmentUpdateWrapper = ({
  open,
  setOpen,
  setOpenRoles,
  selectedDepartment,
  setSelectedDepartment,
}: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <DepartmentUpdateDialog
            open={open}
            setOpen={setOpen}
            setOpenRoles={setOpenRoles}
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
          />
        </Suspense>
      )}
    </>
  );
};

export default DepartmentUpdateWrapper;
