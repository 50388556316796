/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
} from "@mui/icons-material";
import {
  Box,
  Card,
} from "@mui/material";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import EmptyState from "components/emptyState/EmptyState";
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from "corede-common";
import {
  ILeadSource,
  ILeadSourcesInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import {
  useLeadSourceListQuery,
  useLeadSourceUpdateMutation,
} from "../../context/leadSource.api";
import { LeadSourceDeleteDialog } from "../LeadSourceDeleteDialog";
import LeadSourceUpdateDrawerWrapper from "../update/LeadSourceUpdateDrawer.wrapper";
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from "apps/crm/components/list/DefaultList-grid.component";
import {
  IPendingNavigation,
} from "apps/crm/util/handleSideNavigationNavigate.action";

export interface ILeadSourceListGridComponent {
  // leadSource
  setOpenLeadSourceCreateDrawer?: (value: boolean) => void | undefined;
}

const LeadSourceListGridComponent = memo((props: ILeadSourceListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedLeadSource, setSelectedLeadSource] = useState<TListGridSelectedEntity<ILeadSource>>();
  const [openLeadSourceDeleteDialog, setOpenLeadSourceDeleteDialog] = useState(false);
  const [openLeadSourceUpdateDrawer, setOpenLeadSourceUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] =
    useState<IPendingNavigation | null>(null);

  const [leadSourceListFilter, setLeadSourceListFilter] = useState<
    IGraphqlVariables<ILeadSourcesInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10, sort: { "index": 1 } },
    },
  });

  // queries
  const {
    data: leadSourceListData,
    isLoading: leadSourceListLoading,
    isFetching: leadSourceListFetching,
    error: leadSourceListError,
    refetch,
  } = useLeadSourceListQuery(leadSourceListFilter);

  // used for direct update in the list rows
  const [leadSourceUpdate, { isLoading: leadSourceUpdateLoading, error: leadSourceUpdateError }] =
    useLeadSourceUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setLeadSourceListLoading && props.setLeadSourceListLoading(leadSourceListLoading);
  // }, [leadSourceListLoading]);
  useEffect(() => {
    refetch();
  }, [leadSourceListFilter]);


  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadSourceListError as IBaseError, currentLanguage);
  }, [leadSourceListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceUpdateError as IBaseError,
      currentLanguage
    );
  }, [leadSourceUpdateError]);

  const isEmptyState =
    leadSourceListData?.data?.length === 0 &&
    !leadSourceListLoading &&
    !leadSourceListFetching &&
    isNullOrUndefinedOrEmptyObject(leadSourceListFilter?.input?.filter);

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedLeadSource}
      setSelectedEntity={setSelectedLeadSource}
      listFilter={leadSourceListFilter}
      setListFilter={setLeadSourceListFilter}
      listData={leadSourceListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card
        sx={{ bgcolor: "background.secondWithBlur", p: "0px", height: "100%" }}
      >
        {leadSourceListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                leadSourceListData?.data?.map((leadSource, index) => ({
                  id: leadSource.index,
                  index: leadSource.index,
                  _id: leadSource._id,
                  name: leadSource.name,
                  icon: leadSource.icon,
                  color: leadSource.color,
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "id",
                      disableColumnMenu: true,
                      filterable: false,
                      width: 90,
                      align: "center",
                    },
                    headerConfig: {
                      name: "Index",
                      align: "center",
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "name",
                      filterable: true,
                      editable: true,
                      width: 200,
                    },
                    headerConfig: {
                      icon: "radix-icons:text",
                      name: t("crm.name"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.icon,
                    column: {
                      field: "icon",
                      editable: false,
                      filterable: false,
                      align: "left",
                      width: 100,
                    },
                    headerConfig: {
                      icon: "tdesign:icon",
                      name: t("crm.icon"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.color,
                    column: {
                      field: "color",
                      editable: false,
                      filterable: false,
                      align: "left",
                      width: 100,
                    },
                    headerConfig: {
                      icon: "ic:outline-color-lens",
                      name: t("crm.color"),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadSource,
                      setOpenAction: setOpenLeadSourceUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadSource,
                      setOpenAction: setOpenLeadSourceDeleteDialog,
                    },
                  },
                },
                actionHeaderName: t("crm.actions"),
              }}
              loading={leadSourceListLoading || leadSourceUpdateLoading || leadSourceListFetching}
              listFilter={{
                filterInput: leadSourceListFilter,
                setFilterInput: setLeadSourceListFilter,
              }}
              update={{
                updateQuery: leadSourceUpdate,
              }}
              count={leadSourceListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  leadSourceValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t("crm.lead.leadSource.emptyState1")}
              content2={t("crm.lead.leadSource.emptyState2")}
              button1={{
                title: t("crm.lead.leadSource.createLeadSource"),
                onClick: () =>
                  (props?.setOpenLeadSourceCreateDrawer ?? (() => { }))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: "100%", height: "10px" }} />

      <LeadSourceUpdateDrawerWrapper
        open={openLeadSourceUpdateDrawer}
        setOpen={setOpenLeadSourceUpdateDrawer}
        selectedLeadSource={selectedLeadSource}
      />

      <LeadSourceDeleteDialog
        open={openLeadSourceDeleteDialog}
        itemId={selectedLeadSource?._id ?? ""}
        setItem={setSelectedLeadSource}
        onClose={() => {
          setSelectedLeadSource(undefined);
          setOpenLeadSourceDeleteDialog(false);
        }}
        key={selectedLeadSource?._id}
      />
    </DefaultListGridComponent>
  );
});

export default LeadSourceListGridComponent;
