const locale = {
  proposal: 'Proposal',
  title: 'Proposals',
  create: 'Create Proposal',
  update: 'Update Proposal',
  delete: 'Delete',
  view: 'View Proposal',
  list: 'List Proposals',
  createdSuccessfully: 'Proposal created successfully',
  updatedSuccessfully: 'Proposal updated successfully',
  deleteConfirm: 'Are you sure you want to delete? This action cannot be undone.',
  deletedSuccessfully: 'Proposal deleted successfully',
  createInfo: 'You can create new proposals here.',
  prefix: 'Prefix',
  proposalId: 'Proposal',
  timeZone: 'TimeZone',
  prospectTargetEntityId: 'ProspectTarget',
  prospectTargetEntityRef: 'ProspectTargetRef',
  prospectType: 'Prospect Type',
  prospectName: 'Prospect Name',
  prospectVAT: 'Prospect VAT',
  prospectPhoneNumber: 'Prospect PhoneNumber',
  prospectCountry: 'Prospect Country',
  prospectState: 'Prospect State',
  prospectCity: 'Prospect City',
  prospectZIP: 'Prospect ZIP',
  prospectAddress: 'Prospect Address',
  prospectContactFirstName: 'Prospect Contact First Name',
  prospectContactLastName: 'Prospect Contact Last Name',
  prospectContactEmail: 'Prospect Contact Email',
  language: 'Language',
  date: 'Date',
  expireDate: 'Expire Date',
  sendEmailOnExpiration: 'Send Email On Expiration',
  expirationEmailSentDate: 'Expiration Email Sent Date',
  projectOrServiceDesc: 'Project Or Service Desc',
  scopeOfWork: 'Scope Of Work',
  assumptionsAndExclusions: 'Assumptions And Exclusions',
  paymentTerms: 'Payment Terms',
  currency: 'Currency',
  products: 'Products',
  addProducts: 'Add Products',
  offers: 'Offers',
  reference: 'Reference',
  salesAgentIds: 'Sales Agents',
  allowComments: 'Allow Comments',
  useLegalNDA: 'Use NDA',
  legalNDAId: 'Legal NDA',
  useLegalTermAndCond: ' Use Term And Cond',
  legalTermAndCondId: 'Legal Term And Cond',
  signerUserId: 'Signer User',
  signatureImage: 'Signature Image',
  listInfo: 'List of all proposals.',
  importedError: 'Imported error',
  importedSuccessfully: 'Imported successfully',
  downloadFormError: 'Download form error',
  downloadFormSuccessfully: 'Download form successfully',
  downloadExampleFormSuccessfully: 'Download example form successfully',
  downloadExampleFormError: 'Download example form error',
  emptyState1: 'No proposals found here.',
  emptyState2: 'You can create or import proposals immediately.',
  createProposal: 'Create Proposal',
  importProposal: 'Import Proposal',
  importTitle: 'Import Proposals',
  downloadExample: 'Download Example',
  importText1: 'You can import proposals from this page.',
  importText2: 'You can use the sample form below to publish your proposals.',
  importExport: 'Import/Export',
  exportData: 'Export Data',
  importData: 'Import Data',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'View',
  edit: 'Edit',
  junk: 'Junk',
  unJunk: 'Un-Junk',
  addNewRole: 'Add New Role',
  actions: 'Actions',
  businessName: 'Business Name',
  governmentAgency: 'Government Agency',
  fullName: 'Full Name',
  VAT: 'VAT',
  phoneNumber: 'Phone Number',
  country: 'Country',
  state: 'State',
  city: 'City',
  ZIP: 'ZIP',
  address: 'Address',
  contactFirstName: 'Contact First Name',
  contactLastName: 'Contact Last Name',
  contactEmail: 'Contact Email',
  sentDate: 'Sent Date',
  name: 'Name',
  description: 'Description',
  productUnit: 'Unit',
  quantity: 'Quantity',
  price: 'Price',
  priceType: 'Price Type',
  discountedPrice: 'Discounted Price',
  finalPrice: 'Final Price',
  tax: 'Tax',
  service: 'Service',
  subTotal: 'Sub Total',
  taxTotal: 'Tax Total',
  discount: 'Discount',
  product: 'Product',
  services: 'Services',
  references: 'References',
  add: 'Add',
  salesAgent: 'Sales Agent',
  executiveSummary: 'Executive Summary',
  companyBackground: 'Company Background',
  projectorServiceDesc: 'Detailed Project/Service Description',
  costBreakdown: 'Cost Breakdown',
  projectTimeline: 'Project Timeline',
  testimonialCaseStudies: 'Testimonial Case Studies',
  conclusion: 'Conclusion',
  proposalCreateSuccess: 'Proposal created successfully',
  sentSuccessfully: 'Proposal sent successfully',
  send: 'Send Proposal',
  sendSureTitle: 'Send Proposal',
  sendSureDesc: 'Are you sure you want to send this proposal?',

  // alert
  noProposal: 'There are no proposals',
  noProposalContent1: 'Proposals not found',
  noProposalSent: 'There are no sent proposals',

  // generalInfo
  generalInfoTitle: 'General Information',
  status: 'Status',
  createdAt: 'Created At',
  createdBy: 'Created By',
  updatedAt: 'Updated At',
  notAvailable: 'Not Available',
  updateStatus: 'Update Status',

  // customerInfo
  customerInfoTitle: 'Customer Information',
  contactPhoneNumber: 'Phone Number',

  //productInfo
  productInfoTitle: 'Product Details',
  productNameUnknown: 'Unknown Product',
  noProducts: 'No products available.',

  //legalInfo
  legalInfoTitle: 'Legal Information',
  ndaTitle: 'Non-Disclosure Agreement (NDA)',
  termsTitle: 'Terms and Conditions',
  documentName: 'Document Name',
  documentDescription: 'Description',
  documentContent: 'Content',

  //financialInfo
  financialInfoTitle: 'Financial Information',
  shippingTotal: 'Shipping Total',
  total: 'Total Price',
  noFinancialData: 'No financial data available.',

  // documentInfo
  documentsTitle: 'Documents',
  documentNameUnknown: 'Unknown Document',
  mimeType: 'File Type',
  download: 'Download',
  noDocuments: 'No documents available.',

  // salesAgents
  salesAgentsTitle: 'Sales Agents',
  unknownAgent: 'Unknown Agent',
  noSalesAgents: 'No sales agents available.',

  //signature
  signatureTitle: 'Signature Information',
  additionalInfoTitle: 'Additional Information',
  signature: 'Signature',
  signerUser: 'Signer User',

  //additionalInfo
  yes: 'Yes',
  no: 'No',
};

export default locale;
