import { useState, lazy, Suspense } from "react";
import { ILeadDetailDrawerProps } from "./LeadDetailDrawer";

const LeadDetailDrawer = lazy(() => import("./LeadDetailDrawer"));

const LeadDetailDrawerWrapper = ({
  open,
  setOpen,
  selectedLead,
  sideNavigationProps,
}: ILeadDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <LeadDetailDrawer
            open={open}
            setOpen={setOpen}
            selectedLead={selectedLead}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default LeadDetailDrawerWrapper;
