import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { resources } from "./resouces";
import { enumValues, Language } from "corede-common";

const savedLanguage =
  (localStorage.getItem("language") as Language) || Language.en;

i18n.use(initReactI18next).init({
  resources: resources,
  lng: savedLanguage,
  fallbackLng: Language.en,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

export function changeLanguage(lang: Language) {
  localStorage.setItem("language", lang);
  i18n.changeLanguage(lang);
}

export function getCurrentLanguage(): Language {
  return i18n.language as Language;
}

export function getTranslatedText(key: string): string {
  return i18n.t(key);
}

export function convertEnums<T extends object>(
  enumObj: T
): { [key in keyof T]: string } {
  const result: { [key in keyof T]: string } = {} as {
    [key in keyof T]: string;
  };

  for (const key of Object.keys(enumObj) as Array<keyof T>) {
    if (Object.prototype.hasOwnProperty.call(enumObj, key)) {
      const enumValue = enumObj[key];
      result[key] = i18n.t(`enums.${enumValue}`);
    }
  }

  return result;
}

export function getTranslatedEnumValue(enumValue: string): string {
  return i18n.t(`enums.${enumValue}`);
}

export function getTranslatedEnumValues<T extends object>(
  enumObj: T
): string[] {
  return enumValues(enumObj).map((value) => getTranslatedEnumValue(value));
}


export function generateAutocompleteTranslatedOptions<T extends object>(
  enumObj: T
): { name: string; _id: any }[] {
  return enumValues(enumObj).map((value) => ({
    name: getTranslatedEnumValue(value),
    _id: value,
  }));
}
