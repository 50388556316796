/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IProposalDetailResult } from 'corede-common-cocrm';
import { ProposalDetailDrawerLeftPanelHeaderTagsComponent } from './ProposalDetailDrawer-leftPanel.header.tags.component';
import { ProposalDetailDrawerLeftPanelActionsComponent } from './ProposalDetailDrawer-leftPanel-header-actions.component';

export interface IProposalDetailDrawerLeftPanelHeaderComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const ProposalDetailDrawerLeftPanelHeaderComponent = (
  props: IProposalDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* TAGS */}
      {downLg ? null : (
        <ProposalDetailDrawerLeftPanelHeaderTagsComponent
          status={props.proposalDetailData?.status}
        />
      )}

      {/* Proposal Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.proposalDetailData?.proposalId ?? ''}
      </Typography>

      <ProposalDetailDrawerLeftPanelActionsComponent
        proposalDetailData={props.proposalDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
