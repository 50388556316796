import { MoreVert, Download, Delete } from '@mui/icons-material';
import {
  Card,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Avatar,
} from '@mui/material';
import { EntityDeleteDialog } from 'components/entityDeleteDialog/EntityDeleteDialog';
import { fileTypeForIcon } from 'components/uploadFile/UploadDocument';
import { IBaseError, IUserProfile } from 'corede-common';
import { IFileListItemResult } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useLazyFileDownloadQuery, useFileDeleteMutation } from '../../context/document.api';
import FileDetailDrawer from '../drawer/FileDetailDrawer';

export const FilesGridListCard = (props: { file: IFileListItemResult }) => {
  const { t } = useTranslation();
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openFileDetailDrawer, setOpenFileDetailDrawer] = useState(false);

  const [fileDownload, { error: fileDownloadError }] = useLazyFileDownloadQuery();

  const [
    fileDelete,
    { data: fileDeleteData, isLoading: fileDeleteLoading, error: fileDeleteError },
  ] = useFileDeleteMutation();

  const toggleMenu = (event?: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorElMenu(event ? event.currentTarget : null);

  const handleDownload = async () => {
    toggleMenu();
    await fileDownload({
      input: {
        _id: props.file._id,
      },
    })
      .then((res) => {
        window.open(res.data?.downloadPresignedUrl, '_blank');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (fileDownloadError) {
      DefaultErrorHandlerUseEffect(fileDownloadError as IBaseError, getCurrentLanguage());
    }
  }, [fileDownloadError]);

  return (
    <Card
      onDoubleClick={() => setOpenFileDetailDrawer(true)}
      sx={{
        m: 0,
        bgcolor: 'background.secondWithBlur',
        p: 2,
        ':hover': {
          boxShadow: '0px 32px 32px rgba(0, 0, 0, 0.11)',
        },
      }}
    >
      <Stack direction={'column'} gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <img
            src={fileTypeForIcon(props.file.mimeType)}
            alt="file"
            style={{ width: '30px', height: '30px' }}
          />
          <IconButton
            id="'document-button'"
            aria-controls={openMenu ? 'document-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={toggleMenu}
            color="inherit"
            size="small"
          >
            <MoreVert fontSize="medium" />
          </IconButton>
          <Menu
            id="document-menu"
            open={openMenu}
            anchorEl={anchorElMenu}
            onClose={() => toggleMenu()}
            MenuListProps={{
              'aria-labelledby': 'document-button',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleDownload()}>
              <Stack direction={'row'} alignItems={'center'}>
                <Download fontSize="small" sx={{ mr: 1 }} />
                <Typography fontSize={'15px'} fontWeight={600}>
                  {t('download')}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => setOpenDeleteDialog(true)}>
              <Delete fontSize="small" sx={{ mr: 1 }} color="error" />
              <Typography fontSize={'15px'} fontWeight={600} color={'error'}>
                {t('delete')}
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
        <Stack height={'40px'}>
          <Tooltip title={props.file.customName}>
            <Typography
              fontSize={'14px'}
              fontWeight={600}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
              }}
            >
              {props.file.customName}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack>
          <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
            {moment(props.file.createdAt).format('DD/MM/YYYY hh:mm')}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
          <Avatar
            src={(props.file?.createdBy as IUserProfile)?.profileImage?.thumbnailPublicUrl ?? ''}
            sx={{ width: '25px', height: '25px' }}
          />
          <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
            {(props.file?.createdBy as IUserProfile)?.name ?? ''}{' '}
            {(props.file?.createdBy as IUserProfile)?.surname ?? ''}
          </Typography>
        </Stack>
      </Stack>

      <EntityDeleteDialog
        open={openDeleteDialog}
        itemId={props.file._id}
        deleteMutation={fileDelete}
        deleteMutationData={{
          data: fileDeleteData,
          isLoading: fileDeleteLoading,
          error: fileDeleteError as IBaseError,
        }}
        dialog={{
          title: t('disk.document.document.delete'),
          successMessage: t('disk.document.document.deletedSuccessfully'),
          content: t('disk.document.document.deleteConfirm'),
        }}
        onClose={() => setOpenDeleteDialog(false)}
      />

      <FileDetailDrawer
        openFileDetailDrawer={openFileDetailDrawer}
        setOpenFileDetailDrawer={setOpenFileDetailDrawer}
        file={props.file}
        handleDownload={handleDownload}
      />
    </Card>
  );
};
