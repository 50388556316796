import { documentDomainRoutes } from "../domains/document/routes/document.base.route";
import { DocumentDomainRouter } from "../domains/document/routes/DocumentDomainRouter";
import { Route } from "react-router-dom";
import { DocumentRouter } from "../domains/document/subdomains/document/routes/DocumentRouter";
import DiskLayout from "../layouts/DiskLayout";

export const DiskRouter = () => {
  return (
    <Route path="" element={<DiskLayout />}>
      <Route index element={< DocumentRouter />} />
      <Route
        path={`${documentDomainRoutes.base}/*`}
        element={<DocumentDomainRouter />}
      />
      <Route path="*" element={<DocumentDomainRouter />} />
    </Route>
  );
};