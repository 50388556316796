/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useCustomerCategoryDeleteMutation } from "../context/customerCategory.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const CustomerCategoryDeleteDialog = memo(
  (props: {
    open: boolean;
    estimateId: string;
    onClose: () => void;
    setEstimate: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      customerCategoryDelete,
      {
        data: customerCategoryDeleteData,
        isLoading: customerCategoryDeleteIsLoading,
        error: customerCategoryDeleteError,
      },
    ] = useCustomerCategoryDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.estimateId}
        setItem={props.setEstimate}
        deleteMutation={customerCategoryDelete}
        deleteMutationData={{
          data: customerCategoryDeleteData,
          isLoading: customerCategoryDeleteIsLoading,
          error: customerCategoryDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.customer.customerCategory.delete"),
          successMessage: t("crm.customer.customerCategory.deletedSuccessfully"),
          content: t("crm.customer.customerCategory.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
