/* eslint-disable react-hooks/exhaustive-deps */
import EstimateListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/estimate/components/EstimateListTabComponent';
import ProposalListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/proposal/components/ProposalListTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  EstimateProspectTargetEntityRef,
  ITaskDetailResult,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { TaskDetailDrawerTabInfoComponent } from './TaskDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useTaskDocumentCreateManyMutation,
  useTaskDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';

export interface ITaskDetailDrawerRightPanelTabsComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const TaskDetailDrawerRightPanelTabsComponent = (
  props: ITaskDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <DefaultTabsComponent<ITaskDetailResult> entityData={props.taskDetailData}>
      <TaskDetailDrawerTabInfoComponent
        taskDetailData={props.taskDetailData}
        tabKey="info"
        name="crm.info"
      />

      {/* <EstimateListTabSectionComponent
        prospectTargetId={props.taskDetailData?._id ?? ''}
        prospectTargetEntityRef={EstimateProspectTargetEntityRef.Customer}
        tabKey="estimates"
        name="crm.estimates"
      />

      <ProposalListTabSectionComponent
        prospectTargetId={props.taskDetailData?._id ?? ''}
        prospectTargetEntityRef={ProposalProspectTargetEntityRef.Customer}
        tabKey="proposals"
        name="crm.proposals"
      /> */}

      {/* <TaskListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.User}
        name="crm.tasks"
        tabKey="tasks"
      /> */}

      <DocumentListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useTaskDocumentCreateManyMutation}
        useDocumentAddManyMutation={useTaskDocumentAddManyMutation}
      />

      <NoteListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Task}
        targetDisplayName={props.taskDetailData?.subject ?? ''}
        tabKey="notes"
        name="crm.notes"
      />

      <ReminderListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Task}
        targetDisplayName={props.taskDetailData?.subject ?? ''}
        tabKey="reminders"
        name="crm.reminders"
      />

      {/* <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      /> */}
    </DefaultTabsComponent>
  );
};
