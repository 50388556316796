/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  IInvoiceDetailResult,
  NoteTargetType,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { InvoiceDetailDrawerTabInfoComponent } from './InvoiceDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useInvoiceDocumentAddManyMutation,
  useInvoiceDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { InvoiceDetailDrawerTabAdditionalInfoComponent } from './InvoiceDetailDrawer-tab-additionalInfoSection.component';
// import {
//   useInvoiceDocumentCreateManyMutation,
//   useInvoiceDocumentAddManyMutation,
// } from 'apps/disk/domains/document/subdomains/document';

export interface IInvoiceDetailDrawerRightPanelTabsComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const InvoiceDetailDrawerRightPanelTabsComponent = (
  props: IInvoiceDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <DefaultTabsComponent<IInvoiceDetailResult> entityData={props.invoiceDetailData}>
      <InvoiceDetailDrawerTabInfoComponent
        invoiceDetailData={props.invoiceDetailData}
        tabKey="info"
        name="crm.info"
      />

      <InvoiceDetailDrawerTabAdditionalInfoComponent
        invoiceDetailData={props.invoiceDetailData}
        tabKey="additionalInfo"
        name="crm.additionalInfo"
      />

      <TaskListTabComponent
        targetId={props.invoiceDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.Invoice}
        name="crm.tasks"
        tabKey="tasks"
      />

      <DocumentListTabComponent
        targetId={props.invoiceDetailData?._id ?? ''}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useInvoiceDocumentCreateManyMutation}
        useDocumentAddManyMutation={useInvoiceDocumentAddManyMutation}
      />

      <NoteListTabComponent
        targetId={props.invoiceDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Invoice}
        targetDisplayName={props.invoiceDetailData?.invoiceId.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
      />

      <ReminderListTabComponent
        targetId={props.invoiceDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Invoice}
        targetDisplayName={props.invoiceDetailData?.invoiceId.toString() ?? ''}
        tabKey="reminders"
        name="crm.reminders"
      />

      <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      />
    </DefaultTabsComponent>
  );
};
