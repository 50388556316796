import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useCustomerListCountQuery } from '../../context/customer.api';
import { CustomerListKanbanComponent } from './CustomerList-kanban.component';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import CustomerListComponent from './CustomerList-grid.component';
import { useLocation } from 'react-router-dom';
import { CustomerListStatisticsView } from './CustomerList-statistics.component';
import CustomerCreateDrawerWrapper from '../create/CustomerCreateDrawer.wrapper';
import { useUserDetailOwnQuery } from 'apps/auth/context';

const CustomerList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openCustomerCreateDrawer, setOpenCustomerCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [customerListType, setCustomerListType] = useState('list');

  // queries
  const { data: customerListCountData, isLoading: customerListCountLoading } =
    useCustomerListCountQuery({});
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  let totalCustomerCount = customerListCountData?.count ?? 0;
  let isEmpty = totalCustomerCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenCustomerCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'body1'}>
              {t(`crm.customer.customer.welcome`)},
            </Typography>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {userDetailOwnData?.name} 👋🏻
            </Typography>
          </Stack>
        }
        description={
          <Typography variant={downMd ? 'caption' : 'body1'}>
            {t(`crm.customer.customer.description`)}
          </Typography>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.customer.customer.create`),
          onClick: () => setOpenCustomerCreateDrawer(true),
        }}
        // TODO rightButton2={{
        //   visibility: !isEmpty,
        //   icon: <FileUpload sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
        //   onClick: () => navigate(importCustomerRoute()),
        //   title: t(`crm.customer.customer.importCustomer`),
        // }}
      />

      <CustomerListStatisticsView
        count={totalCustomerCount}
        customerListCountLoading={customerListCountLoading}
      />

      {customerListType === 'list' ? (
        <CustomerListComponent setOpenCustomerCreateDrawer={setOpenCustomerCreateDrawer} />
      ) : (
        <CustomerListKanbanComponent
          statuses={[]}
          customers={[]}
          setOpenCustomerStatusesDrawer={() => null}
          setSelectedCustomer={null}
          setOpenUserDrawer={null}
        />
      )}

      <CustomerCreateDrawerWrapper
        open={openCustomerCreateDrawer}
        setOpen={setOpenCustomerCreateDrawer}
      />
    </Grid>
  );
});

export default CustomerList;
