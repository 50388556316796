

export const salesDomainRoutes = {
  base: "sales",
  subdomains: {
    invoice: {
      base: "invoice",
    },
    payment: {
      base: "payment",
    },
    return: {
      base: "return",
    },
  },
};


export const baseSalesRoute = (): string => `/${salesDomainRoutes.base}/`;
export const listInvoicesRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.invoice.base}`;
export const listPaymentsRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.payment.base}`;
export const listReturnsRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.return.base}`;

