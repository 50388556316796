const locale = {
  demo: "Demo",
  title: "What is the <highlight>CoCRM</highlight> Community?",
  description:
    "At CoCRM, in addition to providing you with effective management of your business and customer processes, we also offer a collaboration space designed to expand your network and business opportunities.",
  featuresTitle: "With the CoCRM Community, you can:",
  features1: "Gain new business opportunities through referrals.",
  features2: "Connect with reliable suppliers.",
  features3: "Find trustworthy business partners.",
  features4: "Expand your network to offer 360° services to your customers.",
  buttonTitle: "Join the Community",
  formTitle: "Join Waitlist",
  formDescription:
    "Please fill out the form below and we will get in touch with you as soon as possible.",
  sendSuccess: "Thank you! We will get back to you as soon as possible.",
  formButtonTitle: "Join",
  fullName: "Full Name",
  email: "Email",
  phone: "Phone",
  orgName: "Organization Name",
};

export default locale;
