import { Grid, Skeleton } from '@mui/material';

const FilesGridSkeleton = () => {
  return (
    <Grid container mt={2} spacing={2}>
      {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Skeleton variant="rectangular" height={150} sx={{ borderRadius: 2 }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FilesGridSkeleton;
