const locale = {
  // actions
  create: 'Create',
  update: 'Update Customer',
  detail: 'Customer Detail',
  view: 'View Customer',
  list: 'List Customers',
  addNewRole: 'Add New Role',
  tagsPlaceholder: 'Add tags',
  listInfo: 'List of all customers.',
  weeklyReports: 'Weekly Reports',

  // situational messages
  createdSuccessfully: 'Customer created successfully',
  updatedSuccessfully: 'Customer updated successfully',
  deleteConfirm: 'Are you sure you want to delete? This action cannot be undone.',
  deletedSuccessfully: 'Customer deleted successfully',
  createInfo: 'You can create new customers here.',
  updateCustomerSuccess: 'Customer updated successfully',
  createCustomerSuccess: 'Customer created successfully',
  emptyState1: 'No customers found here.',
  emptyState2: 'You can create or import customers immediately.',

  // import
  importCustomer: 'Import Customer',
  importTitle: 'Import Customers',
  importText1: 'You can import customers from this page.',
  importText2: 'You can use the sample form below to publish your customers.',

  // fields and names
  title: 'Customers',
  customer: 'Customer',
  customers: 'Customers',
  assigneds: 'Assignees',
  qualification: 'Qualification',
  justOwn: 'Just Own',
  isJunk: 'Is Junk',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Junk',
  unJunk: 'Un-Junk',
  prospectName: 'Prospect Name',
  businessType: 'Business Type',
  customerType: 'Customer Type',
  customerCount: 'customers',
  customerValue: 'Customer Value',

  // contact
  addContact: 'Add Contact',
  contactedDate: 'Contacted Date',
  emptyStateContactContent1: 'No contacts found here.',
  contactDetail: 'Contact Detail',
  contactHistory: 'Contact History',
  lastContacted: 'Last Contacted',
  lastContact: 'Last Contact',
  contacted: 'Contacted',

  onSiteEstimateWith: 'On site estimate with',

  // new
  customerListTitle: 'List Customers',
  headerTitle: 'Customers',
  relatedCustomers: 'Related Customers',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Customer Name',
  customerEmailLabel: 'Customer Email',
  customerPhoneLabel: 'Customer Phone',
  customerTypeLabel: 'Customer Type',
  customerPictureLabel: 'Customer Picture',
  customerLanguageLabel: 'Customer Language',

  createSuccess: 'Customer created successfully',

  prospectType: 'Prospect Type',
  prospectAddress: 'Prospect Address',
  prospectContactName: 'Prospect Contact Name',

  // accordions
  primaryContact: 'Primary Contact',
  primaryBillingInfo: 'Primary Billing Info',
  primaryShippingInfo: 'Primary Shipping Info',
  detailInformation: 'Detail Information',
  address: 'Address',

  // labels
  welcome: 'Welcome',
  description: "Your customer progress this week is amazing. Let's continue.",
};

export default locale;
