/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { ITaskDetailResult, TaskPriority } from 'corede-common-cocrm';
import React from 'react';
import { t } from 'i18next';
import {
  EventAvailableOutlined,
  EventBusyOutlined,
  PriorityHighOutlined,
  Tag,
} from '@mui/icons-material';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { IUserProfile } from 'corede-common';

export interface ITaskDetailDrawerLeftPanelContactDetailComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container for views in contact detail section of left panel header
 */
export const TaskDetailDrawerLeftPanelContactDetailComponent = (
  props: ITaskDetailDrawerLeftPanelContactDetailComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      <Stack direction={{ xs: 'row', lg: 'column' }} width={'100%'} gap={2} mt={2}>
        {downLg ? null : (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.contactDetails')}
            </Typography>
            <Divider />
          </Stack>
        )}

        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Box
            sx={{
              width: 40,
              height: 40,
              bgcolor: 'background.fourth',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EventAvailableOutlined sx={{ color: 'text.secondary' }} />
          </Box>
          <Stack direction={'column'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {t('crm.startDate')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {moment(props.taskDetailData?.startDate).format('DD/MM/YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'row', lg: 'column' }} width={'100%'} gap={2} mt={2}>
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Box
            sx={{
              width: 40,
              height: 40,
              bgcolor: 'background.fourth',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EventBusyOutlined sx={{ color: 'text.secondary' }} />
          </Box>
          <Stack direction={'column'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {t('crm.dueDate')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {moment(props.taskDetailData?.dueDate).format('DD/MM/YYYY')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Box
            sx={{
              width: 40,
              height: 40,
              bgcolor: 'background.fourth',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PriorityHighOutlined sx={{ color: 'text.secondary' }} />
          </Box>
          <Stack direction={'column'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {t('crm.priority')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {getTranslatedEnumValue(props.taskDetailData?.priority as TaskPriority)}
            </Typography>
          </Stack>
        </Stack>
        {downLg ? null : (
          <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
            <Box
              sx={{
                width: 40,
                height: 40,
                bgcolor: 'background.fourth',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tag sx={{ color: 'text.secondary' }} />
            </Box>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  color: 'text.secondary',
                }}
              >
                {t('crm.tags')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  color: 'text.primary',
                }}
              >
                {props.taskDetailData?.tags
                  ? props.taskDetailData?.tags?.length > 0
                    ? props.taskDetailData?.tags?.map((tag) => tag).join(', ')
                    : '-'
                  : '-'}
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Avatar
            alt={(props.taskDetailData?.createdBy as IUserProfile)?.name ?? ''}
            // alt={'XXX'}
            src={
              (props.taskDetailData?.createdBy as IUserProfile)?.profileImage?.thumbnailPublicUrl ??
              ''
            }
            sx={{ width: 40, height: 40 }}
          />
          <Stack direction={'column'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {t('crm.createdBy')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {(props.taskDetailData?.createdBy as IUserProfile)?.name ?? ''}{' '}
              {(props.taskDetailData?.createdBy as IUserProfile)?.surname ?? ''}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Avatar
            alt={(props.taskDetailData?.updatedBy as IUserProfile)?.name ?? ''}
            src={
              (props.taskDetailData?.updatedBy as IUserProfile)?.profileImage?.thumbnailPublicUrl ??
              ''
            }
            sx={{ width: 40, height: 40 }}
          />
          <Stack direction={'column'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {t('crm.updatedBy')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {(props.taskDetailData?.updatedBy as IUserProfile)?.name ?? ''}{' '}
              {(props.taskDetailData?.updatedBy as IUserProfile)?.surname ?? ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
