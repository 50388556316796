import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const defaultTableStyle: SxProps<Theme> = {
  fontSize: '12px',
  borderRadius: '24px',
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  },
  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: '12px',
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: '12px',
    p: '2px',
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
};
