
import { useState, lazy, Suspense } from 'react';

const PaymentUpdateDrawer = lazy(() => import('./PaymentUpdateDrawer'));

const PaymentUpdateDrawerWrapper = ({
    open,
    setOpen,
    selectedPayment,
}: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <PaymentUpdateDrawer
                        open={open}
                        setOpen={setOpen}
                        selectedPayment={selectedPayment}
                    />
                </Suspense>
            )}
        </>
    );
};

export default PaymentUpdateDrawerWrapper;
