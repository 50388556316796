import { Grid, useMediaQuery, Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const DemoDomainLayout = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      gap={2}
      justifyContent="space-between"
      alignItems={downMd ? 'flex-start' : 'center'}
      sx={{
        height: '100%',
        px: {
          xs: 0,
          lg: 2,
        },
        py: downMd ? 3 : 1,
      }}
    >
      <Outlet />
    </Grid>
  );
};
