/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Box,
  Typography,
  useMediaQuery,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { convertEnums } from 'localization';
import { ILeadDetailResult, LeadQualification } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerCreateFromLeadDialog } from '../CustomerCreateFromLeadDialog';
import * as Icons from '@mui/icons-material';

import { LeadDetailDrawerLeftPanelActionsComponent } from './LeadDetailDrawer-leftPanel-header-actions.component';
import { LeadDetailDrawerLeftPanelLastProposalComponent } from './LeadDetailDrawer-leftPanel-header-lastProposal.component';
import { LeadDetailDrawerLeftPanelHeaderTagsComponent } from './LeadDetailDrawer-leftPanel.header.tags.component';

export interface ILeadDetailDrawerLeftPanelHeaderComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */

const DrawerMenu = (props: ILeadDetailDrawerLeftPanelHeaderComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConvertForm, setOpenConvertForm] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="lead-detail-button"
        aria-controls={open ? 'lead-detail-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: 24,
          width: 24,
          minWidth: 0,
          borderRadius: '50%',
          padding: 0,
        }}
      >
        <Icons.MoreVert />
      </Button>
      <Menu
        id="lead-detail-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lead-detail-button',
        }}
      >
        <MenuItem onClick={() => setOpenConvertForm(true)}>
          <Icons.Refresh sx={{ mr: 1 }} />
          <Typography fontSize={'small'} fontWeight={'bold'}>
            {t('crm.lead.lead.convertToCustomer')}
          </Typography>
        </MenuItem>
      </Menu>

      <CustomerCreateFromLeadDialog
        leadDetailData={props.leadDetailData}
        openConvertForm={openConvertForm}
        setOpenConvertForm={setOpenConvertForm}
        setOpen={props.setOpen}
      />
    </>
  );
};

export const LeadDetailDrawerLeftPanelHeaderComponent = (
  props: ILeadDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const localizedLeadQualifications = convertEnums(LeadQualification);

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
      width={'100%'}
    >
      {/* TAGS */}
      {/* {downLg ? null : (
        <LeadDetailDrawerLeftPanelHeaderTagsComponent
          businessType={props.leadDetailData?.businessType}
          isJunk={props.leadDetailData?.isJunk}
        />
      )} */}

      {/* Lead Base Info Section */}
      <Stack direction={'column'} alignItems={'center'} gap={2} width={'100%'}>
        <Box
          sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Avatar
            src={'https://dummyimage.com/400x400/000/fff'}
            sx={{
              width: 82,
              height: 82,
              borderRadius: '50%',
            }}
          />

          <DrawerMenu leadDetailData={props.leadDetailData} setOpen={props.setOpen} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant={'body1'} sx={{ fontWeight: '500' }}>
            {props.leadDetailData?.fullName}
          </Typography>

          {downLg ? null : (
            <Typography variant={'body2'} color={'text.secondary'}>
              {localizedLeadQualifications[
                (props.leadDetailData as any)
                  ?.qualification as keyof typeof localizedLeadQualifications
              ] ?? ''}
            </Typography>
          )}
        </Box>
      </Stack>

      {/* <LeadDetailDrawerLeftPanelActionsComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
      />

      <LeadDetailDrawerLeftPanelLastProposalComponent
        leadDetailData={props.leadDetailData}
        setOpen={props.setOpen}
      /> */}
    </Stack>
  );
};
