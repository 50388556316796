/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import { CustomerType, ICustomerCreateFromLeadInput, ILeadDetailResult } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useCustomerCreateFromLeadMutation } from '../context/lead.api';
import ActionDialog from 'components/dialog/ActionDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCustomerCreateFromLeadInput } from '../validations/customerCreateFromLead';
import { DefaultTextInputComponent } from '../../../../../components/mutation/DefaultTextInputComponent';
import { DefaultEnumInputComponent } from '../../../../../components/mutation/DefaultEnumInputComponent';

export interface ICustomerCreateFromLeadDialogProps {
  leadDetailData: ILeadDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  openConvertForm: boolean;
  setOpenConvertForm: Dispatch<SetStateAction<boolean>>;
}

export const CustomerCreateFromLeadDialog = memo((props: ICustomerCreateFromLeadDialogProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    customerCreateFromLead,
    {
      data: customerCreateFromLeadData,
      isLoading: customerCreateFromLeadLoading,
      error: customerCreateFromLeadError,
    },
  ] = useCustomerCreateFromLeadMutation();

  // constants

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<IGraphqlVariables<ICustomerCreateFromLeadInput>>({
    defaultValues: {
      input: {
        leadId: props.leadDetailData?._id,
        type: CustomerType.business,
        primaryContact: {
          email: props.leadDetailData?.email,
          firstName: props.leadDetailData?.fullName.split(' ')[0],
          lastName: props.leadDetailData?.fullName.split(' ')[1],
          phone: props.leadDetailData?.phoneNumber,
          position: props.leadDetailData?.position,
        },
      },
    },
    resolver: yupResolver(validateCustomerCreateFromLeadInput),
    mode: 'onChange',
  });

  // action handlers

  const handleConvertLead = async (values: IGraphqlVariables<ICustomerCreateFromLeadInput>) => {
    customerCreateFromLead(values);
  };

  // useEffects
  useEffect(() => {
    if (props.leadDetailData) {
      setValue('input.leadId', props.leadDetailData._id);
      setValue('input.primaryContact.email', props.leadDetailData.email);
      setValue('input.primaryContact.firstName', props.leadDetailData.fullName.split(' ')[0]);
      setValue('input.primaryContact.lastName', props.leadDetailData.fullName.split(' ')[1]);
      setValue('input.primaryContact.phone', props.leadDetailData.phoneNumber ?? '');
      setValue('input.primaryContact.position', props.leadDetailData.position ?? '');
    }
  }, [props.leadDetailData]);

  // useEffects.success
  useEffect(() => {
    if (customerCreateFromLeadData) {
      enqueueSnackbar(t('crm.lead.lead.leadConvertedSuccessfully'), {
        variant: 'success',
      });
      reset();
      props.setOpen(false);
      props.setOpenConvertForm(false);
    }
  }, [customerCreateFromLeadData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerCreateFromLeadError as IBaseError, currentLanguage);
  }, [customerCreateFromLeadError]);

  return (
    <ActionDialog
      open={props.openConvertForm}
      onClose={() => props.setOpenConvertForm(false)}
      title={t('crm.lead.lead.convertToCustomer')}
      handleClick={handleSubmit(handleConvertLead)}
      loading={customerCreateFromLeadLoading}
      buttonTitle={t('crm.convert')}
      width={500}
    >
      <Grid item xs={12}>
        <DefaultTextInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          control={control}
          setValue={setValue}
          errors={errors}
          touchedFields={touchedFields}
          fieldKey="input.primaryContact.email"
          label={t('crm.email')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultEnumInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          enum={CustomerType}
          control={control}
          fieldKey="input.type"
          label={t('crm.lead.lead.customerType')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          control={control}
          setValue={setValue}
          errors={errors}
          touchedFields={touchedFields}
          fieldKey="input.primaryContact.firstName"
          label={t('crm.firstName')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          control={control}
          setValue={setValue}
          errors={errors}
          touchedFields={touchedFields}
          fieldKey="input.primaryContact.lastName"
          label={t('crm.lastName')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          control={control}
          setValue={setValue}
          errors={errors}
          touchedFields={touchedFields}
          fieldKey="input.primaryContact.phone"
          label={t('crm.phone')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextInputComponent<IGraphqlVariables<ICustomerCreateFromLeadInput>>
          control={control}
          setValue={setValue}
          errors={errors}
          touchedFields={touchedFields}
          fieldKey="input.primaryContact.position"
          label={t('crm.position')}
        />
      </Grid>
    </ActionDialog>
  );
});
