import { useState, lazy, Suspense, Dispatch, SetStateAction } from 'react';

const UserCreateDialog = lazy(() => import('./UserCreateDialog'));

const UserCreateDialogWrapper = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <UserCreateDialog open={props.open} setOpen={props.setOpen} />
        </Suspense>
      )}
    </>
  );
};

export default UserCreateDialogWrapper;
