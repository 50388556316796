import customersLocale from "../subdomains/customer/locales/tr";
import customerCategoryLocale from "../subdomains/customerCategory/locales/tr";
import contactsLocale from "../subdomains/contact/locales/tr";

const locale = {
  customer: {
    ...customersLocale
  },
  contact: {
    ...contactsLocale,
  },
  customerCategory: {
    ...customerCategoryLocale,
  },
};

export default locale;
