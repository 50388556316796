/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useTaskCreateMutation } from '../../context/task.api';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { IGraphqlVariables, unknownError } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { ITaskCreateInput, TaskPriority, TaskTargetType } from 'corede-common-cocrm';
import { useForm } from 'react-hook-form';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import ActionDrawer from 'components/drawer/ActionDrawer';
import { Grid } from '@mui/material';
import { UpsertTaskComponent } from '../form/UpsertTaskComponent';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

type ITaskCreateOverlay = {
  open: boolean;
  setOpen: (open: boolean) => void;
  targetId: string;
  targetEntityRef: TaskTargetType | undefined;
  overlayType: OverlayType;
};

const TaskCreateOverlay = (props: ITaskCreateOverlay) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  //mutation
  const [
    taskCreate,
    { data: taskCreateData, isLoading: taskCreateIsLoading, error: taskCreateError },
  ] = useTaskCreateMutation();

  //form

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, touchedFields, isValid },
  } = useForm<IGraphqlVariables<ITaskCreateInput>>({
    values: {
      input: {
        relatedEntityId: props.targetId ?? undefined,
        relatedEntityRef: props.targetEntityRef ?? undefined,

        subject: '',
        description: '',
        startDate: undefined,
        dueDate: undefined,
        assigneeIds: [],
        followerIds: undefined,
        checklist: [
          {
            description: '',
            assigneeId: '',
            isChecked: undefined,
          },
        ],
        departmentId: undefined,
        priority: TaskPriority.medium,
        tags: [],
        hourlyRate: undefined,
        hourlyRateCurrency: undefined,
        isBillable: false,
        isRecurrent: false,
        repeatDays: undefined,
        totalCycle: undefined,
      },
    },
  });

  const onSubmit = async (values: IGraphqlVariables<ITaskCreateInput>) => {
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await taskCreate(transformedValues as IGraphqlVariables<ITaskCreateInput>);
  };

  //effects

  useEffect(() => {
    if (taskCreateData) {
      enqueueSnackbar(t('crm.task.task.createTaskSuccess'), { variant: 'success' });
      reset();
      props.setOpen(false);
    }
  }, [taskCreateData]);

  useEffect(() => {
    if (taskCreateError) {
      enqueueSnackbar(
        (taskCreateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [taskCreateError]);


  const commonContent = (
    <Grid item xs={12}>
      <UpsertTaskComponent
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        control={control}
        errors={errors}
        touchedFields={touchedFields}
        type="create"
        targetEntityRef={props.targetEntityRef}
      />
    </Grid>
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDrawer
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.task.task.create')}
          loading={taskCreateIsLoading}
          buttonTitle={t('create')}
          handleSubmit={handleSubmit(onSubmit)}
        >
          {commonContent}
        </ActionDrawer>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.task.task.create')}
          loading={taskCreateIsLoading}
          buttonTitle={t('create')}
          handleClick={handleSubmit(onSubmit)}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
};

export default TaskCreateOverlay;
