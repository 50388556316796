
import contractLocale from "../subdomains/contract/locales/tr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  contract: {
    ...contractLocale,
  },
};

export default locale;

