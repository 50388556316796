import { useState, lazy, Suspense } from 'react';

const ProductCategoryCreateDrawer = lazy(() => import('./ProductCategoryCreateDrawer'));

const CategoryCreateWraper = ({ open, setOpen }: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ProductCategoryCreateDrawer open={open} setOpen={setOpen} />
        </Suspense>
      )}
    </>
  );
};

export default CategoryCreateWraper;
