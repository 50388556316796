import { Outlet } from 'react-router';
import MainLayout from 'layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import {
  baseDiskRoute,
  allDocumentRoute,
  privateDocumentRoute,
  myDocumentRoute,
} from '../domains/document/routes/document.base.route';

const DiskLayout = () => {
  const { t } = useTranslation();

  const treeItems = [
    {
      itemId: baseDiskRoute(),
      label: t('disk.document.document.sharedDocuments'),
      href: baseDiskRoute(),
    },
    {
      itemId: privateDocumentRoute(),
      label: t('disk.document.document.privateDocuments'),
      href: privateDocumentRoute(),
    },
    {
      itemId: myDocumentRoute(),
      label: t('disk.document.document.myDocuments'),
      href: myDocumentRoute(),
    },
    {
      itemId: allDocumentRoute(),
      label: t('disk.document.document.allDocuments'),
      href: allDocumentRoute(),
    },
  ];

  return (
    <MainLayout treeItems={treeItems}>
      <Outlet />
    </MainLayout>
  );
};

export default DiskLayout;
