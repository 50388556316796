import crmLocale from '../../apps/crm/locales/en';
import authLocale from '../../apps/auth/locales/en';
import leadPlusLocale from '../../apps/leadPlus/locales/en';
import communityLocale from '../../apps/community/locales/en';
import marketingLocale from '../../apps/marketing/locales/en';
import windowLocale from '../../apps/window/locales/en';
import settingsLocale from '../../apps/settings/locales/en';
import accountLocale from '../../apps/account/locales/en';
import webNotificationLocale from '../../apps/webNotification/locales/en';
import diskLocale from '../../apps/disk/locales/en';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    active: 'Active',
    pending: 'Pending',
    passive: 'Passive',
    neutral: 'Neutral',
    cold: 'Cold',
    warm: 'Warm',
    hot: 'Hot',
    customer: 'Customer',
    unqualified: 'Unqualified',
    IQL: 'IQL (Information)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Sales)',
    en: 'English',
    fr: 'French',
    de: 'German',
    it: 'Italian',
    pt: 'Portuguese',
    ru: 'Russian',
    es: 'Spanish',
    tr: 'Turkish',
    individual: 'Individual',
    business: 'Business',
    government: 'Government',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Flat',
    hourly: 'Hourly',
    daily: 'Daily',
    monthly: 'Monthly',
    NDA: 'NDA',
    termsAndConditions: 'Terms & Conditions',
    lead: 'Lead',
    unknown: 'Unknown',
    everyday: 'Everyday',
    everyWeek: 'Every Week',
    annual: 'Annual',
    email: 'Email',
    sms: 'Sms',
    push: 'Push',
    web: 'Web',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    All: 'All',
    Unread: 'Unread',
    automatic: 'Automatic',
    manual: 'Manual',
    high: 'High',
    low: 'Low',
    medium: 'Medium',
    urgnet: 'Urgent',
    scheduled: 'Scheduled',
    unscheduled: 'Unscheduled',
    expired: 'Expired',
    cancelled: 'Cancelled',
    draft: 'Draft',
    sent: 'Sent',
    opened: 'Opened',
    revised: 'Revised',
    declined: 'Declined',
    accepted: 'Accepted',
    product: 'Product',
    service: 'Service',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Money Transfer',

    //task status
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    tested: 'Tested',
    awaitingFeedback: 'Awaiting Feedback',
    completed: 'Completed',

    // target type
    Lead: 'Lead',
    Customer: 'Customer',
    Estimate: 'Estimate',
    Proposal: 'Proposal',
    Invoice: 'Invoice',
    Task: 'Task',
    User: 'User',
    Organization: 'Organization',
    Project: 'Project',
    None: 'None',

    //ticket categories
    incidentTicket: 'Incident Ticket',
    requestForInformation: 'Request for Information',
    serviceRequest: 'Service Request',
    problemTicket: 'Problem Ticket',
    changeRequest: 'Change Request',
    featureRequest: 'Feature Request',
    feedback: 'Feedback',
    complaint: 'Complaint',
    maintenanceAndOutageReport: 'Maintenance and Outage Report',
    billingAndPaymentIssue: 'Billing and Payment Issue',
    internalTicket: 'Internal Ticket',

    //permissions
    auth: 'Auth',
    comment: 'Comment',
    file: 'File',
    note: 'Note',
    notification: 'Notification',
    payment: 'Payment',
    presale: 'Presale',
    reminder: 'Reminder',
    sale: 'Sale',
    task: 'Task',
    user: 'User',
    permission: 'Permission',
    role: 'Role',
    leadSource: 'Lead Source',
    leadStatus: 'Lead Status',
    leadTag: 'Lead Tag',
    productCategory: 'Product Category',
    webNotification: 'Web Notification',
    notificationHistory: 'Notification History',
    order: 'Order',
    purchase: 'Purchase',
    subscription: 'Subscription',
    proposal: 'Proposal',
    estimate: 'Estimate',
    customerCategory: 'Customer Category',
    customerTag: 'Customer Tag',
    invoice: 'Invoice',
    invoicePayment: 'Payment',
    invoiceReturn: 'Return',
    organizationSettings: 'Organization Settings',
    organizationChart: 'Organization Chart',
    department: 'Department',
    userRole: 'User Role',
    userPermission: 'User Permission',
    manage: 'Manage',
    view: 'View',
    export: 'Export',
    import: 'Import',
  },
  organization: 'Organization',
  department: 'Department',
  user: 'User',
  aboutUs: 'About Us',
  termsAndConditions: 'Terms & Conditions',
  uploadDocuments: 'Upload documents',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  resources: 'Resources',
  allRightsReserved: 'All rights reserved',
  rowsPerPage: 'Rows per page',
  chartUpdateSuccess: 'Chart updated successfully!',
  imageUploadSuccess: 'Image upload successfully!',
  uploadPicture: 'Upload a picture',
  expiresIn: 'Expires in ',
  expiresDays: ' days',
  subscriptionExpired: 'Your plan has expired!',
  noOptionsText: 'No options',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  fullNameIsRequired: 'Full Name is required',
  emailIsRequired: 'Email is required',
  emailIsInvalid: 'Email is invalid',
  nameIsRequired: 'Name is required',
  titleIsRequired: 'Title is required',
  profileImageIsRequired: 'Profile Image is required',
  descriptionIsRequired: 'Description is required',
  phoneNumberIsRequired: 'Phone Number is required',
  quotaYupIsRequired: 'Quota is required',
  dateYupIsRequired: 'Date is required',
  statusYupIsRequired: 'Status is required',
  locationYupIsRequired: 'Location is required',
  detailsIsRequired: 'Details is required',
  surnameIsRequired: 'Surname is required',
  birthDateIsRequired: 'Birth Date is required',
  genderIsRequired: 'Gender is required',
  countryIsRequired: 'Country is required',
  cityIsRequired: 'City is required',
  addressIsRequired: 'Address is required',
  contentIsRequired: 'Content is required',
  readingTimeIsRequired: 'Reading Time is required',
  tagsIsRequired: 'Tags is required',
  referencesIsRequired: 'References is required',
  relatedVideoIsRequired: 'Related Video is required',
  authorIsRequired: 'Author is required',
  categoryIsRequired: 'Category is required',
  targetCategoryIsRequired: 'Target Category is required',
  languageIsRequired: 'Language is required',
  domainIsRequired: 'Domain is required',
  targetIsRequired: 'Target is required',
  questionIsRequired: 'Question is required',
  answerIsRequired: 'Answer is required',
  titleIsTooShort25: 'Title is too short, minimum length is 25',
  titleIsTooLong50: 'Title is too long, maximum length is 50',
  contentIsTooShort50: 'Content is too short, minimum length is 50',
  contentIsTooLong250: 'Content is too long, maximum length is 250',
  descriptionIsTooShort400: 'Description is too short, minimum length is 400',
  descriptionIsTooLong4000: 'Description is too long, maximum length is 4000',
  quotaMustBePositive: 'Quota must be positive',
  quotaMustBeMultipleOf10: 'Quota must be multiple of 10',
  participantsIsRequired: 'Participants is required',
  startDateIsRequired: 'Start Date is required',
  durationIsRequired: 'Duration is required',
  quotaIsRequired: 'Quota is required',
  lastApplicationDateIsRequired: 'Last Application Date is required',
  statusIsRequired: 'Status is required',
  locationIsRequired: 'Location is required',
  typeIsRequired: 'Type is required',
  participationLinkIsRequired: 'Participation Link is required',
  durationMustBeMultipleOf5: 'Duration must be multiple of 5',
  durationMustBePositive: 'Duration must be positive',
  nameIsTooShort2: 'Name is too short, minimum length is 2',
  nameIsTooLong50: 'Name is too long, maximum length is 50',
  surnameIsTooShort2: 'Surname is too short, minimum length is 2',
  surnameIsTooLong50: 'Surname is too long, maximum length is 50',
  stateIsRequired: 'State is required',
  positionIsRequired: 'Position is required',
  companyIsRequired: 'Company is required',
  sectorIsRequired: 'Sector is required',
  qualificationIsRequired: 'Qualification is required',
  prospectNameIsRequired: 'Prospect Name is required',
  prospectPhoneNumberIsRequired: 'Prospect Phone Number is required',
  prospectContactFirstNameIsRequired: 'Prospect Contact First Name is required',
  prospectContactLastNameIsRequired: 'Prospect Contact Last Name is required',
  prospectContactEmailIsRequired: 'Prospect Contact Email is required',
  websiteIsInvalid: 'Web Site is invalid',
  identityNumberIsRequired: 'Identity Number is required',
  taxOfficeIsRequired: 'Tax Office is required',
  streetIsRequired: 'Street is required',
  zipIsRequired: 'Zip is required',
  passwordIsRequired: 'Password is required',
  passwordIsInvalid: 'Must be 8+ chars, include uppercase, lowercase, number and symbol.',
  passwordAgainIsInvalid: 'Passwords must match',

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Cancel',
  save: 'Save',
  update: 'Update',
  delete: 'Delete',
  create: 'Create',
  add: 'Add',
  close: 'Close',
  submit: 'Submit',
  addDocument: 'Add Document',
  upload: 'Upload',
  view: 'View',
  edit: 'Edit',
  remove: 'Remove',
  myPlan: 'My Plan',
  expandAll: 'Expand All',
  collapseAll: 'Collapse All',
  show: 'Show',
  download: 'Download',
  yes: 'Yes',
  no: 'No',
  completed: 'Completed',
  uploadDocument: 'Upload Document',
  newTask: 'New Task',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Actions',
  title: 'Title',
  content: 'Content',
  category: 'Category',
  target: 'Target',
  status: 'Status',
  date: 'Date',
  location: 'Location',
  details: 'Details',
  name: 'Name',
  surname: 'Surname',
  birthDate: 'Birth Date',
  fullName: 'Full Name',
  email: 'Email',
  subject: 'Subject',
  createdAt: 'Created At',
  userType: 'User Type',
  page: 'Page',
  news: 'News',
  offer: 'Offer',
  products: 'Products',
  updatedAt: 'Updated At',
  readingTime: 'Reading Time',
  tags: 'Tags',
  references: 'References',
  version: 'Version',
  domain: 'Domain',
  type: 'Type',
  language: 'Language',
  duration: 'Duration',
  startDate: 'Start Date',
  participants: 'Participants',
  quota: 'Quota',
  question: 'Question',
  answer: 'Answer',
  lastApplicationDate: 'Last Application Date',
  selectAnOption: 'Select an Option',
  showing: 'Showing {{start}} to {{end}} of {{total}} entries',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Dashboard',
  blog: 'Blog',
  blogs: 'Blogs',
  categories: 'Categories',
  authors: 'Authors',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'Faq Categories',
  form: 'Form',
  contactForm: 'Contact Forms',
  subscriptionForm: 'Subscription Forms',
  blogTargetCategories: 'Target Categories',
  users: 'Users',
  departments: 'Departments',
  permissions: 'Permissions',
  leadStatuses: 'Lead Statuses',
  leadSources: 'Lead Sources',
  leadForms: 'Lead Forms',
  customers: 'Customers',
  contacts: 'Contacts',
  customerCategories: 'Customer Categories',
  estimates: 'Estimates',
  proposals: 'Proposals',
  invoices: 'Invoices',
  payments: 'Payments',
  contracts: 'Contracts',
  returns: 'Returns',
  services: 'Services',
  reminders: 'Reminders',
  appointments: 'Appointments',
  starred: 'Starred',
  recent: 'Recent',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Organization Level',
  organizationLevelDesc: 'The level of the organization in which the user is located.',
  departmentLevel: 'Department Level',
  departmentLevelDesc: 'The level of the department in which the user is located.',
  userLevel: 'User Level',
  userLevelDesc: 'The level of the user in which the user is located.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'min',
};

export default locale;
