/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Card, Chip, Divider, Grid, Grow, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { FilledButton, MyTab, OutlinedButton, SettingsTitle } from "components";
import { IGraphqlVariables, IBaseTranslation } from "corede-common";
import { INotificationChannel, IOrganizationSettingsUpdateInput, LegalAgreementType, NotificationChannelType } from "corede-common-cocrm";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOrganizationDetailOwnQuery, useOrganizationSettingsUpdateOwnMutation } from "../context/setting.api";
import { convertEnums } from "localization";
import { Add, BusinessCenterOutlined, DeleteOutline, ExpandMore, RefreshOutlined } from "@mui/icons-material";
import { useLegalAgreementListQuery } from "apps/crm/domains/05-preSale/subdomains/legalAgreement";
import ReactQuill from "react-quill-new";
import { enqueueSnackbar } from "notistack";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useSearchParams } from "react-router-dom";
import EmptyState from "components/emptyState/EmptyState";
import { useTranslation } from "react-i18next";


const Crm = () => {

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedTabName, setSelectedTabName] = useState("");
  const [tabLabels, setTabLabels] = useState([""]);
  const { data: organizationDetailOwnData, isLoading: organizationDetailOwnLoading, error: organizationDetailOwnError } = useOrganizationDetailOwnQuery({});
  const [organizationSettingsUpdateOwn, { data: organizationSettingsUpdateOwnData, isLoading: organizationSettingsUpdateOwnLoading, error: organizationSettingsUpdateOwnError }] = useOrganizationSettingsUpdateOwnMutation();
  const status = organizationDetailOwnData?.status;


  //Common

  useEffect(() => {
    if (organizationSettingsUpdateOwnData) {
      enqueueSnackbar(t("settings.settings.settings.updateSuccess"), { variant: 'success' });
    }
  }, [organizationSettingsUpdateOwnData]);

  useEffect(() => {
    switch (status) {
      case "restricted":
        setTabLabels(["Crm"])
        break;
      case "active":
        setTabLabels([
          t("settings.settings.settings.lead"),
          t("settings.settings.settings.customer"),
          t("settings.settings.settings.estimate"),
          t("settings.settings.settings.proposal"),
          t("settings.settings.settings.invoice"),
          t("settings.settings.settings.project"),
          t("settings.settings.settings.task"),
          t("settings.settings.settings.ticket"),
          t("settings.settings.settings.fileManagement"),
          t("settings.settings.settings.calendar"),
          t("settings.settings.settings.notification"),
        ])
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case "estimate":
          setSelectedTabName(t("settings.settings.settings.estimate"));
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab]);


  return <Stack direction={"column"} width={"100%"} spacing={2} pt={3}>
    <Stack direction={"row"} sx={{
      overflow: "hidden", borderRadius: "30px",
      px: 5
    }}
    >
      <MyTab
        type='settings'
        labels={tabLabels}
        setSelectedTab={setSelectedTabName}
        selectedTab={selectedTabName} />
    </Stack>

    {selectedTabName === t("settings.settings.settings.lead") && <LeadTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.customer") && <CustomerTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.estimate") && <EstimateTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.proposal") && <ProposalTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.invoice") && <InvoiceTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.project") && <ProjectTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.task") && <TaskTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.ticket") && <TicketTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.fileManagement") && <FileManagementTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.calendar") && <CalendarTab selectedTabName={selectedTabName} />}
    {selectedTabName === t("settings.settings.settings.notification") && <NotificationTab selectedTabName={selectedTabName} />}

  </Stack >;
};


const LeadTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.lead")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.lead")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noLeadContent1")}
                content2={t("settings.settings.settings.noLeadContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const CustomerTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.customer")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.customer")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noCustomerContent1")}
                content2={t("settings.settings.settings.noCustomerContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const EstimateTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.estimate")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.estimate")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noEstimateContent1")}
                content2={t("settings.settings.settings.noEstimateContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const ProposalTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.proposal")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.proposal")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noProposalContent1")}
                content2={t("settings.settings.settings.noProposalContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const InvoiceTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.invoice")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.invoice")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noInvoiceContent1")}
                content2={t("settings.settings.settings.noInvoiceContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const ProjectTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.project")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.project")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noProjectContent1")}
                content2={t("settings.settings.settings.noProjectContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const TaskTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.task")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.task")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noTaskContent1")}
                content2={t("settings.settings.settings.noTaskContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const TicketTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.ticket")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.ticket")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noTicketContent1")}
                content2={t("settings.settings.settings.noTicketContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const FileManagementTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.fileManagement")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.fileManagement")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noFileManagementContent1")}
                content2={t("settings.settings.settings.noFileManagementContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const CalendarTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.calendar")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.calendar")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noCalendarContent1")}
                content2={t("settings.settings.settings.noCalendarContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

const NotificationTab = (props: { selectedTabName: string }) => {

  const { t } = useTranslation();

  return <Grid container spacing={2} px={2}>
    <SettingsTitle selectedTabName={t("settings.settings.settings.notification")} />
    <Grid item xs={12}>
      <Grow in={props.selectedTabName === t("settings.settings.settings.notification")} timeout={500}>
        <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
          <Grid container >
            <Grid item xs={12} mb={2}>
              <EmptyState
                content1={t("settings.settings.settings.noNotificationContent1")}
                content2={t("settings.settings.settings.noNotificationContent2")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </Grid>
  </Grid>
}

export default Crm;