

import { LeadRouter } from "../subdomains/lead/routes/LeadRouter";
import { LeadDomainLayout } from "../layouts";
import { leadDomainRoutes } from "./lead.base.route";
import { Route, Routes } from "react-router-dom";
import { LeadStatusesRouter } from "../subdomains/leadStatus/routes/LeadStatusesRouter";
import { LeadFormsRouter } from "../subdomains/leadForm/routes/LeadFormsRouter";
import { LeadSourcesRouter } from "../subdomains/leadSource/routes/LeadSourcesRouter";
 
export const LeadDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LeadDomainLayout />}>
        <Route index element={<LeadRouter />} />
        <Route
          path={`${leadDomainRoutes.subdomains.lead.base}/*`}
          element={<LeadRouter />}
        />
        <Route
          path={`${leadDomainRoutes.subdomains.status.base}/*`}
          element={<LeadStatusesRouter />}
        />
        <Route
          path={`${leadDomainRoutes.subdomains.form.base}/*`}
          element={<LeadFormsRouter />}
        />
        <Route
          path={`${leadDomainRoutes.subdomains.source.base}/*`}
          element={<LeadSourcesRouter />}
        />
      </Route>
    </Routes>
  );
};
