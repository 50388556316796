/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  IconButton,
  Typography,
  Button,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ArrowBackIos, Update } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { listLeadRoute } from '../../../../routes/lead.base.route';
import { useLeadDetailQuery } from '../../context/lead.api';
import BgUserDetail from 'assets/images/bg-user-detail.jpg';
import { LeadDetailProfileSectionComponent } from './LeadDetail-profileSection.component';
import {
  EstimateProspectTargetEntityRef,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { MyTab } from 'components';
import { DefaultEmptyTabComponent } from 'apps/crm/components/tabs/DefaultTab.emptyState.component';
import EstimateListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/estimate/components/EstimateListTabComponent';
import ProposalListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/proposal/components/ProposalListTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import { LeadContactHistoryListTabSectionComponent } from '../leadContact/LeadContactHistory-tabSection.component';
import { LeadDetailTabInfoSectionComponent } from './LeadDetail-tab-infoSection.component';
import LeadUpdateDrawerWrapper from '../update/LeadUpdateWrapper';
import { DefaultDetailTabsComponent } from 'apps/crm/components/detail/DefaultDetail.tabs.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useLeadDocumentCreateManyMutation,
  useLeadDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { Add } from '@mui/icons-material';
import { Iconify } from 'components';
import { OverlayType } from 'components/dialog/ActionDialog';
import { LeadDetailStatusComponent } from './LeadDetail-rightPanel-status.component';

/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface

 */
export const LeadDetail = memo(() => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listLeadRoute());
  }

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="hugeicons:contact" width={18} />,
    <Iconify icon="teenyicons:cost-estimate-outline" width={18} />,
    <Iconify icon="nimbus:money" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="hugeicons:task-01" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  // useStates
  const tabNames = new Map<string, string>([
    ['info', t('crm.info')],
    ['contactHistory', t('crm.lead.lead.contactHistory')],
    ['estimates', t('crm.estimates')],
    ['proposals', t('crm.proposals')],
    ['notes', t('crm.notes')],
    ['reminders', t('crm.reminders')],
    ['tasks', t('crm.tasks')],
    ['documents', t('crm.documentLabel')],
    ['activities', t('crm.activities')],
  ]);

  const tabKeys = Array.from(tabNames.keys());
  const tabValues = Array.from(tabNames.values());
  const firstSelectedTab = downMd ? '' : tabNames.get(tabKeys.at(0) ?? '');

  // useStates
  const [selectedTabName, setSelectedTabName] = useState(firstSelectedTab);
  const [openLeadUpdateDrawer, setOpenLeadUpdateDrawer] = useState(false);

  // queries
  const { data: leadData } = useLeadDetailQuery({
    input: {
      _id: id || '',
    },
  });

  return (
    <Grid item xs={12}>
      {!downMd && <HeaderBase title={t('crm.lead.lead.detail')} showBackButton={true} />}

      <LeadDetailStatusComponent leadDetailData={leadData} />

      <Stack
        direction={'column'}
        mt={2}
        borderRadius={3}
        sx={{ boxShadow: downMd ? 'none' : '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
      >
        {!downMd && (
          <Box
            width={'100%'}
            sx={{
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              height: '72px',
              bgcolor: 'secondary.extraLight',
            }}
          />
        )}
        <Stack direction={'column'} gap={1}>
          {!downMd && <LeadDetailProfileSectionComponent leadData={leadData} />}

          {downMd && (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Button
                sx={{
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  padding: 0,
                  minWidth: '24px',
                }}
                onClick={() => setSelectedTabName('')}
              >
                <Iconify icon={'iconamoon:arrow-left-2-light'} width={18} />
              </Button>

              <Typography variant="body1">
                {selectedTabName !== '' ? selectedTabName : t('crm.lead.lead.detail')}
              </Typography>
            </Stack>
          )}

          {selectedTabName === '' && (
            <MenuList>
              {tabValues.map((tabName, index) => (
                <MenuItem
                  sx={{
                    width: '100%',
                    borderRadius: '6px',
                    boxShadow: '0px 2px 5px 0px rgba(35, 32, 32, 0.1)',
                    border: '1px solid',
                    borderColor: 'divider',
                    boxSizing: 'border-box',
                    p: 1.5,
                    mb: 1,
                  }}
                  onClick={() => setSelectedTabName(tabName)}
                >
                  <ListItemIcon
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText>
                    {' '}
                    <Typography variant="body1">{tabName}</Typography>
                  </ListItemText>
                  <Iconify icon={'iconamoon:arrow-right-2-light'} width={18} />
                </MenuItem>
              ))}
            </MenuList>
          )}

          {!downMd && (
            <Stack width={'100%'} direction={'row'} justifyContent={'center'}>
              <MyTab
                labels={tabValues}
                setSelectedTab={setSelectedTabName}
                selectedTab={selectedTabName}
                icons={[
                  <Iconify icon="fluent:info-32-regular" width={18} />,
                  <Iconify icon="hugeicons:contact" width={18} />,
                  <Iconify icon="teenyicons:cost-estimate-outline" width={18} />,
                  <Iconify icon="nimbus:money" width={18} />,
                  <Iconify icon="carbon:product" width={18} />,
                  <Iconify icon="iconoir:bell" width={18} />,
                  <Iconify icon="hugeicons:task-01" width={18} />,
                  <Iconify icon="fluent:document-32-regular" width={18} />,
                  <Iconify icon="tabler:activity" width={18} />,
                ]}
              />
            </Stack>
          )}

          {downMd && <Divider />}
        </Stack>

        {selectedTabName !== '' && (
          <DefaultDetailTabsComponent
            tabNames={tabNames}
            selectedTabName={selectedTabName}
            entityData={leadData}
          >
            <LeadDetailTabInfoSectionComponent
              leadData={leadData}
              setOpenLeadUpdateDrawer={setOpenLeadUpdateDrawer}
              tabKey="info"
              name="crm.info"
            />

            <LeadContactHistoryListTabSectionComponent
              contactHistoryList={leadData?.contactHistory ?? []}
              tabKey="contactHistory"
              name="crm.lead.lead.contactHistory"
              entityData={leadData}
            />

            <EstimateListTabSectionComponent
              prospectTargetId={leadData?._id ?? ''}
              prospectTargetEntityRef={EstimateProspectTargetEntityRef.Lead}
              tabKey="estimates"
              name="crm.estimates"
            />

            <ProposalListTabSectionComponent
              prospectTargetId={leadData?._id ?? ''}
              prospectTargetEntityRef={ProposalProspectTargetEntityRef.Lead}
              tabKey="proposals"
              name="crm.proposals"
            />


<NoteListTabComponent
              targetId={leadData?._id ?? ''}
              targetEntityRef={NoteTargetType.Lead}
              targetDisplayName={leadData?.fullName ?? ''}
              tabKey="notes"
              name="crm.notes"
            />

            <ReminderListTabComponent
              targetId={leadData?._id ?? ''}
              targetEntityRef={ReminderTargetType.Lead}
              targetDisplayName={leadData?.fullName ?? ''}
              tabKey="reminders"
              name="crm.reminders"
              overlayType={OverlayType.drawer}
            />

            <TaskListTabComponent
              targetId={leadData?._id ?? ''}
              targetEntityRef={TaskTargetType.Lead}
              tabKey={'tasks'}
              name={'crm.tasks'}
              overlayType={OverlayType.drawer}
            />

            <DocumentListTabComponent
              targetId={leadData?._id ?? ''}
              tabKey={'documents'}
              name={'crm.documents'}
              useDocumentCreateManyMutation={useLeadDocumentCreateManyMutation}
              useDocumentAddManyMutation={useLeadDocumentAddManyMutation}
            />

<DefaultEmptyTabComponent
              // title={t('crm.activities')}
              title={t('')}
              emptyStateContent={t('crm.noActivityContent1')}
              tabKey="activities"
              nameTranslation={t('crm.activities')}
            />
          </DefaultDetailTabsComponent>
        )}

        <Overlays
          openLeadUpdateDrawer={openLeadUpdateDrawer}
          setOpenLeadUpdateDrawer={setOpenLeadUpdateDrawer}
          leadData={leadData}
        />
      </Stack>
    </Grid>
  );
});

const Overlays = ({ openLeadUpdateDrawer, setOpenLeadUpdateDrawer, leadData }: any) => {
  return (
    <>
      <LeadUpdateDrawerWrapper
        open={openLeadUpdateDrawer}
        setOpen={setOpenLeadUpdateDrawer}
        selectedLead={leadData}
      />
    </>
  );
};
