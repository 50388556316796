/* eslint-disable react-hooks/exhaustive-deps */
import { IEstimateDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { EstimateDetailDrawerRightPanelHeaderComponent } from './EstimateDetailDrawer-rightPanel-header.component';
import { EstimateDetailDrawerRightPanelTabsComponent } from './EstimateDetailDrawer-rightPanel.tabs.component';

export interface IEstimateDetailDrawerRightPanelComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

export const EstimateDetailDrawerRightPanelComponent = (
  props: IEstimateDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <EstimateDetailDrawerRightPanelHeaderComponent estimateDetailData={props.estimateDetailData} />

      {/* Tabs Section */}
      <EstimateDetailDrawerRightPanelTabsComponent estimateDetailData={props.estimateDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
