import { getTranslatedText } from 'localization';
import * as Yup from 'yup';

export class ValidationHelper {
  public static RequiredString(message: string = getTranslatedText('nameIsRequired')) {
    return Yup.string().required(message);
  }

  public static NotRequiredString() {
    return Yup.string().notRequired();
  }

  public static RequiredObject(message: string) {
    return Yup.object().required(message);
  }

  public static NotRequiredObject() {
    return Yup.object().notRequired();
  }

  public static RequiredDate(message: string) {
    return Yup.date().required(message);
  }

  public static RequiredArray(message: string) {
    return Yup.array().required(message);
  }

  public static RequiredBoolean(message: string) {
    return Yup.boolean().required(message);
  }

  public static RequiredBooleanTrue(message: string) {
    return Yup.boolean().oneOf([true], message);
  }

  public static NotRequiredDate() {
    return Yup.date().notRequired();
  }

  public static RequiredInteger(message: string) {
    return Yup.number().integer().required(message);
  }

  public static NotRequiredInteger() {
    return Yup.number().integer().notRequired();
  }

  public static RequiredNumber(message: string) {
    return Yup.number().required(message);
  }

  public static NotRequiredNumber() {
    return Yup.number().notRequired();
  }

  public static NotRequiredArray() {
    return Yup.array().notRequired();
  }

  public static NotRequiredBoolean() {
    return Yup.boolean().notRequired();
  }

  public static RequiredEmail(
    invalidMessage: string = getTranslatedText('emailIsInvalid'),
    requiredMessage: string = getTranslatedText('emailIsRequired'),
  ) {
    return Yup.string().email(invalidMessage).required(requiredMessage);
  }

  public static RequiredWebSite(
    invalidMessage: string = getTranslatedText('websiteIsInvalid'),
    requiredMessage: string = getTranslatedText('websiteIsRequired'),
  ) {
    return Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|localhost)([\/\w \.-]*)*\/?$/,
        invalidMessage,
      )
      .required(requiredMessage);
  }

  public static NotRequiredWebSite(invalidMessage: string = getTranslatedText('websiteIsInvalid')) {
    return Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|localhost)([\/\w \.-]*)*\/?$/,
        invalidMessage,
      )
      .notRequired();
  }

  public static RequiredPassword(
    invalidMessage: string = getTranslatedText('passwordIsInvalid'),
    requiredMessage: string = getTranslatedText('passwordIsRequired'),
  ) {
    return Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+,.\\\/;':"-])[A-Za-z\d!@#$%^&*()_+,.\\\/;':"-]{8,}$/,
        // /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[!@#$%^&()+,.\\\/;':"-])[A-Za-z\d!@#$%^&()+,.\\\/;':"-]{8,}$/,
        invalidMessage,
      )
      .required(requiredMessage);
  }

  public static RequiredPasswordAgain(
    invalidMessage: string = getTranslatedText('passwordAgainIsInvalid'),
    requiredMessage: string = getTranslatedText('passwordIsRequired'),
  ) {
    return Yup.string()
      .oneOf([Yup.ref('password'), ""], invalidMessage)
      .required(requiredMessage);
  }

  public static NotRequiredEmail() {
    return Yup.number().notRequired();
  }

  public static BuildObjectValidationSchema(objectValidation: any) {
    return Yup.object().shape(objectValidation);
  }

  public static MinValueString(value: number, message: string) {
    return `min(${value}, ${message})`;
  }
}
