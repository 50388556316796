/* eslint-disable react-hooks/exhaustive-deps */
import { ITaskDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { TaskDetailDrawerRightPanelHeaderComponent } from './TaskDetailDrawer-rightPanel-header.component';
import { TaskDetailDrawerRightPanelTabsComponent } from './TaskDetailDrawer-rightPanel.tabs.component';

export interface ITaskDetailDrawerRightPanelComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
}

export const TaskDetailDrawerRightPanelComponent = (
  props: ITaskDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <TaskDetailDrawerRightPanelHeaderComponent taskDetailData={props.taskDetailData} />

      {/* Tabs Section */}
      <TaskDetailDrawerRightPanelTabsComponent taskDetailData={props.taskDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
