/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';

export interface IInvoiceDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IInvoiceDetailResult> {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  tabKey: 'additionalInfo';
  name: 'crm.additionalInfo';
}

export const InvoiceDetailDrawerTabAdditionalInfoComponent = (
  props: IInvoiceDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const totalPrice = props.invoiceDetailData?.totalPrice;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';
  const { useLegalNDA, useLegalTermAndCond } = props.invoiceDetailData || {};
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('crm.sale.invoice.additionalInfoTitle')}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Legal NDA */}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          {t('crm.sale.invoice.useLegalNDA')}
        </Typography>
        <Typography variant="body2">
          {useLegalNDA ? t('crm.sale.invoice.yes') : t('crm.sale.invoice.no')}
        </Typography>
      </Box>

      {/* Terms and Conditions */}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          {t('crm.sale.invoice.useLegalTermAndCond')}
        </Typography>
        <Typography variant="body2">
          {useLegalTermAndCond ? t('crm.sale.invoice.yes') : t('crm.sale.invoice.no')}
        </Typography>
      </Box>
    </Box>
  );
};
