import userLocale from "../subdomains/user/locales/tr";
import departmentLocale from "../subdomains/department/locales/tr";
import permissionLocale from "../subdomains/permission/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  user: {
    ...userLocale,
  },
  department: {
    ...departmentLocale,
  },
  permission: {
    ...permissionLocale
  }

  

};

export default locale;
