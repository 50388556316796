import { IMenuItem } from 'interfaces/menu-item.interface';
import { useTranslation } from 'react-i18next';
import { basePricingRoute } from '../routes/pricing.base.route';

export const usePricingDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t('pricing'),
    path: basePricingRoute(),
    children: [],
  };
};
