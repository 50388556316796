/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import { Update } from "@mui/icons-material";
import DetailBox from "components/box/DetailBox";
import { IUserDetailResult } from "corede-common-cocrm";
import { getTranslatedEnumValue } from "localization";
import { Gender, Language } from "corede-common";
import { t } from "i18next";
import { DefaultTabComponentProps } from "../../../../../../components/tabs/DefaultTabs.component";
import moment from "moment";

interface IUserDetailTabInfoSectionComponent
  extends DefaultTabComponentProps<IUserDetailResult> {
  userData: IUserDetailResult | undefined;
  setOpenUserUpdateDrawer: (value: boolean) => void;
  tabKey: "info";
  name: "crm.info";
}

export const UserDetailTabInfoSectionComponent = (
  props: IUserDetailTabInfoSectionComponent
) => {

  return (
    <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12}>
        <DetailBox
          title={t("crm.about")}
          rightButton={{
            title: t("update"),
            icon: <Update sx={{ mr: 1 }} />,
            onClick: () => props.setOpenUserUpdateDrawer(true),
          }}
        >
          <Grid item display="flex" flexDirection="column" gap={1}>
            {[
              t("crm.email"),
              t("crm.phoneNumber"),
              t("crm.country"),
              t("crm.city"),
              t("crm.address"),
              t("crm.birthDate"),
              t("crm.gender"),
              t("crm.language"),
              t("crm.description"),
              t("crm.organizationalChart.user.lastLoginDate"),
            ].map((label, index) => (
              <Box
                display="flex"
                alignItems="center"
                minHeight={35}
                key={index}
              >
                <Typography fontSize={"14px"} fontWeight={600}>
                  {label}
                </Typography>
              </Box>
            ))}
          </Grid>
          <Grid item display="flex" flexDirection="column" gap={1}>
            {[
              props.userData?.email,
              (props.userData as any)?.phoneNumber,
              props.userData?.country,
              props.userData?.city,
              props.userData?.address,
              props.userData?.birthDate && moment(props.userData?.birthDate).format("DD/MM/YYYY"),
              props.userData?.gender ? getTranslatedEnumValue(props.userData?.gender as Gender) : "",
              props.userData?.language ? getTranslatedEnumValue(props.userData?.language as Language) : "",
              props.userData?.description,
              props.userData?.lastLoginDate && moment(props.userData?.lastLoginDate).format("DD/MM/YYYY"),
            ].map((value, index) => (
              <Box
                display="flex"
                alignItems="center"
                minHeight={35}
                key={index}
              >
                <Typography fontSize={"14px"} fontWeight={400}>
                  : {value}
                </Typography>
              </Box>
            ))}
          </Grid>
        </DetailBox>
      </Grid>
    </Grid>
  );
};
