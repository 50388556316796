import { Stack, Box, Select, MenuItem, TablePaginationProps } from '@mui/material';
import { PaginationComponent } from './PaginationComponent';
import { IPaginationComponentProps } from './PaginationComponent.interface';
import { defaultPageSize } from './PaginationComponent.constant';
import { useTranslation } from 'react-i18next';

// React.JSXElementConstructor<GridSlotProps["pagination"]> | null

export const CustomPagination = (props: IPaginationComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const start = (Number(props.page) - 1) * Number(props.pageSize) + 1;
  const end = Math.min(Number(props.page) * Number(props.pageSize), Number(props.count));
  const total = props.count;

  return (
    <Stack
      direction={'row'}
      width={'100%'}
      px={2}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Stack direction={'row'} gap={1} justifyContent={'flex-end'} alignItems={'center'}>
        <Select
          name="pageSize"
          value={props.pageSize ?? defaultPageSize}
          size="small"
          sx={{
            borderRadius: '4px',
            '& .MuiSelect-select': {
              padding: '4px 28px 4px 4px !important',
            },
            '& .MuiSvgIcon-root': {
              width: '18px',
            },
          }}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                page: 1,
                pageSize: Number(e.target.value),
              });
            }
          }}
        >
          <MenuItem sx={{ fontSize: '12px' }} value={1}>
            1
          </MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value={5}>
            5
          </MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value={10}>
            10
          </MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value={50}>
            50
          </MenuItem>
        </Select>
        {/* {t('show')} */}
        {/* //  TODO: get numbers of pagination datas */}
        {t('showing', { start, end, total })}
      </Stack>
      <PaginationComponent {...props} />
    </Stack>
  );
};
