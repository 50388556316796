import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { IUser, IUserListResult } from 'corede-common-cocrm';

const AvatarGroupBase = (props: {
  userList: IUserListResult[] | undefined | IUser[];
  setSelectedUser?: (user: any) => void;
  setOpenUserDetail?: (open: boolean) => void;
  width?: number;
  max?: number;
}) => {
  return (
    <AvatarGroup
      max={props.max ?? 5}
      sx={{
        '& .MuiAvatar-root': {
          border: '2px solid white',
          width: props.width ?? 35,
          height: props.width ?? 35,
          fontSize: '14px',
          backgroundColor: 'background.main',
        },
        '& .MuiAvatarGroup-avatar:nth-of-type(1)': {
          zIndex: 4,
        },
        '& .MuiAvatarGroup-avatar:nth-of-type(2)': {
          zIndex: 3,
        },
        '& .MuiAvatarGroup-avatar:nth-of-type(3)': {
          zIndex: 2,
        },
        '& .MuiAvatarGroup-avatar:nth-of-type(4)': {
          zIndex: 1,
        },
      }}
    >
      {props.userList?.map((user: any, index: number) => (
        <Tooltip
          title={user?.name + ' ' + user?.surname}
          key={index}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            props.setSelectedUser && props.setSelectedUser(user);
            props.setOpenUserDetail && props.setOpenUserDetail(true);
          }}
        >
          <Avatar alt={user?.name} src={user?.profileImage?.thumbnailPublicUrl} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default AvatarGroupBase;
