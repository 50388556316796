import {

  productListQuery,
  IProductListInput,
  IProductListRequest,
  IProductListResponse,
  IProductListResult,

  productDetailQuery,
  IProductDetailRequest,
  IProductDetailInput,
  IProductDetailResponse,
  IProductDetailResult,

  productCreateQuery,
  productDeleteQuery,
  IProductCreateInput,
  IProductCreateRequest,
  IProductCreateResponse,
  IProductCreateResult,
  IProductDeleteInput,
  IProductDeleteRequest,
  IProductDeleteResponse,
  IProductDeleteResult,
  IProductUpdateFilterInput,
  IProductUpdateInput,
  IProductUpdateRequest,
  IProductUpdateResponse,
  IProductUpdateResult,
  productUpdateQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { productsServicesTags } from "./productsService.tags";
import { IGraphqlVariables } from "corede-common";

export const productApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    productList: builder.query<
      IProductListResult,
      IGraphqlVariables<IProductListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductListRequest,
        IProductListResponse,
        IProductListResult,
        IProductListInput
      >({
        query: productListQuery,
        providesTags: [
          productsServicesTags.productList,
          productsServicesTags.productDetail,
        ],
      })
    ),

    productDetail: builder.query<
      IProductDetailResult,
      IGraphqlVariables<IProductDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductDetailRequest,
        IProductDetailResponse,
        IProductDetailResult,
        IProductDetailInput
      >({
        query: productDetailQuery,
        providesTags: [
          productsServicesTags.productDetail,
        ],
      })
    ),

    // mutation
    productUpdate: builder.mutation<
      IProductUpdateResult,
      IGraphqlVariables<IProductUpdateInput, IProductUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductUpdateRequest,
        IProductUpdateResponse,
        IProductUpdateResult,
        IProductUpdateInput,
        IProductUpdateFilterInput
      >({
        query: productUpdateQuery,
        invalidatesTags: [
          productsServicesTags.productList,
          productsServicesTags.productDetail,
        ],
      })
    ),

    productCreate: builder.mutation<
      IProductCreateResult,
      IGraphqlVariables<IProductCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductCreateRequest,
        IProductCreateResponse,
        IProductCreateResult,
        IProductCreateInput
      >({
        query: productCreateQuery,
        invalidatesTags: [
          productsServicesTags.productDetail,
          productsServicesTags.productList,
        ],
      })
    ),

    productDelete: builder.mutation<
      IProductDeleteResult,
      IGraphqlVariables<IProductDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProductDeleteRequest,
        IProductDeleteResponse,
        IProductDeleteResult,
        IProductDeleteInput
      >({
        query: productDeleteQuery,
        invalidatesTags: [
          productsServicesTags.productList,
        ],
      })
    ),
  }),
});

export const {
  useProductListQuery,
  useLazyProductListQuery,

  useProductDetailQuery,
  useLazyProductDetailQuery,

  useProductUpdateMutation,
  useProductCreateMutation,
  useProductDeleteMutation,
} = productApi;
