
import documentLocale from "../subdomains/document/locales/en";

const locale = {

  document: {
    ...documentLocale,
  },
};

export default locale;