import estimateLocale from '../subdomains/estimate/locales/tr';
import proposalLocale from '../subdomains/proposal/locales/tr';

const locale = {
  estimate: {
    ...estimateLocale,
  },
  proposal: {
    ...proposalLocale,
  },
};

export default locale;
