import { Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add,ViewList, ViewWeek } from '@mui/icons-material';
import { useTaskListQuery } from '../../context/task.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskCreateDrawerWrapper from '../create/TaskCreateOverlay';
import { OverlayType } from 'components/dialog/ActionDialog';
import TaskListGridComponent from './TaskList-grid.component';
import TaskListKanbanComponent from './TaskList-kanban.component';

const TaskList = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [openTaskCreateDrawer, setOpenTaskCreateDrawer] = useState(false);

  const [taskListType, setTaskListType] = useState('kanban');

  // queries
  const { data: taskListData, isLoading: taskListLoading } = useTaskListQuery({});

  let totalTaskCount = taskListData?.count ?? 0;
  let isEmpty = totalTaskCount === 0;


  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenTaskCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            {t(`crm.task.task.title`)}

            <ToggleButtonGroup
              value={taskListType}
              exclusive
              size="small"
              defaultValue={'list'}
              onChange={(e, value) => {
                if (value !== null) {
                  setTaskListType(value as 'list' | 'kanban');
                }
              }}
            >
              <ToggleButton value="list" selected={taskListType === 'list'}>
                <ViewList fontSize="small" />
              </ToggleButton>
              <ToggleButton value="kanban" selected={taskListType === 'kanban'}>
                <ViewWeek fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.task.task.create`),
          onClick: () => setOpenTaskCreateDrawer(true),
        }}
      />

      {taskListType === 'list' ? (
        <TaskListGridComponent setOpenTaskCreateDrawer={setOpenTaskCreateDrawer} />
      ) : taskListType === 'kanban' ? (
        <TaskListKanbanComponent setOpenTaskCreateDrawer={setOpenTaskCreateDrawer} />
      ) : null}

      <TaskCreateDrawerWrapper
        open={openTaskCreateDrawer}
        setOpen={setOpenTaskCreateDrawer}
        overlayType={OverlayType.drawer}
        targetId={''}
        targetEntityRef={undefined}
      />
    </Grid>
  );
});

export default TaskList;
